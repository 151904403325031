/**
 * Proposal Item
 *
 * Attila Németh, UBG
 * 02.05.2019
 */

import {UbgProposalActionType} from '../model/taxonomies/proposal_action_type';

export class UbgProposalItem {
  type: string = 'ubg_proposal_item--ubg_proposal_item';
  id?: string;
  attributes: {
    // "from" and "to" are not required for antrag type sonstige
    from?: number,
    to?: number,
    content: any, // content can be just string or an object contain value, process and format properties
    keine_begruendung?: boolean
    reason?: { // proposal begrundung
      value: string
      format?: string
    }
  };
  relationships: {
    action: {
      data: UbgProposalActionType,
    }
  };
}
