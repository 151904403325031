/**
 * Proposal bearbeiten
 * 
 * Attila Németh, UBG
 * 28.05.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {UbgProposalStructured} from '../../../model/proposal_structured';
import {UbgProposal} from '../../../model/proposal';
import {UbgProposalService} from '../../../services/proposal.service';

@Component({
  selector: 'ubg-proposal-edit',
  templateUrl: './edit.component.html',
})
export class UbgProposalEditComponent {

  @Input() proposal: UbgProposalStructured
  @Output() changed = new EventEmitter

  constructor(private service: UbgProposalService) {}
  
  itemChanged() {
    this.service.get(this.proposal.id).then((response: UbgProposal) => {
      response.attributes.custom_title = this.proposal.attributes.custom_title;
      response.attributes.org_user_name = this.proposal.attributes.org_user_name;
      this.service.update(response).then(() => {
        this.changed.emit();
      });
    });
  }
  
}
