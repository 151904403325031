/**
 * Seitenbar-Modul
 * 
 * Attila Németh, UBG
 * 15.03.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {UbgSidebarButtonComponent} from './components/button/button.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    UbgSidebarButtonComponent,
  ],
  exports: [
    UbgSidebarButtonComponent,
  ],
  entryComponents: [
  ],
})
export class UbgSidebarModule {

  constructor() {}

}

