/**
 * Votum
 * 
 * Attila Németh, UBG
 * 08.08.2019
 */
 
import {UbgProposal} from './proposal';
import {UbgZiffer} from './ziffer';
import {UbgEmpfehlungUberweisung} from './taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungType} from './empfehlung_type';

export class UbgVotum {
  
  id: string
  // Empfehlung hat Bundles, so der Type kann verschiedene Werte haben: 'ubg_empfehlung--{bundle}'
  type: string
  attributes: {
    title: string
    // Der Text wird automatisch erstellt
    text: {
      value: string
      format: string
    }
    empfehlung?: {
      value: string
      format: string
    }
    fassung?: {
      value: string
      format: string
    }
    fassung_titel?: string
    level: number
    statusLabel?: string
    typeLabel?: string
  }
  relationships: {
    proposals: {
      data: Array<UbgProposal>
    }
    ziffer?: {
      data: UbgZiffer
    }
    uberweisung_an?: {
      data: UbgEmpfehlungUberweisung
    }
    ubg_empfehlung_type: {
      data: UbgEmpfehlungType
    }
  }
  
}