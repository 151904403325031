import { Injectable } from '@angular/core';
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UBGBeschlussModel} from '../model/beschluss.model';
import {MatSnackBar} from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class UbgBeschlussService {

  constructor(private contenta: UbgContentaService) { }


  getByAntrag (antragId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/beschluss/beschluss/?filter[antrag.id]=' + antragId ).then((response: {
        data: UBGBeschlussModel
      }) => {
        resolve(response.data[0]); // it is just one but in an array because of filter
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getPreparedByAntrag(antragId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/antrag/' + antragId + '/beschluss').then((response: {
        data: UBGBeschlussModel
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  print(beschluss: UBGBeschlussModel, text: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/beschluss/' + beschluss.id + '/print'
      this.contenta.postGetFile(path, {
        text: text,
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  msWord(beschluss: UBGBeschlussModel,text: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/beschluss/' + beschluss.id + '/msword'
      this.contenta.postGetFile(path, {
        text: text,
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  update(beschluss: UBGBeschlussModel) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/beschluss/beschluss/' + beschluss.id, {
        data: beschluss
      }).then((response: {
        data: UBGBeschlussModel
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}
