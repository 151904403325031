/**
 * Banner-Nachricht
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Component} from '@angular/core';

@Component({
  selector: 'ubg-banner-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class UbgBannerMessageComponent {
  
  constructor() {}
  
}