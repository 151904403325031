/**
 * Thema bearbeiten
 * 
 * Attila Németh, UBG
 * 26.07.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgThema} from '../../../../model/taxonomies/thema';

@Component({
  templateUrl: './edit.component.html',
})
export class UbgThemaEditDialogComponent {
  
  constructor(private dialogRef: MatDialogRef<UbgThemaEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                thema: UbgThema
              }) {}
 
}