/**
 * Statistik als selbständige Seite
 * 
 * Attila Németh, UBG
 * 11.09.2019
 */
 
import {Component, Input, OnInit} from '@angular/core';

import {ActivatedRoute} from '@angular/router';

import {UbgProject} from '../../../../model/project';
import {UbgProjectService} from '../../../../services/project_service';

@Component({
  templateUrl: './stat.page.component.html',
})
export class UbgProjectDashboardStatPageComponent implements OnInit {

  project: UbgProject = null

  constructor(private route: ActivatedRoute,
                private service: UbgProjectService,) {
    this.route.params.subscribe((params: {
      id: string
    }) => {
      if (params.id !== null) {
        this.service.setActiveProjectId(params.id);
        this.loadProject();
      }
    });
  }

  ngOnInit() {}
  
  loadProject() {
    this.service.get(this.route.snapshot.paramMap.get('id')).then((response: UbgProject) => {
      this.project = response;
    });
  }
  
}