/**
 * Offene Anträge
 * 
 * Attila Németh, UBG
 * 30.07.2019
 */
 
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material';

import {UbgProject} from '../../../../model/project';
import {UbgProposal} from '../../../../model/proposal';
import {UbgProposalStructured} from '../../../../model/proposal_structured';
import {UbgProjectService} from '../../../../services/project_service';
import {UbgProposalService} from '../../../../services/proposal.service';

@Component({
  selector: 'ubg-dashboard-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgProjectDashboardOpenComponent implements OnInit {

  @Input() project: UbgProject  
  @Input() proposals: Array<UbgProposalStructured>
  displayedColumns = ['title', 'org_user', 'custom_title', 'created', 'goto', 'process', 'votum', 'zuweisung']; 
  filterParams: {
    keinVotum: boolean
    keineZuweisung: boolean
  }
  sortParams: Sort = {
    active: null,
    direction: null
  }
  isLoading: boolean = false
  isPrinting: boolean = false
  
  constructor(private router: Router,
                private snackbar: MatSnackBar,
                private service: UbgProjectService,
                private proposalService: UbgProposalService) {
    this.filterParams = {
      keinVotum: true,
      keineZuweisung: true,
    };
  }
                
  ngOnInit() {}
  
  back() {
    this.router.navigate(['project/' + this.project.id]);
  }
  
  gotoProposal(proposal: UbgProposalStructured) {
    this.proposalService.getStructured(proposal.id).then((responseS: UbgProposalStructured) => {
      this.proposalService.get(proposal.id).then((response: UbgProposal) => {
        responseS.relationships.ziffer = response.relationships.ziffer;
        this.proposalService.setActiveProposal(responseS);
        this.router.navigate(['project/' + this.project.id], {
          queryParams: {
            antrag: response.relationships.antrag.data.id,
          }
        });
      });
    })
  }
  
  refresh() {
    this.isLoading = true;
    this.service.getAllProposals(this.project, this.filterParams.keinVotum, this.filterParams.keineZuweisung, this.sortParams.active, this.sortParams.direction).then((response: Array<UbgProposalStructured>) => {
      this.proposals = response;
      this.isLoading = false;
    }).catch(() => {
      this.proposals = [];
      this.isLoading = false;
    });
  }
  
  sortData(sort: Sort) {
    this.sortParams = sort;
    this.refresh();
  }
  
  print() {
    this.isPrinting = true;
    this.service.printAllProposals(this.project, this.filterParams.keinVotum, this.filterParams.keineZuweisung, this.sortParams.active, this.sortParams.direction).then((response: any) => {
      this.isPrinting = false;
      let fileName = 'Offene_Antraege.pdf';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.isPrinting = false;
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: OP-106', null, {
        duration: 8000,
      });
    });
  }
  
  getZuweisung(proposal: UbgProposalStructured): string {
    if (proposal.relationships.zustandigkeit === undefined) {
      return '';
    }
    if (proposal.relationships.zustandigkeit.data === undefined) {
      return '';
    }
    if (proposal.relationships.zustandigkeit.data === null) {
      return '';
    }
    let names: Array<string> = [];
    for (let i in proposal.relationships.zustandigkeit.data) {
      names.push(proposal.relationships.zustandigkeit.data[i].attributes.nachname + ', '
        + proposal.relationships.zustandigkeit.data[i].attributes.vorname);
    }
    return names.join(' | ');
  }

}    
