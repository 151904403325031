/**
 * Empfehlung-Überweisung-Element
 * 
 * Attila Németh, UBG
 * 13.06.2019
 */
 
import {Component, OnInit, Output, EventEmitter} from '@angular/core';

import {MatSelectChange} from '@angular/material/select';

import {UbgEmpfehlungUberweisung} from '../../../../../../../model/taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungUberweisungService} from '../../../../../../../services/taxonomies/empfehlung_uberweisung.service';
import {UbgAutocompleteOption} from '../../../../../../../../ubg_components/autocomplete/model/option';

@Component({
  selector: 'ubg-proposal-empfehlung-uberweisung',
  templateUrl: 'uberweisung.component.html',
})
export class UbgProposalEmpfehlungUberweisungComponent implements OnInit {
  
  @Output() uberweisungSelected = new EventEmitter();
  selectedUberweisungen: Array<UbgEmpfehlungUberweisung> = []
  allUberweisungen: Array<UbgEmpfehlungUberweisung> = []
  uberweisungOptions: Array<UbgAutocompleteOption> = []
  searchString: string = '';
  
  constructor(private uberweisungService: UbgEmpfehlungUberweisungService) {}
  
  ngOnInit() {
    this.uberweisungService.getAll().then((uberweisungen: Array<UbgEmpfehlungUberweisung>) => {
      this.allUberweisungen = uberweisungen;
      let newOptions: Array<UbgAutocompleteOption> = [];
      for (let i in uberweisungen) {
        newOptions.push({
          id: uberweisungen[i].id,
          label: uberweisungen[i].attributes.name,
        });
      }
      this.uberweisungOptions = newOptions;
    });
  }
  
  optionSelected(uberweisungId: string) {
    let newUberweisungen: Array<UbgEmpfehlungUberweisung> = [];
    for (let i in this.allUberweisungen) {
      if (this.allUberweisungen[i].id == uberweisungId) {
        this.searchString = this.allUberweisungen[i].attributes.name;
        newUberweisungen.push(this.allUberweisungen[i]);
      }
    }
    for (let i in this.selectedUberweisungen) {
      if (this.selectedUberweisungen[i].id !== uberweisungId) {
        newUberweisungen.push(this.selectedUberweisungen[i]);
      }
    }
    this.selectedUberweisungen = newUberweisungen;
    setTimeout(() => {
      this.searchString = '';
    }, 100);
    this.uberweisungSelected.emit(this.selectedUberweisungen);
  }
  
  selectSelected(event: MatSelectChange) {
    this.optionSelected(event.value);
  }
  
  removeUberweisung(uberweisung: UbgEmpfehlungUberweisung) {
    let newUberweisungen: Array<UbgEmpfehlungUberweisung> = [];
    for (let i in this.selectedUberweisungen) {
      if (this.selectedUberweisungen[i].id !== uberweisung.id) {
        newUberweisungen.push(this.selectedUberweisungen[i]);
      }
    }
    this.selectedUberweisungen = newUberweisungen;
    this.uberweisungSelected.emit(this.selectedUberweisungen);
  }

}