/**
 * Layout-Modul
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UbgLayoutComponent} from './components/layout/layout.component';
import {UbgLeftColumnComponent} from './components/column/left_column.component';
import {UbgRightColumnComponent} from './components/column/right_column.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UbgLayoutComponent,
    UbgLeftColumnComponent,
    UbgRightColumnComponent,
  ],
  exports: [
    UbgLayoutComponent,
    UbgLeftColumnComponent,
    UbgRightColumnComponent,
  ],
  entryComponents: [
  ],
})
export class UbgLayoutModule {

  constructor() {}

}
