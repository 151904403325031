/**
 * Gliederungen
 * 
 * Attila Németh, UBG
 * 10.04.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';
import {PageEvent} from '@angular/material/paginator';

import {UbgProject} from '../../../../model/project';
import {UbgProjectService} from '../../../../services/project_service';
import {UbgPriorityStructure} from '../../../../model/priority_structure';
import {UbgPriorityStructureService} from '../../../../services/priority_structure.service';
import {UbgProjectChangeStructureDialogComponent} from './dialogs/change/change.component';
import {UbgOrgUser} from '../../../../model/org_user';
import {UbgOrgUserService} from '../../../../services/org_user.service';
import {UbgAccessToken} from '../../../../model/access_token';
import {UbgAccessTokenService} from '../../../../services/access_token.service';
import {UbgOrgUserPasswordsDialogComponent} from './dialogs/passwords/passwords.component';
import {UBGAntragService} from '../../../../services/antrag.service';

@Component({
  templateUrl: './org_user.component.html',
  styleUrls: ['./org_user.component.scss']
})
export class UbgProjectOrgUserComponent implements OnInit {
  
  project: UbgProject
  orgUsers: Array<UbgOrgUser>
  orgUsersSelected: Array<UbgOrgUser>
  orgUsersChecked: {}
  orgUsersAllChecked: boolean = false
  orgUserPasswords: {}
  orgUsersEnabledSave: {}
  isLoading: boolean = false
  hasError: boolean = false
  displayedColumns = ['checkbox', 'title', 'lvkv', 'email', 'anrede', 'vorname', 'nachname', 'password', 'gruppe', 'operations']
//  searchString: string = ''
//  searchGroupId: string = ''
  limit: number = 50
  offset: number = 0
  orgUsersLength: number = 0
  filterParams: {
    name: string
    gruppe: string
  } = {
    name: '',
    gruppe: '',
  };
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgProjectService,
                private structure: UbgPriorityStructureService,
                private orgUser: UbgOrgUserService,
                private accessToken: UbgAccessTokenService,
                private antragService: UBGAntragService) {
    this.service.setActiveProjectId(this.route.snapshot.paramMap.get('id'));
    this.orgUsers = [];
    this.orgUsersSelected = [];
    this.orgUsersChecked = {};
    this.orgUsersEnabledSave = {};
  }
  
  ngOnInit() {
    this.loadProject();
  }
  
  loadProject() {
    this.isLoading = true;
    this.service.get(this.service.activeProjectId).then((response: UbgProject) => {
      this.project = response;
      this.structure.get(this.project.relationships.priority_structure.data.id).then((response: UbgPriorityStructure) => {
        this.project.relationships.priority_structure.data = response;
        this.loadOrgUsers();
      });
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  loadOrgUsers() {
    this.isLoading = true;
    this.orgUser.getbyStructure(this.project.relationships.priority_structure.data.id, this.limit, this.offset, this.filterParams).then((response: {
      data: Array<UbgOrgUser>
      meta: {
        length: number
      }
    }) => {
      this.orgUsers = response.data;
      this.orgUsersLength = response.meta.length;
      this.accessToken.getByProject(this.project).then((response: Array<UbgAccessToken>) => {
        this.orgUserPasswords = {};
        for (let i in response) {
          this.orgUserPasswords[response[i].relationships.org_user.data.id] = response[i].attributes.passwort;
        }
        this.orgUsersSelected = this.orgUsers;
        this.addEmptyOrgUser();
        this.offset = 0;
        this.isLoading = false;
      });
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    })
  }
  
  private addEmptyOrgUser(): void {
    let filterIsEmpty: boolean = true;
    for (let key in this.filterParams) {
      if (this.filterParams[key] !== '') {
        filterIsEmpty = false;
      }
    }
    if (filterIsEmpty) {
      let newOrgUser = new UbgOrgUser;
      newOrgUser.type = 'ubg_org_user--gliederung';
      newOrgUser.attributes = {
        title: '',
        lvkv_nummer: '',
        email: '',
        anrede: '',
        vorname: '',
        nachname: '',
      };      
      this.orgUsersSelected.push(newOrgUser);
    }
  }
  
  editStructure() {
    this.router.navigate(['struktur/' + this.project.relationships.priority_structure.data.id + '/' + this.project.id]);
  }
  
  changeStructure() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      structureId: '',
    }
    let formDialog = this.dialog.open(UbgProjectChangeStructureDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.structure.get(dialogConfig.data['structureId']).then((response: UbgPriorityStructure) => {
          this.project.relationships.priority_structure.data = response;
          this.update();
        }).catch(() => {
          this.snackbar.open('Die neue Struktur kann nicht angewendet werden, bitte versuchen Sie es später', null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  update() {
    this.isLoading = true;
    this.structure.update(this.project.relationships.priority_structure.data).then((response: UbgPriorityStructure) => {
      this.project.relationships.priority_structure.data = response;
      this.service.update(this.project).then((response: UbgProject) => {
        this.service.setActiveProjectId(response.id);
        this.loadProject();
      }).catch(() => {
        this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
          duration: 15000,
        });
      });
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
        duration: 15000,
      });
    });
  }
  
  validateOrgUser(orgUser: UbgOrgUser) {
    let orgUserId: string
    if (orgUser.id === undefined) {
      orgUserId = '-1';
    }
    else {
      orgUserId = orgUser.id;
    }
    this.orgUsersEnabledSave[orgUserId] = true;
    if (orgUser.attributes.title == null || orgUser.attributes.title == '') {
      this.orgUsersEnabledSave[orgUserId] = false;
    }
    if (orgUser.attributes.email !== undefined && orgUser.attributes.email !== '') {
      var re = /\S+@\S+\.\S+/;
      if (!re.test(orgUser.attributes.email)) {
        this.orgUsersEnabledSave[orgUserId] = false;
      }
    }
  }
  
  updateOrgUser(orgUser: UbgOrgUser) {
    if (orgUser.id === undefined) {
      orgUser.relationships = {
        struktur: {
          data: [this.project.relationships.priority_structure.data],
        }
      };
      console.info('Gliederung wird erstellt...');
      this.isLoading = true;
      this.orgUser.create(orgUser).then((response: UbgOrgUser) => {
        console.info('Gliederung wurde erstellt');
        this.orgUsersEnabledSave[response.id] = false;
        this.loadOrgUsers();
      }).catch(() => {
        this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
          duration: 15000,
        });
        this.isLoading = false;
      });
    }
    else {
      console.info('Gliederung wird aktualisiert...');
      this.isLoading = true;
      this.orgUser.update(orgUser).then((response: UbgOrgUser) => {
        console.info('Gliederung wurde aktualisiert.');
        this.orgUsersEnabledSave[response.id] = false;
        this.loadOrgUsers();
      }).catch(() => {
        this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
          duration: 15000,
        });
        this.isLoading = false;
      })
    }
  }
  
  isOrgUserEnabledSave(orgUser: UbgOrgUser) {
    let orgUserId: string
    if (orgUser.id === undefined) {
      orgUserId = '-1';
    }
    else {
      orgUserId = orgUser.id;
    }
    if (this.orgUsersEnabledSave[orgUserId] === undefined) {
      return false;
    }
    return this.orgUsersEnabledSave[orgUserId];
  }
  
  clearSearchString() {
    this.filterParams.name = '';
    this.loadOrgUsers();
  }
  
  getGroup(orgUser: UbgOrgUser) {
    if (orgUser.relationships !== undefined) {
      for (let i in orgUser.relationships.gruppe.data) {
        for (let j in this.project.relationships.priority_structure.data.relationships.gruppe.data) {
          if (orgUser.relationships.gruppe.data[i].id === this.project.relationships.priority_structure.data.relationships.gruppe.data[j].id) {
            return orgUser.relationships.gruppe.data[i].id;
          }
        }
      }
    }
    return null;
  }
  
  updateOrgUserGroup(orgUser: UbgOrgUser, event: MatSelectChange) {
    let newGroups = [];
    for (let i in orgUser.relationships.gruppe.data) {
      let groupIsForeign = true;
      for (let j in this.project.relationships.priority_structure.data.relationships.gruppe.data) {
        if (orgUser.relationships.gruppe.data[i].id === this.project.relationships.priority_structure.data.relationships.gruppe.data[j].id) {
          groupIsForeign = false;
        }
      }
      if (groupIsForeign) {
        // Diese Gruppe gehört einer anderen Struktur, sie muss beibehalten werden
        newGroups.push(orgUser.relationships.gruppe.data[i]);
      }
    }
    for (let j in this.project.relationships.priority_structure.data.relationships.gruppe.data) {
      if (event.value === this.project.relationships.priority_structure.data.relationships.gruppe.data[j].id) {
        // Diese Gruppe wurde ausgewählt
        newGroups.push(this.project.relationships.priority_structure.data.relationships.gruppe.data[j]);
      }
    }
    orgUser.relationships.gruppe.data = newGroups;
    this.updateOrgUser(orgUser);
  }
  
  back() {
    this.router.navigate(['project/' + this.project.id]);
  }
  
  checkedCount(): number {
    let count = 0;
    for (let i in this.orgUsersChecked) {
      if (this.orgUsersChecked[i]) {
        count++;
      }
    }
    return count;
  }
  
  toggleAllCheck() {
    if (!this.orgUsersAllChecked) {
      this.orgUsersChecked = {};
      this.orgUsersAllChecked = false;
    }
    else {
      this.orgUsersChecked = {};
      for (let i in this.orgUsersSelected) {
        if (this.orgUsersSelected[i].id !== undefined) {
          this.orgUsersChecked[this.orgUsersSelected[i].id] = true;
        }
      }
      this.orgUsersAllChecked = true;
    }
  }
  
  updateOrgUsersAllChecked() {
    this.orgUsersAllChecked = true;
    for (let i in this.orgUsersSelected) {
      if (this.orgUsersSelected[i].id !== undefined) {
        if (this.orgUsersChecked[this.orgUsersSelected[i].id] === undefined) {
          this.orgUsersAllChecked = false;
        }
        else if (this.orgUsersChecked[this.orgUsersSelected[i].id] === false) {
          this.orgUsersAllChecked = false;
        }
      }
    }
  }
  
  updateCheckedOrgUserGroup(event: MatSelectChange) {
    for (let i in this.orgUsersChecked) {
      if (this.orgUsersChecked[i]) {
        for (let j in this.orgUsers) {
          if (this.orgUsers[j].id == i) {
            this.updateOrgUserGroup(this.orgUsers[j], event);
          }
        }
      }
    }
    this.orgUsersChecked = {};
    this.orgUsersAllChecked = false;
  }
  
  download() {
    this.orgUser.download(this.project, this.project.relationships.priority_structure.data, this.filterParams).then((response: any) => {
      let fileName = 'abg.csv';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.snackbar.open('Ihr Dokument kann nicht heruntergeladen werden, versuchen Sie es später wieder!', null, {
        duration: 15000,
      });
    });
  }
  
  passwords() {
    let options: Array<{
        id: string
        label: string
      }> = [
      {
        id: 'all',
        label: 'Alle Gliederungen',
      },
      {
        id: 'nopassword',
        label: 'Alle die kein Passwort haben',
      },
    ];
    if (this.filterParams.name !== '' || this.filterParams.gruppe !== '') {
      options.push({
        id: 'selected',
        label: 'Ausgewählte Gliederungen',
      });
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      selected: null,
      options: options,
    }
    let formDialog = this.dialog.open(UbgOrgUserPasswordsDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let selected: string = dialogConfig.data['selected'];
        let orgUsers: Array<UbgOrgUser> = [];
        if (dialogConfig.data['selected'] == 'selected') {
          orgUsers = this.orgUsersSelected;
        }
        this.isLoading = true;
        this.orgUser.passwords(this.project, selected, orgUsers).then(() => {
          this.loadOrgUsers();
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten, versuchen Sie es später wieder!', null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  page(event: PageEvent) {
    this.offset = event.pageIndex * this.limit;
    this.loadOrgUsers();
  }
  
  updateFilter() {
    this.loadOrgUsers();
  }
  
  removeOrgUser(orgUser: UbgOrgUser) {
    this.isLoading = true;
    this.orgUser.get('gliederung', orgUser.id).then((orgUserResponse: UbgOrgUser) => {
      let newGroups = [];
      for (let i in orgUserResponse.relationships.gruppe.data) {
        let thisGroupToDelete = false;
        for (let j in this.project.relationships.priority_structure.data.relationships.gruppe.data) {
          if (orgUserResponse.relationships.gruppe.data[i].id === this.project.relationships.priority_structure.data.relationships.gruppe.data[j].id) {
            thisGroupToDelete = true;
          }
        }
        if (!thisGroupToDelete) {
          newGroups.push(orgUserResponse.relationships.gruppe.data[i]);
        }
      }
      orgUserResponse.relationships.gruppe.data = newGroups;
      let newStructures = [];
      for (let i in orgUserResponse.relationships.struktur.data) {
        if (orgUserResponse.relationships.struktur.data[i].id !== this.project.relationships.priority_structure.data.id) {
          newStructures.push(orgUserResponse.relationships.struktur.data[i]);
        }
      }
      orgUserResponse.relationships.struktur.data = newStructures;
      this.orgUser.update(orgUserResponse).then(() => {
        this.loadOrgUsers();
      });
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
        duration: 15000,
      });
    });
  }
  
  reorder() {
    this.isLoading = true;
    let n: number = 0;
    for (let i in this.project.relationships.antrage.data) {
      this.antragService.reorder(this.project.relationships.antrage.data[i]).then(() => {
        n++;
        if (n == this.project.relationships.antrage.data.length) {
          this.isLoading = false;
        }
      });
    }
  }

}
