/**
 * Proposal-Header-Komponent
 * 
 * Attila Németh, UBG
 * 16.08.2019
 */
 
import {Component, Input} from '@angular/core';
import {DatePipe} from '@angular/common';

import {Subscription} from 'rxjs';

import {UbgProposalStructured} from '../../../model/proposal_structured';
import {UbgProposal} from '../../../model/proposal';
import {UbgVotum} from '../../../model/votum';
import {UbgLastChangeService} from '../../../services/lastchange.service';

@Component({
  selector: 'ubg-proposal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class UbgProposalHeaderComponent {
  
  @Input() set proposal (proposal: UbgProposalStructured) {
    this.proposalData = proposal;
    this.setVotumLabel();
    this.setLastChangeLabel();
  }
  proposalData: UbgProposalStructured = null
  @Input() ngClass: string = ''
  votumLabel: string = ''
  lastChangeSubscription: Subscription
  lastChangeInfo: string = null
  
  constructor(private datePipe: DatePipe,
              private lastChangeService: UbgLastChangeService) {}
  
  setVotumLabel() {
    this.votumLabel = '';
    for (let i in this.proposalData.relationships.votum.data) {
//      if (this.proposalData.relationships.votum.data[i].attributes.level == 19) {
        this.votumLabel = this.proposalData.relationships.votum.data[i].attributes.typeLabel;
//      }
    }
  }
  
  setLastChangeLabel() {
    if (this.proposalData.attributes.bearbeitung === undefined) {
      this.lastChangeInfo = null;
    }
    else {
      let l = this.proposalData.attributes.bearbeitung.length - 1;
      let dateString: string = this.proposalData.attributes.bearbeitung[l] + 'Z';
      let d = new Date(dateString);
      this.lastChangeInfo = this.datePipe.transform(d, 'dd.MM.yyyy H:mm') 
      + ' (' + this.proposalData.relationships.bearbeiter.data[l].fullName + ')';
    }
  }
  
}
