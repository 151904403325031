/**
 * Development Environment
 * 
 * Attila Németh
 * 28.03.2019
 */

export const environment = {
  production: false,
  contenta: {
    clientId: 'cad94bb8-7fe6-456e-9055-563a59ccfb9b',
    clientSecret: 'test$123',
    baseUrl: 'https://contentaan.ubgnet.de/',
    tokenUrl: 'https://contentaan.ubgnet.de/oauth/token',
    scopes: ['authenticated', 'antragswesen_angular', 'cdu_experte', 'redaktion', 'antragswesen_angular_gliederung'],
  },
  site: {
    name: 'Antragswesen',
    url: 'https://antragswesen.cdu.de/',
  },
  cduplus: {
    clientId: 'campaign',
    clientSecret: 'test$123',
  }
};
