/**
 * Proposal Action Type Service
 * 
 * Attila Németh, UBG
 * 02.05.2019
 */
 
import { Injectable } from '@angular/core';

import {UbgContentaService} from '../../../contenta/services/contenta.service';
import {UbgProposalActionType} from '../../model/taxonomies/proposal_action_type';

@Injectable({
  providedIn: 'root'
})
export class UbgProposalActionTypeService {

  constructor(private contenta: UbgContentaService) {
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/proposal_action_type').then((response: {
        data: Array<UbgProposalActionType>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/proposal_action_type/' + id).then((response: {
        data: UbgProposalActionType
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/proposal_action_type?filter[name]=' + name).then((response: {
        data: UbgProposalActionType
      }) => {
        resolve(response.data[0]);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

}