/**
 * Gemeinsam-Komponent
 * 
 * Attila Németh, UBG
 * 29.05.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

import {UbgProposal} from '../../../../../../model/proposal';
import {UbgProposalService} from '../../../../../../services/proposal.service';
import {UbgEmpfehlung} from '../../../../../../model/empfehlung';


@Component({
  templateUrl: './gemeinsam.component.html',
  styleUrls: ['./gemeinsam.component.scss'],
})
export class UbgProposalGemeinsamDialogComponent implements OnInit {
  
  disabled: boolean = true
  empfehlungOptions: Array<{
    id: string
    label: string
  }> = []
  allEmpfehlungen: Array<UbgEmpfehlung>
  isLoading: boolean = false
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    proposals: Array<UbgProposal>
                    empfehlung: UbgEmpfehlung
                  },
                  private service: UbgProposalService) {}
              
  ngOnInit() {
    let empfehlungen: Array<UbgEmpfehlung> = [];
    let proposalCounter = this.data.proposals.length;
    this.isLoading = true;
    for (let i in this.data.proposals) {
      this.service.getPossibleGemeinsam(this.data.proposals[i]).then((response: Array<UbgEmpfehlung>) => {
        for (let j in response) {
          empfehlungen.push(response[j]);
        }
        proposalCounter--;
        if (proposalCounter == 0) {
          let newOptions: Array<{
            id: string
            label: string,
            firstLabel: string
          }> = [];
          for (let j in empfehlungen) {
            let found: boolean = false;
            for (let k in newOptions) {
              if (newOptions[k].id == empfehlungen[j].id) {
                found = true;
              }
            }
            if (!found) {
              let titles: Array<string> = [];
              for (let k in empfehlungen[j].relationships.proposals.data) {
                titles.push(empfehlungen[j].relationships.proposals.data[k].attributes.title);
              }
              newOptions.push({
                id: empfehlungen[j].id,
                label: titles.join(', '),
                firstLabel: titles[0],
              });
            }
          }
          let sortedOptions: Array<{
            id: string
            label: string,
            firstLabel: string
          }> = newOptions.sort((option1: {
            id: string
            firstLabel: string
          }, option2: {
            id: string
            firstLabel: string
          }) => {
              let letter1 = option1.firstLabel.match(/^([A-Za-z]+)/g);
              let number1 = option1.firstLabel.match(/([0-9]+)$/g);
              let letter2 = option2.firstLabel.match(/^([A-Za-z]+)/g);
              let number2 = option2.firstLabel.match(/([0-9]+)$/g);
              if (letter1[0] == this.data.proposals[0].attributes.title.substring(0,1)
                  && letter2[0] != this.data.proposals[0].attributes.title.substring(0,1)) {
                return -1;
              }
              else if (letter2[0] == this.data.proposals[0].attributes.title.substring(0,1)
                  && letter1[0] != this.data.proposals[0].attributes.title.substring(0,1)) {
                return 1;
              }
              else if (letter1[0] == letter2[0]) {
                if (Number(number1[0]) < Number(number2[0])) {
                  return -1
                }
                else {
                  return 1;
                }
              }
              else if (letter1[0].charCodeAt(0) < letter2[0].charCodeAt(0)) {
                return -1;
              }
              else {
                return 1;
              }
          });
          this.empfehlungOptions = sortedOptions;
          this.allEmpfehlungen = empfehlungen;
          this.isLoading = false;
        }
      });
    }
  }
  
  optionSelected(event: MatSelectChange) {
    this.disabled = true;
    for (let i in this.allEmpfehlungen) {
      if (this.allEmpfehlungen[i].id == event.value) {
        this.data.empfehlung = this.allEmpfehlungen[i];
        this.disabled = false;
      }
    }
  }
  
  autocompleteSelected(votumId: string) {
    this.disabled = true;
    for (let i in this.allEmpfehlungen) {
      if (this.allEmpfehlungen[i].id == votumId) {
        this.data.empfehlung = this.allEmpfehlungen[i];
        this.disabled = false;
      }
    }
  }
 
}
