/**
 * Antrag-Komponent
 * 
 * Erstellt von Ramin Hdesehy
 * Aufgebaut von Attila Németh
 * 
 * ES IST NICHT ANGEWENDET
 * 
 * 21.05.2019
 */

import {Component, Input} from '@angular/core';

import {UBGAntragModel} from '../../model/antrag.model';

@Component({
  selector: 'ubg-antrag',
  templateUrl: './antrag.component.html',
  styleUrls: ['./antrag.component.scss']
})
export class AntragComponent {
  
  antragType: string = null

  @Input() set antrag(antrag: UBGAntragModel) {
    if (antrag !== null) {
      this.antragType = antrag.relationships.ubg_antrag_type.data.attributes.name;
      this.loadedAntrag = antrag;
    }
    else {
      this.antragType = null;
    }
  }
  loadedAntrag: UBGAntragModel = null

  constructor() {}

}
