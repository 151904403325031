/**
 * Ziffer-Komponent
 * 
 * Attila Németh, UBG
 * 04.06.2019
 */
 
import {Component, Input, ElementRef, ViewChild, AfterViewInit, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {Subscription} from 'rxjs';

import {UbgZifferStructured} from '../../../../../../../model/ziffer_structured';
import {UbgZiffer} from '../../../../../../../model/ziffer';
import {UbgFilterService} from '../../../../../../../services/filter.service';
import {UbgFilterParams} from '../../../../../../../model/filter_params';
import {UbgProjectService} from '../../../../../../../services/project_service';
import {UbgProposalStructured} from '../../../../../../../model/proposal_structured';
import {UbgProposal} from '../../../../../../../model/proposal';
import {UbgZifferService} from '../../../../../../../services/ziffer.service';
import {UbgZifferAssignmentDialogComponent} from '../../../dialogs/assignment/ziffer.component';
import {UbgTextEditorDialogComponent} from '../../../dialogs/text/text.component';
import {UbgFassungEditorDialogComponent} from '../../../dialogs/fassung/fassung.component';
import {UbgPrintConfirmDialogComponent} from '../../../dialogs/print/print.component';
import {UbgLine} from '../../../../../../../model/line';
import {UbgBegrundung} from '../../../../../../../model/begrundung';
import {UbgBegrundungService} from '../../../../../../../services/begrundung.service';
import {UbgProposalLeitantragEmpfehlungDialogComponent} from '../proposal/dialog/empfehlung/empfehlung.component';
import {UbgConfirmComponent} from '../../../../../../../../ubg_components/confirm/components/confirm.component';
import {UbgProposalService} from '../../../../../../../services/proposal.service';
import {UbgVotum} from '../../../../../../../model/votum';
import {UbgVotumService} from '../../../../../../../services/votum.service';
import {UbgLastChangeService} from '../../../../../../../services/lastchange.service';
import {TextHelperService} from '../../../../../../../services/helpers/text-helper.service';

@Component({
  selector: 'ubg-project-antrag-leitantrag-ziffer',
  templateUrl: './ziffer.component.html',
  styleUrls: ['./ziffer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgProjectAntragLeitantragZifferComponent implements AfterViewInit, OnInit, OnDestroy {
  
  @Input() ziffer: UbgZifferStructured
  isOpen: boolean = false
  isExpanded: boolean = false
  filterParams: UbgFilterParams = {}
  projectId: string = null
  proposals: Array<UbgProposalStructured> = []
  leitantragProposal: UbgProposalStructured = null
  leitantragProposalTitle: string = '...'
  leitantragProposalNumber: string = ''
  leitantragEmpfehlung: UbgVotum = null
  empfehlungTexts: Array<string> = []
  fassung: string = null
  blockHeight: number = 0
  begrundung: string = ''
  isUpdating: boolean = false
  zuweisung: string = ''
  showMarkup: boolean = false
  originalText: string = ''
  voten: Array<UbgVotum> = []
  activeProposalSubscription: Subscription
  lastChangeSubscription: Subscription
  
  @ViewChild('block') block: ElementRef;
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgZifferService,
                private filterService: UbgFilterService,
                private projectService: UbgProjectService,
                private begrundungService: UbgBegrundungService,
                private proposalService: UbgProposalService,
                private votumService: UbgVotumService,
                private lastChangeService: UbgLastChangeService,
                private textHelper: TextHelperService) {
    this.filterService.params.subscribe((params: UbgFilterParams) => {
      this.filterParams = params;
    });
    this.projectId = this.projectService.activeProjectId;
  }
  
  ngAfterViewInit() {
    this.checkActiveProposal();
    this.activeProposalSubscription = this.proposalService.activeProposal.subscribe((proposal: any) => {
      this.checkActiveProposal();
    });
  }
  
  ngOnInit() {
    this.projectService.activeProjectIds.subscribe((projectId: string) => {
      if (projectId !== this.projectId) {
        this.projectId = projectId;
      }
    });
    this.updateZuweisung();
    this.lastChangeSubscription = this.lastChangeService.changedProposal.subscribe((proposal: UbgProposal) => {
      for (let i in this.proposals) {
        if (this.proposals[i].id == proposal.id) {
          if (this.proposals[i].attributes.changed < proposal.attributes.changed) {
            this.proposalChanged();
          }
        }
      }
    });
  }
  
  ngOnDestroy() {
    this.activeProposalSubscription.unsubscribe();
    this.lastChangeSubscription.unsubscribe();
  }
  
  panelOpened() {
    this.isOpen = true;
    this.loadProposals();
    this.loadEmpfehlungen();
  }
  
  panelClosed() {
    this.isOpen = false;
  }
  
  loadProposals() {
    this.service.getStructuredProposals(this.projectId, this.ziffer, this.filterParams).then((response: Array<UbgProposalStructured>) => {
      let proposals: Array<UbgProposalStructured> = [];
      for (let i in response) {
        if (response[i].attributes.leitantrag) {
          this.leitantragProposal = response[i];
          this.leitantragProposalTitle = this.leitantragProposal.attributes.longTitle;
          this.leitantragProposalNumber = this.leitantragProposal.attributes.title;
        }
        else {
          proposals.push(response[i]);
        }
      }
      this.loadVoten();
      this.proposals = proposals;
      this.updateLeitantragEmpfehlung();
    }).catch(() => {
      this.snackbar.open('Ein Fehler ist aufgetreten, versuchen Sie es später noch einmal', null, {
        duration: 15000
      });
      this.proposals = [];
      this.leitantragProposalTitle = this.ziffer.attributes.title;
    });
  }
  
  loadVoten() {
    this.votumService.getByZiffer(this.ziffer.id).then((response: Array<UbgVotum>) => {
      this.voten = response;
      for (let i in response) {
        if (response[i].type == 'ubg_empfehlung--annahme_in_folgender_fassung') {
          if (response[i].attributes.fassung === undefined) {
            this.fassung = '';
          }
          else {
            this.fassung = response[i].attributes.fassung.value;
          }
        }
      }
      this.updateLeitantragEmpfehlung();
    });
  }
  
  updateLeitantragEmpfehlung() {
    if (this.leitantragProposal === null) {
      this.leitantragEmpfehlung = null;
    }
    else {
      this.leitantragEmpfehlung = null;
      for (let i in this.voten) {
        for (let j in this.voten[i].relationships.proposals.data) {
          if (this.voten[i].relationships.proposals.data[j].id == this.leitantragProposal.id) {
            this.leitantragEmpfehlung = this.voten[i];
          }
        }
      }
    }
  }
  
  loadEmpfehlungen() {
    this.service.getEmpfehlungTexts(this.ziffer).then((response: {
      empfehlungen: Array<string>
    }) => {
      this.empfehlungTexts = response.empfehlungen;
      this.adjustHeight();
    });
    this.loadBegrundung();
  }
  
  private adjustHeight() {
    this.blockHeight = this.block.nativeElement['offsetHeight'];
    if (this.blockHeight < 96) {
      this.blockHeight = 96;
    }
  }
  
  assign() {
    this.service.get(this.ziffer.id).then((ziffer: UbgZiffer) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        ziffer: ziffer,
        users: this.ziffer.relationships.zustandigkeit.data,
      }
      let formDialog = this.dialog.open(UbgZifferAssignmentDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          ziffer.relationships.zustandigkeit.data = dialogConfig.data['users'];
          ziffer.attributes.zuweisungsdatum = new Date().toJSON().slice(0, 19) + '+0000';
          this.service.update(ziffer).then((response: UbgZiffer) => {
            this.ziffer.relationships.zustandigkeit.data = ziffer.relationships.zustandigkeit.data;
            this.snackbar.open('Die Ziffer wurde zugewiesen', null, {
              duration: 6000,
            });
          }).catch(() => {
            this.snackbar.open('Ein Fehler ist aufgetreten, versuchen Sie es später noch einmal', null, {
              duration: 15000
            });
          });
        }
      });
    }).catch(() => {
      this.snackbar.open('Ein Fehler ist aufgetreten, versuchen Sie es später noch einmal', null, {
        duration: 15000
      });
    })
  }
  
  editFassung() {
//    this.proposalService.setLock(this.leitantragProposal).then(() => {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      this.getFassung().then((response: {
        fassung: string
        restore: string
      }) => {
        console.debug(response);
        dialogConfig.data = {
          text: response.fassung,
          label: 'Fassung',
          restore: response.restore,
        }
        let formDialog = this.dialog.open(UbgFassungEditorDialogComponent, dialogConfig);
        formDialog.afterClosed().subscribe((response) => {
          if (response == 1) {
            this.isUpdating = true;
            let votenToUpdate: Array<UbgVotum> = [];
            for (let i in this.voten) {
              if (this.voten[i].type == 'ubg_empfehlung--annahme_in_folgender_fassung') {
                this.voten[i].attributes.fassung = {
                  value: dialogConfig.data['text'],
                  format: null,
                };
                votenToUpdate.push(this.voten[i]);
              }
            }
            this.votenUpdate(votenToUpdate).then(() => {
              this.proposalService.releaseLock(this.leitantragProposal);
              this.loadVoten();
              this.loadEmpfehlungen();
              this.isUpdating = false;
            });
          }
          else {
            this.proposalService.releaseLock(this.leitantragProposal);
          }
        });
      });
//    }).catch((error) => {
//      console.error(error);
//      this.snackbar.open('Der Antrag wird gerade von einem anderen Benutzer bearbeitet', null, {
//        duration: 8000,
//      })
//    });
  }
  
  votenUpdate(voten: Array<UbgVotum>) {
    let promise = new Promise((resolve, reject) => {
      this.votumService.update(voten[0]).then((updatedVotum: UbgVotum) => {
        let newVoten: Array<UbgVotum> = [];
        for (let i in voten) {
          if (voten[i].id !== updatedVotum.id) {
            newVoten.push(voten[i]);
          }
        }
        if (newVoten.length == 0) {
          resolve();
        }
        else {
          this.votenUpdate(newVoten).then(() => {
            resolve();
          });
        }
      });
    });
    return promise;
  }
  
  private getFassung() {
    let promise = new Promise((resolve, reject) => {
//      this.service.get(this.ziffer.id).then((rawZiffer: UbgZiffer) => {
//        const fassung = this.textHelper.getPlainParagraph(rawZiffer.relationships.lines.data);
//        let response = {
//          restore: fassung,
//          fassung: fassung,
//        };
//        for (let i in this.voten) {
//          if (this.voten[i].type == 'ubg_empfehlung--annahme_in_folgender_fassung'
//            && this.voten[i].attributes.fassung !== undefined) {
//            response.fassung = this.voten[i].attributes.fassung.value;
//          }
//        }
//        resolve(response);
//      });
      this.service.getNewFassung(this.ziffer).then((fassung: string) => {
        let response = {
          restore: fassung,
          fassung: fassung,
        };
        for (let i in this.voten) {
          if (this.voten[i].type == 'ubg_empfehlung--annahme_in_folgender_fassung'
            && this.voten[i].attributes.fassung !== undefined) {
            response.fassung = this.voten[i].attributes.fassung.value;
          }
        }
        resolve(response);
      });
//      this.service.getLines(this.ziffer).then((lines: Array<UbgLine>) => {
//        let textLines: Array<string> = [];
//        for (let i in lines) {
//          textLines.push(lines[i].attributes.content['value']);
//        }
//        let text: string = textLines.join('');
//        let response = {
//          restore: text,
//          fassung: text,
//        };
//        for (let i in this.voten) {
//          if (this.voten[i].type == 'ubg_empfehlung--annahme_in_folgender_fassung'
//            && this.voten[i].attributes.fassung !== undefined) {
//            response.fassung = this.voten[i].attributes.fassung.value;
//          }
//        }
//        resolve(response);
//      });
    });
    return promise;
  }
  
  print() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diese Ziffer drucken?',
      mitEmpfehlungen: false,
    }
    if (this.empfehlungTexts.length > 0) {
      dialogConfig.data['empfehlungenPossible'] = true;
    }
    else {
      dialogConfig.data['empfehlungenPossible'] = false;
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.print(this.ziffer, {
          mitEmpfehlungen: dialogConfig.data['mitEmpfehlungen'],
        }).then((response: any) => {
          let fileName = 'Antrag-' + this.leitantragProposalNumber.replace(' ', '') + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
            duration: 15000,
          })
        });
      }
    });
  }
  
  proposalChanged() {
    this.loadProposals();
    this.loadEmpfehlungen();
    this.loadVoten();
  }
  
  isBegrundungPossible() {
    if (this.empfehlungTexts.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  
  loadBegrundung() {
    if (this.ziffer.relationships.begrundung.data !== null) {
      if (this.ziffer.relationships.begrundung.data.attributes.text.value !== undefined) {
        this.begrundung = this.ziffer.relationships.begrundung.data.attributes.text.value;
      }
      else if (this.ziffer.relationships.begrundung.data.attributes.text[0]['value'] !== undefined) {
        this.begrundung = this.ziffer.relationships.begrundung.data.attributes.text[0]['value'];
      }
    }
  }
  
  editBegrundung() {
    let begrundung: string = '';
    if (this.ziffer.relationships.begrundung.data !== null) {
      begrundung = this.ziffer.relationships.begrundung.data.attributes.text.value;
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      text: begrundung,
      label: 'Begründung',
    }
    let formDialog = this.dialog.open(UbgTextEditorDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        if (this.ziffer.relationships.begrundung.data === null) {
          // Begründung erstellen
          let begrundung = new UbgBegrundung;
          begrundung.attributes = {
            title: 'Begründung ' + this.ziffer.attributes.title,
            text: {
              value: dialogConfig.data['text'],
              format: null,
            }
          };
          this.begrundungService.create(begrundung).then((begrundungResponse: UbgBegrundung) => {
            this.service.get(this.ziffer.id).then((ziffer: UbgZiffer) => {
              ziffer.relationships.begrundung = {
                data: begrundungResponse,
              };
              this.service.update(ziffer).then(() => {
                this.ziffer.relationships.begrundung.data = begrundungResponse;
                this.loadBegrundung();
              });
            });
          });
        }
        else {
          // Begründung ändern
          this.ziffer.relationships.begrundung.data.attributes.text.value = dialogConfig.data['text'];
          this.begrundungService.update(this.ziffer.relationships.begrundung.data).then(() => {
            this.loadBegrundung();
          });
        }
      }
    });
  }
  
  addEmpfehlung() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      proposals: [
        this.leitantragProposal,
      ],
      empfehlungType: null,
      fields: {
        ziffer: null,
        empfehlung: null,
        fassung: null,
        uberweisung_an: null,
        begruendung: '',
      }
    }
//    this.proposalService.setLock(this.leitantragProposal).then(() => {
      let formDialog = this.dialog.open(UbgProposalLeitantragEmpfehlungDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          let votum = this.votumService.emptyVotum(dialogConfig.data['empfehlungType']);
          for (let fieldName in dialogConfig.data['fields']) {
            if (dialogConfig.data['fields'][fieldName] !== null 
                  && dialogConfig.data['fields'][fieldName] !== undefined) {
              votum.attributes[fieldName] = dialogConfig.data['fields'][fieldName];
            }
          }
          if (this.leitantragProposal !== null) {
            this.proposalService.get(this.leitantragProposal.id).then((loadProposal: UbgProposal) => {
              votum.relationships.proposals = {
                data: [loadProposal],
              };
              this.votumService.create(votum).then(() => {
                this.loadProposals();
                this.loadVoten();
                this.loadEmpfehlungen();
                this.proposalService.releaseLock(this.leitantragProposal);
              }).catch(() => {
                this.loadProposals();
                this.loadVoten();
                this.loadEmpfehlungen();
                this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: Z-445', null, {
                  duration: 15000,
                });
                this.proposalService.releaseLock(this.leitantragProposal);
              });
            });
          }
        }
      });
//    }).catch(() => {
//      this.snackbar.open('Der Antrag wird gerade von einem anderen Benutzer bearbeitet', null, {
//        duration: 8000,
//      });
//    });
  }
  
  removeEmpfehlung() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie das Votum der Komission wirklich entfernen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.votumService.getByProposal(this.leitantragProposal.id).then((response: Array<UbgVotum>) => {
          for (let i in response) {
            if (response[i].attributes.level == 19 || response[i].attributes.level == undefined) {
              this.votumService.delete(response[i]).then(() => {
                this.loadProposals();
                this.loadVoten();
                this.loadEmpfehlungen();
              });
            }
          }
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: Z-475', null, {
            duration: 15000,
          });
          this.loadProposals();
          this.loadVoten();
          this.loadEmpfehlungen();
        });
      }
    });
  }
  
  checkActiveProposal() {
    let activeProposal: UbgProposalStructured = this.proposalService.getActiveProposal();
    if (activeProposal !== null && this.ziffer !== undefined) {
      if (activeProposal.relationships.ziffer.data.id == this.ziffer.id) {
        this.isExpanded = true;
      }
    }
  }
  
  userCanAssign() {
    return this.ziffer.meta.permissions.assign;
  }
  
  updateZuweisung() {
    let users: Array<string> = [];
    for (let i in this.ziffer.relationships.zustandigkeit.data) {
      users.push(this.ziffer.relationships.zustandigkeit.data[i].attributes.nachname + ', ' + this.ziffer.relationships.zustandigkeit.data[i].attributes.vorname);
    }
    this.zuweisung = users.join(' | ');
  }
  
  toggleMarkup() {
    if (this.showMarkup) {
      this.showMarkup = false;
    }
    else {
      this.isUpdating = true;
      this.getFassung().then((response: {
        fassung: string
      }) => {
        this.fassung = response.fassung;
        this.service.getLines(this.ziffer).then((response: Array<UbgLine>) => {
          let lines: Array<string> = [];
          for (let i in response) {
            lines.push(response[i].attributes.content['value']);
          }
          this.originalText = lines.join('-*-').replace(/\<\/p\>\-\*\-\<p\>/g, ' ');
          this.isUpdating = false;
          this.showMarkup = true;
        });
      });
    }
  }
  
  printMarkup() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die Ziffer drucken?',
    };
    let formDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        const element = document.getElementById('ziffer-' + this.ziffer.id + '-markup');
        this.service.printMarkup(this.ziffer, element.innerHTML).then((response: any) => {
          let fileName = 'Antrag-' + this.leitantragProposalNumber.replace(' ', '') + '-markup.pdf';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
}
