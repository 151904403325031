/**
 * Dialog-Element-Komponent
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import { Component, Input } from '@angular/core';

import {UbgTabDialogService} from '../../../services/tab_dialog.service';

@Component({
  selector: 'ubg-tab-dialog-dialog-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class UbgTabDialogDialogItemComponent {
  
  @Input() tabId: number

  constructor(private service: UbgTabDialogService) {
  }
  
  getItemClass() {
    if (this.tabId == this.service.activeTab) {
      return 'active';
    }
  }
  
}