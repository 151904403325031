/**
 * Empfehlung-Dialog für Leitantrag
 * 
 * Attila Németh, UBG
 * 06.06.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {UbgEmpfehlungType} from '../../../../../../../../../model/empfehlung_type';
import {UbgEmpfehlungTypeService} from '../../../../../../../../../services/empfehlung_type.service';
import {UbgZiffer} from '../../../../../../../../../model/ziffer';
import {UbgZifferService} from '../../../../../../../../../services/ziffer.service';
import {UbgProposal} from '../../../../../../../../../model/proposal';
import {UbgProposalService} from '../../../../../../../../../services/proposal.service';
import {UBGAntragModel} from '../../../../../../../../../model/antrag.model';
import {UBGAntragService} from '../../../../../../../../../services/antrag.service';
import {UbgEmpfehlungUberweisung} from '../../../../../../../../../model/taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungUberweisungService} from '../../../../../../../../../services/taxonomies/empfehlung_uberweisung.service';

@Component({
  templateUrl: './empfehlung.component.html',
  styleUrls: ['./empfehlung.component.scss'],
})
export class UbgProposalLeitantragEmpfehlungDialogComponent implements OnInit {
  
  empfehlungTypes: Array<UbgEmpfehlungType> = []
  empfehlungTypeId: string = null
  ziffers: Array<UbgZiffer> = []
  assignees: Array<UbgEmpfehlungUberweisung> = [];
  showGemeinsam: boolean = null
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                proposals: Array<UbgProposal>
                empfehlungType: UbgEmpfehlungType
                fields: {
                  ziffer: UbgZiffer
                  empfehlung: string
                  fassung: string
                  uberweisung_an: Array<UbgEmpfehlungUberweisung>,
                  begruendung: string
                }
                gemeinsam: boolean
              },
              private service: UbgEmpfehlungTypeService,
              private ziffer: UbgZifferService,
              private antrag: UBGAntragService,
              private proposal: UbgProposalService,
              private uberweisung: UbgEmpfehlungUberweisungService) {
  }
              
  ngOnInit() {
    this.service.getAllByOptions().then((response: Array<UbgEmpfehlungType>) => {
      this.empfehlungTypes = response;
    });
    for (let i in this.data.proposals) {
      if (this.data.proposals[i].relationships.antrag === undefined) {
        this.proposal.get(this.data.proposals[i].id).then((response: UbgProposal) => {
          this.data.proposals[i] = response;
          this.setGemeinsam();
        });
      }
    }
  }
  
  private setGemeinsam() {
    if (this.data.proposals.length > 1) {
      // Gemeinsam hat nur einen Sinn, wenn es mehr Proposals sind
      for (let i in this.data.proposals) {
        if (this.data.proposals[i].relationships.antrag !== undefined
          && this.showGemeinsam === null) {
          this.showGemeinsam = false;
          this.antrag.get(this.data.proposals[i].relationships.antrag.data.id).then((response: UBGAntragModel) => {
            this.showGemeinsam = true;
          });
        }
      }
    }
  }
  
  selectType(event: MatSelectChange) {
    for (let i in this.empfehlungTypes) {
      if (this.empfehlungTypes[i].id == event.value) {
        this.data.empfehlungType = this.empfehlungTypes[i];
        this.empfehlungTypeId = this.empfehlungTypes[i].attributes.drupal_internal__id;
      }
    }
    if (this.empfehlungTypeId == 'behandlung_bei_ziffer' || this.empfehlungTypeId == 'durch_beschlussfassung_erledigt') {
      this.loadZiffers();
    }
//    if (this.empfehlungTypeId == 'uberweisung_an'
//      || this.empfehlungTypeId == 'annahme_und_uberweisung'
//      || this.empfehlungTypeId == 'annahme_fassubg_uber') {
//      this.loadUberweisung();
//    }
  }
  
  loadZiffers() {
    this.ziffers = [];
    for (let i in this.data.proposals) {
      this.antrag.get(this.data.proposals[i].relationships.antrag.data.id).then((antragResponse: UBGAntragModel) => {
        for (let j in antragResponse.relationships.ziffers.data) {
          this.ziffer.get(antragResponse.relationships.ziffers.data[j].id).then((zifferResponse: UbgZiffer) => {
            this.ziffers.push(zifferResponse);
          });
        }
      });
    }
  }
  
//  loadUberweisung() {
//    this.uberweisung.getAll().then((response: Array<UbgEmpfehlungUberweisung>) => {
//      this.assignees = response;
//    });
//  }
  
  selectZiffer(event: MatSelectChange) {
    for (let i in this.ziffers) {
      if (this.ziffers[i].id == event.value) {
        this.data.fields.ziffer = this.ziffers[i];
      }
    }
  }
  
//  selectUberweisung(event: MatSelectChange) {
//    for (let i in this.assignees) {
//      if (this.assignees[i].id == event.value) {
//        this.data.fields.uberweisung_an = this.assignees[i];
//      }
//    }
//  }
  
  uberweisungSelected(uberweisungen: Array<UbgEmpfehlungUberweisung>) {
    this.data.fields.uberweisung_an = uberweisungen;
  }
  
}
