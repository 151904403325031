/**
 * Politisches Thema
 * 
 * Attila Németh, UBG
 * 26.07.2019
 */
 
export class UbgThema {
  
  id: string;
  type: string = 'taxonomy_term--themen';
  attributes: {
    name: string
    weight: number
  }
  
}