/**
 * Ziffer-Service
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgZiffer} from '../model/ziffer';
import {UbgLine} from '../model/line';
import {UbgProposalStructured} from '../model/proposal_structured';
import {UbgUser} from '../model/user';
import {UBGAntragModel} from '../model/antrag.model';
import {UbgZifferStructured} from '../model/ziffer_structured';
import {UbgFilterParams} from '../model/filter_params';
 
@Injectable({
  providedIn: 'root'
})
export class UbgZifferService {
  
  constructor(private contenta: UbgContentaService) {}
  
  create(ziffer: UbgZiffer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_ziffer/ubg_ziffer', {
        data: ziffer
      }).then((response: {data: UbgZiffer}) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  get (id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_ziffer/ubg_ziffer/' + id + '?include=lines').then((response: {
        data: UbgZiffer
        included: Array<UbgLine>
      }) => {
        for (let i in response.included) {
          for (let j in response.data.relationships.lines.data) {
            if (response.data.relationships.lines.data[j].id == response.included[i].id) {
              response.data.relationships.lines.data[j] = response.included[i];
            }
          }
        }
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getProposals(ziffer: UbgZiffer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/proposal/api/ziffer/' + ziffer.id).then((response: Array<UbgProposalStructured>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(ziffer: UbgZiffer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_ziffer/ubg_ziffer/' + ziffer.id, {
        data: ziffer
      }).then((response: {
        data: UbgZiffer
      }) => {
        resolve(response.data);
        }).catch(() => {
          reject();
        });
    });
    return promise;
  }
  
  getEmpfehlungTexts(ziffer: UbgZifferStructured) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/ziffer/' + ziffer.id + '/empfehlungen').then((response: {
        empfehlungen: Array<string>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  delete(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_ziffer/ubg_ziffer/' + id ).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getReda(ziffer: UbgZiffer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/ziffer/api/reda/' + ziffer.id).then((response: UbgUser) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getAllByAntrag(antragId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_antrag/ubg_antrag/' + antragId + '?include=ziffers').then((response: {
        data: UBGAntragModel,
        included: any
      }) => {
        const ziffers = response.included;
        resolve(ziffers);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  print(ziffer: UbgZifferStructured, args: {
    mitEmpfehlungen: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + ziffer.id + '/print'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printMarkup(ziffer: UbgZifferStructured, text: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + ziffer.id + '/print/markup'
      this.contenta.postGetFile(path, {
        text: text,
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getStructuredProposals(projectId: string, ziffer: UbgZifferStructured, filterParams: UbgFilterParams) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + projectId + '/' + ziffer.id + '/proposals';
      let data = {
        filter: filterParams
      };
      this.contenta.post(path, data).then((response: {
        data: Array<UbgProposalStructured>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getLines(ziffer: UbgZifferStructured) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + ziffer.id + '/lines';
      this.contenta.get(path).then((response: {
        data: Array<UbgLine>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getNewFassung(ziffer: UbgZifferStructured) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + ziffer.id + '/fassung';
      this.contenta.get(path).then((response: string) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getMarkup(ziffer: UbgZifferStructured) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/' + ziffer.id + '/markup';
      this.contenta.get(path).then((response: string) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  breakLines(originalText: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/ziffer/break';
      this.contenta.post(path, {
        original: originalText,
      }).then((response: string) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}