/**
 * Priorty-Struktur-Service
 * 
 * Attila Németh, UBG
 * 05.04.2019
 */
 
import {Injectable} from '@angular/core';

import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgPriorityStructure} from '../model/priority_structure';
import {UbgGroup} from '../model/group';
import {UbgMandant} from '../model/mandant';
  
@Injectable({
  providedIn: 'root'
})
export class UbgPriorityStructureService {
  
  constructor(private contenta: UbgContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_priority_structure/ubg_priority_structure').then((response: {
        data: Array<UbgPriorityStructure>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_priority_structure/ubg_priority_structure/' + id + '?include=gruppe').then((response: {
        data: UbgPriorityStructure
        included: Array<UbgGroup>
      }) => {
        let data = response.data;
        for (let i in response.included) {
          for (let j in response.data.relationships.gruppe.data) {
            if (response.included[i].id == response.data.relationships.gruppe.data[j].id) {
              response.data.relationships.gruppe.data[j] = response.included[i];
            }
          }
        }
        resolve(data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getByMandant (mandant_id) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_mandant/ubg_mandant/' + mandant_id + '?include=struktur').then((response: {
        data: UbgMandant
        included: Array<UbgPriorityStructure>
      }) => {
        const data = response.data;
        for (let i in response.included) {
          for (let j in response.data.relationships.struktur.data) {
            if (response.included[i].id == response.data.relationships.struktur.data[j].id) {
              response.data.relationships.struktur.data[j] = response.included[i];
            }
          }
        }
        resolve(data.relationships.struktur.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(priorityStructure: UbgPriorityStructure) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_priority_structure/ubg_priority_structure/' + priorityStructure.id;
      this.contenta.patch(path, {
        data: priorityStructure
      }).then((response: {
        data: UbgPriorityStructure
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(priorityStructure: UbgPriorityStructure) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_priority_structure/ubg_priority_structure';
      this.contenta.post(path, {
        data: priorityStructure
      }).then((response: {
        data: UbgPriorityStructure
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}