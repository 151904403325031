/**
 * Überweisen
 * 
 * Attila Németh, UBG
 * 09.05.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

import {UbgProposalStructured} from '../../../../../../model/proposal_structured';
import {UbgUser} from '../../../../../../model/user';
import {UbgProposalService} from '../../../../../../services/proposal.service';
import {UbgAutocompleteOption} from '../../../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './assignment.component.html',
})
export class UbgProposalAssignmentDialogComponent implements OnInit {
  
  personOptions: Array<UbgUser>
  personFilteredOptions: Array<UbgAutocompleteOption> = []
  searchString: string = ''
  hasError: boolean = false
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                proposals: Array<UbgProposalStructured>
                users: Array<UbgUser>
              },
    private proposal: UbgProposalService) {
  }
              
  ngOnInit() {
    this.loadPersons();
  }
  
  loadPersons() {
    let users = [];
    let userIds = {};
    for (let i in this.data.proposals) {
      this.proposal.getReda(this.data.proposals[i].id).then((response: Array<UbgUser>) => {
        let newOptions = [];
        for (let i in response) {
          newOptions.push(response[i]);
        }
        this.personOptions = newOptions;
        let freeOptions: Array<UbgAutocompleteOption> = [];
        for (let i in response) {
          let isFree: boolean = true;
          for (let j in this.data.users) {
            if (response[i].id == this.data.users[j].id) {
              isFree = false;
            }
          }
          if (isFree) {
            freeOptions.push({
              id: response[i].id,
              label: response[i].attributes.nachname + ', ' + response[i].attributes.vorname,
            });
          }
        }
        this.personFilteredOptions = freeOptions;
        this.searchString = '';
      }).catch(() => {
        this.hasError = true;
      });
    }
  }
  
  getFullName(user: UbgUser): string {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == user.id) {
        return this.personOptions[i].attributes.nachname + ', ' + this.personOptions[i].attributes.vorname;
      }
    }
    return '...';
  }
  
  removeUser(user: UbgUser): void {
    let newUsers: Array<UbgUser> = [];
    for (let i in this.data.users) {
      if (this.data.users[i].id !== user.id) {
        newUsers.push(this.data.users[i]);
      }
    }
    this.data.users = newUsers;
    this.loadPersons();
  }
  
  userSelected(userId: string) {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == userId) {
        this.searchString = this.personOptions[i].attributes.name;
        this.data.users.push(this.personOptions[i]);
      }
    }
    this.loadPersons();
  }
 
 
}
