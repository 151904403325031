/**
 * Struktur-Liste
 * 
 * Attila Németh, UBG
 * 09.04.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {UbgPriorityStructure} from '../../../model/priority_structure';
import {UbgPriorityStructureService} from '../../../services/priority_structure.service';
import {UbgStructureTitleDialogComponent} from '../dialogs/title/title.component';
import {UbgGroup} from '../../../model/group';
import {UbgGroupService} from '../../../services/group.service';

@Component({
  templateUrl: './list.component.html',
})
export class UbgStructureListComponent implements OnInit {
  
  isLoading: boolean = false
  hasError: boolean = false
  structures: Array<UbgPriorityStructure>
  
  constructor(private router: Router, 
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgPriorityStructureService,
                private group: UbgGroupService) {
    this.structures = [];
  }
  
  ngOnInit() {
    this.loadStructures();
  }
  
  loadStructures() {
    this.isLoading = true;
    this.service.getAll().then((response: Array<UbgPriorityStructure>) => {
      this.structures = response;
      this.isLoading = false;
      this.hasError = false;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  edit(structure: UbgPriorityStructure) {
    this.router.navigate(['struktur/' + structure.id]);
  }
  
  add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: '',
    }
    let formDialog = this.dialog.open(UbgStructureTitleDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let structure = new UbgPriorityStructure;
        structure.attributes = {
          title: dialogConfig.data['title'],
        };
        this.isLoading = true;
        this.service.create(structure).then(() => {
          this.loadStructures();
        }).catch(() => {
          this.snackbar.open('Die neue Struktur kann nicht erstellt werden, bitte versuchen Sie es später', null, {
            duration: 15000,
          })
        });
      }
    });
  }
  
  copy(structure: UbgPriorityStructure) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: structure.attributes.title + ' - Kopie',
    }
    let formDialog = this.dialog.open(UbgStructureTitleDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let newGroups = [];
        for (let i in structure.relationships.gruppe.data) {
          this.group.copy(structure.relationships.gruppe.data[i]).then((response: UbgGroup) => {
            newGroups.push(response);
            if (structure.relationships.gruppe.data.length == newGroups.length) {
              // Alle Gruppen wurden kopiert
              structure.id = null;
              structure.attributes = {
                title: dialogConfig.data['title']
              };
              structure.relationships.gruppe.data = newGroups;
              this.isLoading = true;
              this.service.create(structure).then(() => {
                this.loadStructures();
              }).catch(() => {
                this.snackbar.open('Die neue Struktur kann nicht erstellt werden, bitte versuchen Sie es später', null, {
                  duration: 15000,
                })
              });
            }
          });
        }
      }
    });
  }
  
}
