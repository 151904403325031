/**
 * Meine-Anträge-Element
 * 
 * Attila Németh, UBG
 * 24.06.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {UbgProposal} from '../../../../../model/proposal';

@Component({
  selector: 'ubg-meine-antraege-item',
  templateUrl: './item.component.html',
})
export class UbgMeineAntraegeItemComponent {
    
  @Input() title: string = ''
  @Input() proposals: Array<UbgProposal> = [] 
  @Output() proposalSelected = new EventEmitter;
  
  open(proposal: UbgProposal) {
    this.proposalSelected.emit(proposal);
  }
  
}
