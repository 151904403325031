/**
 * Themen-Service
 * 
 * Attila Németh, UBG
 * 26.07.2019
 */
 
import {Injectable} from '@angular/core';

import {UbgContentaService} from '../../../contenta/services/contenta.service';
import {UbgThema} from '../../model/taxonomies/thema';

@Injectable({
  providedIn: 'root'
})
export class UbgThemaService {

  constructor(private contenta: UbgContentaService) {}

  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/themen/' + id).then((response: {
        data: UbgThema
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/themen?sort=weight').then((response: {
        data: Array<UbgThema>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/themen?filter[name]=' + name).then((response: {
        data: Array<UbgThema>
      }) => {
        if (response.data.length > 0) {
          resolve(response.data[0]);
        }
        else {
          console.warn('Kein Thema gefunden', name);
          reject();
        }
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(thema: UbgThema) { 
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/taxonomy_term/themen/' + thema.id, {
        data: thema
      }).then((response: {
        data: UbgThema
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(thema: UbgThema) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/taxonomy_term/themen', {
        data: thema
      }).then((response: {
        data: UbgThema
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  delete(thema: UbgThema) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/taxonomy_term/themen/' + thema.id).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}