/**
 * Beschlussprotokolle-Komponent
 * 
 * Attila Németh, UBG
 * 02.08.2019
 */
 
import {Component} from '@angular/core';

import {Router, ActivatedRoute} from '@angular/router';

import {UbgProjectService} from '../../../services/project_service';

@Component({
  templateUrl: './protokolle.component.html',
})
export class UbgProjectProtokolleComponent {
  
  projectId: string = null
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private service: UbgProjectService) {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.service.setActiveProjectId(this.projectId);
  }
  
  back() {
    this.router.navigate(['project/' + this.projectId]);
  }
  
  loadProtokoll(name: string, label: string) {
    this.service.msWordProtokoll(this.projectId, name).then((response: any) => {
      const fileName = 'Projekt-Protokoll-' + label + '.doc';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }
  
  loadBeschlussListe() {
    this.service.excelProtokoll(this.projectId).then((response: any) => {
      const fileName = 'Beschlussprotokoll.csv';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }

}
