/**
 * Empfehlungen-Komponent
 * 
 * Attila Németh, UBG
 * 06.06.2019
 */
 
import {Component, Input} from '@angular/core';

@Component({
  selector: 'ubg-project-antrag-empfehlung',
  templateUrl: './empfehlung.component.html',
})
export class UbgProjectAntragEmpfehlungComponent {
  
  @Input() texts: Array<string> = []
  
  constructor() {}
  
}