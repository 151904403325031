/**
 * Bestätigungsdialog
 * 
 * Attila Németh, UBG
 * 08.04.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './confirm.component.html',
})
export class UbgConfirmComponent {
  
  constructor(private dialogRef: MatDialogRef<UbgConfirmComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                question: string
                hint?: string
              }) {}
  
}