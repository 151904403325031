/**
 * Empfehlung-Dialog für Sonstige Anträge (C)
 * 
 * Attila Németh, UBG
 * 06.06.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {UbgEmpfehlungType} from '../../../../../../../../../model/empfehlung_type';
import {UbgEmpfehlungTypeService} from '../../../../../../../../../services/empfehlung_type.service';
import {UbgZiffer} from '../../../../../../../../../model/ziffer';
import {UbgZifferService} from '../../../../../../../../../services/ziffer.service';
import {UbgProposal} from '../../../../../../../../../model/proposal';
import {UbgProposalService} from '../../../../../../../../../services/proposal.service';
import {UBGAntragModel} from '../../../../../../../../../model/antrag.model';
import {UBGAntragService} from '../../../../../../../../../services/antrag.service';
import {UbgEmpfehlungUberweisung} from '../../../../../../../../../model/taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungUberweisungService} from '../../../../../../../../../services/taxonomies/empfehlung_uberweisung.service';
import {UbgAutocompleteOption} from '../../../../../../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './empfehlung.component.html',
  styleUrls: ['./empfehlung.component.scss'],
})
export class UbgProposalSonstigeEmpfehlungDialogComponent implements OnInit {
  
  empfehlungTypes: Array<UbgEmpfehlungType> = []
  empfehlungTypeId: string = null
  ziffers: Array<UbgZiffer> = []
  zifferOptions: Array<{
    id: string
    label: string
  }> = []
  assignees: Array<UbgEmpfehlungUberweisung> = [];
  showGemeinsam: boolean = null
  proposalFassungOptions: Array<UbgAutocompleteOption> = []
  disabled: boolean = false
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                proposals: Array<UbgProposal>
                empfehlungType: UbgEmpfehlungType
                fields: {
                  ziffer: UbgZiffer
                  empfehlung: string
                  fassung: string
                  fassung_titel: string
                  begruendung: string
                  uberweisung_an: Array<UbgEmpfehlungUberweisung>
                  proposal: UbgProposal
                }
                gemeinsam: boolean
              },
              private snackbar: MatSnackBar,
              private service: UbgEmpfehlungTypeService,
              private ziffer: UbgZifferService,
              private antrag: UBGAntragService,
              private proposal: UbgProposalService,
              private uberweisung: UbgEmpfehlungUberweisungService) {
  }
              
  ngOnInit() {
    this.service.getAllByOptions().then((response: Array<UbgEmpfehlungType>) => {
      this.empfehlungTypes = response;
    });
    for (let i in this.data.proposals) {
      if (this.data.proposals[i].relationships.antrag === undefined) {
        this.proposal.get(this.data.proposals[i].id).then((response: UbgProposal) => {
          this.data.proposals[i] = response;
          this.setGemeinsam();
        });
      }
    }
  }
  
  private setGemeinsam() {
    if (this.data.proposals.length > 1) {
      // Gemeinsam hat nur einen Sinn, wenn es mehr Proposals sind
      for (let i in this.data.proposals) {
        if (this.data.proposals[i].relationships.antrag !== undefined
          && this.showGemeinsam === null) {
          this.showGemeinsam = false;
          this.antrag.get(this.data.proposals[i].relationships.antrag.data.id).then((response: UBGAntragModel) => {
            if (response.relationships.ubg_antrag_type.data.attributes.name !== 'Leitantrag') {
              this.showGemeinsam = true;
            }
          });
        }
      }
    }
  }
  
  selectType(event: MatSelectChange) {
    for (let i in this.empfehlungTypes) {
      if (this.empfehlungTypes[i].id == event.value) {
        this.data.empfehlungType = this.empfehlungTypes[i];
        this.empfehlungTypeId = this.empfehlungTypes[i].attributes.drupal_internal__id;
      }
    }
    if (this.empfehlungTypeId == 'behandlung_bei_ziffer' || this.empfehlungTypeId == 'durch_beschlussfassung_erledigt') {
      this.loadZiffers();
    }
    if (this.empfehlungTypeId == 'gemeinsam_mit_fassung') {
      this.loadProposalOptions();
    }
  }
  
  loadProposalOptions() {
    this.proposalFassungOptions = [];
    this.ziffer.get(this.data.proposals[0].relationships.ziffer.data.id).then((ziffer: UbgZiffer) => {
      this.ziffer.getProposals(ziffer).then((allProposals: Array<UbgProposal>) => {
        let newOptions: Array<UbgAutocompleteOption> = [];
        for(let i in allProposals) {
          newOptions.push({
            id: allProposals[i].id,
            label: allProposals[i].attributes.title,
          });
        }
        this.proposalFassungOptions = newOptions;
      });
    });
  }
  
  loadZiffers() {
    this.ziffers = [];
    this.zifferOptions = [];
    for (let i in this.data.proposals) {
      this.proposal.getProjectZiffernByProposal(this.data.proposals[i]).then((response: Array<{
        id: string
        label: string
      }>) => {
        this.zifferOptions = response;
      }).catch(() => {
        this.snackbar.open('Die Ziffern können nicht geladen werden. Code: EC-149', null, {
          duration: 15000,
        });
      });
//      this.antrag.get(this.data.proposals[i].relationships.antrag.data.id).then((antragResponse: UBGAntragModel) => {
//        for (let j in antragResponse.relationships.ziffers.data) {
//          this.ziffer.get(antragResponse.relationships.ziffers.data[j].id).then((zifferResponse: UbgZiffer) => {
//            this.ziffers.push(zifferResponse);
//          });
//        }
//      });
    }
  }
  
  selectZiffer(event: MatSelectChange) {
    this.ziffer.get(event.value).then((response: UbgZiffer) => {
      this.data.fields.ziffer = response;
    });
//    for (let i in this.ziffers) {
//      if (this.ziffers[i].id == event.value) {
//        this.data.fields.ziffer = this.ziffers[i];
//      }
//    }
  }
  
  uberweisungSelected(uberweisungen: Array<UbgEmpfehlungUberweisung>) {
    this.data.fields.uberweisung_an = uberweisungen;
  }
  
  proposalFassungOptionSelected(selectedProposalId) {
    this.disabled = true;
    this.proposal.get(selectedProposalId).then((response: UbgProposal) => {
      this.data.fields.proposal = response;
      this.disabled = false;
    });
  }
  
}
