/**
 * Statistik
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

import {UbgProject} from '../../model/project';
import {UbgFilterService} from '../../services/filter.service';
import {UbgFilterParams} from '../../model/filter_params';
import {SidetabsSyncService} from '../utility/sidetabs/service/sidetabs.sync.service';

@Component({
  selector: 'ubg-project-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class UbgProjectStatComponent {

  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.projectId = project.id;
    }
  }
  projectId: string = null
  antragId: string = null
  queryParams: {}

  constructor(private location: Location,
                private route: ActivatedRoute,
                private filterService: UbgFilterService,
                private sidetabService: SidetabsSyncService) {
    this.filterService.params.subscribe((params: UbgFilterParams) => {
      this.filterParamsChanged(params);
    });
    this.route.queryParams.subscribe((params: any) => {
      let newParams = {};
      let newQuery = {};
      for (let key in params) {
        if (key.match(/^filter_/g)) {
          newParams[key.replace('filter_', '')] = params[key];
        }
        else {
          newQuery[key] = params[key];
        }
        if (key == 'antrag') {
          this.antragId = params[key];
        }
      }
      this.filterService.setParams(newParams);
      this.queryParams = newQuery;
    });
    this.sidetabService.selected_id.subscribe((antragId: string) => {
      this.antragId = antragId;
    });
  }
  
  filterParamsChanged(params: UbgFilterParams) {
    if (this.projectId === null) {
      setTimeout(() => {
        this.filterParamsChanged(params);
      }, 100);
    }
    else {
      let queryParams = {};
      for (let key in this.queryParams) {
        queryParams[key] = this.queryParams[key];
      }
      for (let key in params) {
        if (params[key] !== '') {
          queryParams['filter_' + key] = params[key];
        }
      }
      let queryElements: Array<string> = [];
      for (let key in queryParams) {
        queryElements.push(key + '=' + queryParams[key]);
      }
      this.location.replaceState('project/' + this.projectId, queryElements.join('&'));
    }
  }
  
}
