/**
 * Passwörter-Dialog
 * 
 * Attila Németh, UBG
 * 18.06.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

@Component({
  templateUrl: './passwords.component.html',
})
export class UbgOrgUserPasswordsDialogComponent {
  
  disabled: boolean = true
 
  constructor(@Inject(MAT_DIALOG_DATA)
                  public data: {
                    selected: string,
                    options: Array<{
                      id: string
                      label: string
                    }>,
                  }) {}
             
  selectionChange(event: MatSelectChange) {
    this.data.selected = event.value;
    this.disabled = false;
  }
}
