/**
 * Ziffer-Markup-Komponent
 * 
 * Attila Németh, UBG
 * 23.10.2019
 */
 
import {Component, Input, ViewEncapsulation} from '@angular/core';

import {UbgZifferStructured} from '../../../../../../../../model/ziffer_structured';
import {UbgZifferService} from '../../../../../../../../services/ziffer.service';

@Component({
  selector: 'ubg-project-antrag-leitantrag-ziffer-markup',
  templateUrl: './markup.component.html',
  styleUrls: ['./markup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgProjectAntragLeitantragZifferMarkupComponent {
  
  @Input() set ziffer(ziffer: UbgZifferStructured) {
    this.isLoading = true;
    this.service.getMarkup(ziffer).then((response: string) => {
      this.markup = response;
      this.isLoading = false;
    }).catch(() => {
      this.markup = 'Ein unerwarteter Fehler ist Aufgetreten. Code: MK-25';
      this.isLoading = false;
    });
  }
  markup: string = ''
  isLoading: boolean = false
  
  constructor(private service: UbgZifferService) {}
  
}
