/**
 * Gruppe-Service
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgGroup} from '../model/group';
 
@Injectable({
  providedIn: 'root'
})
export class UbgGroupService {
  
  constructor(private contenta: UbgContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_org_group/ubg_org_group').then((response: {
        data: Array<UbgGroup>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_org_group/ubg_org_group/' + id).then((response: {
        data: UbgGroup
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
  create(group: UbgGroup) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_org_group/ubg_org_group', {
        data: group
      }).then((response: {
        data: UbgGroup
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(group: UbgGroup) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_org_group/ubg_org_group/' + group.id, {
        data: group
      }).then((response: {
        data: UbgGroup
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  copy(group: UbgGroup) {
    let promise = new Promise((resolve, reject) => {
      this.get(group.id).then((response: UbgGroup) => {
        response.id = null;
        response.attributes = {
          title: response.attributes.title,
          kurzbezeichnung: response.attributes.kurzbezeichnung,
        };
        this.create(response).then((response: UbgGroup) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      });
    });
    return promise;
  }
  
}