/**
 * Org-User-Service
 * 
 * Attila Németh, UBG
 * 02.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgOrgUser} from '../model/org_user';
import {UbgProject} from '../model/project';
import {UbgAccessToken} from '../model/access_token';
import {UbgPriorityStructure} from '../model/priority_structure';
 
@Injectable({
  providedIn: 'root'
})
export class UbgOrgUserService {
  
  constructor(private contenta: UbgContentaService) {}

  get(type: string, id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_org_user/' + type + '/' + id).then((response: {
        data: UbgOrgUser
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getLimited(limit: number, offset: number, filter?: {
    name?: string
  }) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/api/org/user/' + limit + '/' + offset;
      if (filter !== undefined && filter.name !== undefined && filter.name !== '') {
        path += '?filter[name]=' + filter.name;
      }
      this.contenta.get(path).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/org/user/all').then((response: Array<UbgOrgUser>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getbyStructure(structureId: string, limit: number, offset?: number, filter?: {
    name?: string
  }) {
    let promise = new Promise((resolve, reject) => {
      if (offset === undefined) {
        offset = 0;
      }
      let path: string = 'ubg/api/org/user/' + limit + '/' + offset + '?structure=' + structureId;
      for (let key in filter) {
        if (filter[key] !== '') {
          path += '&filter[' + key + ']=' + filter[key];
        }
      }
      this.contenta.get(path).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getNamesByStructure(structureId: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/api/org/user/names?structure=' + structureId;
      this.contenta.get(path).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getEmpty(): UbgOrgUser {
    let emptyOrgUser = new UbgOrgUser;
    emptyOrgUser.attributes = {};
    emptyOrgUser.relationships = {
      gruppe: {
        data: []
      },
      mandant: {
        data: [],
      },
      struktur: {
        data: [],
      }
    };
    return emptyOrgUser;
  }
  
  create(orgUser: UbgOrgUser) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/' + orgUser.type.replace('--', '/');
      this.contenta.post(path, {
        data: orgUser
      }).then((response: {
        data: UbgOrgUser
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(orgUser: UbgOrgUser) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/' + orgUser.type.replace('--', '/') + '/' + orgUser.id;
      this.contenta.patch(path, {
        data: orgUser
      }).then((response: {
        data: UbgOrgUser
      }) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  download(project: UbgProject, structure: UbgPriorityStructure, filter: {
    name?: string
    gruppe?: string
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/org/users/download';
      this.contenta.postGetFile(path, {
        filter: filter,
        structure: structure.id,
        project: project.id,
      }).then((response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  passwords(project: UbgProject, selected: string, orgUsers?: Array<UbgOrgUser>) {
    let data = {
      selected: selected,
      orgUsers: []
    };
    for (let i in orgUsers) {
      data.orgUsers.push(orgUsers[i].id);
    }
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/org/users/' + project.id + '/passwords';
      this.contenta.post(path, data).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  delete(orgUser: UbgOrgUser) {
    let promise = new Promise((resolve, reject) => {
      const path = 'api/' + orgUser.type.replace('--', '/') + '/' + orgUser.id;
      this.contenta.delete(path).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getCurrentLoggedIn() {
    let promise = new Promise((resolve, reject) => {
      const path = 'api/org/user/current';
      this.contenta.get(path).then((response: UbgOrgUser) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getLongNameBy(projectId: string, orgUser: UbgOrgUser) {
    let promise = new Promise((resolve, reject) => {
      const path = 'ubg/project/' + projectId + '/org/user/' + orgUser.id + '/long/name';
      this.contenta.get(path).then((response: UbgOrgUser) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}