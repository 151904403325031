/**
 * Projekt auswählen
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Component, OnInit} from '@angular/core';

import {UbgSelectEntity} from '../../../select_entity/model/entity';
import {UbgProjectService} from '../../services/project_service';
import {UbgProject} from '../../model/project';

@Component({
  selector: 'ubg-select-project',
  templateUrl: './select.component.html',
})
export class UbgSelectProjectComponent implements OnInit {

  projectOptions: Array<UbgSelectEntity>
  activeProjectPath: string = ''

  constructor(private service: UbgProjectService) {
    this.service.createdProjectIds.subscribe(() => {
      this.loadProjects();
    });
  }
  
  ngOnInit() {    
    this.loadProjects();
  }
  
  loadProjects() {
    this.service.getAll().then((response: Array<UbgProject>) => {
      let newOptions = [];
      for (let i in response) {
        newOptions.push({
          label: response[i].attributes.title,
          path: 'project/' + response[i].id,
        });
      }
      this.projectOptions = newOptions;
    });
    this.service.activeProjectIds.subscribe((projectId: string) => {
      setTimeout(() => {
        this.activeProjectPath = 'project/' + projectId;
      }, 200);
    });
  }

}