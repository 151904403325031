import { Injectable } from '@angular/core';

import {UbgContentaService} from '../../../contenta/services/contenta.service';
import {AntragStatusModel} from '../../model/taxonomies/antrag_status.model';

@Injectable({
  providedIn: 'root'
})
export class AntragStatusService {

  constructor(private contenta: UbgContentaService) {
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/antrag_status').then((response: {
        data: Array<AntragStatusModel>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

  /**
   * Returns the status taxonomy entity . It can be used to be referenced inside the antrag entity.
   * @param name, first it is draft but hen change to Numbered after antrag numbers are generated.
   * @returns a promise
   */
  getByName(name: string) {
    let path = 'api/taxonomy_term/antrag_status?filter[name]=' + name;
    let promise = new Promise((resolve, reject) => {
      this.contenta.get(path).then((response: {
        data: AntragStatusModel
      }) => {
        resolve(response.data[0]);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

}
