/**
 * Proposal
 *
 * Attila Németh, UBG
 * 18.04.2019
 */

import {UBGAntragModel} from './antrag.model';
import {UbgBegrundung} from './begrundung';
import {UbgEmpfehlung} from './empfehlung';
import {UbgOrgUser} from './org_user';
import {UbgZiffer} from './ziffer';
import {UbgProposalItem} from './proposal_item';
import {UbgProposalStatus} from './taxonomies/proposal_status';
import {UbgThema} from './taxonomies/thema';

export class UbgProposal {

  id?: string;
  type: string = 'ubg_proposal--ubg_proposal';
  attributes: {
    title: string
    org_user_name?: string
    new_content?: any // it can be just plain string or Contenta formatted text field object
    zuweisungsdatum?: string
    custom_title?: string // user entered title for the proposal. (MAX 100 characters)
    is_draft?: boolean
    changed?: number
    bearbeitung?: string
  };
  relationships: {
    antrag: {
      data: UBGAntragModel
    }
    begrundung?: {
      data: UbgBegrundung
    }
//    // Empfehlungen nur bei C
//    empfehlungen?: {
//      data: Array<UbgEmpfehlung>
//    }
    org_user: {
      data: Array<UbgOrgUser>
    }
    // Nicht bei C
    ziffer?: {
      data: UbgZiffer
    }
    item: {
      data: Array<UbgProposalItem>
    }
    // Geladen durch Empfehlung
    empfehlung?: {
      data: Array<UbgEmpfehlung>
    }
    zustandigkeit?: {
      data: {
        type: string
        id: string
      }
    }
    status?: {
      data: UbgProposalStatus
    }
    thema?: {
      data: UbgThema
    }
  };
}
