import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class SidetabsSyncService {

  selected: string;

  private _selected_id = new BehaviorSubject(this.selected);
  public readonly selected_id = this._selected_id.asObservable();

  constructor() { }

  set_selected_id(selected_id: string) {
    if (this.selected !== selected_id) {
      this.selected = selected_id;
      this._selected_id.next(selected_id);
    }
  }

}
