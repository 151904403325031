import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material';

import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {UBGAntragModel} from '../../model/antrag.model';
import {SidetabModel} from '../utility/sidetabs/model/sidetab.model';
import {UbgProject} from '../../model/project';
import {UbgOrgUser} from '../../model/org_user';

import {UbgProjectService} from '../../services/project_service';
import {UBGAntragService} from '../../services/antrag.service';
import {SidetabsSyncService} from '../utility/sidetabs/service/sidetabs.sync.service';
import {UbgZifferService} from '../../services/ziffer.service';
import {UbgOrgUserService} from '../../services/org_user.service';

import {UbgContentaUserService} from '../../../contenta/user/services/user.service';
import {TokenResponse} from '../../../contenta/model/token.response';

@Component({
  selector: 'app-antrag-meine',
  templateUrl: './antrag-meine.component.html',
  styleUrls: ['./antrag-meine.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AntragMeineComponent implements OnInit, OnDestroy {
  loginCode: string = '';
  isLoading: boolean = false;
  hasError: boolean = false;
  project: UbgProject = null;
  selectedTab = new FormControl(0);
  sideAntragTabs: SidetabModel[] = [];
  selectedAntragId: string = null;
  antragsObservable = new Subject<SidetabModel>();
  selectedAntrag: UBGAntragModel = null;
  accessedUser: UbgOrgUser = null;
  selectedOrgUsers: UbgOrgUser[] = [];

  private subSidetabs: any;
  private subProject: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private projectService: UbgProjectService,
              private antragService: UBGAntragService,
              public sidetabSyncService: SidetabsSyncService,
              private snackbar: MatSnackBar,
              private user: UbgContentaUserService,
              private ziffer: UbgZifferService,
              private orgUserService: UbgOrgUserService) {
    this.projectService.setActiveProjectId(this.route.snapshot.paramMap.get('id'));
  }


  ngOnInit() {

    this.loadProject(this.projectService.activeProjectId);
    this.subProject =  this.projectService.activeProjectIdData.pipe(debounceTime(1000)).subscribe((value) => {
        if (this.project === null) {
          this.loadProject(value);
        }
    });

    this.subSidetabs = this.sidetabSyncService.selected_id.subscribe((value) => {
      if (value !== undefined &&
          value !== null &&
          this.selectedAntragId !== value) {
/*        console.log('we also notice the change:');
        console.log(value);*/
        this.receiveSelectedTabChanged(value);
      }
    });

    this.antragsObservable.pipe(debounceTime(200)).subscribe(resp => {
      this.sortAlphabetically();
      if (this.project !== null && this.selectedAntragId === undefined || this.selectedAntragId === null) {
        this.sidetabSyncService.set_selected_id(this.sideAntragTabs[0].id);
      }
    });

  }
  ngOnDestroy() {
    this.subSidetabs.unsubscribe();
    this.antragsObservable.unsubscribe();
    this.subProject.unsubscribe();
  }

  loadProject(id: string) {
    console.log('proid');
    console.log(id);
    this.isLoading = true;
    this.projectService.get(id).then((response: UbgProject) => {
      this.project = response;
      console.log(this.project);
       this.loadProjectAntrage(); // load the project antrags  to the sidetab component
      this.hasError = false;
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in get project request');
      this.hasError = true;
      this.isLoading = false;
    });
  }

  loadAntrag(id: string) {
    this.antragService.get(id).then((response: UBGAntragModel) => {
      this.selectedAntrag = response;
      console.log(response);
    }).catch(() => {
    });
  }

  loadProjectAntrage() {
    this.sideAntragTabs = [];
    // this.selectedAntragId = null;
    for (let i in this.project.relationships.antrage.data) {
      this.antragService.get(this.project.relationships.antrage.data[i].id).then((response: UBGAntragModel) => {
        this.project.relationships.antrage.data[i] = response;
        // make the antrage tabs array
        this.sideAntragTabs.push({id: response.id, label: response.attributes.letter, info: response.attributes.description });
        this.antragsObservable.next({id: response.id, label: response.attributes.letter, info: response.attributes.description }) ;
      }).catch((e) => {
        console.log(e);
        console.log('ERROR in getting project antrags');
      });
    }
  }

  sortAlphabetically(): void {
    // sort the antrage tabs array alphabetically
    this.sideAntragTabs = this.sideAntragTabs.sort((obj1, obj2) => {
      if (obj1.label > obj2.label) {
        return 1;
      }
      if (obj1.label < obj2.label) {
        return -1;
      }
      return 0;
    });
  }

  receiveSelectedOrgUsersChange($event) {
    this.selectedOrgUsers = $event;
  }

  receiveSelectedTabChanged(value) {
    this.selectedAntragId = value;
    this.loadAntrag(value);
  }

  setTabIndex($event) {
    this.selectedTab.setValue($event);
  }

  back() {
    this.antragsObservable.unsubscribe();
    this.router.navigate(['project/' + this.project.id], {
      queryParams: {
        antrag: this.selectedAntragId,
      }
    });
  }

  loadOrgUser() {
    this.orgUserService.getCurrentLoggedIn().then((response: any) => {
      this.accessedUser = response.data;
      this.selectedOrgUsers.push(response.data);
      // this.projectService.setActiveProjectId(this.route.snapshot.paramMap.get('id'));
      this.loadProject(this.route.snapshot.paramMap.get('id'));
    });
  }

  loginSubmit() {
    let params = {
      grant_type: 'ubg_antrag',
      code: this.loginCode,
      project: this.projectService.activeProjectId,
    };
    this.user.requestToken(params).then((response: TokenResponse) => {
      this.user.isLoggedIn = false;
      this.user.isAuthorized = true;
      this.user.updateUserLoginNeeded();
      this.user.setAccessCode(this.loginCode);
      this.loginCode = '';
      this.loadOrgUser();
    }).catch(() => {
      this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', null, {
        duration: 15000,
      });
    });
  }

}
