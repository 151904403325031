/**
 * Statistik-Service
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../../contenta/services/contenta.service';

import {UbgStatisticsSummary} from '../model/summary';
import {UbgStatisticsDetails} from '../model/details'

@Injectable({
  providedIn: 'root'
})
export class UbgStatisticsService {

  constructor(private contenta: UbgContentaService) {}
  
  getSummary(path: string, refresh: boolean, filterParams: {}) {
    let data = {
      refresh: refresh,
      filter: filterParams
    };
    let promise = new Promise((resolve, reject) => {
      this.contenta.post(path, data).then((response: UbgStatisticsSummary) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getDetails(path: string, refresh: boolean, filterParams: {}) {
    let data = {
      refresh: refresh,
      filter: filterParams
    };
    let promise = new Promise((resolve, reject) => {
      this.contenta.post(path, data).then((response: UbgStatisticsDetails) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}