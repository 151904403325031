/**
 * Projekt-Statistik-Komponent
 * 
 * Attila Németh, UBG
 * 31.07.2019
 */
 
import {Component, Input, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {UbgProject} from '../../../../model/project';

@Component({
  selector: 'ubg-dashboard-stat',
  templateUrl: './stat.component.html',
})
export class UbgProjectDashboardStatComponent implements OnInit {

  @Input() project: UbgProject  
  
  constructor(private router: Router) {}
                
  ngOnInit() {}
  
  back() {
    this.router.navigate(['project/' + this.project.id]);
  }
  
}