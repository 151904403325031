/**
 * Ziffer-Überweisen an
 * 
 * Attila Németh, UBG
 * 24.05.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

import {UbgUser} from '../../../../../../model/user';
import {UbgZiffer} from '../../../../../../model/ziffer';
import {UbgZifferService} from '../../../../../../services/ziffer.service';
import {UbgAutocompleteOption} from '../../../../../../../ubg_components/autocomplete/model/option';

@Component({
  // Das gleiche Formular, aber mit anderen Benutzern.
  templateUrl: './assignment.component.html',
})
export class UbgZifferAssignmentDialogComponent implements OnInit {
  
  personOptions: Array<UbgUser>
  personFilteredOptions: Array<UbgAutocompleteOption> = []
  searchString: string = ''
  hasError: boolean = false
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                ziffer: UbgZiffer
                users: Array<UbgUser>
              },
              private service: UbgZifferService) {
  }
              
  ngOnInit() {
    this.loadPersons();
  }
  
  loadPersons() {
    let users = [];
    let userIds = {};
    this.service.getReda(this.data.ziffer).then((response: Array<UbgUser>) => {
      let newOptions = [];
      for (let i in response) {
        newOptions.push(response[i]);
      }
      this.personOptions = newOptions;
      let freeOptions: Array<UbgAutocompleteOption> = [];
      for (let i in response) {
        let isFree: boolean = true;
        for (let j in this.data.users) {
          if (response[i].id == this.data.users[j].id) {
            isFree = false;
          }
        }
        if (isFree) {
          freeOptions.push({
            id: response[i].id,
            label: response[i].attributes.nachname + ', ' + response[i].attributes.vorname,
          });
        }
      }
      this.personFilteredOptions = freeOptions;
      this.searchString = '';
    }).catch(() => {
      this.hasError = true;
    });
  }
  
  getFullName(user: UbgUser): string {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == user.id) {
        return this.personOptions[i].attributes.nachname + ', ' + this.personOptions[i].attributes.vorname;
      }
    }
    return '...';
  }
  
  removeUser(user: UbgUser): void {
    let newUsers: Array<UbgUser> = [];
    for (let i in this.data.users) {
      if (this.data.users[i].id !== user.id) {
        newUsers.push(this.data.users[i]);
      }
    }
    this.data.users = newUsers;
    this.loadPersons();
  }
  
  userSelected(userId: string) {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == userId) {
        this.searchString = this.personOptions[i].attributes.name;
        this.data.users.push(this.personOptions[i]);
      }
    }
    this.loadPersons();
  }
 
 
}
