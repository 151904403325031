/**
 * Dashboard Meine Anträge
 * 
 * Attila Németh, UBG
 * 30.07.2019
 */
 
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {Router} from '@angular/router';

import {Sort} from '@angular/material/sort';

import {UbgProject} from '../../../../model/project';
import {UbgProposal} from '../../../../model/proposal';
import {UbgProposalStructured} from '../../../../model/proposal_structured';
import {UbgProjectService} from '../../../../services/project_service';
import {UbgProposalService} from '../../../../services/proposal.service';

@Component({
  selector: 'ubg-dashboard-meine',
  templateUrl: './meine.component.html',
  styleUrls: ['./meine.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgProjectDashboardMeineComponent implements OnInit {
  
  @Input() project: UbgProject
  myProposals: Array<UbgProposalStructured> = []
  displayedColumns = ['title', 'org_user_name', 'custom_title', 'read', 'process', 'closed', 'assign', 'assigned', 'goto'];
  sortParams: Sort = {
    active: 'zuweisung',
    direction: 'desc',
  }
  
  constructor(private router: Router,
                private service: UbgProjectService,
                private proposalService: UbgProposalService) {}
                
  ngOnInit() {
    this.loadProposals();
  }
  
  loadProposals() {
    this.service.getMyProposals(this.project, this.sortParams.active, this.sortParams.direction).then((response: Array<UbgProposalStructured>) => {
      this.myProposals = response;
    }).catch(() => {
      this.myProposals = [];
    });
  }
  
  back() {
    this.router.navigate(['project/' + this.project.id]);
  }
  
  readProposal(proposal: UbgProposalStructured) {
    this.proposalService.setRead(proposal).then((response: UbgProposalStructured) => {
      this.loadProposals();
    });
  }
  
  getReadClass(proposal: UbgProposalStructured) {
    if (proposal.relationships.gelesen === undefined) {
      return 'unread';
    }
  }
  
  getZuweisungTimestamp(proposal: UbgProposalStructured) {
    const d = new Date(proposal.attributes.zuweisungsdatum);
    return d.getTime();
  }

  gotoProposal(proposal: UbgProposalStructured) {
    this.proposalService.getStructured(proposal.id).then((responseS: UbgProposalStructured) => {
      this.proposalService.get(proposal.id).then((response: UbgProposal) => {
        responseS.relationships.ziffer = response.relationships.ziffer;
        this.proposalService.setActiveProposal(responseS);
        this.router.navigate(['project/' + this.project.id], {
          queryParams: {
            antrag: response.relationships.antrag.data.id,
          }
        });
      });
    })
  }
  
  sortData(sort: Sort) {
    this.sortParams = sort;
    this.loadProposals();
  }
  
  getZugewiesen(proposal: UbgProposalStructured) {
    let names: Array<string> = [];
    for (let i in proposal.relationships.zustandigkeit.data) {
      names.push(proposal.relationships.zustandigkeit.data[i].attributes.nachname + ', '
        + proposal.relationships.zustandigkeit.data[i].attributes.vorname);
    }
    return names.join(' | ');
  }

}
