/**
 * Beschluss-Dialog
 * 
 * Attila Németh, UBG
 * 14.06.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './beschluss.component.html',
  styleUrls: ['./print.component.scss'],
})
export class UbgPrintBeschlussDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                mitMarkups: boolean
              }) {}
                
}
