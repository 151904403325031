/**
 * Priority Structure
 * 
 * Attila Németh, UBG
 * 05.04.2019
 */
 
import {UbgGroup} from './group';
 
export class UbgPriorityStructure {
  
  id: string
  type: string = 'ubg_priority_structure--ubg_priority_structure'
  attributes: {
    title: string
  }
  relationships: {
    gruppe: {
      data: Array<UbgGroup>
    }
  }
  
}