/**
 * Sonstige Anträge
 * 
 * Attila Németh, UBG
 * 04.06.2019
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs';

import {PageEvent} from '@angular/material/paginator';

import {UBGAntragModel} from '../../../../../../model/antrag.model';
import {UBGAntragService} from '../../../../../../services/antrag.service';
import {UbgProposalStructured} from '../../../../../../model/proposal_structured';
import {UbgProjectService} from '../../../../../../services/project_service';
import {UbgFilterService} from '../../../../../../services/filter.service';
import {UbgFilterParams} from '../../../../../../model/filter_params';
import {UbgProposalService} from '../../../../../../services/proposal.service';
import {UbgLastChangeService} from '../../../../../../services/lastchange.service';

@Component({
  selector: 'ubg-project-antrag-sonstige',
  templateUrl: './sonstige.component.html',
})
export class UbgProjectAntragSonstigeComponent implements OnInit, OnDestroy {
  
  @Input() set antrag(antrag: UBGAntragModel) {
    this.sonstigeAntrag = antrag;
    this.offset = 0;
    this.loadProposals();
    this.lastChangeService.addAntrag(antrag);
  }
  sonstigeAntrag: UBGAntragModel = null
  proposals: Array<UbgProposalStructured> = []
  isLoading: boolean = false
  hasError: boolean = false
  filterParams: UbgFilterParams = {}
  projectId: string = null
  offset: number = 0
  pager: number = 0
  count: number = 0
  pageSizeOptions: Array<number> = [10, 25, 50, 100, 500]
  showPaginator: boolean = false
  themeLabels: {}
  activeProposalSubscription: Subscription
  
  constructor(private service: UBGAntragService,
                private projectService: UbgProjectService,
                private filterService: UbgFilterService,
                private proposalService: UbgProposalService,
                private lastChangeService: UbgLastChangeService) {
    this.projectId = this.projectService.activeProjectId;
    this.projectService.activeProjectIds.subscribe((projectId: string) => {
      if (projectId !== this.projectId) {
        this.projectId = projectId;
        this.loadProposals();
      }
    });
    this.filterService.params.subscribe((params: UbgFilterParams) => {
      let currentParams = JSON.stringify(this.filterParams);
      let newParams = JSON.stringify(params);
      if (newParams !== currentParams) {
        this.offset = 0;
        this.filterParams = JSON.parse(newParams);
        this.loadProposals();
      }
    });
  }
  
  ngOnInit() {
    this.activeProposalSubscription = this.proposalService.activeProposal.subscribe((activeProposal: UbgProposalStructured) => {
      if (activeProposal === null) {
        delete this.filterParams.active;
      }
      else {
        this.filterParams.active = activeProposal.id;
      }
    });
  }
  
  ngOnDestroy() {
    this.activeProposalSubscription.unsubscribe();
    delete this.filterParams.active;
    this.lastChangeService.removeAntrag(this.sonstigeAntrag);
  }
 
  loadProposals() {
    if (this.sonstigeAntrag === null) {
      return;
    }
    this.isLoading = true;
    this.service.getSonstigeProposals(this.projectId, this.sonstigeAntrag, this.filterParams, this.offset, this.pager).then((response: {
      data: Array<UbgProposalStructured>,
      meta: {
        count: number
        offset: number
        pager: number
      }
    }) => {
      this.proposals = response.data;
      let lastLabel: string = '';
      this.themeLabels = {};
      for (let i in response.data) {
        this.themeLabels[response.data[i].id] = '';
        if (this.sonstigeAntrag.attributes.sortierung == 'theme') {
          let label:string = 'Ohne Thema';
          if (response.data[i].relationships.thema !== undefined)  {
            label = response.data[i].relationships.thema.data.attributes.name;
          }
          if (label !== lastLabel) {
            lastLabel = label;
            this.themeLabels[response.data[i].id] = label;
          }
        }
      }
      this.isLoading = false;
      this.hasError = false;
      if (response.meta.count > response.meta.pager) {
        this.showPaginator = true;
        this.pager = response.meta.pager;
        this.count = response.meta.count;
      }
      else {
        this.showPaginator = false;
      }
    }).catch((error) => {
      console.warn('HAS ERROR');
      console.warn(error);
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  proposalChanged() {
    this.loadProposals();
  }
  
  paginatorPaged(event: PageEvent) {
    this.pager = event.pageSize;
    this.offset = event.pageIndex * event.pageSize;
    this.loadProposals();
  }
  
  back() {
    delete this.filterParams.active;
    this.loadProposals();
  }
  
}