import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild } from '@angular/core';

import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as CKEDITOR from '../../../../../modules/ckeditor5-build-ubg'; @TODO

import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgOrgUser} from '../../../model/org_user';
import {UbgProject} from '../../../model/project';
import {UbgZiffer} from '../../../model/ziffer';
import {UbgZifferService} from '../../../services/ziffer.service';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgProposalService} from '../../../services/proposal.service';
import {UbgProposalItemService} from '../../../services/proposal_item.service';
import {UbgProposalActionTypeService} from '../../../services/taxonomies/proposal_action_type.service';
import {UbgProposal} from '../../../model/proposal';
import {UbgProposalItem} from '../../../model/proposal_item';
import {UbgProposalActionType} from '../../../model/taxonomies/proposal_action_type';
import {MatSnackBar} from '@angular/material';
import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {ProposalReasonBottomSheetComponent} from '../proposal-reason-sheet/proposal-reason-sheet';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {LeitantragZifferEditableComponent} from './leitantrag-ziffer-editable/leitantrag-ziffer-editable.component';
import {TextHelperService} from '../../../services/helpers/text-helper.service';
import {MatDialog} from '@angular/material/dialog';
import {LeitantragProposalConfirmationDialogComponent} from './leitantrag-proposal-confirmation-dialog/leitantrag-proposal-confirmation-dialog.component';
import {UbgProjectService} from '../../../services/project_service';

@Component({
  selector: 'app-leitantrag-proposal',
  templateUrl: './leitantrag-proposal.component.html',
  styleUrls: ['./leitantrag-proposal.component.scss', '../antrag_mein.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LeitantragProposalComponent implements OnInit, AfterViewChecked  {

  @Input() set accessedUser(accessedUser: UbgOrgUser) {
    if (accessedUser !== null) {
      this.orgUser = accessedUser;
    }
  }
  orgUser: UbgOrgUser;

  @Input() set antrag(antrag: UBGAntragModel) {
    if (antrag !== null) {
      this.activeAntrag = antrag;
      if ( this.selectedOrgUsers ) {
        const users = this.selectedOrgUsers;
        this.selectedOrgUsers = [];
        for (let i = 0 ; i < users.length; i++) {
          this.selectOrgUser(users[i].id, false);
        }
      }
    }
  }
  activeAntrag: UBGAntragModel;

  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.selectedProject = project;
      this.loadOrgUsers();
    }
  }
  selectedProject: UbgProject;

  @Input() set orgUsers(orgUsers: UbgOrgUser[]) {
    if (orgUsers !== null && this.selectedOrgUsers.length === 0) {
      for (let i = 0 ; i < orgUsers.length; i++) {
        this.selectOrgUser(orgUsers[i].id, false);
      }
    }
  }
  selectedOrgUsers: UbgOrgUser[] = [];

  @Output() selectedOrgUsersEvent: EventEmitter<UbgOrgUser[]> = new EventEmitter();

  @ViewChild (LeitantragZifferEditableComponent) editableZifferComponent: LeitantragZifferEditableComponent;

  proposal: UbgProposal;
  proposalItem: UbgProposalItem;
  freeName = false;
  orgUserName = '';
  orgUserOptions: Array<{
    id: string
    label: string
  }> = [];

/*
  public Editor = InlineEditor;
  public Config = {
    language: 'de',
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
*/
  originalZiffer: any;
  lineDiff: string;
  bottomSheetSign = '+';
  isLoading = false;
  hasError = false;
  isVotingTimeOver = false;
  needScroll = false;

  container: HTMLElement;

  isEnhancedEditorActive = false;
  isFallbackEditorActive = true;
  fallbackEditorZiffer: UbgZiffer = null;
  fallbackEditorProposal: UbgProposal = null;

  constructor( private snackbar: MatSnackBar,
               private zifferService: UbgZifferService,
               private proposalService: UbgProposalService,
               private proposalItemService: UbgProposalItemService,
               private proposalTypeService: UbgProposalActionTypeService,
               private orgUserService: UbgOrgUserService,
               private projectService: UbgProjectService,
               private user: UbgContentaUserService,
               private textHelperService: TextHelperService,
               public leitantragProposalDialog: MatDialog,
               private proposalReasonBottomSheet: MatBottomSheet) {  }

  ngOnInit() {
    if (this.isEnhancedEditorActive && this.isFallbackEditorActive) {
      console.error('!! Beide Editors sind aktiv !!');
      this.isEnhancedEditorActive = false;
    }


    this.initialize();
    this.checkDate();
  }
  ngAfterViewChecked() {
    if ( this.needScroll ) {
      this.scrollToZiffer();
      this.needScroll = false;
    }
  }
  initialize() {
    this.proposal = {
      type: 'ubg_proposal--ubg_proposal',
      attributes: {
        title: '',
        new_content: '',
        custom_title: '',
        is_draft: true,
      },
      relationships: {
        antrag: {
          data: null,
        },
        org_user: {
          data: [],
        },
        item: {
          data: [],
        }
      }
    };
    this.proposalItem = {
      type: 'ubg_proposal_item--ubg_proposal_item',
      attributes: {
        content: '',
        reason: {
          value: ''
        },
        keine_begruendung: false,
      },
      relationships: {
        action: {
          data: null,
        }
      }
    };
  }

  /**
   * We need to check to see if project accept proposal
   */
  checkDate() {
//    const today = new Date();
//    const endDate = new Date (this.selectedProject.attributes.end_voting_date);
//    if (today > endDate) {
//      this.isVotingTimeOver = true;
//    }
    if (this.projectService.isExpired(this.selectedProject)) {
      this.isVotingTimeOver = true;
    }
  }

  /**
   * Find the expanded ziffer and scroll to it
   */
  scrollToZiffer(): void {
    // find the expanded ziffer and scroll to it
    for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
      if (this.activeAntrag.relationships.ziffers.data[i].isExpanded ) {
        console.log('this one is expanded ');
        console.log(this.activeAntrag.relationships.ziffers.data[i]);
        try {
          console.log('come on');
          this.container = document.getElementById(this.activeAntrag.relationships.ziffers.data[i].id);
          this.container.scrollIntoView();

        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  loadAntragZiffers() {

    if (!this.selectedProject) {
      console.error('Projekt ID', this.selectedProject);
      return;
    }
    if (!this.activeAntrag) {
      console.error('Antrag', this.activeAntrag);
      return;
    }
    if (this.activeAntrag.relationships.status.data === undefined) {
      this.snackbar.open('Der Leitantrag steht noch nicht bereit', null, {
        duration: 30000,
      });
      return;
    }


    this.activeAntrag.relationships.ziffers.data = [new UbgZiffer()]; // when the org user is not specified, we cannot allow proposal creations
    if (this.activeAntrag.relationships.ziffers.data &&
        this.activeAntrag.relationships.ziffers.data.length > 0 &&
        this.selectedOrgUsers.length > 0) {
      this.isLoading = true;
      this.zifferService.getAllByAntrag( this.activeAntrag.id).then((response: UbgZiffer[]) => {
        this.activeAntrag.relationships.ziffers.data = response;
        this.isLoading = false;

      });
    }
  }

  loadOrgUsers() {
    if ( this.user.isLoggedIn) {
      this.orgUserService.getNamesByStructure(this.selectedProject.relationships.priority_structure.data.id).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        this.orgUserOptions = [];
        let newOptions = [];
        for (let i in response.data) {
          let label:string = response.data[i].attributes.title;
          if (response.data[i].attributes['kurzbezeichnung'] !== '') {
            label = response.data[i].attributes['kurzbezeichnung'] + ' ' + label;
          }
          let isFree = true;
          for (let j in this.selectedOrgUsers) {
            if (response.data[i].id === this.selectedOrgUsers[j].id) {
              isFree = false;
            }
          }
          if (isFree) {
            let newOption = {
              id: response.data[i].id,
              label: label,
            };
            newOptions.push(newOption);
          }
        }
        this.orgUserOptions = newOptions;
      });
    }
  }


  loadZifferLines(zifferId: string, force: boolean = false) {
    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      // Loading the already existing ziffers of the selected antrag
      for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
        if (zifferId === this.activeAntrag.relationships.ziffers.data[i].id) {
          // if it is not already loaded, or if the force reload flag is on
          if (this.activeAntrag.relationships.ziffers.data[i].attributes.content === undefined ||
            this.activeAntrag.relationships.ziffers.data[i].attributes.content === '' ||
            force ) {
            this.zifferService.get(zifferId).then((ziffer: UbgZiffer) => {
              // so we need to put the lines together @todo, no not here, find a better way to check if the ziffer is already loaded
              ziffer.attributes.content = ''; // if it is not defined
              // sort the ziffer lines
              ziffer.relationships.lines.data = this.sortByNumber(ziffer.relationships.lines.data);
              this.lineDiff = ''; // get rid of previous values
              for (let j = 0; j < ziffer.relationships.lines.data.length; j++) {
                ziffer.attributes.content += ziffer.relationships.lines.data[j].attributes.content.value;
                // before any proposal is loaded the diff text is the same
                // this.lineDiff.push(ziffer.relationships.lines.data[j].attributes.content.value);
              }
              this.activeAntrag.relationships.ziffers.data[i] = ziffer;
              this.activeAntrag.relationships.ziffers.data[i].isExpanded = true;
              this.loadZifferProposals(ziffer);
            }).catch((e) => {
              console.log(e);
              console.log('ERROR in load ziffer');
            });
          } else {
            // the ziffer is already loaded we just set the line diff
            this.lineDiff = ''; // get rid of previous values
/*            for (let j = 0; j < this.activeAntrag.relationships.ziffers.data[i].relationships.lines.data.length; j++) {
              // before any proposal is loaded the diff text is the same
              this.lineDiff.push(this.activeAntrag.relationships.ziffers.data[i].relationships.lines.data[j].attributes.content.value);
            }*/
            this.activeAntrag.relationships.ziffers.data[i].isExpanded = true;
          }
        } else {
          this.activeAntrag.relationships.ziffers.data[i].isExpanded = false;
        }
      }
    }
  }

  sortByNumber(arr) {
    // sort the antrage tabs array alphabetically
    arr = arr.sort((obj1, obj2) => {
      if (obj1.attributes.number > obj2.attributes.number) {
        return 1;
      }
      if (obj1.attributes.number < obj2.attributes.number) {
        return -1;
      }
      return 0;
    });
    return arr;
  }

  loadZifferProposals(ziffer: UbgZiffer) {
    // TODO: Who is the user?! using access code?
    let users = this.selectedOrgUsers;
    // console.log('the users:');
    // console.log(users);
    this.proposalService.getZifferProposalsByOrgUser(ziffer.id, users).then((response: UbgProposal[]) => {
      // console.log('the proposal:');
      // console.log(response);
/*      for (let p = 0; p < response.length; p++) {
        response[p].relationships.ziffer.data = ziffer;
      }*/
      for (let i = 0 ; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
        if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
          this.activeAntrag.relationships.ziffers.data[i].proposals = this.parsProposalData(response);
          console.log('the ziffer:');
          console.log(this.activeAntrag.relationships.ziffers.data[i]);
        }
      }

    }).catch((e) => {
      console.log(e);
      console.log('ERROR in load ziffer proposals');
    });
  }
  parsProposalData(proposals) {
    for (let i in proposals) {
      if (proposals[i].attributes.is_draft) {
        proposals[i].status = 'Entwurf';
      } else {
        proposals[i].status = 'Gesendet';
      }
      if (proposals[i].relationships.status === undefined) {
        // Der Status kann nicht geladen werden, z.B: weil der Zugriff fehlt.
        proposals[i].status = '';
      } else if (proposals[i].relationships.status.data !== null &&
        proposals[i].relationships.status.data.attributes.name === 'Angenommen') {
        proposals[i].status = 'Zugestimmt';
      } else if (proposals[i].relationships.status.data !== null &&
        proposals[i].relationships.status.data.attributes.name === 'Abgelehnt') {
        proposals[i].status = 'Abgelehnt';
      }
      proposals[i].editable = false;
      proposals[i].orgUsers = '';
    }
    return proposals;
  }

  /**
   * This method switches the ziffer container to the editor so user can edit the content
   * @param ziffer
   */
  createEditableZiffer(ziffer) {
    this.initialize();
    this.lineDiff = '';
    for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++ ) {
      if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
        this.activeAntrag.relationships.ziffers.data[i].editable = true;
        // we need to keep the original content before user start editing it.@TODO I had to do this trick here to get a real copy
        this.originalZiffer = JSON.parse(JSON.stringify( this.activeAntrag.relationships.ziffers.data[i] ));
        // this.originalZiffer = {...this.activeAntrag.relationships.ziffers.data[i]};
      }
    }
  }

  createEditableZifferProposal(ziffer, proposal) {
    this.proposal = proposal;
    this.proposalItem = proposal.relationships.item.data[0];
    for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++ ) {
      if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
        this.activeAntrag.relationships.ziffers.data[i].editable = true;
        // we need to keep the original content before user start editing it.@TODO I had to do this trick here to get a real copy
        this.originalZiffer = JSON.parse(JSON.stringify( this.activeAntrag.relationships.ziffers.data[i] ));
        // this.originalZiffer = {...this.activeAntrag.relationships.ziffers.data[i]};
      }
    }
  }

  onInlineEditorChange($event) {
    // console.log($event); // it is changed. So what?! do we need this?
  }

  getEditorContent() {
    let response = [];
    // first we consider that the user has removed everything
    for (let i = 0; i < this.originalZiffer.relationships.lines.data.length; i++) {
      // we remove the whole content but keep the html tags.
      response.push(this.textHelperService.stripText(this.originalZiffer.relationships.lines.data[i].attributes.content.value)); // That's an empty line in CKeditor 5.
    }
    const content = this.editableZifferComponent.getContent();
    console.warn('the data from the editor');
    console.warn(content);
    if (content) {
      // let updatedLines =  this.textHelperService.breakContentToLines(this.textHelperService.structureData(content));
      let updatedLines =  this.textHelperService.breakEditedContentToLines(content);
      console.log('AND THE Updated LINES ARE:');
      console.log(updatedLines);
      let maxSim = 0.40; // if it ain't that similar then it ain't the line we are looking for
      let lineIndex = -1;
      let sim = 0;
      for ( let i = 0; i < this.originalZiffer.relationships.lines.data.length; i++ ) {
        maxSim = 0.40;
        lineIndex = -1;
        for ( let j = 0; j < updatedLines.length; j++ ) {
          // the line are 100% equal then no need to do further actions
          // NOTICE: only the similarty of the text matters and not he html tags
          if (this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[i].attributes.content.value) ===
              this.textHelperService.stripHTMLTags(updatedLines[j] )) {
            maxSim = 1;
            lineIndex = j;
            break;
          } else if ( updatedLines[j].includes(this.originalZiffer.relationships.lines.data[i].attributes.content.value) ) {
            // if the whole line still exist in new line, it is most probably the same line with added new content.
            // we just give it a better chance
            sim = this.textHelperService.similarity(this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[i].attributes.content.value), this.textHelperService.stripHTMLTags( updatedLines[j]) ) * 2 ;
          } else {
            sim = this.textHelperService.similarity(this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[i].attributes.content.value), this.textHelperService.stripHTMLTags(updatedLines[j]) );
          }
          if ( sim > maxSim) {
            maxSim = sim;
            lineIndex = j;
          }
        }
        if (lineIndex !== -1) {
          response[i] = updatedLines[lineIndex];
          // is there some data added before this line?
          let newContent = [];
          let newLinesCount = 0;
          for (let k = 0; k < lineIndex; k++) {
            newContent.push(updatedLines[k]);
            newLinesCount++;
          }
          if (newContent) {
            let newContentText = '';
            console.log('the new content is"' + newContent );
            for ( let l = 0; l < newLinesCount; l++) {
              if ( i - (newLinesCount - l) >= 0 && this.textHelperService.stripHTMLTags(response[i - (newLinesCount - l)]) === '') {
                // then it is not new content but a replaced line with new content
                response[i - (newLinesCount - l)] = newContent[l];
              } else {
                newContentText += newContent[l];
              }
            }
            if (newContentText) {
              // response[i] =  newContentText  + response[i]  ;
              if ( i === 0 ) { // we added before the first line
                response[i] =  newContentText  + response[i]  ;
              } else { // we added after the last line
                response[i - 1] =  response[i - 1] + newContentText ;
              }
            }
          }
          // now that it is added we get rid of it so we don't have to compare it with other lines
          updatedLines.splice(0, newLinesCount + 1);
        }
      }
      // if there is anything left in the newlines, it is the text that have been added to the end of ziffer so we added after the last line.
      if (updatedLines.length > 0) {
        response[response.length - 1] +=  updatedLines.join('');
      }
    }
    return response;
  }

  onProposalSave(ziffer) {
    this.isLoading = true;
    let type = 'Ersetzen';
    let lineDiff: any; // an object which represent the text diff between 2 strings
    let startIndex = -1;  // the index of the first line with diff from the original line
    let endIndex = 0; // the index of the last line with diff from the original line
    let total = endIndex - startIndex + 1; // the total number of effected line
    let deleted = 0; // number of lines that are completely removed
    let inserted = 0; // number of lines that have only insert type change
    let edited = 0; // number of lines that have both insert and delete types of changes
    let notChanged = 0; // number of lines in between that have not changed

    if ( !this.proposalItem.attributes.keine_begruendung && !this.proposalItem.attributes.reason.value) {
      this.snackbar.open('Bitte geben Sie die Begründung an', null, {
        duration: 7500,
      });
      this.isLoading = false;
      return;
    }
    // get the lines from the editor component
    const editedLines = this.getEditorContent();
    console.log('and the content here');
    console.log(editedLines);

    // we keep the new content to show the text diff later
    this.proposal.attributes.new_content = editedLines.join('');
    // looking for the edited lines
    for (let j = 0; j < ziffer.relationships.lines.data.length; j++ ) {
      // we update our data with new data from the editor component
      ziffer.relationships.lines.data[j].attributes.content.value = editedLines[j];
      // if it is different from the original content
      if (this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[j].attributes.content.value) !==
        this.textHelperService.stripHTMLTags(ziffer.relationships.lines.data[j].attributes.content.value)) {

        if (startIndex === -1) {
          startIndex = j;
        }
        endIndex = j;
      }
    }
    if (startIndex === -1) { // no change detected
      this.isLoading = false;
      this.snackbar.open('Sie haben keine Änderung vorgeschlagen!', null, {
        duration: 5000,
      });
    } else {
      this.proposalItem.attributes.content = '<p>Ziffer' + ziffer.attributes.number + ', '; // in case of edit we need to get rid of the previous content. Also, this is the heading of the antrag representing the ziffer number.
      this.proposalItem.attributes.from = ziffer.relationships.lines.data[startIndex].attributes.number;
      if (startIndex !== endIndex) {
        this.proposalItem.attributes.to = ziffer.relationships.lines.data[endIndex].attributes.number; // no need when it is only one line
        this.proposalItem.attributes.content += 'Zeilen ' + ziffer.relationships.lines.data[startIndex].attributes.number + ' - ' + ziffer.relationships.lines.data[endIndex].attributes.number;
      } else {
        this.proposalItem.attributes.content += 'Zeile ' + ziffer.relationships.lines.data[startIndex].attributes.number;
      }
      this.proposalItem.attributes.content += '</p>';

      for ( let l = startIndex; l <= endIndex; l++) {
        // a line can be completely removed
        if (!ziffer.relationships.lines.data[l].attributes.content.value || this.textHelperService.stripHTMLTags(ziffer.relationships.lines.data[l].attributes.content.value) === '') {
          deleted++;
          this.proposalItem.attributes.content += '<p>Streiche: Zeile ' + ziffer.relationships.lines.data[l].attributes.number + '.</p>';
        // a line can be untouched between the lines with changes
        } else if ( ziffer.relationships.lines.data[l].attributes.content.value === this.originalZiffer.relationships.lines.data[l].attributes.content.value) {
          notChanged++;
        } else {
          // a line can break in many lines!
          const newlines = this.textHelperService.breakContentToLines(ziffer.relationships.lines.data[l].attributes.content.value, true);
          if (newlines.length > 1) {
            // new text is in the end?
            if (this.textHelperService.stripHTMLTags(newlines[0]) === this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[l].attributes.content.value)) {
              inserted++;
              let preText = '';
              if (endIndex === (ziffer.relationships.lines.data.length - 1)) {
                // it is the last line of the paragraph
                preText = '<p>Am Ende der Ziffer folgenden Text einfügen:</p>';
              } else {
                preText = '<p>Am Ende Zeile ' + ziffer.relationships.lines.data[l].attributes.number + ' folgenden Text einfügen:</p>';
              }
              this.proposalItem.attributes.content += preText + newlines.slice(1).join(''); // remove one from the start. the original line.
              // new text is in the beginning?
            } else if ( this.textHelperService.stripHTMLTags(newlines[newlines.length - 1]) === this.textHelperService.stripHTMLTags(this.originalZiffer.relationships.lines.data[l].attributes.content.value) ) {
              inserted++;
              let preText = '';
              if (endIndex === 0) {
                // it is the first line of the paragraph
                preText = '<p>Am Anfag der Ziffer folgenden Text einfügen:</p>';
              } else {
                preText = '<p>Am Anfag Zeile ' + ziffer.relationships.lines.data[l].attributes.number + ' folgenden Text einfügen:</p>';
              }
              newlines.pop(); // remove one from the end. the original line.
              this.proposalItem.attributes.content += preText + newlines.join('');
            } else { // well it is complicated then, many lines and the original text is not in the beginning or end of the line
              edited++;
              this.proposalItem.attributes.content += '<p>Ersetze Zeile ' + ziffer.relationships.lines.data[l].attributes.number + ' durch nachfolgenden Text:</p>' +
                ziffer.relationships.lines.data[l].attributes.content.value;
            }

          } else { // just one line
            // we look to see if the proposal type is insert, delete, or update(just one line has been changed)
            lineDiff = this.getLineChangeDiff(this.originalZiffer.relationships.lines.data[l].attributes.content.value,
              ziffer.relationships.lines.data[l].attributes.content.value);
            edited++;
            console.log('this is the line diff result:');
            console.log(lineDiff);
            if (lineDiff && (lineDiff.deletedCount > 0 || lineDiff.insertedCount > 0) ) {
              this.proposalItem.attributes.content += '<p>Zeile ' + ziffer.relationships.lines.data[l].attributes.number + ': </p>';
              if (lineDiff.deletedCount > 0 && lineDiff.deleted.join('').trim()) {// igonre if it is just a space
                // Is the whole line removed or only part of it?
                if (lineDiff.deleted.join(' ') === this.originalZiffer.relationships.lines.data[l].attributes.content.value.replace(/<(?:.|\n)*?>/gm, '')) {
                  this.proposalItem.attributes.content += '<p>Streiche: Die ganze Zeile </p>';
                } else {
                  this.proposalItem.attributes.content += '<p>Streiche: ' +  lineDiff.deleted.join(' ') + '</p>';
                }
              }
              if (lineDiff.insertedCount > 0 && lineDiff.inserted.join('').trim() !== '&nbsp;') {// igonre if it is just a space
                this.proposalItem.attributes.content += '<p>Füge ein: ' +  lineDiff.inserted.join(' ') + '</p>';
              }
            }
          }
        }

      }
      console.log('total:');
      console.log(total);
      console.log('deleted:');
      console.log(deleted);
      total = endIndex - startIndex + 1;
      // new we analyze the result of all the line diffs in one
      if (total === deleted) {
        type = 'Löschen';
        this.proposalItem.attributes.content = '<p>Ziffer' + ziffer.attributes.number + '</p>';
        if ( deleted === 1 ) { // if it is only one line
          this.proposalItem.attributes.content += '<p>Streiche: Zeile ' + ziffer.relationships.lines.data[startIndex].attributes.number + '.</p>';
        } else {
          this.proposalItem.attributes.content += '<p>Streiche: Zeilen ' + ziffer.relationships.lines.data[startIndex].attributes.number + ' bis ' + ziffer.relationships.lines.data[endIndex].attributes.number + '.</p>';
        }
      } else if (total + notChanged === inserted) {
        type = 'Hinzufügen';
      } else {
        type = 'Ersetzen';
      }

    // we check to see if user agree with the automatically created proposal
    this._askForConfirmation(ziffer, type, this.proposalItem.attributes.content);
    }
    this.isLoading = false;
  }

  /**
   * this will allow user to confirm the automatically created proposal
   */
  private _askForConfirmation(ziffer, type, content) {

    const dialogRef = this.leitantragProposalDialog.open(LeitantragProposalConfirmationDialogComponent, { data: {content: content}, width: '50%'} );

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.proposalItem.attributes.content = result;
        this._proceedProposalCreation(ziffer, type);
      }
    });
  }

  private _proceedProposalCreation(ziffer, type) {
    this.proposalTypeService.getByName(type).then((response: UbgProposalActionType) => {
      // console.log(response);
      this.proposalItem.relationships.action.data = response;

      // and we create the proposal item and the proposal or edit when user updating and already excisting proposal
      // if proposal has id then it is already created
      if (this.proposal.id) {
        this.editProposalItem();
      } else {
        this.createProposalItem(ziffer);
      }
      // change back from edit mode to normal
      for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++ ) {
        if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
          this.activeAntrag.relationships.ziffers.data[i] = this.originalZiffer;
          this.activeAntrag.relationships.ziffers.data[i].editable = false;
          this.cancelShowDiff(ziffer.id);
        }
      }
      this.isLoading = false;
    });
    this.isLoading = false;
  }

  /**
   * Returns an object
   * @param left
   * @param right
   * @returns {any}
   */
  getLineChangeDiff(left, right) {
    let diff = {
      'type': '',
      'deleted': [],
      'inserted': [],
      'deletedCount': 0,
      'insertedCount': 0,
      'total': 0
    };

    if (left === right) {
        return false; // they are the same
    }
    let leftArr = left.replace(/<(?:.|\n)*?>/gm, '').split(' '); // strip the html and split to array
    let rightArr;
    if ( right === '<p></p>') { // when it is just an empty line
      rightArr = [];
    } else {
      rightArr = right.replace(/<(?:.|\n)*?>/gm, '').split(' ');
    }

/*
    console.log('left');
    console.log(leftArr);
    console.log('right');
    console.log(rightArr);
*/

    // We cannot use the sets, because of cardinality problem.
 /*    let s1 = new Set(s1_arr); // create the set
    let s2 = new Set(s2_arr);

    diff.deleted = Array.from(s1).filter(x => !s2.has(x)); // what s1 has that s2 hasn't? the words that has been REMOVED
    diff.inserted = Array.from(s2).filter(x => !s1.has(x)); // what s2 has that s1 hasn't? the words that has been INSERTED

*/
    const resp: any = this.arrayDiffs(leftArr, rightArr);
    diff.deleted = resp.onlyInArrayOne; // what s1 has that s2 hasn't? the words that has been REMOVED
    diff.inserted = resp.onlyInArrayTwo; // what s2 has that s1 hasn't? the words that has been INSERTED
/*    console.log('deleted');
    console.log(diff.deleted);
    console.log('inserted:');
    console.log(diff.inserted);*/

    diff.deletedCount = diff.deleted.length;
    diff.insertedCount = diff.inserted.length;
    diff.total = leftArr.length;

    if (diff.insertedCount === 0 && diff.deletedCount > 0 ) {
      diff.type = 'Löschen'; // these are set in german language in the backend, we can retrieve their entities with these keys
    } else if ( diff.insertedCount > 0 && diff.deletedCount === 0 ) {
      diff.type = 'Hinzufügen'; // these are set in german language in the backend, we can retrieve their entities with these keys
    } else if (diff.insertedCount > 0 && diff.deletedCount > 0 ) {
      diff.type = 'Ersetzen'; // these are set in german language in the backend, we can retrieve their entities with these keys
    }

    return diff;
  }

  /**
   * @param arrayOne the original text tokenized in an array
   * @param arrayTwo the updated text tokenized in an array
   * @returns Retruns an object of array diffs
   */
  arrayDiffs(arrayOne: string[], arrayTwo: string[]) {

    const onlyInArrayOne = this.textArrayDiff(arrayOne, arrayTwo);
    const onlyInArrayTwo = this.textArrayDiff(arrayTwo, arrayOne);

    return {
      onlyInArrayOne,
      onlyInArrayTwo,
      diff: onlyInArrayOne.concat(onlyInArrayTwo)
    };
  }
  textArrayDiff(array1, array2) {
    let [arrayOneCopy, arrayTwoCopy] = [[...array1], [...array2]];
    arrayTwoCopy = arrayTwoCopy.splice(0);
    return arrayOneCopy.filter((v,i,a) => {
      let index = arrayTwoCopy.indexOf(v);
      if (index > -1) {
        arrayTwoCopy.splice(index, 1);
        return false;
      }
      return !arrayTwoCopy.includes(v);
    });
  }

  onProposalCancel(ziffer) {
    for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++ ) {
      if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
        this.activeAntrag.relationships.ziffers.data[i] = this.originalZiffer;
        this.activeAntrag.relationships.ziffers.data[i].editable = false;
      }
    }
  }

  createProposalItem(ziffer: UbgZiffer) {
    this.isLoading = true;
    this.proposal.relationships.antrag.data = this.activeAntrag;
    this.proposal.relationships.ziffer = {'data' : ziffer};

    // @NOTICE the title is being set in the backend, no matter what we send here
    if (this.proposalItem.attributes.to) {
      this.proposal.attributes.title = this.activeAntrag.attributes.letter + ', Ziffer ' + ziffer.attributes.number + ', Zeilen ' + this.proposalItem.attributes.from + ' - ' + this.proposalItem.attributes.to;
    } else {
      this.proposal.attributes.title = this.activeAntrag.attributes.letter + ', Ziffer ' + ziffer.attributes.number + ', Zeile ' + this.proposalItem.attributes.from;
    }
    // console.log(this.proposalItem);
    // Saving the item and then the proposal itself
    this.proposalService.createItem(this.proposalItem).then(( item: UbgProposalItem ) => {
      this.proposal.relationships.item.data.push(item);
      this.isLoading = false;
      // saving the proposal
      this.addProposal();
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: LP-680', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });


  }

  editProposalItem() {
    this.isLoading = true;
    // updating the item and then the proposal itself
    this.proposalItemService.update(this.proposalItem).then(( item: UbgProposalItem ) => {
      this.isLoading = false;
      // saving the proposal
      this.editProposal();
    });


  }

  addProposal() {
    this.isLoading = true;
    this.proposal.relationships.org_user.data = this.selectedOrgUsers;
    // this.proposal.relationships.org_user.data = this.orgUser;
    // console.log(this.proposal);
    this.proposalService.create(this.proposal).then(( proposal: UbgProposal ) => {
      // console.log(proposal);
      this.loadZifferProposals(this.originalZiffer); // @notice we relaod the proposals, we can't just push it because here the proposal does not contain the includes
      this.isLoading = false;
      this.snackbar.open('Erfolgreich eingefügt!', null, {
        duration: 10000,
      });
      this.needScroll = true;
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in submit proposal request');
    });
  }

  editProposal() {
    this.isLoading = true;
    // console.log(this.proposal);
    this.proposalService.update(this.proposal).then(( proposal: UbgProposal ) => {
      // console.log(proposal);
      this.loadZifferProposals(this.originalZiffer); // @notice we relaod the proposals, we can't just push it because here the proposal does not contain the includes
      this.isLoading = false;
      this.snackbar.open('Erfolgreich eingefügt!', null, {
        duration: 10000,
      });
      this.needScroll = true;
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in submit proposal request');
    });
  }

  createLineDiff(proposal: UbgProposal, zifferId: number) {
    // when a user without closing an expansion panel, opens another, we need to run the close method.
    this.cancelShowDiff( zifferId );
    // console.log(proposal);
    if (proposal.attributes.new_content) {
      this.lineDiff = proposal.attributes.new_content.value;
    } else {
//      this.snackbar.open('Dieser Antrag wurde von der Redaktion eingetragen.', null, {
//        duration: 10000,
//      });
    }

  }

  cancelShowDiff( zifferId) {
    this.lineDiff = ''; // get rid of previous values
/*    for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
      if (zifferId === this.activeAntrag.relationships.ziffers.data[i].id) {
        for (let j = 0; j < this.activeAntrag.relationships.ziffers.data[i].relationships.lines.data.length; j++) {
          // before any proposal is loaded the diff text is the same
          this.lineDiff.push(this.activeAntrag.relationships.ziffers.data[i].relationships.lines.data[j].attributes.content.value);
        }
      }
    }*/
  }

  deleteProposal(ziffer, proposal) {
    if (confirm('Sind Sie sicher?')) {
      this.isLoading = true;
      // console.log(proposal);
      this.proposalService.delete(proposal).then(() => {

        for (let i = 0 ; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
          if (this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
            for (let j = 0; j < this.activeAntrag.relationships.ziffers.data[i].proposals.length; j++) {
              if (this.activeAntrag.relationships.ziffers.data[i].proposals[j].id === proposal.id) {
                this.activeAntrag.relationships.ziffers.data[i].proposals.splice(j, 1);
                this.cancelShowDiff(ziffer.id);
                this.isLoading = false;
                this.needScroll = true;
              }
            }
          }
        }
      }).catch((e) => {
        console.log(e);
        console.log('ERROR in delete proposal request');
        this.isLoading = false;
      });

    }
  }

  ZifferTrackByFn(index, item) {
    return item.id; // unique id corresponding to the item
  }

  ProposalTrackByFn(index, item) {
    return item.id; // unique id corresponding to the item
  }

  selectOrgUser(orgUserId: string, NeedEmit: boolean = true) {
    this.orgUserName = 'orgUserId';
    this.orgUserService.get('gliederung', orgUserId).then((response: UbgOrgUser) => {
      this.selectedOrgUsers.push(response);
      if (NeedEmit) {
        this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
      }
      this.loadOrgUsers();
      this.orgUserName = '';
      this.updateFreeName();
      this.loadAntragZiffers();
    });
  }
  removeOrgUser(orgUser: UbgOrgUser) {
    for (let i = 0 ; i < this.selectedOrgUsers.length; i++) {
      if (this.selectedOrgUsers[i].id === orgUser.id) {
        this.selectedOrgUsers.splice(i, 1);
      }
    }
    this.updateFreeName();
    this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
    this.loadAntragZiffers();
  }

  updateFreeName() {
    let freeName: boolean = false;
    for (let i in this.selectedOrgUsers) {
      if (this.selectedOrgUsers[i].attributes.freier_name === true) {
        freeName = true;
      }
    }
    this.freeName = freeName;
  }
  orgUserNameChanged(event: KeyboardEvent): void {
    if (this.proposal.attributes === undefined) {
      this.proposal.attributes = {
        title: '',
        org_user_name: '',
      };
    }
    this.proposal.attributes.org_user_name = event.target['value'];
  }

  openProposalReasonBottomSheet() {
    this.bottomSheetSign = '-';
    const bottomRef = this.proposalReasonBottomSheet.open(ProposalReasonBottomSheetComponent, { data: {content: this.proposalItem.attributes.reason.value, editable: true}});

    bottomRef.afterDismissed().subscribe(result => {
      if (result !== undefined) {
        this.proposalItem.attributes.reason.value = result;
      }
      this.bottomSheetSign = '+';
    });
  }

  openFallbackEditor(ziffer: UbgZiffer) {
    this.fallbackEditorZiffer = ziffer;
  }

  /**
   * Der Antrag, der erstellt werden soll, oder null
   */
  closeFallbackEditor(proposal: UbgProposal) {
    if (proposal === null) {
      this.loadZifferProposals(this.fallbackEditorZiffer);
      this.fallbackEditorZiffer = null;
      this.fallbackEditorProposal = null;
    }
    else {
      proposal.relationships.antrag.data = this.activeAntrag;
      proposal.relationships.org_user.data = this.selectedOrgUsers;
      this.isLoading = true;
      this.proposalService.create(proposal).then((proposal: UbgProposal) => {
        this.loadZifferProposals(this.fallbackEditorZiffer);
        this.isLoading = false;
        this.snackbar.open('Ihr Antrag wurde als Entwurf erstellt', null, {
          duration: 8000,
        });
        this.needScroll = true;
        this.fallbackEditorZiffer = null;
        this.fallbackEditorProposal = null;
      }).catch((e) => {
        console.log(e);
        this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: LF-998', null, {
          duration: 8000,
        });
        this.isLoading = false;
        this.fallbackEditorZiffer = null;
        this.fallbackEditorProposal = null;
      });
    }
  }

  closeFallbackEditorProposal(proposal: UbgProposal): void  {
    if (proposal === null) {
      let itemCounter = this.fallbackEditorProposal.relationships.item.data.length;
      for (let i in this.fallbackEditorProposal.relationships.item.data) {
        this.proposalItemService.get(this.fallbackEditorProposal.relationships.item.data[i].id).then((response: UbgProposalItem) => {
          this.fallbackEditorProposal.relationships.item.data[i] = response;
          itemCounter--;
          if (itemCounter == 0) {
            this.loadZifferProposals(this.fallbackEditorZiffer);
            this.fallbackEditorZiffer = null;
            this.fallbackEditorProposal = null;
          }
        }).catch((e) => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: LF-1044', null, {
            duration: 8000,
          });
        });
      }
    }
    else {
      this.isLoading = true;
      let itemCounter = proposal.relationships.item.data.length;
      for (let i in proposal.relationships.item.data) {
        this.proposalItemService.update(proposal.relationships.item.data[i]).then(() => {
          itemCounter--;
          if (itemCounter == 0) {
            this.proposalService.update(proposal).then((proposal: UbgProposal) => {
              this.loadZifferProposals(this.fallbackEditorZiffer);
              this.isLoading = false;
              this.snackbar.open('Ihr Antrag wurde aktualisiert', null, {
                duration: 8000,
              });
              this.needScroll = true;
              this.fallbackEditorZiffer = null;
              this.fallbackEditorProposal = null;
            }).catch((e) => {
              console.log(e);
              this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: LF-1032', null, {
                duration: 8000,
              });
              this.isLoading = false;
              this.fallbackEditorZiffer = null;
              this.fallbackEditorProposal = null;
            });
          }
        }).catch((e) => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: LF-1044', null, {
            duration: 8000,
          });
        });
      }
    }
  }

  openFallbackEditorProposal(ziffer: UbgZiffer, proposal: UbgProposal) {
    this.fallbackEditorZiffer = ziffer;
    if (proposal.relationships.item.data[0].attributes.content['value'] !== undefined) {
      proposal.relationships.item.data[0].attributes.content = proposal.relationships.item.data[0].attributes.content['value'];
    }
    this.fallbackEditorProposal = proposal;
  }

}
