/**
 * Strukturierte Daten eines Ziffers
 * 
 * Attila Németh, UBG
 * 17.05.2019
 */
 
import {UbgBegrundung} from './begrundung';
import {UbgUser} from './user';
 
export class UbgZifferStructured {
  
  type: string = 'ubg_ziffer--ubg_ziffer'
  id: string
  attributes: {
    title: string
    number: string
  }
  relationships: {
    begrundung: {
      data: UbgBegrundung
    }
    zustandigkeit: {
      data: Array<UbgUser>
    }
  }
  meta: {
    permissions: {
      edit: boolean
      delete: boolean
      assign: boolean
    }
  }
  
}