/**
 * Contenta-Benutzer-Modul
 * 
 * Attila Németh, UBG
 * 03.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';

import {ContentaUserSidebarComponent} from './components/sidebar/sidebar.component'
import {ContentaUserLoginComponent} from './components/login/login.component';
import {ContentaUserPasswordComponent} from './components/password/password.component';

const userRoutes: Routes = [
  {
    path: 'user/password',
    component: ContentaUserPasswordComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      userRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  declarations: [
    ContentaUserSidebarComponent,
    ContentaUserLoginComponent,
    ContentaUserPasswordComponent,
  ],
  exports: [
    ContentaUserSidebarComponent,
    ContentaUserLoginComponent,
  ],
  entryComponents: [
  ],
})
export class UbgContentaUserModule {

  constructor() {}

}