/**
 * Empfehlungstyp-Service
 * 
 * Attila Németh, UBG
 * 23.04.2019
 */
 
import {Injectable} from '@angular/core';

import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgEmpfehlungType} from '../model/empfehlung_type';

@Injectable({
  providedIn: 'root'
}) 
export class UbgEmpfehlungTypeService {
  
  constructor(private contenta: UbgContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_empfehlung_type/ubg_empfehlung_type/' + id).then((response: {
        data: UbgEmpfehlungType
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_empfehlung_type/ubg_empfehlung_type').then((response: {
        data: Array<UbgEmpfehlungType>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAllByOptions() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_empfehlung_type/ubg_empfehlung_type?sort=optionWeight').then((response: {
        data: Array<UbgEmpfehlungType>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}