import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

import {UBGAntragModel} from '../../../../model/antrag.model';
import {UBGAntragService} from '../../../../services/antrag.service';
import {UbgProject} from '../../../../model/project';
import {UbgFilterParams} from '../../../../model/filter_params';
import {UbgFilterService} from '../../../../services/filter.service';
import {UbgProposalService} from '../../../../services/proposal.service';

@Component({
  selector: 'app-antrage-empfehlung',
  templateUrl: './antrage-empfehlung.component.html',
  styleUrls: ['./antrage-empfehlung.component.scss']
})
export class AntrageEmpfehlungComponent implements OnInit {
    
  lastAction: string
  antrag: UBGAntragModel = null;
  isLoading: number = 0
  @Input() set antragId(antragId: string) {
    if (antragId !== null) {
      this.loadAntrag(antragId);
      this.filterParams = {};
      let queryElements: Array<string> = [
        'antrag=' + antragId
      ];
      if (this.projectId !== null) {
        this.location.replaceState('project/' + this.projectId, queryElements.join('&'));
      }
    }
  } 
  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.projectId = project.id;
    }
  }
  projectId: string = null;
  filterParams: UbgFilterParams
  queryParams: {}

  constructor(private route: ActivatedRoute,
                private service: UBGAntragService,
                private location: Location,
                private filterService: UbgFilterService,
                private proposalService: UbgProposalService) {
    this.route.queryParams.subscribe((params: any) => {
      this.filterParams = {};
      let newParams = {};
      let newQuery = {};
      for (let key in params) {
        if (key.match(/^filter_/g)) {
          newParams[key.replace('filter_', '')] = params[key];
        }
        else {
          newQuery[key] = params[key];
        }
      }
      this.filterService.setParams(newParams);
      this.queryParams = newQuery;
    });
  }
  
  loadAntrag(id: string) {
    this.service.get(id).then((response: UBGAntragModel) => {
      this.antrag = response;
      this.proposalService.clearCheckProposal();
    });
  }

  ngOnInit() {
  }

//  onCheckBoxChange(event, index, item) {
//    item.checked = !item.checked;
//
//    this.lastAction = 'index: ' + index + ', label: ' + item.label + ', checked: ' + item.checked;
//
//    console.log(index, event, item);
//  }
//  
//  proposalChecked(event: {
//    ziffer: UbgZiffer
//    proposals: Array<UbgProposal>
//  }) {
//    this.ziffer.getProposals(event.ziffer).then((response: Array<UbgProposal>) => {
//      let newProposals = [];
//      for (let i in this.checkedProposals) {
//        let thisZiffer = false;
//        for (let j in response) {
//          if (this.checkedProposals[i].id == response[j].id) {
//            thisZiffer = true;
//          }
//        }
//        if (!thisZiffer) {
//          newProposals.push(this.checkedProposals[i]);
//        }
//      }
//      for (let i in event.proposals) {
//        newProposals.push(event.proposals[i]);
//      }
//      this.checkedProposals = newProposals;
//    });
//  }
  
  proposalsUpdated() {
    if (this.antrag === null) {
      setTimeout(() => {
        this.proposalsUpdated();
      }, 100);
    }
    else {
      this.loadAntrag(this.antrag.id);
    }
  }
  
  filterParamsChanged(params: UbgFilterParams) {
    this.filterParams = params;
    let queryParams = {};
    for (let key in this.queryParams) {
      queryParams[key] = this.queryParams[key];
    }
    for (let key in params) {
      if (params[key] !== '') {
        queryParams['filter_' + key] = params[key];
      }
    }
    let queryElements: Array<string> = [];
    for (let key in queryParams) {
      queryElements.push(key + '=' + queryParams[key]);
    }
    this.location.replaceState('project/' + this.projectId, queryElements.join('&'));
    if (this.antrag !== null) {
    }
  }

}
