/**
 * Leitantrag-Fallback Komponent
 * 
 * Attila Németh, UBG
 * 13.09.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';

import {UbgZiffer} from '../../../../model/ziffer';
import {UbgProposal} from '../../../../model/proposal';
import {UbgProposalService} from '../../../../services/proposal.service';
import {UbgOrgUser} from '../../../../model/org_user';

@Component({
  selector: 'ubg-leitantrag-fallback',
  templateUrl: './fallback.component.html',
  styleUrls: ['./fallback.component.scss'],
})
export class UbgLeitantragFallbackComponent {
  
  @Input() set ziffer(ziffer: UbgZiffer) {
    this.proposalZiffer = ziffer;
    this.proposal.relationships.ziffer = {
      data: ziffer,
    };
    let min = 999999999;
    let max = 0;
    for (let i in ziffer.relationships.lines.data) {
      if (ziffer.relationships.lines.data[i].attributes.number < min) {
        min = ziffer.relationships.lines.data[i].attributes.number;
      }
      if (ziffer.relationships.lines.data[i].attributes.number > max) {
        max = ziffer.relationships.lines.data[i].attributes.number;
      }
    }
    this.lineMin = min;
    this.lineMax = max;
    this.validateProposal();
  }
  @Input() set existingProposal(proposal: UbgProposal) {
    if (proposal !== null) {
      this.proposal = proposal;
    }
  }
  @Output() close = new EventEmitter()
  proposalZiffer: UbgZiffer = null
  proposal: UbgProposal = null
  proposalValid: boolean = false
  lineMin: number = 0
  lineMax: number = 0
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
  
  constructor(private service: UbgProposalService) {
    this.proposal = this.service.getEmpty();
    this.proposal.attributes.is_draft = true;
  }
  
  validateProposal() {
    this.proposalValid = true;
    for (let delta in this.proposal.relationships.item.data) {
      if (this.proposal.relationships.item.data[delta].attributes.from === null) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.from > this.lineMax) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.from < this.lineMin) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.to === null) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.to > this.lineMax) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.to < this.lineMin) {
        this.proposalValid = false;
      }
      if (this.proposal.relationships.item.data[delta].attributes.to < this.proposal.relationships.item.data[delta].attributes.from) {
        this.proposalValid = false;
      }
    }
    for (let i in this.proposal.relationships.item.data) {
      if (this.proposal.relationships.item.data[i].attributes.keine_begruendung === false
            || this.proposal.relationships.item.data[i].attributes.keine_begruendung === undefined) {
        if (this.proposal.relationships.item.data[i].attributes.reason === null) {
          this.proposalValid = false;
          this.proposal.relationships.item.data[i].attributes.reason = {
            value: '',
          };
        }
        else if (this.proposal.relationships.item.data[i].attributes.reason.value === null
              || this.proposal.relationships.item.data[i].attributes.reason.value === '') {
          this.proposalValid = false;      
        }
      }
    }
  }
  
  selectLineFrom(event: Event) {
    this.proposal.relationships.item.data[0].attributes.from = event.target['value'] * 1;
    this.validateProposal();
  }

  selectLineTo(event: Event) {
    this.proposal.relationships.item.data[0].attributes.to = event.target['value'] * 1;
    this.validateProposal();
  }
  
  save() {
    this.close.emit(this.proposal);
  }
  
  cancel() {
    this.close.emit(null);
  }

}
