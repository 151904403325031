/**
 * Proposal-Fallback
 *
 * Attila Németh, UBG
 * 29.04.2019
 */

import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {MatBottomSheet, MatSnackBar} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';

import {UbgProposal} from '../../../model/proposal';
import {UbgProposalStructured} from '../../../model/proposal_structured';
import {UbgProposalService} from '../../../services/proposal.service';
import {UbgProjectService} from '../../../services/project_service';
import {UbgProject} from '../../../model/project';
import {UbgOrgUser} from '../../../model/org_user';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgZiffer} from '../../../model/ziffer';
import {UbgZifferService} from '../../../services/ziffer.service';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UBGAntragService} from '../../../services/antrag.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {UbgConfirmComponent} from '../../../../ubg_components/confirm/components/confirm.component';
import {UbgProposalActionType} from '../../../model/taxonomies/proposal_action_type';
import {UbgProposalActionTypeService} from '../../../services/taxonomies/proposal_action_type.service';
import {UbgZifferStructured} from '../../../model/ziffer_structured';
import {UbgBegrundung} from '../../../model/begrundung';
//import {ProposalReasonBottomSheetComponent} from '../../antrag-meine/proposal-reason-sheet/proposal-reason-sheet';

@Component({
  selector: 'app-fallback.component',
  templateUrl: './fallback.component.html',
  styleUrls: ['./fallback.component.scss'],
})
export class UbgProposalFallbackComponent {

  project: UbgProject
  proposal: UbgProposal = null
  proposalValid: boolean = false
  orgUserOptions: Array<{
    id: string
    label: string
  }> = []
  orgUserName: string = ''
  antragOptions: Array<{
    id: string
    label: string
  }> = []
  ziffers: Array<UbgZifferStructured>
  zifferOptions: Array<{
    id: string
    label: string
  }> = []
  lineMin: number = -1
  lineMax: number = -1
  isLoading: boolean = false
  hasError: boolean = false
  actionTypes: Array<UbgProposalActionType> = []
  antragType: string = 'Leitantrag'
  backAntragId: string = ''
  freeName: boolean = false
  enabled: boolean = true
  gotoProposal: boolean = false
  bottomSheetSign = '+';
  previousOrgUsers: Array<UbgOrgUser> = []

  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private service: UbgProposalService,
              private projectService: UbgProjectService,
              private orgUser: UbgOrgUserService,
              private antrag: UBGAntragService,
              private ziffer: UbgZifferService,
              private actionType: UbgProposalActionTypeService,) {
    this.route.params.subscribe((params: {
      id: string
      antrag: string
    }) => {
      this.proposal = this.service.getEmpty();
      // there is no darft state when proposal is sent in fallback page
      this.proposal.attributes.is_draft = false;
      this.projectService.setActiveProjectId(params.id);
      this.loadProject();
      this.backAntragId = params.antrag;
    });
  }

  loadProject() {
    this.isLoading = true;
    this.projectService.get(this.projectService.activeProjectId).then((response: UbgProject) => {
      this.project = response;
//      let projectEnd = new Date(this.project.attributes.end_voting_date);
//      let projectStart = new Date(this.project.attributes.start_voting_date);
//      let today = new Date();
//      if (projectEnd >= today && projectStart <= today) {
        this.loadOrgUsers();
        this.loadAntraege();
        this.loadActionTypes();
        this.isLoading = false;
        this.hasError = false;
//      }
//      else {
//        console.warn('Projekt', projectStart, projectEnd);
//        this.enabled = false;
//        this.isLoading = false;
//        this.hasError = false;
//      }
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }

  loadOrgUsers() {
    this.orgUser.getNamesByStructure(this.project.relationships.priority_structure.data.id).then((response: {
      data: Array<UbgOrgUser>
    }) => {
      this.orgUserOptions = [];
      let newOptions = [];
      for (let i in response.data) {
        let label:string = response.data[i].attributes.title;
        if (response.data[i].attributes['kurzbezeichnung'] !== '') {
          label = response.data[i].attributes['kurzbezeichnung'] + ' ' + label;
        }
        let isFree = true;
        for (let j in this.proposal.relationships.org_user.data) {
          if (response.data[i].id == this.proposal.relationships.org_user.data[j].id) {
            isFree = false;
          }
        }
        if (isFree) {
          let newOption = {
            id: response.data[i].id,
            label: label,
          };
          newOptions.push(newOption);
        }
      }
      this.orgUserOptions = newOptions;
    });
  }

  loadAntraege() {
    for (let i in this.project.relationships.antrage.data) {
      if (this.project.relationships.antrage.data[i].attributes === undefined) {
        this.antrag.get(this.project.relationships.antrage.data[i].id).then((response: UBGAntragModel) => {
          this.project.relationships.antrage.data[i] = response;
          this.updateAntragOptions();
        });
      }
    }
    this.updateAntragOptions();
  }

  loadActionTypes() {
    this.actionType.getAll().then((response: Array<UbgProposalActionType>) => {
      this.actionTypes = response;
    });
  }

  updateAntragOptions() {
    let newOptions = {};
    for (let i in this.project.relationships.antrage.data) {
      if (this.project.relationships.antrage.data[i].attributes !== undefined) {
        let title = this.project.relationships.antrage.data[i].attributes.title;
        if (this.project.relationships.antrage.data[i].relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag'
              && this.project.relationships.antrage.data[i].relationships.ziffers.data.length == 0) {
          title += ' (Kein Inhalt)';
        }
        if (this.project.relationships.antrage.data[i].relationships.ubg_antrag_type.data.attributes.name != 'Leitantrag'
          || !this.freeName) {
          newOptions[this.project.relationships.antrage.data[i].attributes.letter + '-' + this.project.relationships.antrage.data[i].id] = {
            id: this.project.relationships.antrage.data[i].id,
            label: title.trim(),
          };
        }
      }
    }
    let ordered = {};
    Object.keys(newOptions).sort().forEach(function(key) {
      ordered[key] = newOptions[key];
    });
    this.antragOptions = [];
    for (let i in ordered) {
      this.antragOptions.push(ordered[i]);
    }
  }

  loadZiffers() {
    this.ziffers = [];
    this.antrag.getStructuredZiffers(this.project.id, this.proposal.relationships.antrag.data, {}).then((response: Array<UbgZifferStructured>) => {
      for (let i in response) {
        this.ziffers.push(response[i]);
      }
      this.updateZifferOptions();
    });
    this.updateZifferOptions();
  }

  updateZifferOptions() {
    let unSorted = {};
    for (let i in this.ziffers) {
      unSorted[this.ziffers[i].attributes.number] = i;
    }
    let sorted = {};
    Object.keys(unSorted).sort().forEach(function(key) {
      sorted[key] = unSorted[key];
    });
    let newOptions = [];
    for (let i in sorted) {
      newOptions.push({
        id: this.ziffers[sorted[i]].id,
        label: this.ziffers[sorted[i]].attributes.number + ' (' + this.ziffers[sorted[i]].attributes.title + ')',
      });
    }
    this.zifferOptions = newOptions;
  }

  back() {
    this.router.navigate(['project/' + this.project.id], {
      queryParams: {
        antrag: this.backAntragId,
      }
    });
  }

  selectOrgUser(orgUserId: string) {
    this.orgUserName = 'orgUserId';
    this.orgUser.get('gliederung', orgUserId).then((response: UbgOrgUser) => {
      this.proposal.relationships.org_user.data.push(response);
      this.loadOrgUsers();
      this.validateProposal();
      this.orgUserName = '';
      this.updateFreeName();
    });
  }

  selectAntrag(antragId: string) {
    for (let i in this.project.relationships.antrage.data) {
      if (this.project.relationships.antrage.data[i].id == antragId) {
        this.proposal.relationships.antrag.data = this.project.relationships.antrage.data[i];
        this.loadZiffers();
        this.antragType = this.proposal.relationships.antrag.data.relationships.ubg_antrag_type.data.attributes.name;
        if (this.antragType == 'Leitantrag') {
          this.proposal.relationships.ziffer = null;
        }
        else if (this.proposal.relationships.antrag.data.relationships.ziffers.data.length > 0) {
          this.proposal.relationships.ziffer = {
            data: this.proposal.relationships.antrag.data.relationships.ziffers.data[0],
          };
        }
        else {
          this.addDummyZiffer();
        }
      }
    }
  }
  
  addDummyZiffer() {
    if (this.proposal.relationships.antrag.data.relationships.ziffers.data.length == 0) {
      this.antrag.createDummyZiffer(this.proposal.relationships.antrag.data).then((response: UbgZiffer) => {
        this.proposal.relationships.ziffer = {
          data: response,
        }
      });
    }
  }

//  addDummyZiffer() {
//    if (this.proposal.relationships.antrag.data.relationships.ziffers.data.length == 0) {
//      let ziffer = new UbgZiffer;
//      ziffer.attributes = {
//        title: this.proposal.relationships.antrag.data.attributes.letter + ' Anträge',
//        number: 0,
//      };
//      ziffer.relationships = {
//        lines: {
//          data: [],
//        }
//      };
//      this.ziffer.create(ziffer).then((createdZiffer: UbgZiffer) => {
//        this.proposal.relationships.antrag.data.relationships.ziffers.data = [createdZiffer];
//        this.antrag.update(this.proposal.relationships.antrag.data).then((response: UBGAntragModel) => {
//          this.proposal.relationships.antrag.data = response;
//          this.proposal.relationships.ziffer = {
//            data: createdZiffer,
//          };
//        });
//      });
//    }
//    else {
//      this.proposal.relationships.ziffer = {
//        data: this.proposal.relationships.antrag.data.relationships.ziffers.data[0],
//      };
//    }
//  }

  selectZiffer(zifferId: string) {
    this.ziffer.get(zifferId).then((response: UbgZiffer) => {
      this.proposal.relationships.ziffer = {
        data: response,
      };
      let min = 999999999;
      let max = 0;
      for (let i in response.relationships.lines.data) {
        if (response.relationships.lines.data[i].attributes.number < min) {
          min = response.relationships.lines.data[i].attributes.number;
        }
        if (response.relationships.lines.data[i].attributes.number > max) {
          max = response.relationships.lines.data[i].attributes.number;
        }
      }
      this.lineMin = min;
      this.lineMax = max;
      this.validateProposal();
    });
  }

  selectLineFrom(event: Event) {
    this.proposal.relationships.item.data[0].attributes.from = event.target['value'] * 1;
    this.validateProposal();
  }

  selectLineTo(event: Event) {
    this.proposal.relationships.item.data[0].attributes.to = event.target['value'] * 1;
    this.validateProposal();
  }

  selectText({editor}: ChangeEvent) {
    this.proposal.relationships.item.data[0].attributes.content = editor.getData();
    this.validateProposal();
  }

  selectAction(event: MatSelectChange) {
    for (let i in this.actionTypes) {
      if (this.actionTypes[i].id == event.value) {
        this.proposal.relationships.item.data[0].relationships.action.data = this.actionTypes[i];
      }
    }
    this.validateProposal();
  }

  validateProposal() {
    this.proposalValid = true;
    if (this.proposal.relationships.org_user.data === null) {
      this.proposalValid = false;
    }
    if (this.proposal.relationships.antrag === undefined) {
      this.proposalValid = false;
    }
    else if (this.proposal.relationships.antrag.data === null) {
      this.proposalValid = false;
    }
    if (this.antragType == 'Leitantrag') {
      if (this.proposal.relationships.ziffer === undefined) {
        this.proposalValid = false;
      }
      else if (this.proposal.relationships.ziffer.data === null) {
        this.proposalValid = false;
      }
    }
    for (let delta in this.proposal.relationships.item.data) {
      if (this.antragType == 'Leitantrag') {
        if (this.proposal.relationships.item.data[delta].attributes.from === null) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.from > this.lineMax) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.from < this.lineMin) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.to === null) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.to > this.lineMax) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.to < this.lineMin) {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.to < this.proposal.relationships.item.data[delta].attributes.from) {
          this.proposalValid = false;
        }
//        if (this.proposal.relationships.item.data[delta].relationships.action.data === null) {
//          this.proposalValid = false;
//        }
//        else if (this.proposal.relationships.item.data[delta].relationships.action.data.attributes === undefined) {
//          this.proposalValid = false;
//        }
//        else if (this.proposal.relationships.item.data[delta].relationships.action.data.attributes.title !== 'Löschen') {
//          if (this.proposal.relationships.item.data[delta].attributes.content === null) {
//            this.proposalValid = false;
//          }
//        }
      }
      else {
        if (this.proposal.attributes.custom_title === null || this.proposal.attributes.custom_title === '') {
          this.proposalValid = false;
        }
        if (this.proposal.relationships.item.data[delta].attributes.content === null) {
          this.proposalValid = false;
        }
      }
    }
    for (let i in this.proposal.relationships.item.data) {
      if (this.proposal.relationships.item.data[i].attributes.keine_begruendung === false
            || this.proposal.relationships.item.data[i].attributes.keine_begruendung === undefined) {
        if (this.proposal.relationships.item.data[i].attributes.reason.value === null
              || this.proposal.relationships.item.data[i].attributes.reason.value === '') {
          this.proposalValid = false;      
        }
      }
    }
  }

  cancel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie den Antrag verwerfen?',
      hint: 'Diese Aktion kann nicht zurückgesellt werden',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.back();
      }
    });
  }

  save() {
    this.isLoading = true;
    this.proposal.attributes.is_draft = false;
    this.previousOrgUsers = this.proposal.relationships.org_user.data;
    this.service.create(this.proposal).then((response: UbgProposal) => {
      this.snackbar.open('Der Antrag wurde gespeichert', null, {
        duration: 6000
      });
      this.antragOptions = [];
      this.zifferOptions = [];
      this.loadAntraege();
      this.lineMin = -1;
      this.lineMax = -1;
      this.proposal = this.service.getEmpty();
      this.antragType = 'Leitantrag';
      this.isLoading = false;
      if (this.gotoProposal) {
        this.service.getStructured(response.id).then((responseStructured: UbgProposalStructured) => {
          responseStructured.relationships.ziffer = response.relationships.ziffer;
          this.service.setActiveProposal(responseStructured);
          this.router.navigate(['project/' + this.project.id], {
            queryParams: {
              antrag: response.relationships.antrag.data.id,
            }
          });
        });
      }
    }).catch((e) => {
      console.warn(e);
      this.snackbar.open('Der Antrag kann nicht gespeichert werden', null, {
        duration: 15000
      });
      this.isLoading = false;
    });
  }

  removeOrgUser(orgUser: UbgOrgUser) {
    for (let i = 0 ; i < this.proposal.relationships.org_user.data.length; i++) {
      if (this.proposal.relationships.org_user.data[i].id === orgUser.id) {
        this.proposal.relationships.org_user.data.splice(i, 1);
      }
    }
    this.updateFreeName();
  }

  updateFreeName() {
    let freeName: boolean = false;
    for (let i in this.proposal.relationships.org_user.data) {
      if (this.proposal.relationships.org_user.data[i].attributes.freier_name === true) {
        freeName = true;
      }
    }
    this.freeName = freeName;
    this.updateAntragOptions();
  }

  orgUserNameChanged(event: KeyboardEvent): void {
    if (this.proposal.attributes === undefined) {
      this.proposal.attributes = {
        title: '',
        org_user_name: '',
      };
    }
    this.proposal.attributes.org_user_name = event.target['value'];
  }

  areLinesOk() {
    if (this.proposal.relationships.item.data[0].attributes.from === null) {
      return false;
    }
    if (this.proposal.relationships.item.data[0].attributes.to === null) {
      return false;
    }
    if (this.proposal.relationships.item.data[0].attributes.from < this.lineMin) {
      return false;
    }
    if (this.proposal.relationships.item.data[0].attributes.from > this.lineMax) {
      return false;
    }
    if (this.proposal.relationships.item.data[0].attributes.to < this.lineMin) {
      return false;
    }
    if (this.proposal.relationships.item.data[0].attributes.to > this.lineMax) {
      return false;
    }
    return true;
  }
  
  getPreviousOrgUserLabel(): string {
    if (this.previousOrgUsers.length == 1) {
      return this.previousOrgUsers[0].attributes.title;
    }
    else if (this.previousOrgUsers.length == 2) {
      let labels: Array<string> = [
        this.previousOrgUsers[0].attributes.title,
        this.previousOrgUsers[1].attributes.title,
      ];
      return labels.join(' und ');
    }
    else {
      return this.previousOrgUsers.length + ' Gliederungen';
    }
  }
  
  applyPreviousOrgUser(): void {
    this.proposal.relationships.org_user.data = this.previousOrgUsers;
    this.loadOrgUsers();
    this.validateProposal();
    this.orgUserName = '';
    this.updateFreeName();
  }

//  openProposalReasonBottomSheet() {
//    this.bottomSheetSign = '-';
//    const bottomRef = this.proposalReasonBottomSheet.open(ProposalReasonBottomSheetComponent, { data: {content: this.proposal.relationships.item.data[0].attributes.reason.value, editable: true}});
//
//    bottomRef.afterDismissed().subscribe(result => {
//      if (result !== undefined) {
//        this.proposal.relationships.item.data[0].attributes.reason.value = result;
//      }
//      this.bottomSheetSign = '+';
//    });
//  }
}
