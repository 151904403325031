/**
 * Begründung-Service
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';

import {UbgBegrundung} from '../model/begrundung';
 
@Injectable({
  providedIn: 'root'
})
export class UbgBegrundungService {
  
  constructor(private contenta: UbgContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_begrundung/ubg_begrundung').then((response: {
        data: Array<UbgBegrundung>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_begrundung/ubg_begrundung/' + id).then((response: {
        data: UbgBegrundung
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(begrundung: UbgBegrundung) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_begrundung/ubg_begrundung', {
          data: begrundung
      }).then((response: {
          data: UbgBegrundung
      }) => {
          resolve(response.data);
      }).catch(() => {
          reject();
      });
    });
    return promise;
  }
  
  update(begrundung: UbgBegrundung) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_begrundung/ubg_begrundung/' + begrundung.id;
      this.contenta.patch(path, {
        data: begrundung
      }).then((response: {
        data: UbgBegrundung
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}