/**
 * Name bearbeiten
 * 
 * Attila Németh, UBG
 * 10.04.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './title.component.html',
})
export class UbgStructureTitleDialogComponent {
  
  constructor(private dialogRef: MatDialogRef<UbgStructureTitleDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                name: string
              }) {}
 
}
