/**
 * Empfehlung-Überweisung
 * 
 * Attila Németh, UBG
 * 07.05.2019
 */
 
import { Injectable } from '@angular/core';

import {UbgContentaService} from '../../../contenta/services/contenta.service';
import {UbgEmpfehlungUberweisung} from '../../model/taxonomies/empfehlung_uberweisung';

@Injectable({
  providedIn: 'root'
})
export class UbgEmpfehlungUberweisungService {

  constructor(private contenta: UbgContentaService) {
  }

  create(uberweisung: UbgEmpfehlungUberweisung) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.post('api/taxonomy_term/empfehlung_uberweisung', {
        data: uberweisung
      }).then((response: {
        data: UbgEmpfehlungUberweisung
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/empfehlung_uberweisung?sort=weight').then((response: {
        data: Array<UbgEmpfehlungUberweisung>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/empfehlung_uberweisung?filter[name]=' + name).then((response: {
        data: UbgEmpfehlungUberweisung
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

}