/**
 * Autocomplete-Element
 * 
 * Attila Németh, UBG
 * 08.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import {UbgAutocompleteComponent} from './components/autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    UbgAutocompleteComponent,
  ],
  exports: [
    UbgAutocompleteComponent,
  ],
})
export class UbgAutocompleteModule {

  constructor() {}

}