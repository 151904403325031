/**
 * {Description}
 * 
 * Attila Németh, UBG
 * 08.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon';

import {UbgConfirmComponent} from './components/confirm.component'

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    UbgConfirmComponent,
  ],
  entryComponents: [
    UbgConfirmComponent,
  ]
})
export class UbgConfirmModule {

  constructor() {}

}