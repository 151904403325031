import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UbgProjectService} from '../../../services/project_service';
import {UbgProject} from '../../../model/project';
import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  projects: Array<UbgProject>
  isLoading: boolean = false
  hasError: boolean = false
  displayedColumns = ['title', 'active', 'voting', 'open']

  constructor(private router: Router,
                private service: UbgProjectService,
                private userService: UbgContentaUserService) {
    this.projects = [];
    this.service.setActiveProjectId(null);
  }

  ngOnInit() {
    this.loadProjects();
  }
  
  loadProjects() {
    this.isLoading = true;
    this.service.getAll().then((response: Array<UbgProject>) => {
      this.isLoading = false;
      this.projects = response;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  add() {
    this.router.navigate(['project/add']);
  }
  
  isActiveProject(project: UbgProject) {
    let d = new Date();
    let sd = new Date(project.attributes.start_date);
    let ed = new Date(project.attributes.end_date);
    return (d >= sd && d <= ed);
  }
  
  isActiveVoting(project: UbgProject) {
    let d = new Date();
    let sd = new Date(project.attributes.start_voting_date);
    let ed = new Date(project.attributes.end_voting_date);
    return (d >= sd && d <= ed);
  }
  
  open(project: UbgProject) {
    this.router.navigate(['project/' + project.id]);
  }

}
