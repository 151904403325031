/**
 * Markup-Komponent
 * 
 * Attila Németh, UBG
 * 09.07.2019
 */
 
import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ubg-project-markup',
  templateUrl: './markup.component.html',
  styleUrls: ['./markup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgProjectMarkupComponent {
  
  @Input() set id(id: string) {
    this.idData = id;
  }
  @Input() set left(left: string) {
    this.leftData = left;
  }
  @Input() set right(right: string) {
    this.rightData = right;
  }
  idData: string = ''
  leftData: string = ''
  rightData: string = ''
  
}