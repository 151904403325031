/**
 * Contenta-Benutzer-Service
 * 
 * Attila Németh, UBG
 * 03.04.2019
 */
 
import {Injectable} from '@angular/core';

import {Router, RoutesRecognized} from '@angular/router';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import {ContentaUser} from '../model/user';
import {UbgContentaService} from '../../services/contenta.service';
import {TokenResponse} from '../../model/token.response';

@Injectable({
  providedIn: 'root'
})
export class UbgContentaUserService {

  // Angemeldet durch Contenta
  isLoggedIn: boolean = false
  
  // Authorisiert, aber nicht durch Anmeldung in Contenta
  isAuthorized: boolean = false
  
  // Ob in dieser Seite eine Anmeldung nötig ist.
  // Normalerweise Ja, bei einigen Seiten doch nicht.
  // Es kann in Routes konfiguriert werden. Beispiel:
  // { 
  //   path: 'component/path', 
  //   component: ComponentName, 
  //   data: {
  //     loginNeeded: false
  //   }
  // },
  pageLoginNeeded: boolean = true
  
  // Ob der Benutzer sich anmelden muss.
  // Er muss, wenn er nicht angemeldet ist, und eine Anmeldung erforderlich ist.
  userLoginNeeded: boolean = true
  
  user: ContentaUser

  constructor( private router: Router,
                private http: HttpClient,
                private contenta: UbgContentaService) {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        let r = data.state.root;
        this.pageLoginNeeded = true;
        while (r.children.length > 0) {
          r = r.firstChild;
        };
        if (r.data === undefined) {}
        else if (r.data['loginNeeded'] === undefined) {}
        else {
          this.pageLoginNeeded = r.data['loginNeeded'];
        }
        this.updateUserLoginNeeded(); 
      }
    });
  }
  
  updateUserLoginNeeded() {
    if (this.isLoggedIn) {
      this.userLoginNeeded = false;
    }
    else if (!this.pageLoginNeeded) {
      this.userLoginNeeded = false;
    }
    else {
      this.userLoginNeeded = true;
    }
    console.info('Anmeldung ist nötig', this.userLoginNeeded);
  }
  
  hasPermission(permissionName: string): boolean {
    if (this.user === undefined) {
      return false;
    } 
    if (this.user.permissions === undefined) {
      return false;
    }
    if (this.user.permissions[permissionName] === undefined) {
      return false;
    }
    return this.user.permissions[permissionName];
  }
  
  getRefreshCookie() {
    let cookies = this.contenta.getCookies();
    if (cookies['refresh_token'] === undefined) {
      return null;
    }
    else {
      return cookies['refresh_token'];
    }
  }
  
  login(username: string, password: string) {
    let promise = new Promise((resolve, reject) => {
      let params = {
        grant_type: 'password',
        username: username,
        password: password,
      };
      this.contenta.requestToken(params).then((response: TokenResponse) => {
        this.updateUserData(response);
        resolve();
      }).catch(() => reject());
    });
    return promise;
  }
  
  /**
   * Diese Funktion setzt keine Cookies!
   */
  requestToken(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.requestToken(params).then((response: TokenResponse) => {
        resolve(response);
        this.contenta.setAccessToken(response);
      }).catch(() => reject());
    });
    return promise;
  }
  
  setAccessCode(code: string) {
    this.contenta.setAccessCode(code);
  }
  
  logout() {
    this.isLoggedIn = false;
    this.isAuthorized = false;
    this.updateUserLoginNeeded();
    this.user = new ContentaUser;
    this.removeCookies();
  }
  
  refresh(refreshToken: string) {
    let promise = new Promise((resolve, reject) => {
      let params = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      };
      this.contenta.requestToken(params).then((response: TokenResponse) => {
        this.updateUserData(response);
        resolve();
      }).catch(() => {
        this.removeCookies();
        reject();
      });
    });
    return promise;
  }
  
  private updateUserData(response: TokenResponse) {
    this.setCookies(response);
    this.isLoggedIn = true;
    this.updateUserLoginNeeded();
    this.contenta.setAccessToken(response);
    this.getInfo();
  }
  
  getInfo() {
    this.contenta.get('ubg/oauth/info').then((response: ContentaUser) => {
      this.user = response;
    });
  }
  
  private setCookies(tokens:TokenResponse) {
    var d = new Date();
    // Jetzt + 2 Wochen
    d.setTime(d.getTime() + 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=' + tokens.refresh_token + ';' + expiresString + ";path=/";
  }
  
  private removeCookies() {
    var d = new Date();
    // Jetzt - 2 Wochen
    d.setTime(d.getTime() - 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=ex;' + expiresString + ";path=/";
  }
  
  update(uid: number, data: any) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patchRestJson('user/' + uid + '?_format=json', data).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  checkPassword(passwordToCheck: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('oauth/debug?_format=json').then((response: {
        id: number
      }) => {
        let path = 'api/users?filter[uid]=' + response.id;
        this.contenta.getAnonymous(path).then((response: {
          data: Array<{
            attributes: {
              display_name: string
            }
          }>
        }) => {
          let path = 'user/login?_format=json';
          this.contenta.postAnonymous(path, {
              name: response.data[0].attributes.display_name,
              pass: passwordToCheck,
            }).then((response) => {
              resolve(response);
            }).catch((error) => {
              reject(error);
            });
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
}