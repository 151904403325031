/**
 * Begründung
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
export class UbgBegrundung {
  
  id: string
  type: string = 'ubg_begrundung--ubg_begrundung'
  attributes: {
    title: string
    text: {
      value: string
      format: string
    }
  }
  
}