/**
 * Menu-Button-Komponent
 * 
 * Attila Németh, UBG
 * 15.03.2019
 */
 
import {Component} from '@angular/core';

import {UbgSidebarService} from '../../services/sidebar.service';

@Component({
  selector: 'ubg-sidebar-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class UbgSidebarButtonComponent {
  
  constructor(private service: UbgSidebarService) {}
  
  toggle() {
    this.service.toggle();
  }
 
}