import {Component, Input, OnInit} from '@angular/core';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgProject} from '../../../model/project';
import {UbgZiffer} from '../../../model/ziffer';

import {UbgLine} from '../../../model/line';
import {UbgZifferService} from '../../../services/ziffer.service';
import {UBGLineService} from '../../../services/line.service';
import {ActivatedRoute} from '@angular/router';
import {UBGAntragService} from '../../../services/antrag.service';


@Component({
  selector: 'app-leitantrag-numbered',
  templateUrl: './leitantrag-numbered.component.html',
  styleUrls: ['./leitantrag-numbered.component.scss']
})
export class LeitantragNumberedComponent implements OnInit {

  @Input() set project(project: UbgProject) { 
    if (project !== null) {
      this.projectId = project.id;
    }
  }
  projectId: string = null;

  @Input() set antrag(antrag: UBGAntragModel) {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      if (antrag !== null) {
        this.activeAntrag = antrag;
        this.loadAntragZiffers();
      }
    }, 4000);
  }

  activeAntrag: UBGAntragModel = null;

  isLoading: boolean = false;

  constructor( private route: ActivatedRoute,
               private zifferService: UbgZifferService,
               private antragService: UBGAntragService) { }

  ngOnInit() {}

/*  loadAntragZiffers() {
    this.isLoading = true;
    this.ziffers = [];
    // Loading the already existing ziffers of the selected antrag
    for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
      this.zifferService.get(this.activeAntrag.relationships.ziffers.data[i].id).then((ziffer: UbgZiffer) => {
        console.log(ziffer);
        // so we need to put the lines together
        ziffer.attributes.content = ''; // it is not defined
        // sort the ziffer lines
        ziffer.relationships.lines.data = this.sortByNumber(ziffer.relationships.lines.data);
        for (let j = 0; j < ziffer.relationships.lines.data.length; j++) {
          ziffer.attributes.content += ziffer.relationships.lines.data[j].attributes.content.value;
        }
        this.ziffers.push(ziffer);
        this.ziffersObservable.next(ziffer) ;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    }
  }*/

  loadAntragZiffers() {

    if (this.projectId === null) {
      console.error('Projekt ID', this.projectId);
      return;
    }
    if (!this.activeAntrag) {
      console.error('Antrag', this.activeAntrag);
      return;
    }
    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      this.isLoading = true;
      this.zifferService.getAllByAntrag( this.activeAntrag.id).then((response: UbgZiffer[]) => {
        // this.ziffers = response;
        this.activeAntrag.relationships.ziffers.data = response;
        this.isLoading = false;
/*        if (this.ziffers.length > 0) { // just loading the first one
          this.loadZiffer(this.ziffers[0]);
        }*/

      });
    }
  }


  loadZiffer(zifferId: string) {
    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      // Loading the already existing ziffers of the selected antrag
      for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
        if (zifferId === this.activeAntrag.relationships.ziffers.data[i].id) {
          // if it is not already loaded
          if (this.activeAntrag.relationships.ziffers.data[i].attributes.content === undefined ||
              this.activeAntrag.relationships.ziffers.data[i].attributes.content === '') {
            this.zifferService.get(zifferId).then((ziffer: UbgZiffer) => {
              // so we need to put the lines together
              ziffer.attributes.content = ''; // it is not defined
              // sort the ziffer lines
              ziffer.relationships.lines.data = this.sortByNumber(ziffer.relationships.lines.data);
              for (let j = 0; j < ziffer.relationships.lines.data.length; j++) {
                ziffer.attributes.content += ziffer.relationships.lines.data[j].attributes.content.value;
              }
              this.activeAntrag.relationships.ziffers.data[i] = ziffer;
              this.activeAntrag.relationships.ziffers.data[i].isExpanded = true;
            }).catch(() => {
              console.log('ERROR in load ziffer');
            });
          } else {
            this.activeAntrag.relationships.ziffers.data[i].isExpanded = true;
          }
        }
      }
    }
  }

  sortByNumber(arr) {
    // sort the antrage tabs array alphabetically
    arr = arr.sort((obj1, obj2) => {
      if (obj1.attributes.number > obj2.attributes.number) {
        return 1;
      }
      if (obj1.attributes.number < obj2.attributes.number) {
        return -1;
      }
      return 0;
    });
    return arr;
  }


}
