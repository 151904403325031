/**
 * Votum-Service
 * 
 * Attila Németh, UBG
 * 08.08.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';

import {UbgEmpfehlungType} from '../model/empfehlung_type';
import {UbgProposal} from '../model/proposal';
import {UbgProposalStructured} from '../model/proposal_structured';
import {UbgZiffer} from '../model/ziffer';
import {UbgEmpfehlungUberweisung} from '../model/taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungUberweisungService} from '../services/taxonomies/empfehlung_uberweisung.service';
import {UbgVotum} from '../model/votum';
import {UbgProposalService} from './proposal.service';
 
@Injectable({
  providedIn: 'root'
})
export class UbgVotumService {
  
  constructor(private contenta: UbgContentaService,
                private uberweisung: UbgEmpfehlungUberweisungService,
                private proposalService: UbgProposalService) {}

  get(type: string, id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_empfehlung/' + type + '/' + id).then((response: {
        data: UbgVotum
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
  getAll(type: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_empfehlung/' + type).then((response: {
        data: Array<UbgVotum>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  emptyVotum(type: UbgEmpfehlungType) {
    let votum = new UbgVotum;
    votum.type = 'ubg_empfehlung--' + type.attributes.drupal_internal__id;
    votum.attributes = {
      title: '',
      level: 19,
      text: {
        value: '',
        format: null,
      },
    };
    votum.relationships = {
      proposals: {
        data: [],
      },
      ubg_empfehlung_type: null,
    };
    return votum;
  }
  
  create(empfehlung: UbgVotum) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/' + empfehlung.type.replace('--', '/');
      this.contenta.post(path, {
        data: empfehlung
      }).then((response: {
        data: UbgVotum
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByProposal(proposalId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/proposal/' + proposalId + '/voten').then((response: {
        data: Array<UbgVotum>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByZiffer(zifferId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/ziffer/' + zifferId + '/voten').then((response: {
        data: Array<UbgVotum>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
//  getByProposalStructured(proposalStructured: UbgProposalStructured) {
//    let promise = new Promise((resolve, reject) => {
//      let proposal = new UbgProposal;
//      proposal.id = proposalStructured.id;
//      this.getByProposal(proposal).then((response: Array<UbgVotum>) => {
//        resolve(response);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
//  
//  getByProposal(proposal: UbgProposal) {
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.get('ubg/proposal/api/empfehlung/' + proposal.id).then((response: {
//        data: Array<UbgVotum>
//      }) => {
//        resolve(response.data);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
  
  update(empfehlung: UbgVotum) {
    let promise = new Promise((resolve, reject) => {
      const path = 'api/ubg_empfehlung/' + empfehlung.type.replace('ubg_empfehlung--', '') + '/' + empfehlung.id;
      if (empfehlung.attributes.typeLabel !== undefined) {
        delete empfehlung.attributes.typeLabel;
      }
      if (empfehlung.attributes.statusLabel !== undefined) {
        delete empfehlung.attributes.statusLabel;
      }
      this.contenta.patch(path, {
        data: empfehlung
      }).then((response: {
        data: UbgVotum
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  delete(empfehlung: UbgVotum) {
    let promise = new Promise((resolve, reject) => {
      const path = 'api/ubg_empfehlung/' + empfehlung.type.replace('ubg_empfehlung--', '') + '/' + empfehlung.id;
      this.contenta.delete(path).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  removeProposal(votum: UbgVotum, proposalId: string) {
    let promise = new Promise((resolve, reject) => {
      let newProposals = [];
      for (let i in votum.relationships.proposals.data) {
        if (votum.relationships.proposals.data[i].id !== proposalId) {
          newProposals.push(votum.relationships.proposals.data[i]);
        }
      }
      if (newProposals.length > 0) {
        votum.relationships.proposals.data = newProposals;
        this.update(votum).then((response: UbgVotum) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      }
      else {
        this.delete(votum).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      }
    });
    return promise;
  }
  
  removeProposals(votum: UbgVotum, proposalIds: Array<string>) {
    let promise = new Promise((resolve, reject) => {
      let newProposals = [];
      for (let i in votum.relationships.proposals.data) {
        let proposalNeeded = true;
        for (let j in proposalIds) {
          if (votum.relationships.proposals.data[i].id == proposalIds[j]) {
            proposalNeeded = false;
          }
        }
        if (proposalNeeded) {
          newProposals.push(votum.relationships.proposals.data[i]);
        }
      }
      if (newProposals.length > 0) {
        votum.relationships.proposals.data = newProposals;
        this.update(votum).then((response: UbgVotum) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      }
      else {
        this.delete(votum).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      }
    });
    return promise;
  }
  
  addProposal(votum: UbgVotum, proposalId: string) {
    let promise = new Promise((resolve, reject) => {
      let newProposals: Array<UbgProposal> = [];
      this.proposalService.get(proposalId).then((response: UbgProposal) => {
        for (let i in votum.relationships.proposals.data) {
          if (votum.relationships.proposals.data[i].id !== response.id) {
            newProposals.push(votum.relationships.proposals.data[i]);
          }
        }
        newProposals.push(response);
        votum.relationships.proposals.data = newProposals;
        this.update(votum).then((response: UbgVotum) => {
          resolve(response);
        });
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}