/**
 * Beschluss-Komponent
 * 
 * Attila Németh, UBG
 * 08.08.2019
 */
 
import {Component, Input, OnInit} from '@angular/core';

import {MatDialog, MatDialogConfig, MatSnackBar} from '@angular/material';

import {UBGAntragModel} from '../../model/antrag.model';
import {UbgBeschlussService} from '../../services/beschluss.service';
import {UBGBeschlussModel} from '../../model/beschluss.model';
import {UbgPrintBeschlussDialogComponent} from '../project/project-detail/antrage-empfehlung/dialogs/print/beschluss.component';
import {UbgConfirmComponent} from '../../../ubg_components/confirm/components/confirm.component';

@Component({
  selector: 'app-beschluss',
  templateUrl: './beschluss.component.html',
})
export class BeschlussComponent implements OnInit {

  @Input() set antrag (antrag: UBGAntragModel) {
    this.currentAntrag = antrag;
    this.selectedView = null;
    this.loadBeschluss();
  }
  currentAntrag: UBGAntragModel
  beschluss: UBGBeschlussModel = null
  selectedView: string = null
  viewOptions: Array<{
    value: string
    label: string
  }> = []
  viewText: string = ''
  isLoading: boolean = false
  hasError: boolean = false
  container: HTMLElement;

  constructor(private dialog: MatDialog,
                private service: UbgBeschlussService) {}
  
  ngOnInit() {
  }
  
  loadBeschluss() {
    this.isLoading = true;
    this.service.getPreparedByAntrag(this.currentAntrag.id).then((response: UBGBeschlussModel) => {
      this.isLoading = false;
      this.hasError = false;
      this.beschluss = response;
      let newOptions: Array<{
        value: string
        label: string
      }> = [];
      if (this.currentAntrag.relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag') {
        newOptions.push({
          value: 'leitantrag',
          label: 'Leitantrag',
        });
      }
      else {
        newOptions.push({
          value: 'leitantrag',
          label: 'Anträge',
        });
      }
      newOptions.push({
        value: 'schluss',
        label: 'Beschluss',
      });
      newOptions.push({
        value: 'markup',
        label: 'Markup',
      });
      this.viewOptions = newOptions;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  selectView() {
    if (this.beschluss.attributes[this.selectedView] === undefined) {
      this.viewText = '---';
    }
    else {
      this.viewText = this.beschluss.attributes[this.selectedView]['value'];
    }
  }
  
  print() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mitMarkups: false,
    };
    let formDialog = this.dialog.open(UbgPrintBeschlussDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        //this.container = document.getElementById(this.selectedView);
        //this.service.print(this.beschluss, this.container.innerHTML).then((response: any) => {
        this.service.print(this.beschluss, this.viewText).then((response: any) => {
          let fileName = 'Beschluss-' + this.currentAntrag.attributes.letter + '.pdf';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
  msword() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten sie den Text in MS Word exportieren?',
    };
    let formDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
//        this.container = document.getElementById(this.selected);
        this.service.msWord(this.beschluss, this.viewText).then((response: any) => {
          let fileName = 'Beschluss-' + this.currentAntrag.attributes.letter + '.doc';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
}
