import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

// import * as inlineEditor from '@ckeditor/ckeditor5-build-inline';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {UbgOrgUser} from '../../../model/org_user';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgProject} from '../../../model/project';
import {UbgProposal} from '../../../model/proposal';

import {UbgProposalActionTypeService} from '../../../services/taxonomies/proposal_action_type.service';
import {UbgProposalItemService} from '../../../services/proposal_item.service';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgProposalService} from '../../../services/proposal.service';
import {UbgProjectService} from '../../../services/project_service';
import {UbgProposalItem} from '../../../model/proposal_item';
import {UbgProposalStatusService} from '../../../services/taxonomies/proposal_status.servie';
import {UbgProposalStatus} from '../../../model/taxonomies/proposal_status';
import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {Subject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {ProposalReasonBottomSheetComponent} from '../proposal-reason-sheet/proposal-reason-sheet';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {UbgGroupService} from '../../../services/group.service';
import {UbgGroup} from '../../../model/group';


@Component({
  selector: 'app-antrag-list',
  templateUrl: './antrag-list.component.html',
  styleUrls: ['./antrag-list.component.scss', '../antrag_mein.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AntragListComponent implements OnInit {
  @Input() set antrag(antrag: UBGAntragModel) {
    if (antrag !== null) {
      this.activeAntrag = antrag;
      this.proposals = [];
      if ( this.selectedOrgUsers ) {
        let users = this.selectedOrgUsers;
        this.selectedOrgUsers = [];
        for (let i in users) {
          this.selectOrgUser(users[i].id, false);
        }
      }
    }
  }
  activeAntrag: UBGAntragModel;

  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.selectedProject = project;
      this.loadOrgUsers();
      this.proposals = [];
      this.checkDate();
    }
  }
  selectedProject: UbgProject;

  @Input() set accessedUser(accessedUser: UbgOrgUser) {
    if (accessedUser !== null ) {
      this.orgUser = accessedUser;
      this.userObservable.next(accessedUser);
    }
  }
  orgUser: UbgOrgUser;

  @Input() set orgUsers(orgUsers: UbgOrgUser) {

    if (orgUsers !== null && this.selectedOrgUsers.length === 0) {
      for (let i in orgUsers) {
        this.selectOrgUser(orgUsers[i].id, false);
      }
    }
  }
  selectedOrgUsers: UbgOrgUser[] = [];

  @Output() selectedOrgUsersEvent: EventEmitter<UbgOrgUser[]> = new EventEmitter();


  orgUserOptions: Array<{
    id: string
    label: string
  }> = [];

  isLoading: boolean = false;
  hasError: boolean = false;
  isVotingTimeOver: boolean = false;
  proposal: UbgProposal;
  proposals = [];
  isPrinting: boolean = false
  isEditableProposalValid: boolean = false

  freeName: boolean = false;
  orgUserName: string = '';

  public Config = {
    language: 'de',
    toolbar: [
      /*      'heading',
            '|',*/
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      'subscript', 'superscript',
      '|',
      'undo',
      'redo',
    ],
  };
  public Editor = ClassicEditor;

  userObservable = new Subject<UbgOrgUser>();

  bottomSheetSign = '+';
  // Keine Ahnung, was war das ursprünglich...
  processing: boolean = false

  constructor(private route: ActivatedRoute,
              private proposalService: UbgProposalService,
              private proposalItemService: UbgProposalItemService,
              private proposalTypeService: UbgProposalActionTypeService,
              private proposalStatusService: UbgProposalStatusService,
              private projectService: UbgProjectService,
              private orgUserService: UbgOrgUserService,
              private user: UbgContentaUserService,
              private userGroupService: UbgGroupService,
              private proposalReasonBottomSheet: MatBottomSheet) {  }

  ngOnInit() {
    this.proposals = [];

    this.userObservable.pipe(delay(500)).subscribe((value) => {
      if (value !== null && this.selectedOrgUsers.length === 0) {
        if (this.activeAntrag) {
          this.selectOrgUser(value.id, false);
          this.userObservable.unsubscribe();
        } else {
          this.userObservable.next(value); // antrag is not loaded yet! so we try again :)
        }
      }
    });
  }

  /**
   * We need to check to see if project accept proposal
   */
  checkDate() {
//    const today = new Date();
//    const endDate = new Date (this.selectedProject.attributes.end_voting_date);
//    if (today > endDate) {
//      this.isVotingTimeOver = true;
//    }
    if (this.projectService.isExpired(this.selectedProject)) {
      this.isVotingTimeOver = true;
    }
  }


  loadOrgUsers() {
    if ( this.user.isLoggedIn) {
      this.orgUserService.getNamesByStructure(this.selectedProject.relationships.priority_structure.data.id).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        this.orgUserOptions = [];
        let newOptions = [];
        for (let i in response.data) {
          let label:string = response.data[i].attributes.title;
          if (response.data[i].attributes['kurzbezeichnung'] !== '') {
            label = response.data[i].attributes['kurzbezeichnung'] + ' ' + label;
          }
          let isFree = true;
          for (let j in this.selectedOrgUsers) {
            if (response.data[i].id === this.selectedOrgUsers[j].id) {
              isFree = false;
            }
          }
          if (isFree) {
            let newOption = {
              id: response.data[i].id,
              label: label,
            };
            newOptions.push(newOption);
          }
        }
        this.orgUserOptions = newOptions;
      });
    }

  }

  setProposals(proposals) {
    this.proposals = [];
    for (let i in proposals) {
      if (proposals[i].attributes.is_draft) {
        proposals[i].status = 'Entwurf - noch nicht gesendet';
      } else {
        proposals[i].status = 'Gesendet';
      }
      if (proposals[i].relationships.status === undefined) {
        // Der Status kann nicht geladen werden, z.B: weil der Zugriff fehlt.
        proposals[i].status = '';
      } else if (proposals[i].relationships.status.data !== null &&
                  proposals[i].relationships.status.data.attributes.name === 'Angenommen') {
        proposals[i].status = 'Zugestimmt';
      } else if (proposals[i].relationships.status.data !== null &&
                  proposals[i].relationships.status.data.attributes.name === 'Abgelehnt') {
        proposals[i].status = 'Abgelehnt';
      }
      proposals[i].editable = false;
      proposals[i].orgUsers = '';
      this.proposals.push(proposals[i]);
    }
    this.setProposalUserGroup();
  }

  setProposalUserGroup() {
    for (let g = 0; g < this.selectedOrgUsers.length; g++) {
      this.userGroupService.get(this.selectedOrgUsers[g].relationships.gruppe.data[0].id).then((group: UbgGroup) => { // TODO: i think user should be only in one group in a project or not?
        this.selectedOrgUsers[g].relationships.gruppe.data[0] = group;
        for (let i in this.proposals) { // we also set the group in  proposals
          for (let o = 0; o < this.proposals[i].relationships.org_user.data.length; o++) {
/*             console.log(group);
             console.log(this.proposals[i].relationships.org_user.data[o]);*/
            if (this.proposals[i].relationships.org_user.data[o].id === this.selectedOrgUsers[g].id &&
                this.proposals[i].relationships.org_user.data[o].relationships.gruppe.data[0].id === group.id) {
              this.proposals[i].relationships.org_user.data[o].relationships.gruppe.data[0] = group;
              if (this.proposals[i].orgUsers !== '') { // add a comma befor ethe last user when there are more than one.
                this.proposals[i].orgUsers += ', ';
              }
              this.proposals[i].orgUsers += (group.attributes.kurzbezeichnung ? group.attributes.kurzbezeichnung : group.attributes.title) + ' ' + this.proposals[i].relationships.org_user.data[o].attributes.title + ' ';
            }
          }
        }
/*        if (this.selectedOrgUsers.length - 1 === g) { // after the last one we set them in the proposals

        }*/
      }).catch((e) => {
        console.log(e);
        console.log('ERROR in get user group info');
      });
    }
  }


  sendProposal(proposal) {
    this.isLoading = true;
    for ( let i = 0; i < this.proposals.length; i++ ) {
      if (this.proposals[i].id === proposal.id) {
        // this.proposalStatusService.getByName('In Bearbeitung').then((status: UbgProposalStatus) => {
        // this.proposals[i].relationships.status.data = status;
        this.proposals[i].attributes.is_draft = false;
        this.proposalService.update(this.proposals[i]).then((response: UbgProposal)  => {
          this.proposals[i].attributes = response.attributes;
          this.proposals[i].status = 'Gesendet';
          this.isLoading = false;
        }).catch((e) => {
          console.log(e);
          console.log('ERROR in update proposal status');
          this.isLoading = false;
        });

/*        }).catch((e) => {
          console.log(e);
          console.log('ERROR in get proposal status by name');
          this.isLoading = false;
        });*/

      }
    }
  }

  deleteProposal(proposal) {
    if (confirm('Sind Sie sicher?')) {
      this.isLoading = true;
      // console.log(proposal);
      this.proposalService.delete(proposal).then(() => {

        for ( let i = 0; i < this.proposals.length; i++ ) {
          if (this.proposals[i].id === proposal.id) {
            this.proposals.splice(i, 1);
            this.isLoading = false;
          }
        }
      }).catch((e) => {
        console.log(e);
        console.log('ERROR in delete proposal request');
        this.isLoading = false;
      });

    }
  }

  /**
   * This method switches the proposal container to the editor so user can edit the content
   * @param id proposal id
   */
  createEditableProposal(proposal) {
    for ( let i = 0; i < this.proposals.length; i++ ) {
      if (this.proposals[i].id === proposal.id) {
        for (let j in this.proposals[i].relationships.item.data) {
          if (this.proposals[i].relationships.item.data[j].attributes.reason == null) {
            this.proposals[i].relationships.item.data[j].attributes.reason = {
              value: '',
            };
          }
        }
        this.proposals[i].editable = true;
      }
    }
  }

  editProposalItem (proposal: UbgProposal, item: UbgProposalItem) {
    this.isLoading = true;
    for ( let i = 0; i < this.proposals.length; i++ ) {
      if (this.proposals[i].id === proposal.id) {
        this.proposals[i].editable = false;
      }
    }
    this.proposalItemService.update(item).then((response: UbgProposalItem) => {
        item = response;
        this.proposalService.update(proposal).then((response: UbgProposal) => {
          this.loadSelectedUsersProposals();
          this.isLoading = false;
        });
      }).catch((e) => {
        console.log(e);
        console.log('ERROR in edit proposal item request');
        this.isLoading = false;
      });
  }

  discardEdit(proposal) {
    for ( let i = 0; i < this.proposals.length; i++ ) {
      if (this.proposals[i].id === proposal.id) {
        this.proposals[i].editable = false;
      }
    }
    this.loadSelectedUsersProposals();
  }

  loadSelectedUsersProposals() {
    // we load all the proposals from these users
    this.proposalService.getAntragProposalsByOrgUsers(this.activeAntrag.id, this.selectedOrgUsers).then((response: UbgProposal[]) => {
      // console.log(response);
      this.loadOrgUsers();
      this.orgUserName = '';
      this.updateFreeName();
      this.setProposals(response);
      this.isLoading = false;
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in get proposal by org user request');
    });
  }

  selectOrgUser(orgUserId: string, NeedEmit: boolean = true) {
    if (!this.activeAntrag) {
      console.log('ERROR: The antrag is not loaded yet');
      return false;
    }
    this.isLoading = true;
    this.orgUserName = 'orgUserId';
    this.orgUserService.get('gliederung', orgUserId).then((user: UbgOrgUser) => {
      // console.log(user);
      this.selectedOrgUsers.push( user );
      if (NeedEmit) {
        this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
      }
      this.loadSelectedUsersProposals();
    });
  }
  removeOrgUser(orgUser: UbgOrgUser) {
    for (let i = 0 ; i < this.selectedOrgUsers.length; i++) {
      if (this.selectedOrgUsers[i].id === orgUser.id) {
        this.selectedOrgUsers.splice(i, 1);
      }
    }
    this.updateFreeName();
    this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
    this.loadSelectedUsersProposals();
  }

  updateFreeName() {
    let freeName: boolean = false;
    for (let i in this.selectedOrgUsers) {
      if (this.selectedOrgUsers[i].attributes.freier_name === true) {
        freeName = true;
      }
    }
    this.freeName = freeName;
  }

  openProposalReasonBottomSheet(item, editable) {
    this.bottomSheetSign = '-';
    if (!item.attributes.reason) {
      item.attributes.reason = {value: ''}; // if it is null!
    }
    const bottomRef = this.proposalReasonBottomSheet.open(ProposalReasonBottomSheetComponent, { data: {content: item.attributes.reason.value, editable: editable}});

    bottomRef.afterDismissed().subscribe(result => {
      if (result !== undefined) {
        if ( result ) {
          item.attributes.reason.value = result;
        } else {// it is empty so we discard
          item.attributes.reason = null;
        }
      } else {
        if (!item.attributes.reason.value) {
          item.attributes.reason = null;
        }
      }
      this.bottomSheetSign = '+';
    });
  }

  printAntraege() {
    this.isPrinting = true;
    this.projectService.printMeine(this.selectedProject, this.selectedOrgUsers).then((response: any) => {
      this.isPrinting = false;
      let fileName = 'Antraege.pdf';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      }
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.isPrinting = false;
    });
  }

  /**
   * Ob es ein Leitantrag (A) ist, oder Sonstige (C)
   */
  isAntragLeitantrag() {
    if (this.activeAntrag.relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag') {
      return true;
    }
    else {
      return false;
    }
  }

  getLineInfo(item: UbgProposalItem): string {
    if (item.attributes.from === null || item.attributes.from === undefined) {
      return '';
    }
    else if (item.attributes.to === null || item.attributes.to === undefined) {
      return 'Zeile ' + item.attributes.from
    }
    else if (item.attributes.from == item.attributes.to) {
      return 'Zeile ' + item.attributes.from
    }
    else {
      return 'Zeilen ' + item.attributes.from + ' bis ' + item.attributes.to;
    }
  }

  updateEditProposalValid(proposal: UbgProposal) {
    let isValid: boolean = true;
    if (proposal.attributes.custom_title == null || proposal.attributes.custom_title == '') {
      if (!this.isAntragLeitantrag()) {
        isValid = false;
      }
    }
    if (proposal.relationships.item.data[0].attributes.keine_begruendung == false) {
      if (proposal.relationships.item.data[0].attributes.reason.value == '') {
        isValid = false;
      }
    }
    this.isEditableProposalValid = isValid;
  }

}
