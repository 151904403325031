/**
 * Proposal-Item Edit
 *
 * Attila Németh, UBG
 * 28.05.2019
 */
 
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {UbgProposalItem} from '../../../model/proposal_item';
import {UbgProposalItemService} from '../../../services/proposal_item.service';
import {UbgProposalActionTypeService} from '../../../services/taxonomies/proposal_action_type.service';
import {UbgProposalActionType} from '../../../model/taxonomies/proposal_action_type';

@Component({
  selector: 'ubg-proposal-edit-item',
  templateUrl: './item.component.html',
})
export class UbgProposalEditItemComponent implements OnInit {

  @Input() item: UbgProposalItem
  @Output() changed = new EventEmitter
  action: string = null
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo'
    ],
  };

  constructor(private service: UbgProposalItemService,
                private actionType: UbgProposalActionTypeService) {}
  
  ngOnInit() {
    this.service.get(this.item.id).then((response: UbgProposalItem) => {
      if (response.attributes.reason === null) {
        response.attributes.reason = {
          value: '',
        };
      }
      this.item = response;
      if (response.relationships.action.data !== undefined) {
        if (response.relationships.action.data !== null) {
          this.actionType.get(response.relationships.action.data.id).then((response: UbgProposalActionType) => {
            this.action = response.attributes.title;
          });
        }
      }
    });
  }
  
  save() {
    this.item.attributes.content.format = null;
    if (this.item.attributes.keine_begruendung == true) {
      this.item.attributes.reason = null;
    }
    this.service.update(this.item).then(() => {
      this.changed.emit();
    });
  }
  
  cancel() {
    this.changed.emit();
  }
  
}