import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';

import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import { SidetabModel } from './model/sidetab.model';
import {SidetabsSyncService} from './service/sidetabs.sync.service';
import {UbgProjectService} from '../../../services/project_service';

@Component({
	selector: 'app-sidetabs',
	templateUrl: './sidetabs.component.html',
	styleUrls: ['./sidetabs.component.scss']
})
export class SidetabsComponent implements OnInit, OnDestroy {

  sideTabItems: Array<SidetabModel>;
  @Input() set sidetabs(tabs: SidetabModel[]) {
    if (tabs !== undefined ) {
      this.sideTabItems = tabs;
    }
  }
  @Input() displayMeine: boolean = false
  selectedId: string;
  dashboardLink: string = ''
  displayMinimized: boolean = false
  breakpointSubscription: Subscription
  projectSubscription: Subscription
  selectedLabel: string = ''

  constructor(private breakpointObserver: BreakpointObserver,
                private router: Router,
                public syncService: SidetabsSyncService,
                private projectService: UbgProjectService) {
    this.projectSubscription = this.projectService.activeProjectIdData.subscribe((projectId: string) => {
      this.dashboardLink = '/project/' + projectId + '/dashboard';
    });
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-height: 720px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.displayMinimized = true;
      }
      else {
        this.displayMinimized = false;
      }
    });
  }

  ngOnInit() {
    this.sideTabItems = [];
    this.syncService.selected_id.subscribe((value) => {
      if (value !== undefined && value !== null) {
        this.selectedId = value;
      }
    });
    // Es ist keine schöne Lösung, doch ich habe keinen Bock, alles umzugestalten.
    setTimeout(() => {
      this.updateSelectedLabel();
    }, 1500);
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
  }

  onSelect(sidetabId: string): void {
    this.selectedId = sidetabId;
    this.updateSelectedLabel();

    // send the selected item to parent components
    this.syncService.set_selected_id(sidetabId);
    // this.selected.emit(sidetabId);
  }
  
  gotoDashboard() {
    this.router.navigate([this.dashboardLink]);
  }
  
  updateSelectedLabel() {
    let found: boolean = false;
    for (let i in this.sideTabItems) {
      if (this.sideTabItems[i].id == this.selectedId) {
        this.selectedLabel = this.sideTabItems[i].label;
        found = true;
      }
    }
    if (!found) {
      this.selectedLabel = '';
      setTimeout(() => {
        this.updateSelectedLabel();
      }, 500);
    }
  }
  
}
