/**
 * Leitantrag
 * 
 * Attila Németh, UBG
 * 04.06.2019
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';

import {UBGAntragModel} from '../../../../../../model/antrag.model';
import {UbgZifferStructured} from '../../../../../../model/ziffer_structured';
import {UBGAntragService} from '../../../../../../services/antrag.service';
import {UbgProjectService} from '../../../../../../services/project_service';
import {UbgFilterService} from '../../../../../../services/filter.service';
import {UbgFilterParams} from '../../../../../../model/filter_params';
import {UbgLastChangeService} from '../../../../../../services/lastchange.service';

@Component({
  selector: 'ubg-project-antrag-leitantrag',
  templateUrl: './leitantrag.component.html',
})
export class UbgProjectAntragLeitantragComponent implements OnInit, OnDestroy {
  
  @Input() set antrag(antrag: UBGAntragModel) {
    this.leitAntrag = antrag;
    this.loadZiffern();
    this.lastChangeService.addAntrag(antrag);
  }
  leitAntrag: UBGAntragModel
  ziffern: Array<UbgZifferStructured> = []
  filterParams: UbgFilterParams = {}
  projectId: string = null
  isLoading: boolean = false
  hasError: boolean = false
  
  constructor(private service: UBGAntragService,
                private projectService: UbgProjectService,
                private filterService: UbgFilterService,
                private lastChangeService: UbgLastChangeService) {
    this.projectId = this.projectService.activeProjectId;
    this.projectService.activeProjectIds.subscribe((projectId: string) => {
      if (projectId !== this.projectId) {
        this.projectId = projectId;
        this.loadZiffern();
      }
    });
    this.filterService.params.subscribe((params: UbgFilterParams) => {
      let currentParams = JSON.stringify(this.filterParams);
      let newParams = JSON.stringify(params);
      if (newParams !== currentParams) {
        this.filterParams = JSON.parse(newParams);
        this.loadZiffern();
      }
    });
  }
  
  ngOnInit() {}
  
  ngOnDestroy() {
    this.lastChangeService.removeAntrag(this.leitAntrag);
  }
  
  loadZiffern() {
    this.isLoading = true;
    this.service.getStructuredZiffers(this.projectId, this.leitAntrag, this.filterParams).then((response: Array<UbgZifferStructured>) => {
      this.ziffern = response;
      this.isLoading = false;
      this.hasError = false;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  getNotFoundMessage() {
    let message: string = 'Der Antrag hat keine Ziffer';
    Object.keys(this.filterParams).forEach(key => {
      if (this.filterParams[key] !== null 
            && this.filterParams[key] !== undefined
            && this.filterParams[key] !== '') {
        message = 'Kein Ziffer eintspricht den Suchkriterien';
      }
    });
    return message;
  }
  
}
