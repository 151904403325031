import {Component, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as inlineEditor from '@ckeditor/ckeditor5-build-inline';
// import * as ClassicEditor from '../../../../../../dist/ckeditor5-build-ubg';


import { NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {UbgProject} from '../../../model/project';
import {UbgProjectService} from '../../../services/project_service';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgProposal} from '../../../model/proposal';
import {UbgProposalItem} from '../../../model/proposal_item';
import {UbgProposalActionType} from '../../../model/taxonomies/proposal_action_type';
import {UbgOrgUser} from '../../../model/org_user';
import {UbgProposalService} from '../../../services/proposal.service';
import {UbgProposalItemService} from '../../../services/proposal_item.service';
import {UbgProposalActionTypeService} from '../../../services/taxonomies/proposal_action_type.service';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {ProposalReasonBottomSheetComponent} from '../proposal-reason-sheet/proposal-reason-sheet';
import {UbgZiffer} from '../../../model/ziffer';
import {UbgZifferService} from '../../../services/ziffer.service';
import {UBGAntragService} from '../../../services/antrag.service';

@Component({
  selector: 'app-antrag-add',
  templateUrl: './antrag-add.component.html',
  styleUrls: ['./antrag-add.component.scss', '../antrag_mein.scss']
})
export class AntragAddComponent implements OnInit {

  @ViewChild( 'demoForm' ) demoForm?: NgForm;

  @Input() set antrag(antrag: UBGAntragModel) {
    if (antrag !== null) {
      this.activeAntrag = antrag;
      if ( this.selectedOrgUsers ) {
        let users = this.selectedOrgUsers;
        this.selectedOrgUsers = [];
        for (let i in users) {
          this.selectOrgUser(users[i].id, false);
        }
      }
    }
  }
  activeAntrag: UBGAntragModel;

  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.selectedProject = project;
      this.loadOrgUsers();
//      let projectEnd = new Date(project.attributes.end_voting_date);
//      let projectStart = new Date(project.attributes.start_voting_date);
//      let today = new Date();
//      if (projectEnd >= today && projectStart <= today) {
//        this.enabled = true;
//      }
//      else {
//        console.warn('Projekt', projectStart, projectEnd);
//        this.enabled = false;
//      }
      if (this.projectService.isExpired(project)) {
        this.enabled = false;
      }
      else {
        this.enabled = true;
      }
    }
  }
  selectedProject: UbgProject;

  @Input() set accessedUser(accessedUser: UbgOrgUser) {
    if (accessedUser !== null) {
      this.orgUser = accessedUser;
    }
  }
  orgUser: UbgOrgUser;

  @Input() set orgUsers(orgUsers: UbgOrgUser) {
    if (orgUsers !== null && this.selectedOrgUsers.length === 0) {
      for (let i in orgUsers) {
        this.selectOrgUser(orgUsers[i].id, false);
      }
    }
  }
  selectedOrgUsers: UbgOrgUser[] = [];

  @Output() selectedEvent: EventEmitter<number> = new EventEmitter();
  @Output() selectedOrgUsersEvent: EventEmitter<UbgOrgUser[]> = new EventEmitter();

  proposal: UbgProposal;
  proposalItem: UbgProposalItem;

  freeName: boolean = false;
  orgUserName: string = '';

  public Editor = ClassicEditor;
  public Config = {
    language: 'de',
    toolbar: [
//      'heading',
//      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };

  orgUserOptions: Array<{
    id: string
    label: string
  }> = [];

  isLoading: boolean = false;
  hasError: boolean = false;
  bottomSheetSign = '+';
  enabled: boolean = false
  isInvalid: boolean = true

  constructor( private snackbar: MatSnackBar,
               private proposalService: UbgProposalService,
               private proposalItemService: UbgProposalItemService,
               private proposalTypeService: UbgProposalActionTypeService,
               private orgUserService: UbgOrgUserService,
               private user: UbgContentaUserService,
               private proposalReasonBottomSheet: MatBottomSheet,
               private zifferSerrvice: UbgZifferService,
               private antragService: UBGAntragService,
               private projectService: UbgProjectService) {  }

  ngOnInit() {


    this.proposal = {
      type: 'ubg_proposal--ubg_proposal',
      attributes: {
        title: '',
        custom_title: '',
        is_draft: true,
      },
      relationships: {
        antrag: {
          data: null,
        },
        org_user: {
          data: [],
        },
        item: {
          data: [],
        }
      }
    };
    this.proposalItem = {
      type: 'ubg_proposal_item--ubg_proposal_item',
      attributes: {
        content: '',
        reason: {
          value: null,
        },
        keine_begruendung: false,
      },
      relationships: {
        action: {
          data: null,
        }
      }
    };
    // sonstige proposal type is always insert or maybe type make no sense for sonstige at all
    this.proposalTypeService.getByName('Hinzufügen').then((type: UbgProposalActionType) => {
      this.proposalItem.relationships.action.data = type;
    });

  }


  loadOrgUsers() {
    if ( this.user.isLoggedIn) {
    this.orgUserService.getNamesByStructure(this.selectedProject.relationships.priority_structure.data.id).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        this.orgUserOptions = [];
        let newOptions = [];
        for (let i in response.data) {
          let label:string = response.data[i].attributes.title;
          if (response.data[i].attributes['kurzbezeichnung'] !== '') {
            label = response.data[i].attributes['kurzbezeichnung'] + ' ' + label;
          }
          let isFree = true;
          for (let j in this.selectedOrgUsers) {
            if (response.data[i].id === this.selectedOrgUsers[j].id) {
              isFree = false;
            }
          }
          if (isFree) {
            let newOption = {
              id: response.data[i].id,
              label: label,
            };
            newOptions.push(newOption);
          }
        }
        this.orgUserOptions = newOptions;
      });
    }
  }

  onsubmit() {
    
    // Diese Funktion läuft nur bei C Anträge
    this.isLoading = true;
    this.proposal.relationships.antrag.data = this.activeAntrag;
//    if (this.proposal.relationships.ziffer === undefined) {
//      this.addDummyZiffer();
//    }
    
//    this.proposal.attributes.title = this.activeAntrag.attributes.letter + ' ';

    this.addDummyZiffer().then(() => {
      // Saving the item and then the proposal itself
      this.proposalService.createItem(this.proposalItem).then(( item: UbgProposalItem ) => {
        // content formating cause problem, and actually we don't need the item after it is related to the proposal
        // this.proposalItem = item;
        this.proposal.relationships.item.data.push(item);
        this.addProposal();
      }).catch((error) => {
        console.warn(error);
        this.snackbar.open('Der Antrag kann nicht generiert werden', null, {
          duration: 15000,
        });
        this.isLoading = false;
      });
    });
  }
  
  addDummyZiffer() {
    let promise = new Promise((resolve, reject) => {
      if (this.proposal.relationships.ziffer === undefined) {
        if (this.proposal.relationships.antrag.data.relationships.ziffers.data.length == 0) {
          this.antragService.createDummyZiffer(this.proposal.relationships.antrag.data).then((response: UbgZiffer) => {
            this.proposal.relationships.ziffer = {
              data: response,
            }
            resolve();
          });
        }
        else {
          this.proposal.relationships.ziffer = {
            data: this.proposal.relationships.antrag.data.relationships.ziffers.data[0],
          };
          resolve();
        }
      }
      else {
        resolve();
      }
    });
    return promise;
  }
  

//  Es ist nicht mehr nötig
//  
//  addDummyZiffer() {
//    let promise = new Promise((resolve, reject) => {
//      if (this.proposal.relationships.ziffer === undefined) {
//        if (this.proposal.relationships.antrag.data.relationships.ziffers.data.length == 0) {
//          let ziffer = new UbgZiffer;
//          ziffer.attributes = {
//            title: this.proposal.relationships.antrag.data.attributes.letter + ' Anträge',
//            number: 0,
//          };
//          ziffer.relationships = {
//            lines: {
//              data: [],
//            }
//          };
//          this.zifferSerrvice.create(ziffer).then((createdZiffer: UbgZiffer) => {
//            this.proposal.relationships.antrag.data.relationships.ziffers.data = [createdZiffer];
//            this.antragService.update(this.proposal.relationships.antrag.data).then((response: UBGAntragModel) => {
//              this.proposal.relationships.antrag.data = response;
//              this.proposal.relationships.ziffer = {
//                data: createdZiffer,
//              };
//              resolve();
//            });
//          });
//        }
//        else {
//          this.proposal.relationships.ziffer = {
//            data: this.proposal.relationships.antrag.data.relationships.ziffers.data[0],
//          };
//          resolve();
//        }
//      }
//      else {
//        resolve();
//      }
//    });
//    return promise;
//  }

  addProposal() {
    this.isLoading = true;
    this.proposal.relationships.org_user.data = this.selectedOrgUsers;
    // this.proposal.relationships.org_user.data = this.orgUser;
    console.log(this.proposal);
    this.proposalService.create(this.proposal).then(( proposal: UbgProposal ) => {
      console.log(proposal);
      // then we just redirect to the meine antrage list
      this.isLoading = false;
      // this.selectedEvent.emit(0); // we select the first tab
      this.snackbar.open('Erfolgreich eingefügt!', null, {
        duration: 10000,
      });
      // get rid of the content inside the editor
      this.proposalItem.attributes.content = '';
      this.proposalItem.attributes.reason.value = '';
      this.proposal.relationships.item.data = [];
      delete this.proposal.relationships.ziffer;
      this.isLoading = false;
      this.proposal.attributes.custom_title = '';
      this.proposalItem = new UbgProposalItem();
      this.proposalItem.attributes = {
        content: '',
        reason: {
          value: '',
        }
      };
      this.validate();
    }).catch((error) => {
      console.warn(error);
      this.snackbar.open('Der Antrag kann nicht generiert werden', null, {
        duration: 15000,
      });
      this.isLoading = false;
      this.validate();
    });
  }

  selectOrgUser(orgUserId: string, NeedEmit: boolean = true) {
    this.orgUserName = 'orgUserId';
    this.orgUserService.get('gliederung', orgUserId).then((response: UbgOrgUser) => {
      this.selectedOrgUsers.push(response);
      if (NeedEmit) {
        this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
      }
      this.loadOrgUsers();
      this.orgUserName = '';
      this.updateFreeName();
    });
  }
  removeOrgUser(orgUser: UbgOrgUser) {
    for (let i = 0 ; i < this.selectedOrgUsers.length; i++) {
      if (this.selectedOrgUsers[i].id === orgUser.id) {
        this.selectedOrgUsers.splice(i, 1);
      }
    }
    this.updateFreeName();
    this.selectedOrgUsersEvent.emit(this.selectedOrgUsers); // we are syncing the selected org users in other tabs
  }

  updateFreeName() {
    let freeName: boolean = false;
    for (let i in this.selectedOrgUsers) {
      if (this.selectedOrgUsers[i].attributes.freier_name === true) {
        freeName = true;
      }
    }
    this.freeName = freeName;
  }
  orgUserNameChanged(event: KeyboardEvent): void {
    if (this.proposal.attributes === undefined) {
      this.proposal.attributes = {
        title: '',
        org_user_name: '',
      };
    }
    this.proposal.attributes.org_user_name = event.target['value'];
  }

  openProposalReasonBottomSheet() {
    this.bottomSheetSign = '-';
    const bottomRef = this.proposalReasonBottomSheet.open(ProposalReasonBottomSheetComponent, { data: {content: this.proposalItem.attributes.reason.value, editable: true}});

    bottomRef.afterDismissed().subscribe(result => {
      if (result !== undefined) {
        this.proposalItem.attributes.reason.value = result;
      }
      this.bottomSheetSign = '+';
    });
  }
  
  validate() {
    let isInvalid: boolean = false;
    if (this.proposal.attributes.custom_title == '') {
      isInvalid = true;
    }
    if (!this.proposalItem.attributes.keine_begruendung 
    && (this.proposalItem.attributes.reason.value == '' || this.proposalItem.attributes.reason.value === null)) {
      isInvalid = true;
    }
    this.isInvalid = isInvalid;
  }

}


