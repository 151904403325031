import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {UbgProjectService} from '../../../services/project_service';
import {UbgProject} from '../../../model/project';
import {SidetabModel} from '../../utility/sidetabs/model/sidetab.model';
import {UBGAntragService} from '../../../services/antrag.service';
import {UBGAntragModel} from '../../../model/antrag.model';
import {SidetabsSyncService} from '../../utility/sidetabs/service/sidetabs.sync.service';
import {UbgProposalStructured} from '../../../model/proposal_structured';
import {UbgProposalService} from '../../../services/proposal.service';
import {UbgPrintConfirmDialogComponent} from './antrage-empfehlung/dialogs/print/print.component';
import {UbgConfirmComponent} from '../../../../ubg_components/confirm/components/confirm.component';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

  project: UbgProject = null;
  hasError: boolean = false;

  sideAntragTabs: SidetabModel[] = [];
  selectedAntragId: string = null;
  antragsObservable = new Subject<SidetabModel>();
  selectedAntrag: UBGAntragModel = null;
  myProposals: Array<UbgProposalStructured> = []
  selectedProposal: UbgProposalStructured
  tabIndex: number = 0
  isPrinting: boolean = false

  private subRoute: any;
  private subRouteParams: any;
  private subSidetabs: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private service: UbgProjectService,
              private antragService: UBGAntragService,
              public sidetabSyncService: SidetabsSyncService,
              private proposal: UbgProposalService) { }

  ngOnInit() {   
    this.subRoute = this.route.params.subscribe((params: {
      id: string
    }) => {
      if (params.id !== null) {
        this.service.setActiveProjectId(params.id);
        this.loadProject();
      }
    });


    this.subRouteParams = this.route.queryParams.subscribe((params: any) => {
      if (params['antrag'] !== undefined && params['antrag'] !== '' && params['antrag'] !== this.selectedAntrag) {
        this.sidetabSyncService.set_selected_id(params['antrag']);
      }
    });

    this.subSidetabs = this.sidetabSyncService.selected_id.subscribe((value) => {
      if (value !== undefined && value !== null && value !==  this.selectedAntragId ) {
//        console.log('we notice the change:');
//        console.log(value);
        this.receiveSelectedTabChanged(value);
      }
    });

    this.antragsObservable.pipe(debounceTime(200)).subscribe(resp => {
      this.sortAlphabetically();
      if (this.selectedAntragId === undefined || this.selectedAntragId === null) {
        this.sidetabSyncService.set_selected_id(this.sideAntragTabs[0].id);
      }
      else {
        let isProjectAntrag: boolean = false;
        for (let i in this.project.relationships.antrage.data) {
          if (this.project.relationships.antrage.data[i].id === this.selectedAntragId) {
            isProjectAntrag = true;
          }
        }
        if (!isProjectAntrag) {
          this.sidetabSyncService.set_selected_id(this.sideAntragTabs[0].id);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subSidetabs.unsubscribe();
    this.antragsObservable.unsubscribe();
    this.subRouteParams.unsubscribe();
    this.subRoute.unsubscribe();
  }

  loadProject() {
    this.service.get(this.route.snapshot.paramMap.get('id')).then((response: UbgProject) => {
      this.project = response;
      this.loadProjectAntrage(); // load the project antrags  to the sidetab component
      this.hasError = false;
    }).catch(() => {
      this.hasError = true;
    });
  }
  
  antragGenerated(antragId: string) {
    this.loadAntrag(antragId);
  }

  loadAntrag(id: string) {
    this.antragService.get(id).then((response: UBGAntragModel) => {
      this.selectedAntrag = response;
      this.loadMyProposals();
    }).catch(() => {});
  }

  editGliederungen() {
    this.router.navigate(['project/' + this.project.id + '/gliederungen']);
  }

  fallback() {
    this.router.navigate(['project/' + this.project.id + '/fallback/' + this.selectedAntrag.id]);
  }

  meineAntrag() {
    this.antragsObservable.unsubscribe();
    this.router.navigate(['project/' + this.project.id + '/mein-antrag']);
  }

  loadProjectAntrage() {
    this.sideAntragTabs = [];
//    this.selectedAntragId = null;
    for (let i in this.project.relationships.antrage.data) {
      this.antragService.get(this.project.relationships.antrage.data[i].id).then((response: UBGAntragModel) => {
        this.project.relationships.antrage.data[i] = response;
        // make the antrage tabs array
        this.sideAntragTabs.push({id: response.id, label: response.attributes.letter, info: response.attributes.description });
        this.antragsObservable.next({id: response.id, label: response.attributes.letter, info: response.attributes.description }) ;
      }).catch(() => {
        console.log('ERROR in getting project antrags');
      });
    }
  }

  sortAlphabetically(): void {
    // sort the antrage tabs array alphabetically
    this.sideAntragTabs = this.sideAntragTabs.sort((obj1, obj2) => {
      if (obj1.label > obj2.label) {
        return 1;
      }
      if (obj1.label < obj2.label) {
        return -1;
      }
      return 0;
    });
  }


  receiveSelectedTabChanged(value) {
    this.selectedAntragId = value;
    this.loadAntrag(value);
  }

  loadMyProposals() {
    this.proposal.getMyProposals(this.selectedAntrag).then((response: Array<UbgProposalStructured>) => {
      this.myProposals = response;
    });
  }

  setTabIndex(event) {
    this.tabIndex = event;
  }

  selectProposal(proposal: UbgProposalStructured) {
    if (this.selectedAntrag.relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag') {
      this.tabIndex = 1;
    }
    else {
      this.tabIndex = 0;
    }
    this.proposal.setActiveProposal(proposal);
  }

  print() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diese Anträge drucken?',
      mitEmpfehlungen: false,
      mitBegruendungen: false,
      empfehlungenPossible: true,
      begruendungPossible: true,
      mitMarkups: false,
      markupsPossible: true,
      mitEntwurf: false,
      entwurfPossible: true,
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        this.service.print(this.project, {
          mitEmpfehlungen: dialogConfig.data['mitEmpfehlungen'],
          mitBegruendungen: dialogConfig.data['mitBegruendungen'],
          mitMarkups: dialogConfig.data['mitMarkups'],
          mitEntwurf: dialogConfig.data['mitEntwurf'],
        }).then((response: any) => {
          this.isPrinting = false;
          //let fileName = 'Broschuere.pdf';
          let fileName = 'Broschuere.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', null, {
            duration: 15000,
          });
          this.isPrinting = false;
        });
      }
    });
  }
  
  printBegrundungen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die Begründungen drucken?',
    }
    let formDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        this.service.printBegrundungen(this.project).then((response: any) => {
          this.isPrinting = false;
          let fileName = 'Begruendungen.pdf';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', null, {
            duration: 15000,
          });
          this.isPrinting = false;
        });
      }
    });
  }

  deleteProject() {
    if (confirm('Sind Sie sicher?')) {

      this.snackbar.open('Delete project is not yet implemented!', null, {
        duration: 10000,
      });
    }
  }

  editProject() {
    this.router.navigate(['project/' + this.project.id + '/edit']);
  }
  
  openDashboard() {
    this.router.navigate(['project/' + this.project.id + '/dashboard']);
  }
  
  protokolle() {
    this.router.navigate(['project/' + this.project.id + '/protokolle']);
  }
  
  statistik() {
    this.router.navigate(['project/' + this.project.id + '/statistik']);
  }

}
