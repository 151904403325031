/**
 * Benutzereinstellungen-Komponent
 * 
 * Attila Németh, UBG
 * 27.06.2019
 */
 
import {Component, OnInit} from '@angular/core';

import {MatSnackBar} from '@angular/material';

import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {UbgUserService} from '../../../services/user.service';
import {UbgUser} from '../../../model/user';

@Component({
  templateUrl: './settings.component.html'
})
export class UbgUserSettingsComponent implements OnInit {
  
  isLoading: boolean = false
  user: UbgUser = null
  
  constructor(private snackbar: MatSnackBar,
                private contentaService: UbgContentaUserService,
                private service: UbgUserService) {}
  
  ngOnInit() {
    this.loadUserData();
  }
  
  loadUserData() {
    this.isLoading = true;
    if (this.contentaService.user === undefined) {
      setTimeout(() => {
        this.loadUserData();
      }, 250);
    }
    else {
      this.service.get(this.contentaService.user.id).then((response: UbgUser) => {
        this.isLoading = false;
        this.user = response;
      });
    }
  }
  
  save() {
    this.isLoading = true;
    this.service.update(this.user).then((response: UbgUser) => {
      this.user = response;
      this.isLoading = false;
      this.snackbar.open('Ihre Änderungen wurden gespeichert', null, {
        duration: 6000,
      });
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden', null, {
        duration: 15000,
      });
    });
  }
  
}

