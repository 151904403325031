/**
 * Statistik-Element-Modul
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';

import {UbgStatisticsItemComponent} from './components/item.component'
import {UbgStatisticsSummaryComponent} from './components/elements/summary/summary.component'
import {UbgStatisticsDetailsComponent} from './components/elements/details/details.component'
import {UbgStatisticsSummaryItemComponent} from './components/elements/summary_item/summary_item.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,  
    MatCardModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
  ],
  declarations: [
    UbgStatisticsItemComponent,
    UbgStatisticsSummaryComponent,
    UbgStatisticsSummaryItemComponent,
    UbgStatisticsDetailsComponent,
  ],
  exports: [
    UbgStatisticsItemComponent,
    UbgStatisticsSummaryItemComponent,
  ],
  entryComponents: [],
})
export class UbgStatisticsModule {

  constructor() {}

}
