/**
 * Seitenbar-Elemente
 * 
 * Attila Németh, UBG
 * 15.03.2019
 */
 
import {Component, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

import {UbgContentaUserService} from '../../services/user.service';

@Component({
  selector: 'ubg-contenta-user-sidebar',
  templateUrl: './sidebar.component.html',
})
export class ContentaUserSidebarComponent {
  
  @Output() clicked = new EventEmitter();
  
  constructor(private router: Router, 
                private service: UbgContentaUserService) {}
  
  logout() {
    this.clicked.emit();
    this.service.logout();
  }
  
  emitClick() {
    this.clicked.emit();
  }
  
}