/**
 * Org-User
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {UbgGroup} from './group';
import {UbgMandant} from './mandant';
import {UbgPriorityStructure} from './priority_structure';
 
export class UbgOrgUser {
  
  id: string
  type: string
  attributes: {
    title?: string
    lvkv_nummer?: string
    email?: string
    anrede?: string
    vorname?: string
    nachname?: string
    freier_name?: boolean
  }
  relationships: {
    gruppe?: {
      data: Array<UbgGroup>
    }
    mandant?: {
      data: Array<UbgMandant>
    }
    struktur: {
      data: Array<UbgPriorityStructure>
    }
  }
  
}