/**
 * Autocomplete-Komponent
 * 
 * Attila Németh, UBG
 * 08.04.2019
 */
 
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {UbgAutocompleteOption} from '../model/option';

@Component({
  selector: 'ubg-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class UbgAutocompleteComponent implements OnInit {
  
  @Input() placeholder: string
  @Input() set options(options: Array<UbgAutocompleteOption>) {
    this.optionsAll = options;
    for (let i in options) {
      if (options[i].id == this.valueData) {
        this.searchString = options[i].label;
      }
    }
    this.updateOptions();
  }
  @Input() optionsLength: number = 5
  @Input() disabled: boolean
  @Input() set value(value: string) {
    this.searchString = '';
    for (let i in this.optionsAll) {
      if (this.optionsAll[i].id == value || this.optionsAll[i].label == value) {
        this.searchString = this.optionsAll[i].label;
        this.updateOptions();
      }
    }
    this.valueData = value;
  }
  valueData: string = ''
  @Output() optionSelected = new EventEmitter();
  @Output() optionTyped = new EventEmitter();
  searchString: string = ''
  optionsAll: Array<UbgAutocompleteOption>
  optionsSelectable: Array<UbgAutocompleteOption>
  
  constructor() {
    this.optionsSelectable = [];
  }
  
  ngOnInit() {
    this.updateOptions();
  }
  
  updateOptions() {
    this.optionTyped.emit(this.searchString);
    let unsortedOptions = {};
    for (let i in this.optionsAll) {
      if (Object.keys(unsortedOptions).length < this.optionsLength
        && this.optionsAll[i].label.toLowerCase().indexOf(this.searchString.toLowerCase()) >= 0) {
        unsortedOptions[this.optionsAll[i].label + '--' + this.optionsAll[i].id] = this.optionsAll[i];
      } 
    }
    let newOptions: Array<UbgAutocompleteOption> = [];
    for (let i in unsortedOptions) {
      newOptions.push(unsortedOptions[i]);
    }
    this.optionsSelectable = newOptions;
  }
  
  onSelected() {
    this.optionSelected.emit(this.searchString);
    for (let i in this.optionsAll) {
      if (this.searchString == this.optionsAll[i].id) {
        this.searchString = this.optionsAll[i].label;
      }
    }
  }
  
}