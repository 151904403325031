/**
 * Einen Antrag löschen
 * 
 * Attila Németh, UBG
 * 04.03.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';

import {UbgProposal} from '../../../../../../model/proposal';

@Component({
  templateUrl: './delete.component.html',
})
export class UbgDeleteAntragDialogComponent {
  
  title: string = ''
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                proposal: UbgProposal,
              }) {
    this.title = data.proposal.attributes.title;
  }
  
}