/**
 * Benutzer-Service
 * 
 * Attila Németh, UBG
 * 27.06.2019
 */
 
import {Injectable} from '@angular/core';

import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgContentaUserService} from '../../contenta/user/services/user.service';
import {UbgUser} from '../model/user';
  
@Injectable({
  providedIn: 'root'
})
export class UbgUserService {
  
  constructor(private contenta: UbgContentaService,
                private contentaUser: UbgContentaUserService) {}
  
  get (id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/users/' + id).then((response: {
        data: UbgUser
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(user: UbgUser) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/users/' + user.id, {
        data: user
      }).then((response: {
        data: UbgUser
      }) => {
        this.contenta.accessTokenString = '';
        this.contenta.accessTokenExpires = -1;
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}