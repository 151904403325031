/**
 * Strukturierter Proposal
 * 
 * Attila Németh, UBG
 * 09.05.2019
 */
 
import {UbgProposalItem} from './proposal_item';
import {UbgEmpfehlungStructured} from './empfehlung_structured';
import {UbgProposalStatus} from './taxonomies/proposal_status';
import {UbgBegrundung} from './begrundung';
import {UbgZiffer} from './ziffer';
import {UbgUser} from './user';
import {UbgThema} from './taxonomies/thema';
 
export class UbgProposalStructured {
  
  id: string
  type: string = 'ubg_proposal--ubg_proposal'
  attributes: {
    title: string
    longTitle: string
    leitantrag: boolean
    zuweisungsdatum?: string
    custom_title?: string
    statusId?: string
    changed?: number
    org_user_name?: string
    bearbeitung: Array<string>
  }
  relationships: {
    item: {
      data: Array<UbgProposalItem>
    }
    votum: {
      data: Array<UbgEmpfehlungStructured>
    }
    status: {
      data: UbgProposalStatus,
    }
    begrundung: {
      data: UbgBegrundung
    }
    ziffer?: {
      data: UbgZiffer
    }
    gelesen?: {
      data: UbgUser
    }
    zustandigkeit: {
      data: Array<UbgUser>
    }
    thema?: {
      data: UbgThema
    }
    bearbeiter?: {
      data: Array<{
        fullName: string
      }>
    }
  }
  meta: {
    permissions: {
      edit: boolean
      delete: boolean
      assign: boolean
    }
  }
  
}