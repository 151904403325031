/**
 * Filter-Service
 * 
 * Attila Németh, UBG
 * 04.06.2019
 */
 
import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
  
import {UbgFilterParams} from '../model/filter_params';
 
@Injectable({
  providedIn: 'root'
})
export class UbgFilterService {
  
  private _params: BehaviorSubject<UbgFilterParams> = new BehaviorSubject({});
  public params: Observable<UbgFilterParams> = this._params.asObservable();

  constructor() {}
  
  setParams(params: UbgFilterParams): void {
    this._params.next(params);
  }
    
}