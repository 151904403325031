/**
 * Tab
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import { Component, Input } from '@angular/core';

import {UbgTabDialogService} from '../../../services/tab_dialog.service';

@Component({
  selector: 'ubg-tab-dialog-tab-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class UbgTabDialogTabItemComponent {
  
  @Input() tabId: number

  constructor(private service: UbgTabDialogService) {
    this.service.push();
  }
  
  itemClicked() {
    this.service.setActiveTab(this.tabId);
  }
  
  getTabClass() {
    let classes = [
      'ubg-tab-dialog-count-' + this.service.count,
    ];
    if (this.tabId == this.service.activeTab) {
      classes.push('active');
      classes.push('ubg-color-foreground-primary');
    }
    return classes.join(' ');
  }
  
}