/**
 * Zuweisung-Komponent
 * 
 * Attila Németh, UBG
 * 25.06.2019
 */
 
import {Component, Input, OnInit} from '@angular/core';

import {UbgUser} from '../../../../../../../model/user';

@Component({
  selector: 'ubg-zuweisung',
  templateUrl: './zuweisung.component.html',
  styleUrls: ['./zuweisung.component.scss'],
})
export class UbgProjectAntragZuweisungComponent implements OnInit {
  
  @Input() set entity(entity: {
    relationships: {
      zustandigkeit: {
        data: Array<UbgUser>
      }
    }
    meta: {
      permissions: {
        assign: boolean
      }
    }
  }) {
    this.entityData = entity;
    this.updateZuweisung();
  }
  entityData: {
    relationships: {
      zustandigkeit: {
        data: Array<UbgUser>
      }
    }
    meta: {
      permissions: {
        assign: boolean
      }
    }
  }
  
  zuweisung: string = ''
  
  constructor() {}
  
  ngOnInit() {
    this.updateZuweisung();
  }
  
  userCanAssign() {
    return this.entityData.meta.permissions.assign;
  }
  
  updateZuweisung() {
    let users: Array<string> = [];
    this.zuweisung = '';
    if (this.entityData.relationships.zustandigkeit !== undefined) {
      for (let i in this.entityData.relationships.zustandigkeit.data) {
        users.push(this.entityData.relationships.zustandigkeit.data[i].attributes.nachname 
        + ', ' 
        + this.entityData.relationships.zustandigkeit.data[i].attributes.vorname);
      }
      this.zuweisung = users.join(' | ');
    }
  }
  
}
