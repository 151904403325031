/**
 * Meine Anträge
 * 
 * Attila Németh, UBG
 * 13.05.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog, MatDialogConfig} from "@angular/material";

import {UbgProposalService} from '../../../../services/proposal.service';
import {UbgProject} from '../../../../model/project';
import {UBGAntragService} from '../../../../services/antrag.service';
import {UBGAntragModel} from '../../../../model/antrag.model';
import {UbgProposalStructured} from '../../../../model/proposal_structured';
import {UbgProposal} from '../../../../model/proposal';
import {UbgConfirmComponent} from '../../../../../ubg_components/confirm/components/confirm.component';

@Component({
  selector: 'ubg-meine-antraege',
  templateUrl: './meine.component.html',
})
export class UbgMeineAntraegeComponent {
    
  @Input() set antragId(antragId: string) {
    if (antragId !== null) {
      this.loadAntrag(antragId);
    }
  }
  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.projectId = project.id;
    }
  }
  @Output() selectProposal = new EventEmitter;
  projectId: string = null
  isLoading: boolean = false
  hasError: boolean = false
  proposals: Array<UbgProposalStructured>
  proposalsStructured: {
    inProcess: Array<UbgProposalStructured>
    open:  Array<UbgProposalStructured>
    closed:  Array<UbgProposalStructured>
    recent:  Array<UbgProposalStructured>
  }
  categoryToDisplay: string = ''
  summary: Array<{
    label: string
    count: number
    class: string
  }>
  displayedColumns = ['label', 'status', 'link', 'read', 'return']
  
  constructor(private dialog: MatDialog,
                private service: UbgProposalService,
                private antrag: UBGAntragService) {
    this.proposalsStructured = {
      inProcess: [],
      open: [],
      closed: [],
      recent: [],
    };                
  }
  
  loadAntrag(id: string) {
    this.isLoading = true;
    this.antrag.get(id).then((response: UBGAntragModel) => {
      this.service.getMyProposals(response).then((response: Array<UbgProposalStructured>) => {
        this.proposals = response;
        this.groupProposals();
        this.isLoading = false;
        this.hasError = false;
      });
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  groupProposals() {
    this.proposalsStructured = {
      inProcess: [],
      open: [],
      closed: [],
      recent: [],
    };
    for (let i in this.proposals) {
      if (this.proposals[i].relationships.gelesen.data === null) {
        this.proposalsStructured.recent.push(this.proposals[i]);
      }
      if (this.proposals[i].relationships.status.data !== null) {
        if (this.proposals[i].relationships.status.data.attributes.name == 'In Bearbeitung') {
          this.proposalsStructured.inProcess.push(this.proposals[i]);
        }
        else {
          this.proposalsStructured.closed.push(this.proposals[i]);
        }
      }
      else if (!this.proposals[i].attributes.leitantrag) {
        this.proposalsStructured.open.push(this.proposals[i]);
      }
    }
  }
  
  getStatusLabel(proposal: UbgProposalStructured): string {
    let label: string = '---';
    if (proposal.relationships.status.data !== null) {
      label = proposal.relationships.status.data.attributes.name;
    }
    else {
      label = 'Offen';
    }
    return label;
  }
  
  gotoProposal(proposal: UbgProposalStructured) {
    this.selectProposal.emit(proposal);
  }
  
  clicked(category: string) {
    this.categoryToDisplay = category;
  }
  
  getSelectedProposals(): Array<UbgProposalStructured> {
    return this.proposalsStructured[this.categoryToDisplay];
  }
  
  readProposal(proposal: UbgProposalStructured) {
    this.service.setRead(proposal).then((response: UbgProposalStructured) => {
      this.service.getStructured(response.id).then((response: UbgProposalStructured) => {
        let newProposals: Array<UbgProposalStructured> = [];
        for (let i in this.proposals) {
          if (this.proposals[i].id == response.id) {
            newProposals.push(response);
          }
          else {
            newProposals.push(this.proposals[i]);
          }
        }
        this.proposals = newProposals;
        this.groupProposals();
      });
    });
  }
  
  reassignProposal(proposal: UbgProposalStructured) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie diesen Antrag wirklich zurückweisen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.get(proposal.id).then((response: UbgProposal) => {
          response.relationships.zustandigkeit.data = null;
          this.service.update(response).then((response: UbgProposal) => {
            this.service.getStructured(response.id).then((response: UbgProposalStructured) => {
              let newProposals: Array<UbgProposalStructured> = [];
              for (let i in this.proposals) {
                if (this.proposals[i].id != response.id) {
                  newProposals.push(this.proposals[i]);
                }
              }
              this.proposals = newProposals;
              this.groupProposals();
            });
          });
        });
      }
    });
  }

}
