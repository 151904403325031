/**
 * Zusemmenfassug-Element
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ubg-statistics-item-summary-item',
  templateUrl: './summary_item.component.html',
  styleUrls: ['./summary_item.component.scss'],
})
export class UbgStatisticsSummaryItemComponent {
  
  @Input() label: string
  @Input() count: number
  @Input() class: string
  
  constructor() {}
  
}