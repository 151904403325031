import {Component, Input, OnInit, ViewChild, AfterViewChecked, Output, EventEmitter} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as inlineEditor from '@ckeditor/ckeditor5-build-inline';
// import * as ClassicEditor from '../../../../../../dist/ckeditor5-build-ubg';


import { NgForm } from '@angular/forms';

import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';


import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {TokenResponse} from '../../../../contenta/model/token.response';
import {UBGLineService} from '../../../services/line.service';
import {UBGAntragService} from '../../../services/antrag.service';
import {UbgZifferService} from '../../../services/ziffer.service';
import {AntragStatusService} from '../../../services/taxonomies/antrag_status.service';
import {TextHelperService} from '../../../services/helpers/text-helper.service';


import {UbgZiffer} from '../../../model/ziffer';
import {UbgProject} from '../../../model/project';
import {UbgLine} from '../../../model/line';
import {UBGAntragModel} from '../../../model/antrag.model';
import {ActivatedRoute} from '@angular/router';
import {AntragStatusModel} from '../../../model/taxonomies/antrag_status.model';
import {UbgZifferStructured} from '../../../model/ziffer_structured';


@Component({
  selector: 'app-leitantrag-add',
  templateUrl: './leitantrag-add.component.html',
  styleUrls: ['./leitantrag-add.component.scss'],
})
export class LeitantragAddComponent implements OnInit, AfterViewChecked {
  @ViewChild( 'demoForm' ) demoForm?: NgForm;

  @Input() set project(project: UbgProject) {
    if (project !== null) {
      this.projectId = project.id;
    }
  }
  projectId: string = null;

  @Input() set antrag(antrag: UBGAntragModel) {
    if (antrag !== null) {
      this.activeAntrag = antrag;
      this.loadAntragZiffers();
    }
  }
  activeAntrag: UBGAntragModel;
  
  @Output() antragGenerated = new EventEmitter

  isLoading: boolean = false;
  panelOpenState: boolean = false;
  isLineBreakNeeded: boolean = true;
  isLineBreakNeededEditable: boolean = true;

  needSave: boolean = false;
  processing: boolean = false;
  needOrdering: boolean = false;
  needScroll: boolean = false;
  container: HTMLElement;

  public Editor = ClassicEditor;
  public Config = {
    language: 'de',
    toolbar: [
/*      'heading',
      '|',*/
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      'subscript', 'superscript',
      '|',
      'undo',
      'redo',
    ],
  };
  // public Editor2 = inlineEditor;

  public ziffer: UbgZiffer;
  public ziffers = [];
  ziffersObservable = new Subject<UbgZiffer>();

  public isChecked = false;
  public startWithZero = false;

  lines: string[] = [];
  linesObservable = new Subject<UbgLine>();

  constructor( private route: ActivatedRoute,
               private zifferService: UbgZifferService,
               private lineService: UBGLineService,
               private antragService: UBGAntragService,
               private antragStatusService: AntragStatusService,
               private textHelperService: TextHelperService
  ) {
    this.ziffer = {
      id: null,
      type: 'ubg_ziffer--ubg-ziffer',
      attributes: {
        title: '',
        number: 0,
        content: '',
      },
      relationships: {
        lines: {
            data: [],
        }
      }
    };
  }

  ngOnInit() {

    /**
     * We look to see when all the lines are posted to the server so we can post the ziffer
     */
    this.linesObservable.pipe(debounceTime(200)).subscribe(resp => {
      // @TODO: IT would be nice to have a progressbar
      // let's just make sure all the lines are inserted
      if (this.ziffer.relationships.lines.data.length === this.lines.length ) {
        // all the lines are posted, we post the ziffer
        this.postZiffer();
      }
    });

    /**
     * We look to see when all the ziffers are loaded and sort them by numbers
     */
    this.ziffersObservable.pipe(debounceTime(200)).subscribe(resp => {
        this.ziffers = this.sortByNumber(this.ziffers);
    });
  }
  
  
  
  
  
  ngAfterViewChecked() {
    if ( this.needScroll ) {
      this.scrollToBottom();
      this.needScroll = false;
    }

  }

  scrollToBottom(): void {
    try {
      this.container = document.getElementById('mainEditor');
      this.container.scrollIntoView();

    } catch (err) {
      console.log(err);
    }
  }

  /**
   * deprecated
   */
  loadAntragZiffers_old() {
    this.ziffers = [];
    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      this.isLoading = true;
      // Loading the already existing ziffers of the selected antrag
      for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
        this.zifferService.get(this.activeAntrag.relationships.ziffers.data[i].id).then((ziffer: UbgZiffer) => {
          console.log(ziffer);
          // so we need to put the lines together
          ziffer.attributes.content = ''; // it is not defined
          // sort the ziffer lines
          ziffer.relationships.lines.data = this.sortByNumber(ziffer.relationships.lines.data);
          for (let j = 0; j < ziffer.relationships.lines.data.length; j++) {
            ziffer.attributes.content += ziffer.relationships.lines.data[j].attributes.content.value;
          }
          this.activeAntrag.relationships.ziffers.data[i] = ziffer;
          this.ziffers.push(ziffer);
          this.ziffersObservable.next(ziffer);

          if (this.needOrdering && i === this.activeAntrag.relationships.ziffers.data.length - 1 ) {

            setTimeout(() => {
              console.log('we are reordering');
              this.zifferReorder();
              this.needOrdering = false;
              this.needSave = true;
            }, 200);


          }
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    }
  }

  loadAntragZiffers() {
    this.ziffers = [];
    if (this.projectId === null) {
      console.error('Projekt ID', this.projectId);
      return;
    }
    if (!this.activeAntrag) {
      console.error('Antrag', this.activeAntrag);
      return;
    }
    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      this.isLoading = true;
      this.zifferService.getAllByAntrag( this.activeAntrag.id).then((response: UbgZiffer[]) => {
        this.activeAntrag.relationships.ziffers.data = response;
        this.ziffers = response;
        this.checkStartNumber();
        this.ziffers = this.sortByNumber(this.ziffers);
        this.zifferReorder();
        this.isLoading = false;
        this.needScroll = true;
      });
    }
  }

  checkStartNumber() {
    let zeroExist = false;
    for (let i = 0 ; i < this.ziffers.length; i++) {
      if ( this.ziffers[i].attributes.number === 0) {
        zeroExist = true;
        break;
      }
    }
    if (zeroExist) {
      this.startWithZero = true;
    } else {
      this.startWithZero = false;
    }
  }

  loadZiffer(zifferId: string) {
    //find the ziffer and load its content
/*    let z = this.activeAntrag.relationships.ziffers.data.find(function(ziffer) {
      return ziffer.id === zifferId;
    });*/

    if (this.activeAntrag.relationships.ziffers.data && this.activeAntrag.relationships.ziffers.data.length > 0) {
      // Loading the already existing ziffers of the selected antrag
      for (let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
        if (zifferId === this.activeAntrag.relationships.ziffers.data[i].id) {
          // if it is not already loaded
          if (this.ziffers[i].attributes.content === undefined || this.ziffers[i].attributes.content === '') {
            this.zifferService.get(zifferId).then((ziffer: UbgZiffer) => {
              if (ziffer.attributes.number != this.ziffers[i].attributes.number) {
                console.info('Ziffer', ziffer.attributes.number, '->', this.ziffers[i].attributes.number);
                ziffer.attributes.number = this.ziffers[i].attributes.number;
              }
              // so we need to put the lines together
              ziffer.attributes.content = ''; // it is not defined
              // sort the ziffer lines
              ziffer.relationships.lines.data = this.sortByNumber(ziffer.relationships.lines.data);
              for (let j = 0; j < ziffer.relationships.lines.data.length; j++) {
                ziffer.attributes.content += ziffer.relationships.lines.data[j].attributes.content.value;
              }
              ziffer.attributes.content = this.textHelperService.dropSpans(ziffer.attributes.content);
              this.activeAntrag.relationships.ziffers.data[i] = ziffer;
              this.ziffers[i] = ziffer;
              this.ziffers[i].isExpanded = true;
            }).catch(() => {
              console.log('ERROR in load ziffer');
            });
          } else {
            this.ziffers[i].isExpanded = true;
          }
        }
      }
    }
  }

  ZifferTrackByFn(index, item) {
    return item.id; // unique id corresponding to the item
  }

  addZiffer(checkbox) {
    this.processing = true;
    this.isChecked = checkbox.checked;
    if (this.startWithZero) {
      this.isChecked = false;
    }
    if (this.isChecked) {
      this.ziffer.attributes.number = 0;
      this.ziffer.attributes.title = '0';
      this.startWithZero = true;
      // this.ziffers.unshift( this.ziffer);
      // reorder once in case null already exist
      // this.zifferReorder();
    } else {
      if (this.startWithZero){
        this.ziffer.attributes.number = this.ziffers.length ;
        this.ziffer.attributes.title = (this.ziffers.length ).toString();
      } else {
        this.ziffer.attributes.number = this.ziffers.length + 1;
        this.ziffer.attributes.title = (this.ziffers.length + 1).toString();
      }

      // this.ziffers.push( this.ziffer);
    }

    // We need to to first post all the ziffer lines so that we can include them in the ziffer
    this.zifferService.breakLines(this.ziffer.attributes.content).then((response: Array<string>) => {
      this.lines = response;
      this.postLines();
    });
//    this.postLines();
    
    
//    this.lines = this.textHelperService.breakContentToLines(this.textHelperService.structureData(this.ziffer.attributes.content), true);
//    console.log('lines:');
//    console.log(this.lines);
//    this.postLines();

    /** we gotta make sure that all the lines are posted and then post the ziffer
     * this.postZiffer(); post ziffer happens in the line post subscriber. This is just a hint so we can follow the steps later on.
     * when ziffer is insterted we need to relate it to the parent antrag.
     * this.relateZifferToAntrag(); this also not happening here. The ziffer ought be posted first.
     * PS. UPDATE. we bulk post the lines.
     */
  }

  postZiffer() {
    this.zifferService.create(this.ziffer).then((response: UbgZiffer) => {
      console.log('The Ziffer is posted:');
      console.log(response);
      this.relateZifferToAntrag(response); // add the ziffer to the parent antrag
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in add ziffer');
    });
  }

  postLines() {
/*    for ( let i = 0; i < this.lines.length; i++) {
      console.log(this.lines[i]);
      this.lineService.create({
        type: 'ubg_line--ubg-line',
        attributes: {
          title: (Date.now()).toString(), // timestamp
          content: this.lines[i],
          number: i
        }
      }).then((response: UbgLine) => {
        console.log(response);
        this.ziffer.relationships.lines.data.push(response);
        this.linesObservable.next(response); // let the subscriber know a that a line has been posted.
      }).catch(() => {
        console.log('ERROR in add line');
      });
    }*/

    let lines = [];
    for ( let i = 0; i < this.lines.length; i++) {
      lines.push({
        type: 'ubg_line--ubg-line',
        attributes: {
          title: (Date.now()).toString(), // timestamp
          content: this.lines[i],
          number: i
        }
      });
    }

    this.lineService.bulkCreate(lines).then((response: UbgLine[]) => {
      console.log(response);
      this.ziffer.relationships.lines.data = response;
      this.postZiffer();
    }).catch(() => {
      console.log('ERROR in add line');
    });
  }

  relateZifferToAntrag(ziffer: UbgZiffer) {
    // we place the ziffer in antrag relationships and update the antrag
    this.activeAntrag.relationships.ziffers.data.push(ziffer);
    // and update the antrag
    this.antragService.update(this.activeAntrag).then((response: UBGAntragModel) => {
      this.activeAntrag = response;
      this.loadAntragZiffers(); // reload the ziffer list
      // renew the ziffer model
      this.ziffer = {
        id: null,
        type: 'ubg_ziffer--ubg-ziffer',
        attributes: {
          title: '',
          number: 0,
          content: '',
        },
        relationships: {
          lines: {
            data: [],
          }
        }
      };
      this.processing = false;
    }).catch((e) => {
      console.log(e);
      console.log('ERROR in anrtrag relations update');
    });
  }


  deleteZiffer(ziffer) {

    // remove the ziffer from activeAntrag
    for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
      if (this.activeAntrag.relationships.ziffers.data[i] && this.activeAntrag.relationships.ziffers.data[i].id === ziffer.id) {
        this.activeAntrag.relationships.ziffers.data.splice(i, 1); // remove 1 element from index i
      }
    }
    if (this.activeAntrag.relationships.ziffers.data.length === 1 && !this.activeAntrag.relationships.ziffers.data[0]) {
      this.activeAntrag.relationships.ziffers.data = [];
    }
    // console.log(this.activeAntrag);

    this.zifferService.delete(ziffer.id).then(() => {
      // we need tp update antrag
      this.antragService.get(this.activeAntrag.id).then((response: UBGAntragModel) => {
        this.activeAntrag = response;
        this.needOrdering = true;
        this.loadAntragZiffers();
      }).catch(() => {
        console.log('ERROR in sync activeAntrag');
      });

    }).catch((e) => {
      console.log(e);
      console.log('ERROR in ziffer delete');
    });

  }


  editZiffer(ziffer) {
    this.processing = true;
    // Ziffer has many line we need to check which one are changed and edit them
    for ( let i = 0; i < this.ziffers.length; i++ ) {
      if (this.ziffers[i].id === ziffer.id) {

        const lineContents = this.textHelperService.breakContentToLines(this.textHelperService.structureData(this.ziffers[i].attributes.content), true);
        
/*        let minLength = Math.min(lines.length, this.ziffers[i].relationships.lines.data.length);
        for ( let l = 0; l < minLength; l++) {
/!*          console.log('the line:');
          console.log(lines[l]);
          console.log('and the other one:');
          console.log(this.ziffers[i].relationships.lines.data[l].attributes.content.value);*!/
          if ( lines[l] !== this.ziffers[i].relationships.lines.data[l].attributes.content.value) { // we only update if it is different
            this.ziffers[i].relationships.lines.data[l].attributes.content = lines[l];
            this.ziffers[i].relationships.lines.data[l].attributes.number = l;
            this.lineService.update(this.ziffers[i].relationships.lines.data[l]).then((response: UbgLine) => {
              this.ziffers[i].relationships.lines.data[l] = response;
              console.log(response);
            }).catch(() => {
              console.log('ERROR in edit line');
            });
          }
        }

        if ( lines.length > this.ziffers[i].relationships.lines.data.length) {
          // we need to insert
          for ( let l = this.ziffers[i].relationships.lines.data.length; l < lines.length ; l++) {
            this.lineService.create({
              type: 'ubg_line--ubg-line',
              attributes: {
                title: (Date.now()).toString(), // timestamp
                content: lines[l],
                number: l
              }
            }).then((response: UbgLine) => {
              this.ziffers[i].relationships.lines.data.push(response);
              if (l + 1 === lines.length) { // update ziffer after last one
                this.updateZifferLines(this.ziffers[i]);
              }
            }).catch(() => {
              console.log('ERROR in add line');
            });
          }
        } else if (lines.length < this.ziffers[i].relationships.lines.data.length) {
          // we need to delete
          for ( let l = lines.length; l < this.ziffers[i].relationships.lines.data.length ; l++) {
            this.lineService.delete(this.ziffers[i].relationships.lines.data[l].id).then(() => {
              delete this.ziffers[i].relationships.lines.data[l];
              if (l + 1 === this.ziffers[i].relationships.lines.data.length) { // update ziffer after last one
                this.updateZifferLines(this.ziffers[i]);
              }
          }).catch(() => {
              console.log('ERROR in delete line');
            });
          }
        } // else they are equal*/
        let lines = [];
        for ( let i = 0; i < lineContents.length; i++) {
          lines.push({
            type: 'ubg_line--ubg-line',
            attributes: {
              title: ('ziffer ' + ziffer.attributes.title + ', line ' + i + ', ' + Date.now()).toString(), // timestamp
              content: lineContents[i],
              number: i
            }
          });
        }

        this.lineService.bulkEdit(lines, ziffer.id).then((response: UbgLine[]) => {
          console.log(response);
          this.ziffers[i].relationships.lines.data = response;
          this.loadAntragZiffers();
        }).catch(() => {
          console.log('ERROR in add line');
        });


        this.ziffers[i].editable = false;
        this.processing = false;
      }
    }

  }

  updateZifferLines(ziffer) {
    let content = ziffer.attributes.content;
    delete ziffer.attributes.content;
    this.zifferService.update(ziffer).then(() => {
      // we set the ziffer content back
      ziffer.attributes.content = content;
    }).catch(() => {
      console.log('ERROR in update antrag');
    });
  }

  /**
   * This method switches the ziffer container to the editor so user can edit the content
   * @param ziffer
   */
  createEditableZiffer(ziffer) {
    for ( let i = 0; i < this.ziffers.length; i++ ) {
      if (this.ziffers[i].id === ziffer.id) {
        this.ziffers[i].editable = true;
      }
    }
  }


  /**
   * when a user reorders the ziffer list, they need to press the save btn to save the new order
   */
  onSaveClick() {
    // saveing the ziffer order
    let savedCount: number = 0;        
    for ( let i = 0; i < this.ziffers.length; i++) {
      // ziffer has no content,(just lines),
      // we just have it here for simplifying things
      // and we need to get rid of it before sending request to server.
      let content = this.ziffers[i].attributes.content;
      delete this.ziffers[i].attributes.content;
      this.zifferService.update(this.ziffers[i]).then(() => {
        // we set the ziffer content back
        this.ziffers[i].attributes.content = content;
        savedCount++;
        if (savedCount >= this.ziffers.length) {
          this.needSave = false;
        }
      }).catch(() => {
        console.log('ERROR in update antrag orders');
      });
    }
  }

  /**
   * Delete all the inserted ziffers
   */
  onDiscardClick() {
    if (confirm('Sind Sie sicher, dass die eingefügten ziffern entfernt werden sollen?')) {

      let ziffers = this.activeAntrag.relationships.ziffers.data;
      // remove the ziffer ER from the parent antrag
      this.activeAntrag.relationships.ziffers.data = [];
      this.antragService.update(this.activeAntrag).then((response: UBGAntragModel) => {
        this.activeAntrag = response;

        while (ziffers.length) {
          let z = ziffers.pop();
          this.isLoading = true;
          this.zifferService.delete(z.id).then(() => {
            if (ziffers.length === 0 ) {
              // after the last delete we refresh the page
              this.isLoading = false;
              this.loadAntragZiffers();
              this.startWithZero = false;
            }
          }).catch(() => {
            console.log('ERROR in delete ziffers discard');
          });

        }
      }).catch(() => {
        console.log('ERROR in sync activeAntrag');
      });
    }
  }

  onGenerateClick() {
    if (confirm('Sind Sie sich sicher, dass Sie mit Zeilennummerierung fortsetzen wollen?\n' +
      'Der Prozess kann nur durch Administrator rückgängig  gemacht werden.\n')) {
      this.isLoading = true;
      this.ziffers = [];
      // we go through all the ziffers and lines and correct their numbers
      this.antragService.generateNumbers(this.activeAntrag.id).then(() => {
        // we set the antrag status to numbered
        this.antragStatusService.getByName('numbered').then((response: AntragStatusModel) => {
          setTimeout(() => {
            this.activeAntrag.relationships.status.data = response;
            this.antragService.update(this.activeAntrag).then((updatedAntrag: UBGAntragModel) => {
              this.activeAntrag = null;
              setTimeout(() => {
                this.isLoading = false;
                this.activeAntrag = updatedAntrag;
                this.antragGenerated.emit(updatedAntrag.id);
              }, 2500);
            }).catch(() => {
              console.error('Der Leitantrag kann nicht generiert werden!');
              this.activeAntrag.relationships.status.data = null;
              this.isLoading = false;
              this.loadAntragZiffers();
            });
          }, 2500);
        }).catch(() => {
          console.log('ERROR in get antrag status taxonomy by name');
        });
      }).catch(() => {
        console.log('ERROR in antrag generate number request');
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ziffers, event.previousIndex, event.currentIndex);
    // reorder the ziffer numbers
    this.zifferReorder();
    this.needSave = true;
  }

  onEditorChange() {
    this.isLineBreakNeeded = true;
  }
  onInlineEditorChange() {
    this.isLineBreakNeededEditable = true;
  }

  zifferReorder() {
    let transcend = 1;
    if (this.startWithZero) {
      transcend = 0;
    }
    for ( let i = 0; i < this.ziffers.length; i++) {
      this.ziffers[i].attributes.number = i + transcend;
    }
/*    for ( let i = 0; i < this.activeAntrag.relationships.ziffers.data.length; i++) {
      if (this.activeAntrag.relationships.ziffers.data[i].attributes) {
        this.activeAntrag.relationships.ziffers.data[i].attributes.number =  i + transcend;
      }
    }*/
  }

  sortByNumber(arr) {
    // sort the antrage tabs array alphabetically
    arr = arr.sort((obj1, obj2) => {
      if (obj1.attributes.number > obj2.attributes.number) {
        return 1;
      }
      if (obj1.attributes.number < obj2.attributes.number) {
        return -1;
      }
      return 0;
    });
    return arr;
  }

  breakLines() {
    this.zifferService.breakLines(this.ziffer.attributes.content).then((response: Array<string>) => {
      this.ziffer.attributes.content = this.textHelperService.dropSpans(response.join(''));
      setTimeout(() => {
        this.isLineBreakNeeded = false;
      }, 500);
    });
//    
//    
//    this.ziffer.attributes.content = this.textHelperService.dropSpans(this.textHelperService.structureData( this.ziffer.attributes.content));
//    
//    
//    setTimeout(() => {
//      this.isLineBreakNeeded = false;
//      console.log('the new content:');
//      console.log(this.ziffer.attributes.content);
//    }, 200);

  }
  
  



  EditableBreakLines(ziffer) {

    let content = this.textHelperService.dropSpans(this.textHelperService.structureData( ziffer.attributes.content ));
    ziffer.attributes.content = content;
    for ( let i = 0; i < this.ziffers.length; i++ ) {
      if (this.ziffers[i].id === ziffer.id) {
        this.ziffers[i].attributes.content = content;
      }
    }
    setTimeout(() => {
      this.isLineBreakNeededEditable = false;
    }, 200);

  }

}
