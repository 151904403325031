/**
 * Proposal Status Service
 * 
 * Attila Németh, UBG
 * 08.05.2019
 */
 
import { Injectable } from '@angular/core';

import {UbgContentaService} from '../../../contenta/services/contenta.service';
import {UbgProposalStatus} from '../../model/taxonomies/proposal_status';

@Injectable({
  providedIn: 'root'
})
export class UbgProposalStatusService {

  constructor(private contenta: UbgContentaService) {
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/proposal_status').then((response: {
        data: Array<UbgProposalStatus>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/proposal_status?filter[name]=' + name).then((response: {
        data: Array<UbgProposalStatus>
      }) => {
        if (response.data.length > 0) {
          resolve(response.data[0]);
        }
        else {
          console.warn('Kein Status gefunden', name);
          reject();
        }
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

}