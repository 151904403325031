/**
 * Proposal-Service
 *
 * Attila Németh, UBG
 * 02.05.2019
 */

import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgProposal} from '../model/proposal';
import {UbgProposalItem} from '../model/proposal_item';
import {UbgEmpfehlung} from '../model/empfehlung';
import {UbgProposalStructured} from '../model/proposal_structured';
import {UbgUser} from '../model/user';
import {UBGAntragModel} from '../model/antrag.model';
import {UbgOrgUser} from '../model/org_user';
import {UbgBegrundung} from '../model/begrundung';
import {UbgBegrundungService} from '../services/begrundung.service';
import {st} from '@angular/core/src/render3';
import {UBGAntragService} from './antrag.service';
import {UbgZiffer} from '../model/ziffer';

@Injectable({
  providedIn: 'root'
})
export class UbgProposalService {

  private checkedProposalsData: BehaviorSubject<UbgProposalStructured[]> = new BehaviorSubject([]);
  public checkedProposals: Observable<UbgProposalStructured[]> = this.checkedProposalsData.asObservable();
  private activeProposalData: BehaviorSubject<UbgProposalStructured> = new BehaviorSubject(null);
  public activeProposal: Observable<UbgProposalStructured> = this.activeProposalData.asObservable();

  constructor(private contenta: UbgContentaService,
                private begruendungService: UbgBegrundungService,
                private antragService: UBGAntragService) {}

  create(proposal: UbgProposal) {
    const promise = new Promise((resolve, reject) => {
      this.checkItem(proposal).then((proposal: UbgProposal) => {
        this.contenta.post('api/ubg_proposal/ubg_proposal', {
          data: proposal
        }).then((response: {
          data: UbgProposal
        }) => {
          resolve(response.data);
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  checkItem(proposal: UbgProposal) {
    const promise = new Promise((resolve, reject) => {
      let allItemOk: boolean = true;
      for (let delta in proposal.relationships.item.data) {
        if (proposal.relationships.item.data[delta].id === undefined) {
          this.createItem(proposal.relationships.item.data[delta]).then((item: UbgProposalItem) => {
            proposal.relationships.item.data[delta] = item;
            this.checkItem(proposal).then((proposal: UbgProposal) => {
              resolve(proposal);
            });
          });
          allItemOk = false;
        }
      }
      if (allItemOk) {
        resolve(proposal);
      }
    });
    return promise;
  }

  createItem(item: UbgProposalItem) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_proposal_item/ubg_proposal_item', {
        data: item
      }).then((response: {
        data: UbgProposalItem
      }) => {
        resolve(response.data);
      }).catch((response: any) => {
        console.log(response);
        reject();
      });
    });
    return promise;
  }

  getEmpty(): UbgProposal {
    let item = new UbgProposalItem;
    item.attributes = {
      from: null,
      to: null,
      content: null,
      reason: {
        value: null,
      }
    };
    item.relationships = {
      action: {
        data: null
      }
    };
    let proposal = new UbgProposal;
    proposal.relationships = {
      antrag: {
        data: null,
      },
      org_user: {
        data: [],
      },
      item: {
        data: [
          item,
        ],
      }
    }
    proposal.attributes = {
      title: '',
    };
    return proposal;
  }

  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_proposal/ubg_proposal/' + id).then((response: {
        data: UbgProposal
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getStructured(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/proposal/api/proposal/' + id).then((response: {
        data: UbgProposalStructured
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  update(proposal: UbgProposal) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_proposal/ubg_proposal/' + proposal.id, {
        data: proposal
      }).then((response: {
        data: UbgProposal
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getReda(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/proposal/api/reda/' + id).then((response: UbgUser) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getMyProposals(antrag: UBGAntragModel) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/antrag/api/' + antrag.id + '/my').then((response: {
        data: Array<UbgProposalStructured>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getEmpfehlungTexts(proposal: UbgProposalStructured) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/proposal/' + proposal.id + '/empfehlungen').then((response: {
        empfehlungen: Array<string>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getAntragProposalsByOrgUsers(antragId: string, orgUsers: UbgOrgUser[]) {
    let orgFilter = '&filter[id-filter][condition][path]=org_user.id' +
      '&filter[id-filter][condition][operator]=IN';
    for (let i in orgUsers) {
      orgFilter += '&filter[id-filter][condition][value][]=' + orgUsers[i].id;
    }

    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_proposal/ubg_proposal/?filter[antrag.id]=' + antragId + orgFilter + '&include=item,status,org_user,ziffer').then((response: {
        data: UbgProposal[],
        included: any
      }) => {
        // item field should have been named items actually
        for (let i in response.included) {
          if (response.included[i].type === 'ubg_proposal_item--ubg_proposal_item') {
            for (let j in response.data) {
              for (let k in response.data[j].relationships.item.data) {
                if (response.data[j].relationships.item.data[k].id === response.included[i].id) {
                  response.data[j].relationships.item.data[k] = response.included[i];
                }
              }
            }
          } else if (response.included[i].type === 'taxonomy_term--proposal_status') {
            for (let j in response.data) {
              // status could be null
              if (response.data[j].relationships.status && response.data[j].relationships.status.data && response.data[j].relationships.status.data.id === response.included[i].id) {
                response.data[j].relationships.status.data = response.included[i];
              }
            }
          } else if (response.included[i].type === 'ubg_org_user--gliederung') {
            for (let j in response.data) {
              for (let k in response.data[j].relationships.org_user.data) {
                if ( response.data[j].relationships.org_user.data[k].id === response.included[i].id) {
                  response.data[j].relationships.org_user.data[k] = response.included[i];
                }
              }
            }
          }
          else if (response.included[i].type === 'ubg_ziffer--ubg_ziffer') {
            for (let j in response.data) {
              if (response.data[j].relationships.ziffer !== null
                      && response.data[j].relationships.ziffer.data !== null
                      && response.data[j].relationships.ziffer.data.id == response.included[i]['id']) {
                response.data[j].relationships.ziffer.data = response.included[i];
              }
            }
          }
        }
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
    return promise;
  }

  getZifferProposalsByOrgUser(zifferId: string, orgUsers: UbgOrgUser[]) {

    let orgFilter = '&filter[id-filter][condition][path]=org_user.id' +
                      '&filter[id-filter][condition][operator]=IN';
    for (let i in orgUsers) {
      orgFilter += '&filter[id-filter][condition][value][]=' + orgUsers[i].id;
    }

    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_proposal/ubg_proposal/?filter[ziffer.id]=' + zifferId + orgFilter + '&include=item,status,org_user').then((response: {
        data:  Array<UbgProposal>,
        included: any
      }) => {
        // item field should have been named items actually
        for (let i in response.included) {
          if (response.included[i].type === 'ubg_proposal_item--ubg_proposal_item') {
            for (let j in response.data) {
              for (let k in response.data[j].relationships.item.data) {
                if (response.data[j].relationships.item.data[k].id === response.included[i].id) {
                  response.data[j].relationships.item.data[k] = response.included[i];
                }
              }
            }
          } else if (response.included[i].type === 'taxonomy_term--proposal_status') {
            for (let j in response.data) {
              // status could be null
              if (response.data[j].relationships.status && response.data[j].relationships.status.data && response.data[j].relationships.status.data.id === response.included[i].id) {
                response.data[j].relationships.status.data = response.included[i];
              }
            }
          } else if (response.included[i].type === 'ubg_org_user--gliederung') {
            for (let j in response.data) {
              for (let k in response.data[j].relationships.org_user.data) {
                if ( response.data[j].relationships.org_user.data[k].id === response.included[i].id) {
                  response.data[j].relationships.org_user.data[k] = response.included[i];
                }
              }
            }
          }
        }
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  delete(proposal: UbgProposal) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_proposal/ubg_proposal/' + proposal.id).then(() => {
        resolve();

      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getPossibleGemeinsam(proposal: UbgProposal) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/proposal/' + proposal.id + '/gemeinsam').then((response: {
        data: Array<UbgEmpfehlung>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  print(proposal: UbgProposalStructured, args: {
    mitEmpfehlungen: boolean
    mitBegruendungen: boolean
    mitStellerBegr?: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/proposal/' + proposal.id + '/print'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  checkProposal(proposal: UbgProposalStructured) {
    let newProposals: Array<UbgProposalStructured> = [
      proposal
    ];
    for (let i in this.checkedProposalsData.value) {
      if (this.checkedProposalsData.value[i].id !== proposal.id) {
        newProposals.push(this.checkedProposalsData.value[i]);
      }
    }
    this.checkedProposalsData.next(newProposals);
  }

  uncheckProposal(proposal: UbgProposalStructured) {
    let newProposals: Array<UbgProposalStructured> = [];
    for (let i in this.checkedProposalsData.value) {
      if (this.checkedProposalsData.value[i].id !== proposal.id) {
        newProposals.push(this.checkedProposalsData.value[i]);
      }
    }
    this.checkedProposalsData.next(newProposals);
  }

  clearCheckProposal() {
    this.checkedProposalsData.next([]);
  }

  setActiveProposal(proposal: UbgProposalStructured) {
    this.activeProposalData.next(proposal);
  }

  getActiveProposal():UbgProposalStructured {
    return this.activeProposalData.value;
  }

  cleanActiveProposal() {
    this.activeProposalData.next(null);
  }

  setRead(proposal: UbgProposalStructured) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/proposal/' + proposal.id + '/setread';
      this.contenta.post(path, null).then((response: {
        data: UbgProposal
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  setLock(proposal: UbgProposalStructured) {
    return this.get(proposal.id);
//    let promise = new Promise((resolve, reject) => {
//      let path = 'ubg/proposal/' + proposal.id + '/lock/1'
//      this.contenta.post(path, null).then((response: {
//        data: UbgProposal
//      }) => {
//        resolve(response.data);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
  }

  releaseLock(proposal: UbgProposalStructured) {
    return this.get(proposal.id);
//    let promise = new Promise((resolve, reject) => {
//      let path = 'ubg/proposal/' + proposal.id + '/lock/0'
//      this.contenta.post(path, null).then((response: {
//        data: UbgProposal
//      }) => {
//        resolve(response.data);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
  }

  printMarkup(proposal: UbgProposalStructured, text: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/proposal/' + proposal.id + '/print/markup'
      this.contenta.postGetFile(path, {
        text: text,
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  antragReorder(proposal: UbgProposal) {
    let promise = new Promise((resolve, reject) => {
      this.antragService.get(proposal.relationships.antrag.data.id).then((response: UBGAntragModel) => {
        this.antragService.reorder(response).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    });
    return promise;
  }
  
  getProjectZiffernByProposal(proposal: UbgProposal) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/proposal/' + proposal.id + '/ziffer';
      this.contenta.get(path).then((response: Array<{
        id: string
        label: string
      }>) => {
        resolve(response);
      }).catch(() => {
          reject();
        });
    });
    return promise;
  }
  
  applyBearbeitung(proposalId: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/proposal/' + proposalId + '/bearbeitung';
      this.contenta.post(path, null).then((response: {
        data: UbgProposal
      }) => {
        resolve(response.data);
      }).catch(() => {
          reject();
        });
    });
    return promise;
  }

}
