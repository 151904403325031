/**
 * Leitantrag-Proposal-Komponent
 * 
 * Attila Németh, UBG
 * 06.06.2019
 */
 
import {Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {Subscription} from 'rxjs';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {UbgProposalService} from '../../../../../../../services/proposal.service';
import {UbgProposalStructured} from '../../../../../../../model/proposal_structured';
import {UbgProposal} from '../../../../../../../model/proposal';
import {UbgProposalStatus} from '../../../../../../../model/taxonomies/proposal_status';
import {UbgProposalStatusService} from '../../../../../../../services/taxonomies/proposal_status.servie';
import {UbgProposalLeitantragEmpfehlungDialogComponent} from './dialog/empfehlung/empfehlung.component';
//import {UbgEmpfehlungService} from '../../../../../../../services/empfehlung.service';
//import {UbgEmpfehlung} from '../../../../../../../model/empfehlung';
import {UbgConfirmComponent} from '../../../../../../../../ubg_components/confirm/components/confirm.component';
import {UbgProposalAssignmentDialogComponent} from '../../../dialogs/assignment/assignment.component';
import {UbgPrintConfirmDialogComponent} from '../../../dialogs/print/print.component';
import {UbgProposalGemeinsamDialogComponent} from '../../../dialogs/gemeinsam/gemeinsam.component';
import {UbgBegrundung} from '../../../../../../../model/begrundung';
import {UbgBegrundungService} from '../../../../../../../services/begrundung.service';
import {UbgTextEditorDialogComponent} from '../../../dialogs/text/text.component';
import {UbgVotum} from '../../../../../../../model/votum';
import {UbgVotumService} from '../../../../../../../services/votum.service';
import {UbgLastChangeService} from '../../../../../../../services/lastchange.service';
import {UbgAntragsstellerDialogComponent} from '../../../dialogs/antragssteller/antragssteller.component';
import {UbgDeleteAntragDialogComponent} from '../../../dialogs/delete/delete.component';

@Component({
  selector: 'ubg-project-antrag-leitantrag-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class UbgProjectAntragLeitantragProposalComponent implements OnInit, OnDestroy {
  
  @Input() proposal: UbgProposalStructured
  @Output() proposalChanged = new EventEmitter();
  @ViewChild('activeProposal') activeProposalRef: ElementRef
  isOpen: boolean = false
  proposalClass: string = ''
  checked: boolean = false
  editForm: boolean = false
  proposalStatusLabel: string = ''
  isExpanded: boolean = false
  isActive: boolean = false
  zuweisung: string = ''
  isLoading: boolean = false
  voten: Array<UbgVotum> = []
  activeProposalSubscription: Subscription
  lastChangeSubscription: Subscription
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private service: UbgProposalService,
                private statusService: UbgProposalStatusService,
                private begrundungService: UbgBegrundungService,
                private votumService: UbgVotumService,
                private lastChangeService: UbgLastChangeService) {}
  
  ngOnInit() {
    this.updateProposalClass();
    this.checkActiveProposal();
    this.activeProposalSubscription = this.service.activeProposal.subscribe(() => {
      this.checkActiveProposal();
    });
    this.loadVoten();
    this.lastChangeSubscription = this.lastChangeService.changedProposal.subscribe((proposal: UbgProposal) => {
      if (proposal.id == this.proposal.id) {
        if (this.proposal.attributes.changed < proposal.attributes.changed) {
          if (!this.isLoading) {
            this.loadProposal();
            this.loadVoten();
          }
        }
      }
    });
  }
  
  ngOnDestroy() {
    this.activeProposalSubscription.unsubscribe();
    this.service.cleanActiveProposal();
    this.lastChangeSubscription.unsubscribe();
  }
  
  loadProposal() {
    this.service.getStructured(this.proposal.id).then((response: UbgProposalStructured) => {
      this.proposal = response;
      this.updateProposalClass();
      this.loadVoten();

    });
  }
  
  loadVoten() {
    this.votumService.getByProposal(this.proposal.id).then((response: Array<UbgVotum>) => {
      this.voten = response;
      this.updateProposalClass();
      this.isLoading = false;
    });
  }
  
  hasVotum(level: number) {
    for (let i in this.voten) {
      if (this.voten[i].attributes.level === undefined && level === 19) {
        return true;
      }
      if (this.voten[i].attributes.level === level) {
        return true;
      }
    }
    return false;
  }
  
  hasParteitagVotum(): boolean
  {
    return this.hasVotum(20);
  }
  
  isInProcess() {
    if (this.proposal.relationships.status !== null
      && this.proposal.relationships.status.data !== null
      && this.proposal.relationships.status.data !== undefined
      && this.proposal.relationships.status.data.attributes.name === 'In Bearbeitung') {
        return true;
    }
    else {
      return false;
    }
  }
  
  userCanDelete() {
    return this.proposal.meta.permissions.delete;
  }
  
  userCanEdit() {
    return this.proposal.meta.permissions.edit;
  }
  
  userCanAssign() {
    return this.proposal.meta.permissions.assign;
  }
  
  panelOpened() {
    this.isOpen = true;
  }
  
  panelClosed() {
    this.isOpen = false;
  }
  
  private updateProposalClass() {
    let classes = [
      'proposal-zum-leitantrag',
    ];
    let votumClasses: Array<string>;
    for (let i in this.proposal.relationships.votum.data) {
      votumClasses = [
        'votum-type--' + this.proposal.relationships.votum.data[i].type,
        'votum-status--' + this.proposal.relationships.votum.data[i].attributes.statusId,
      ];
    }
    for (let i in votumClasses) {
      classes.push(votumClasses[i]);
    }
    if (this.proposal.attributes.statusId !== null && this.proposal.attributes.statusId !== undefined) {
      classes.push('proposal-status--' + this.proposal.attributes.statusId);
    }
    this.proposalClass = classes.join(' ');
  }
  
  private updateProposalClassDeprecated() {
    this.proposalStatusLabel = '';
    let classes = [];
    if (this.proposal.relationships.status !== null
                                    && this.proposal.relationships.status.data !== undefined
                                    && this.proposal.relationships.status.data !== null) {
      classes.push('proposal-status--' + this.proposal.relationships.status.data.attributes.name.replace(' ', '-').toLowerCase());
      this.proposalStatusLabel = this.proposal.relationships.status.data.attributes.name;
    }
    for (let i in this.voten) {
      if (this.voten[i].type !== undefined) {
        classes.push(this.voten[i].type);
        classes.push('empfehlung-status--' + this.voten[i].attributes.statusLabel.toLowerCase());
        this.proposalStatusLabel = this.voten[i].attributes.typeLabel;
      }
    }
    if (this.checked) {
      classes.push('checked');
    }
    this.proposalClass = classes.join(' ');
  }
  
  inProcess(value: number) {
    if (value === 1) {
      this.statusService.getByName('In Bearbeitung').then((statusTerm: UbgProposalStatus) => {
        this.service.get(this.proposal.id).then((response: UbgProposal) => {
          response.relationships.status.data = statusTerm;
          this.service.update(response).then(() => {
            this.loadProposal();
          });
        });
      }).catch(() => {
        this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, versuchen Sie es später', null, {
          duration: 15000
        })
      });
    }
    else if (value === 0) {
      this.service.get(this.proposal.id).then((response: UbgProposal) => {
        response.relationships.status.data = null;
        this.service.update(response).then(() => {
          this.loadProposal();
        });
      });
    }
    else {
      console.warn('Ungültig', value);
    }
  }
  
  addEmpfehlung() {
    this.addVotum(19);
  }
  
  parteitagVotum() {
    this.addVotum(20);
  }
  
  addVotum(level: number = 19) {
//    this.service.setLock(this.proposal).then(() => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        proposals: [
          this.proposal,
        ],
        empfehlungType: null,
        fields: {
          ziffer: null,
          empfehlung: null,
          fassung: null,
          begruendung: null,
          uberweisung_an: null,
        }
      }
      if (this.proposal.relationships.begrundung !== undefined
        && this.proposal.relationships.begrundung.data !== null) {
        dialogConfig.data['fields']['begruendung'] = 
                        this.proposal.relationships.begrundung.data.attributes.text.value;
      }
      let formDialog = this.dialog.open(UbgProposalLeitantragEmpfehlungDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          this.isLoading = true;
          this.addBegruendung(dialogConfig.data['fields']['begruendung']).then(() => {
            let votum = this.votumService.emptyVotum(dialogConfig.data['empfehlungType']);
            for (let fieldName in dialogConfig.data['fields']) {
              if (dialogConfig.data['fields'][fieldName] !== null 
                    && dialogConfig.data['fields'][fieldName] !== undefined) {
                if (fieldName == 'uberweisung_an' || fieldName == 'ziffer') {
                  votum.relationships[fieldName] = {
                    data: dialogConfig.data['fields'][fieldName],
                  };
                }
                else {
                  votum.attributes[fieldName] = dialogConfig.data['fields'][fieldName];
                }
              }
            }
            votum.relationships.proposals = {
              data: dialogConfig.data['proposals'],
            };
            votum.attributes.level = level;
            this.votumService.create(votum).then(() => {
              this.service.applyBearbeitung(this.proposal.id).then(() => {
                this.loadProposal();
                this.proposalChanged.emit();
                this.service.releaseLock(this.proposal);
              });
            }).catch((error) => {
              console.error(error);
              this.loadProposal();
              this.proposalChanged.emit();
              this.service.releaseLock(this.proposal);
              this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PL-215', null, {
                duration: 15000,
              });
            });
          }).catch((error) => {
            console.error(error)
            this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PL-181', null, {
              duration: 15000,
            });
            this.service.releaseLock(this.proposal);
            this.isLoading = false;
          });
        }
        else {
          this.service.releaseLock(this.proposal);
        }
      });
//    }).catch((error) => {
//      console.error(error);
//      this.snackbar.open('Der Antrag wird gerade von einem anderen Benutzer bearbeitet', null, {
//        duration: 8000,
//      })
//    });
  }
  
  addBegruendung(text: string) {
    let promise = new Promise((resolve, reject) => {
      if (text === null || text === '') {
        resolve();
      }
      else {
        let begruendung = new UbgBegrundung;
        begruendung.attributes = {
          title: 'Begründung' + this.proposal.attributes.title,
          text: {
            value: text,
            format: null,
          }
        };
        this.begrundungService.create(begruendung).then((response: UbgBegrundung) => {
          this.service.get(this.proposal.id).then((proposal: UbgProposal) => {
            proposal.relationships.begrundung.data = response;
            this.service.update(proposal).then(() => {
              resolve();
            });
          });
        }).catch(() => {
          reject();
        });
      }
    });
    return promise;
  }
  
  removeEmpfehlung() {
    this.isLoading = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie das Votum der Komission wirklich entfernen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
//        this.proposal.relationships.begrundung.data = null;
        this.proposal.relationships.status.data = null;
        for (let i in this.voten) {
          if (this.voten[i].attributes.level === undefined || this.voten[i].attributes.level === 19) {
            this.votumService.removeProposal(this.voten[i], this.proposal.id).then(() => {
              this.service.applyBearbeitung(this.proposal.id).then(() => {
                this.loadProposal();
                this.proposalChanged.emit();
                this.isLoading = false;
              });
            }).catch(() => {
              this.snackbar.open('Ein Fehler ist aufgetreten. Code: PP-274', null, {
                duration: 15000,
              });
              this.isLoading = false;
            });
          }
        }
      }
      else {
        this.isLoading = false;
      }
    });
  }
  
  removeParteitagVotum(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die Entscheidung des Parteitages wirklich entfernen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.get(this.proposal.id).then((proposal: UbgProposal) => {
          proposal.relationships.status.data = null;
          this.service.update(proposal).then(() => {
            for (let i in this.voten) {
              if (this.voten[i].attributes.level === undefined || this.voten[i].attributes.level === 20) {
                this.votumService.removeProposal(this.voten[i], this.proposal.id).then(() => {
                  this.loadProposal();
                  this.loadVoten();
                }).catch(() => {
                  this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PP-421', null, {
                    duration: 15000,
                  });
                  this.loadProposal();
                });
              }
            }
            
          });
        });
      }
    });
  }
  
  replaceEmpfehlung(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie das Votum dieses Antrags ändern?',
    }
    let votumCount: number = 0;
    for (let i in this.voten) {
      if (this.voten[i].attributes.level === undefined || this.voten[i].attributes.level === 19) {
        dialogConfig.data['hint'] = 'Aktuelles Votum: ' + this.voten[i].attributes.typeLabel;
        votumCount++;
      }
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isLoading = true;
        this.service.get(this.proposal.id).then((proposal: UbgProposal) => {
          proposal.relationships.status.data = null;
          this.service.update(proposal).then(() => {
            for (let i in this.voten) {
              if (this.voten[i].attributes.level === undefined || this.voten[i].attributes.level === 19) {
                this.votumService.removeProposal(this.voten[i], this.proposal.id).then(() => {
                  votumCount--;
                  this.addVotum(19);
                }).catch(() => {
                  this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PC-473', null, {
                    duration: 15000,
                  });
                  this.loadProposal();
                });
              }
            }
            
          });
        });
      }
    });
  }
  
  assign() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      proposals: [this.proposal],
      users: [],
    }
    this.service.get(this.proposal.id).then((response: UbgProposal) => {
      dialogConfig.data['users'] = response.relationships.zustandigkeit.data;
      let formDialog = this.dialog.open(UbgProposalAssignmentDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          this.service.get(this.proposal.id).then((response: UbgProposal) => {
            response.relationships.zustandigkeit.data = dialogConfig.data['users'];
            response.attributes.zuweisungsdatum = new Date().toJSON().slice(0, 19) + '+0000';
            this.service.update(response).then(() => {
              let projectId = this.route.snapshot.paramMap.get('id');
              let path = 'project/' + projectId + '/dashboard';
              this.router.navigate([path], {
                queryParams: {
                  tab: 'offene',
                }
              });
              this.loadProposal();
            });
          });
        }
      });
    });
  }
  
  edit() {
    this.editForm = true;
//    this.service.setLock(this.proposal).then(() => {
//      this.editForm = true;
//    }).catch(() => {
//      this.snackbar.open('Der Antrag wird gerade von einem anderen Benutzer bearbeitet', null, {
//        duration: 8000,
//      })
//    });
  }
  
  proposalEdited() {
    this.loadProposal();
    this.editForm = false;
    this.service.releaseLock(this.proposal);
  }
  
  delete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie diesen Antrag wirklich löschen?',
      hint: 'Diese Operation kann nicht rückgängig gemacht werden'
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        const dialogReconfirmConfig = new MatDialogConfig();
        dialogReconfirmConfig.autoFocus = true;
        dialogReconfirmConfig.data = {
          proposal: this.proposal,
        };
        let reconfirmDialog = this.dialog.open(UbgDeleteAntragDialogComponent, dialogReconfirmConfig);
        reconfirmDialog.afterClosed().subscribe((response: number) => {
          if (response == 1) {
            let proposalId = this.proposal.id;
            this.service.get(proposalId).then((response: UbgProposal) => {
              this.service.delete(response).then(() => {
                this.proposalChanged.emit(proposalId);
              });
            });
          }
        });
      }
    });
  }
  
  print() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diesen Antrag drucken?',
      mitEmpfehlungen: false,
      mitStellerBegr: false,
      stellerBegrPossible: false,
    }
    if (this.voten.length > 0) {
      dialogConfig.data['empfehlungenPossible'] = true;
    }
    else {
      dialogConfig.data['empfehlungenPossible'] = false;
    }
    if (this.proposal.relationships.begrundung.data === null) {
      dialogConfig.data['begruendungPossible'] = false;
    }
    else if (this.proposal.relationships.begrundung.data.id === undefined) {
      dialogConfig.data['begruendungPossible'] = false;
    }
    else {
      dialogConfig.data['begruendungPossible'] = true;
    }
    for (let i in this.proposal.relationships.item.data) {
      if (this.proposal.relationships.item.data[i].attributes.reason !== null 
            && this.proposal.relationships.item.data[i].attributes.reason !== undefined) {
        dialogConfig.data['stellerBegrPossible'] = true;
      }
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.print(this.proposal, {
          mitEmpfehlungen: dialogConfig.data['mitEmpfehlungen'],
          mitBegruendungen: dialogConfig.data['mitBegruendungen'],
          mitStellerBegr: dialogConfig.data['mitStellerBegr'],
        }).then((response: any) => {
          let fileName = 'Antrag-' + this.proposal.attributes.title.replace(' ', '') + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
            duration: 15000,
          })
        });
      }
    });
  }
  
  proposalChecked(event: MouseEvent) {
    event.stopPropagation();
    if (this.checked) {
      this.checked = false;
      this.service.uncheckProposal(this.proposal);
    }
    else {
      this.checked = true;
      this.service.checkProposal(this.proposal);
    }
  }
  
  addGemeinsam() {
    this.service.get(this.proposal.id).then((proposalResponse: UbgProposal) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        proposals: [proposalResponse],
        empfehlung: null
      };
      let formDialog = this.dialog.open(UbgProposalGemeinsamDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          this.isLoading = true;
          this.votumService.addProposal(dialogConfig.data['empfehlung'], this.proposal.id).then(() => {
            this.isLoading = false;
            this.loadProposal();
          }).catch(() => {
            this.isLoading = false;
            this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PL-461', null, {
              duration: 15000,
            });
          });
        }
      });
    }).catch(() => {
      this.snackbar.open('Ein Fehler ist aufgetreten, versuchen Sie es später noch einmal', null, {
        duration: 15000
      });
    });
  }
  
  checkActiveProposal() {
    let activeProposal: UbgProposalStructured = this.service.getActiveProposal();
    if (activeProposal !== null && this.proposal !== undefined) {
      if (activeProposal.id == this.proposal.id) {
        this.isActive = true;
        setTimeout(() => {
          this.scrollTo();
        }, 100);
      }
    }
  }
  
  scrollTo() {
    if (this.activeProposalRef === null) {
      setTimeout(() => {
        this.scrollTo();
      }, 100);
    }
    else {
      this.isExpanded = true;
      this.activeProposalRef.nativeElement.scrollIntoView();
    }
  }
  
  isBegrundungPossible() {
    if (this.voten.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  
  editBegrundung() {
    let begrundung: string;
    if (this.proposal.relationships.begrundung === undefined) {
      begrundung = '';
    }
    else if (this.proposal.relationships.begrundung.data === null) {
      begrundung = '';
    }
    else if (this.proposal.relationships.begrundung.data.attributes.text == undefined) {
      begrundung = '';
    }
    else {
      begrundung = this.proposal.relationships.begrundung.data.attributes.text.value;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      text: begrundung,
      label: 'Begründung',
    }
    let formDialog = this.dialog.open(UbgTextEditorDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        if (this.proposal.relationships.begrundung === undefined
          || this.proposal.relationships.begrundung.data === null) {
          // Begründung erstellen
          let begrundung = new UbgBegrundung;
          begrundung.attributes = {
            title: 'Begründung ' + this.proposal.attributes.title,
            text: {
              value: dialogConfig.data['text'],
              format: null,
            },
          };
          this.begrundungService.create(begrundung).then((begrundungResponse: UbgBegrundung) => {
            this.service.get(this.proposal.id).then((proposalResponse: UbgProposal) => {
              proposalResponse.relationships.begrundung = {
                data: begrundungResponse,
              };
              this.service.update(proposalResponse).then(() => {
                this.service.applyBearbeitung(this.proposal.id).then(() => {
                  this.loadProposal();
                  this.gemeinsamBegrundung(begrundungResponse);
                });
              });
            });
          });
        }
        else {
          // Begründung ändern
          this.proposal.relationships.begrundung.data.attributes.text.value = dialogConfig.data['text'];
          this.begrundungService.update(this.proposal.relationships.begrundung.data).then(() => {
            this.service.applyBearbeitung(this.proposal.id).then(() => {
              this.loadProposal();
              this.gemeinsamBegrundung(this.proposal.relationships.begrundung.data);
            });
          });
        }
      }
    });
  }
  
  gemeinsamBegrundung(begrundung: UbgBegrundung): void {
    for (let i in this.voten) {
      for (let j in this.voten[i].relationships.proposals.data) {
        if (this.voten[i].relationships.proposals.data[j].id !== this.proposal.id) {
          // Ein anderer Antrag, der mit diesem gemeinsam behndelt wird.
          this.service.get(this.voten[i].relationships.proposals.data[j].id).then((response: UbgProposal) => {
            response.relationships.begrundung.data = begrundung;
            this.service.update(response);
          });
        }
      }
    }
  }
  
  antragssteller(): void {
    this.isLoading = true;
    this.service.get(this.proposal.id).then((proposal: UbgProposal) => {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        proposal: proposal
      }
      let formDialog = this.dialog.open(UbgAntragsstellerDialogComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          this.service.update(proposal).then(() => {
            this.service.antragReorder(proposal).then(() => {
              this.proposalChanged.emit();
              this.loadProposal();
            });
          });
        }
      });
    });
  }
  
}
