/**
 * UBG Left Column 
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import {Component, ElementRef, ViewChild, HostListener , Inject} from '@angular/core';

@Component({
  selector: 'ubg-right-column',
  templateUrl: './right_column.component.html',
})
export class UbgRightColumnComponent {
  
  pTop: number = 0
  pTopPrev: number = 0
  @ViewChild('rightColumn') elementView: ElementRef;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    setTimeout(() => {
      this.setPTop();
    }, 500);
  }
  
  setPTop() {
    const pos: elementScrollPos = this.elementView.nativeElement.getBoundingClientRect();
    const elementHeight = pos.height - this.pTop;
    if (elementHeight < window.innerHeight) {
      this.pTop = -pos.y;
    }
    else {
      this.pTop = window.innerHeight - elementHeight - pos.y;
    }
    if (this.pTop < 0) {
      this.pTop = 0;
    }
  }
  
}

export class elementScrollPos {
  
  x: number
  y: number
  width: number
  height: number
}