/**
 * Themenliste
 * 
 * Attila Németh, UBG
 * 26.07.2019
 */
 
import {Component, OnInit} from '@angular/core';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {UbgThema} from '../../../model/taxonomies/thema';
import {UbgThemaService} from '../../../services/taxonomies/thema.service';
import {UbgThemaEditDialogComponent} from '../dialog/edit/edit.component';
import {UbgConfirmComponent} from '../../../../ubg_components/confirm/components/confirm.component';

@Component({
  templateUrl: './list.component.html',
})
export class UbgThemenListComponent implements OnInit {
  
  themen: Array<UbgThema> = []
  isLoading: boolean = false
  hasError: boolean = false
  toBeUpdated: number = 0
  isUpdated: number = 0
    
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgThemaService) {}
  
  ngOnInit() {
    this.loadThemen();
  }
  
  loadThemen() {
    this.isLoading = true;
    this.service.getAll().then((response: Array<UbgThema>) => {
      this.themen = response;
      this.isLoading = false;
      this.hasError = false;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.themen, event.previousIndex, event.currentIndex);
    this.updateThemen();
  }
  
  updateThemen(): void {
    let updates: Array<UbgThema> = [];
    let w: number = 0;
    for (let i in this.themen) {
      if (this.themen[i].attributes.weight !== w) {
        this.themen[i].attributes.weight = w;
        updates.push(this.themen[i]);
      }
      w++;
    }
    this.toBeUpdated = updates.length;
    this.isUpdated = 0;
    this.updateThemenBatch(updates);
  }
  
  updateThemenBatch(updates: Array<UbgThema>) {
    const first = updates.shift();
    this.service.update(first).then(() => {
      this.isUpdated++;
      if (updates.length > 0) {
        this.updateThemenBatch(updates);
      }
      else {
        this.toBeUpdated = 0;
        this.loadThemen();
      }
    }).catch(() => {
      updates.push(first);
      this.updateThemenBatch(updates);
    });
  }
  
  add() {
    let thema = new UbgThema;
    thema.attributes = {
      name: '',
      weight: -1,
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      thema: thema
    }
    let formDialog = this.dialog.open(UbgThemaEditDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.create(thema).then((response: UbgThema) => {
          this.loadThemen();
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten, bitte versichen Sie es später', null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  edit(thema: UbgThema) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      thema: thema
    }
    let formDialog = this.dialog.open(UbgThemaEditDialogComponent, dialogConfig);
    const themaOriginal = JSON.parse(JSON.stringify(thema));
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.update(thema).then((response: UbgThema) => {
          this.loadThemen();
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten, bitte versichen Sie es später', null, {
            duration: 15000,
          });
        });
      }
      else {
        let newThemen: Array<UbgThema> = [];
        for (let i in this.themen) {
          if (this.themen[i].id == thema.id) {
            newThemen.push(JSON.parse(JSON.stringify(themaOriginal)));
          }
          else {
            newThemen.push(this.themen[i]);
          }
        }
        this.themen = newThemen;
      }
    });
  }
  
  delete(thema: UbgThema): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie das Thema "' + thema.attributes.name + '" entfernen?',
      hint: 'Diese Aktion kann nicht rückgängig gemacht werden',
    }
    let formDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    const themaOriginal = JSON.parse(JSON.stringify(thema));
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.delete(thema).then(() => {
          this.loadThemen();
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten, Code: TL-159', null, {
            duration: 15000,
          });
        })
      }
    });
  }
  
}
