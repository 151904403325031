import {Component, OnInit, ViewChild} from '@angular/core';

import {NgForm, FormControl, Validators} from '@angular/forms';

import {Router} from '@angular/router';

import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

import {UbgProject} from '../../../model/project';
import {AntragTypeModel} from '../../../model/taxonomies/antrag_type.model';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgPriorityStructure} from '../../../model/priority_structure';
import {UbgMandant} from '../../../model/mandant';

import {UbgProjectService} from '../../../services/project_service';
import {UbgPriorityStructureService} from '../../../services/priority_structure.service';
import {AntragTypeService} from '../../../services/taxonomies/antrag_type.service';
import {UbgMandantService} from '../../../services/mandant.service';
import {UBGAntragService} from '../../../services/antrag.service';
import {MatSnackBar} from '@angular/material';
import {MAT_DATE_LOCALE} from '@angular/material/core';



@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss'],
})

export class ProjectAddComponent implements OnInit {
  @ViewChild( 'projectForm' ) projectForm: NgForm;
  @ViewChild( 'antragTypeForm' ) projectAntragForm: NgForm;
  @ViewChild( 'priorityForm' ) priorityForm: NgForm;
  @ViewChild( 'mandantForm' ) mandantForm: NgForm;

  pipe = new DatePipe('de-DE');

  displayedColumns = ['letter', 'title', 'type', 'sort', 'star'];

  antragTypes: AntragTypeModel[];
  selectedAntragType: AntragTypeModel;
  antragSortingTypes = [{title: 'Klassisch', value: 'classic'}, {title: 'Themen', value: 'theme'}];
  selectedAntragSorting = 'classic';

  antragModel: UBGAntragModel;
  antrags: UBGAntragModel[]; // this is the list of the antrag user make but it is not submitted to the server and has no uuid
  submitedAntrage: UBGAntragModel[];

  priorityStructures: UbgPriorityStructure[];
  selectedPriorityStructure: UbgPriorityStructure;

  mandants: UbgMandant[];
  selectedMandant: UbgMandant;

  projectModel: UbgProject;

  antragLetterValidator = new FormControl('', [Validators.required, Validators.maxLength(1)]);
  antragTypeValidator = new FormControl('', [Validators.required]);
  antragDescriptionValidator = new FormControl('');
  projectTitleValidator = new FormControl('', [Validators.required]);
  projectBeginValidator = new FormControl('', [Validators.required]);
  projectEndValidator = new FormControl('', [Validators.required]);
  projectStartVotingValidator = new FormControl('', [Validators.required]);
  projectEndVotingValidator = new FormControl('', [Validators.required]);
  projectPriorityValidator = new FormControl('', [Validators.required]);
  mandantValidator = new FormControl('', [Validators.required]);

  minDate: Date;
  maxDate: Date;

  processing = false;

  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private project: UbgProjectService,
              private priorityService: UbgPriorityStructureService,
              private antragTypeService: AntragTypeService,
              private antragService: UBGAntragService,
              private mandantService: UbgMandantService
              ) {


    this.antragModel = {
      type: 'ubg_antrag--ubg_antrag',
      attributes: {
        title: '',
        description: '',
        letter: '',
        sortierung: this.selectedAntragSorting,
      },
      relationships: {
        ubg_antrag_type: {
          data: null,
        },
        status: {
          data: null,
        },
        ziffers: {
          data: null,
        }
      }
    };
    this.submitedAntrage = [];

    this.projectModel = new UbgProject;
    this.projectModel.type = 'ubg_project--ubg_project';
    this.projectModel.attributes = {
        title: '',
        start_date: '',
        end_date: '',
        start_voting_date: '',
        end_voting_date: '',
        description: '',
        ergebnis_anzeigen: null,
    };
    this.projectModel.relationships = {
      priority_structure: {
        data: this.selectedPriorityStructure,
      },
      mandant: {
        data: this.selectedMandant,
      },
      antrage: {
        data: this.submitedAntrage,
      }
    };
    let today = new Date();
    let todayYear = today.getFullYear();
    let todayMonth = today.getMonth();
    let todayDay = today.getDate();
    this.minDate = new Date(todayYear - 2, 1, 1);
    this.maxDate = new Date(todayYear + 1, 12, 31);
  }


  ngOnInit() {
    // this.loadOptionsPriorities();
    this.loadOptionsAntragTypes();
    this.loadOptionsMandants();

  }

  loadOptionsAntragTypes() {
    this.antragTypeService.getAll().then((response: Array<AntragTypeModel>) => {
      this.antragTypes = response;
      console.log(response);

    }).catch(() => {
      console.log('error in getting antrag type list'); // TODO we need a error reporting
    });
  }
  
  loadOptionsMandants() {
    this.mandantService.getAll().then((response: Array<UbgMandant>) => {
      this.mandants = response;
      console.log(response);

    }).catch(() => {
      console.log('error in getting mandants'); // TODO we need a error reporting
    });

  }
  loadOptionsPriorities() {

    this.priorityService.getAll().then((response: Array<UbgPriorityStructure>) => {
      this.priorityStructures = response;
      console.log(response);

    }).catch(() => {
      console.log('error in getting structures'); // TODO we need a error reporting
    });

  }
  loadOptionsPrioritiesByMandant(id) {

    this.priorityStructures = [];
    this.priorityService.getByMandant(id).then((response: Array<UbgPriorityStructure>) => {
      for (let i = 0 ; i < response.length; i++) {
       if (response[i].attributes) { // @todo we receive the priority id but not the content when the user has no right!
         this.priorityStructures.push(response[i]);
       }
      }
      console.log(response);

    }).catch(() => {
      console.log('error in getting structures'); // TODO we need a error reporting
    });

  }

  OnChangeMandant(e) {
    this.mandantValidator.setValue(e.value);
    this.selectedMandant = this.mandantValidator.value;
    this.loadOptionsPrioritiesByMandant(this.selectedMandant.id);
  }

  OnChangePriority(e) {
    this.projectPriorityValidator.setValue(e.value);
    this.selectedPriorityStructure = this.projectPriorityValidator.value;
  }

  OnChangeAntragType(e) {
    this.antragTypeValidator.setValue(e.value);
    this.selectedAntragType = this.antragTypeValidator.value;
  }

  getErrorAntragLetter() {
      return this.antragLetterValidator.hasError('required') ? 'Bitte geben Sie einen Buchstaben ein' :
          this.antragLetterValidator.hasError('invalid') ? 'Nicht gültig, nur ein Buchstabe' :
              '';
  }
  getErrorAntragType() {
      return this.antragTypeValidator.hasError('required') ? 'Bitte wählen Sie einen Wert aus' :
              '';
  }
  getErrorTitle() {
      return this.projectTitleValidator.hasError('required') ? 'Bitte geben Sie den Projekttitel ein' :
          '';
  }
  getErrorBegin() {
      return this.projectBeginValidator.hasError('required') ? 'Bitte wählen Sie ein Startdatum aus' :
          '';
  }
  getErrorEnd() {
      return this.projectEndValidator.hasError('required') ? 'Bitte wählen Sie ein Enddatum aus' :
          '';
  }
  getErrorStartVoting() {
      return this.projectStartVotingValidator.hasError('required') ? 'Bitte wählen Sie ein Datum aus' :
          '';
  }
  getErrorEndVoting() {
      return this.projectEndVotingValidator.hasError('required') ? 'Bitte wählen Sie ein Datum aus' :
          '';
  }
  getErrorPriority() {
    return this.projectPriorityValidator.hasError('required') ? 'Bitte wählen Sie ein Produktprioritätsstruktur' :
      '';
  }
  getErrorMandant() {
    return this.mandantValidator.hasError('required') ? 'Bitte wählen Sie ein Mandant' :
      '';
  }


  onSubmitProject() {
    this.processing = true;
    if (this.projectTitleValidator.valid &&
      this.projectBeginValidator.valid &&
      this.projectEndValidator.valid &&
      this.projectStartVotingValidator.valid &&
      this.projectEndVotingValidator.valid &&
      this.projectPriorityValidator.valid
    ) {
      this.postProject();
    } else {
      this.snackbar.open('Bitte überprüfen Sie alle erforderlichen Felder', null, {
        duration: 10000,
      });
      this.processing = false;
    }

  }


  postProject() {
    // Set the Project Relations
    this.projectModel.relationships.priority_structure.data = this.selectedPriorityStructure;
    this.projectModel.relationships.mandant.data = this.selectedMandant;
    this.projectModel.relationships.antrage.data = this.submitedAntrage;
    console.log(this.projectModel);
    // Now we create the project
    this.project.create(this.projectModel).then((response: UbgProject) => {
      // console.log(response);
      this.processing = false;
      // redirect to the created project page
      this.router.navigate(['project/' + response.id]);
    }).catch(() => {
      console.log('ERROR in Project create');
    });
  }

  addAntrag() {
      if (this.antragLetterValidator.valid &&
          this.antragTypeValidator.valid
      ) {
        this.antragModel.relationships.ubg_antrag_type.data = this.selectedAntragType;
        this.antragModel.attributes.title = this.projectModel.attributes.title + ' ' + this.antragModel.attributes.letter;
        this.antragModel.attributes.sortierung = this.selectedAntragSorting;
        this.antragService.create(this.antragModel).then((response: UBGAntragModel) => {
          // we need to set back the type so it doesn't make problem in the table
          response.relationships.ubg_antrag_type.data = this.selectedAntragType;
          this.submitedAntrage = this.submitedAntrage.concat( {...response} );
        }).catch(() => {
          console.log('ERROR in Antrag create');
        });
      }
  }

  deleteAntrag(antrag) {
    this.submitedAntrage = this.submitedAntrage.filter(
      function (element, index, array) {
        return (element.id !== antrag.id);
      }
    );
  }

/*  antragReorder() {
      for ( let i = 0; i < this.antrags.length; i++) {
          this.antrags[i].attributes.index = i + 1;
      }
  }*/

  formatDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // we change the format of the material date junk
    this.projectModel.attributes[type] = this.pipe.transform(event.value, 'yyyy-MM-dd');
    return;
  }



/*
  filterForJSONAPIRelationship(input) {
    let output;
    if (input) {
      output = {...input};
/!*      delete output.attributes;
      delete output.relationships;
      delete output.links;*!/
    }
    return output;
  }
*/



}

