/**
 * Antrag
 * 
 * Attila Németh, UBG
 * 04.06.2019
 */
 
import {Component, Input} from '@angular/core';

import {UBGAntragModel} from '../../../../../model/antrag.model';

@Component({
  selector: 'ubg-project-antrag',
  templateUrl: './antrag.component.html',
})
export class UbgProjectAntragComponent {
  
  @Input() antrag: UBGAntragModel
  
  constructor() {}
  
}
