import {Component, Inject } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
  selector: './app-proposal-reason-sheet',
  templateUrl: './proposal-reason-sheet.html',
  styleUrls: ['./proposal-reason-sheet.scss'],
})
export class ProposalReasonBottomSheetComponent {
  constructor(private _bottomSheetRef: MatBottomSheetRef<ProposalReasonBottomSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}


  onAdd(): void {
    this._bottomSheetRef.dismiss(this.data.content);
  }

  onCancel(): void {
    this._bottomSheetRef.dismiss();
  }
}
