/**
 * Passwortkomponent
 * 
 * Attila Németh, UBG
 * 15.03.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

import {MatSnackBar} from '@angular/material';

import {UbgContentaUserService} from '../../services/user.service';

@Component({
  templateUrl: './password.component.html',
})
export class ContentaUserPasswordComponent implements OnInit {
  
  password: {
    old: string
    new: string
    confirm: string
  }
  errors: Array<{
    element: string
    message: string
  }>
  isLoading: boolean = true
  isCduPlus: boolean = false
  cduPlusData: {
    name: string
    data: {
      cduplus_id?: number
    }
  }
  
  passwortOldControl = new FormControl('');
  passwortNewControl = new FormControl('');
  passwortConfirmControl = new FormControl('');
  
  constructor(private snackbar: MatSnackBar, 
    private service: UbgContentaUserService) {
    this.password = {
      old: '',
      new: '',
      confirm: '',
    };
  }
  
  ngOnInit() {
    this.isLoading = false;
//    this.service.loadCduPlus().then((response: {
//      name: string
//      data: {
//        cduplus_id?: number
//      }
//    }) => {
//      if (response.data.cduplus_id !== undefined) {
//        this.cduPlusData = response;
//        this.isCduPlus = true;
//      }
//      else {
//        this.isCduPlus = false;
//      }
//      this.isLoading = false;
//    }).catch(() => {
//      this.isLoading = false;
//    })
  }
  
  save() {
    this.validate().then((response: {
      current_user: {
        uid: number
      }
    }) => {
      let data = {
        pass:  [{
          existing: this.password.old,
          value: this.password.new,
        }],
      };
      this.service.update(response.current_user.uid, data).then((response) => {
        this.snackbar.open('Ihr Passwort wurde geändert', '', {
          duration: 8000,
        });
      }).catch((error) => {
        this.snackbar.open('Ihr Passwort kann nicht geändert werden', '', {
          duration: 8000,
        });
      });
    });
  }
  
  validate() {
    let promise = new Promise((resolve, reject) => {
      this.errors = [];
      if (this.password.old == '') {
        this.errors.push({
          element: 'password.old',
          message: 'Das alte Passwort muss angegeben werden',
        });
        this.passwortOldControl.setErrors({
          incorrect: true
        });
      }
      if (this.password.new == '') {
        this.errors.push({
          element: 'password.new',
          message: 'Das neue Passwort muss angegeben werden',
        });
        this.passwortNewControl.setErrors({
          incorrect: true
        });
      }
      if (this.password.new !== this.password.confirm) {
        this.errors.push({
          element: 'password.confirm',
          message: 'Das neue Passwort stimmt mit der Bestätigung nicht überen',
        });
        this.passwortConfirmControl.setErrors({
          incorrect: true
        });
      }
      this.service.checkPassword(this.password.old).then((response: any) => {
        resolve(response);
      }).catch((error) => {
        this.errors.push({
          element: 'password.old',
          message: 'Das alte Passwort ist falsch',
        });
        this.passwortOldControl.setErrors({
          incorrect: true
        });
      });
    });
    return promise;
  }
  
  hasError(name: string) {
    for (let i in this.errors) {
      if (this.errors[i].element == name) {
        return true;
      }
    }
    return false;
  }
  
  getError(name: string) {
    for (let i in this.errors) {
      if (this.errors[i].element == name) {
        return this.errors[i].message;
      }
    }
    return false;
  }
  
}