/**
 * Zusammenfassung-Komponent
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {UbgStatisticsService} from '../../../services/statistics.service';
import {UbgStatisticsSummary} from '../../../model/summary';

@Component({
  selector: 'ubg-statistics-item-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class UbgStatisticsSummaryComponent {
  
  @Input() set summary(summary: string) {
    if (summary != this.summaryUrl) {
      this.summaryUrl = summary;
      this.load(false);
    }
  }
  summaryUrl: string = ''
  @Input() areDetails: boolean = false
  @Output() onShowDetails = new EventEmitter();
  @Input() set filterParams(filterParams: {}) {
    let currentParams = JSON.stringify(this.inputFilterParams);
    let newParams = JSON.stringify(filterParams);
    if (newParams !== currentParams) {
      this.inputFilterParams = JSON.parse(newParams);
      this.load(false);
    }
  };
  inputFilterParams: {} = {};
  isLoading: boolean = true
  hasError: boolean = false
  data: UbgStatisticsSummary = null
  detailsActive: boolean = false
  
  constructor(private service: UbgStatisticsService) {}
  
  refresh() {
    this.load(true);
  }
  
  load(refresh: boolean) {
    this.isLoading = true;
    this.service.getSummary(this.summaryUrl, refresh, this.inputFilterParams).then((response: UbgStatisticsSummary) => {
      this.isLoading = false;
      this.hasError = false;
      this.data = response;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  showDetails() {
    if (this.detailsActive) {
      this.detailsActive = false;
    }
    else {
      this.detailsActive = true;
    }
    this.onShowDetails.emit(this.detailsActive);
  }
  
}