import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {UbgProjectService} from '../../services/project_service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectComponent implements OnInit {
  panelOpenState = true;
  constructor(private service: UbgProjectService) { }

  ngOnInit() {
    this.service.getAll().then((response: any) => {
    })
  }

}