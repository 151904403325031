/**
 * Contenta-Benutzer
 * 
 * Attila Németh, UBG
 * 03.04.2019
 */
 
export class ContentaUser {
  
  id: string
  name: string
  permissions: {}
  
}