/**
 * Gruppenname-Dialog
 * 
 * Attila Németh, UBG
 * 11.04.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgGroup} from '../../../../model/group';

@Component({
  templateUrl: './group.component.html',
})
export class UbgStructureGroupDialogComponent {
  
  constructor(private dialogRef: MatDialogRef<UbgStructureGroupDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                group: UbgGroup
              }) {}
 
}
