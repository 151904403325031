/**
 * Antrag-Begründung-Komponent
 * 
 * Attila Németh, UBG
 * 12.06.2019
 */
 
import {Component} from '@angular/core';

@Component({
  selector: 'ubg-antrag-block-begruendung',
  template: '<ng-content></ng-content>',
})
export class AntragBlockBegruendungComponent {
  
  constructor() {}

}