/**
 * Projekt-Dashboard
 * 
 * Attila Németh, UBG
 * 30.07.2019
 */
 
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs';

import {UbgProject} from '../../../model/project';
import {UbgProjectService} from '../../../services/project_service';
import {UbgProposalStructured} from '../../../model/proposal_structured';


@Component({
  templateUrl: './dashboard.component.html',
})
export class UbgProjectDashboardComponent implements OnInit, OnDestroy {
  
  project: UbgProject = null
  hasError: boolean = false
  allProposals: Array<UbgProposalStructured> = []
  allProposalsLoaded: boolean = false
  queryParamsSubscription: Subscription = null
  selectedIndex: number = 0
  
  constructor(private route: ActivatedRoute,
                private service: UbgProjectService) {}
  
  ngOnInit() {
    this.loadProject();
  }
  
  ngOnDestroy() {
    if (this.queryParamsSubscription !== null) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
  
  loadProject() {
    this.service.get(this.route.snapshot.paramMap.get('id')).then((response: UbgProject) => {
      this.project = response;
      this.service.setActiveProject(response);
      this.hasError = false;
      this.service.getAllProposals(this.project, true, true).then((response: Array<UbgProposalStructured>) => {
        this.allProposalsLoaded = true;
        this.allProposals = response;
        this.queryParamsSubscription = this.route.queryParams.subscribe((params: {
          tab: string
        }) => {
          if (params.tab !== undefined && params.tab !== null && params.tab === 'offene') {
            this.selectedIndex = 1;
          }
        });
      }).catch(() => {
        this.allProposalsLoaded = false;
        this.allProposals = [];
      });
    }).catch(() => {
      this.hasError = true;
    });
  }

}
