/**
 * Struktur bearbeiten
 * 
 * Attila Németh, UBG
 * 10.04.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {UbgPriorityStructure} from '../../../model/priority_structure';
import {UbgPriorityStructureService} from '../../../services/priority_structure.service';
import {UbgStructureTitleDialogComponent} from '../dialogs/title/title.component';
import {UbgGroup} from '../../../model/group';
import {UbgGroupService} from '../../../services/group.service';
import {UbgStructureGroupDialogComponent} from '../dialogs/group/group.component';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class UbgStructureEditComponent implements OnInit {
  
  isLoading: boolean = false
  hasError: boolean = false
  structureId: string = ''
  structure: UbgPriorityStructure = null
  groupOptions: Array<{
    id: string
    label: string
  }>
  newGroup: UbgGroup = null
  
  constructor(private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgPriorityStructureService,
                private group: UbgGroupService) {
    this.structureId = this.route.snapshot.paramMap.get('id');
    this.groupOptions = [];
  }
  
  ngOnInit() {
    this.loadStructure();
    this.loadGroupOptions();
  }
  
  loadStructure() {
    this.isLoading = true;
    this.service.get(this.structureId).then((response: UbgPriorityStructure) => {
      this.structure = response;
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  loadGroupOptions() {
    this.group.getAll().then((response: Array<UbgGroup>) => {
      let options = [];
      for (let i in response) {
        options.push({
          id: response[i].id,
          label: response[i].attributes.title,
        });
      }
      this.groupOptions = options;
    });
  }
  
  back() {
    if (this.route.snapshot.params['projectId'] !== undefined) {
      this.router.navigate(['project/' + this.route.snapshot.params['projectId'] + '/gliederungen']);
    }
    else {
      this.router.navigate(['struktur/list']);
    }
  }
  
  editTitle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: this.structure.attributes.title
    }
    let formDialog = this.dialog.open(UbgStructureTitleDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.structure.attributes.title = dialogConfig.data['title'];
        this.isLoading = true;
        this.service.update(this.structure).then((response: UbgPriorityStructure) => {
          this.structure = response;
          this.loadStructure();
        }).catch(() => {
          this.snackbar.open('Ihre Änderung kann nicht gespeichert werden, bitte versuchen Sie es später noch einmal', null, {
            duration: 15000,
          });
          this.isLoading = false;
        });
      }
    });
  }
  
  editGroupTitle(group: UbgGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      group: group
    }
    let formDialog = this.dialog.open(UbgStructureGroupDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.group.update(dialogConfig.data['group']).then((response: UbgGroup) => {
          for (let i in this.structure.relationships.gruppe.data) {
            if (this.structure.relationships.gruppe.data[i].id == response.id) {
              this.structure.relationships.gruppe.data[i] = response;
              this.isLoading = false;
            }
          }
        }).catch(() => {
          this.snackbar.open('Ihre Änderung kann nicht gespeichert werden, bitte versuchen Sie es später noch einmal', null, {
            duration: 15000,
          });
          this.isLoading = false;
        });
      }
    });
  }
  
  hasGroups() {
    if (this.structure === undefined) {
      return false;
    }
    else if (this.structure === null) {
      return false;
    }
    else if (this.structure.relationships === undefined) {
      return false;
    }
    else if (this.structure.relationships.gruppe === undefined) {
      return false;
    }
    else if (this.structure.relationships.gruppe.data === undefined) {
      return false;
    }
    else if (this.structure.relationships.gruppe.data.length === 0) {
      return false;
    }
    return true;
  }
  
  getLabel(group: UbgGroup) {
    if (group.attributes !== undefined) {
      return group.attributes.title;
    }
    else {
      return '...';
    }
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.structure.relationships.gruppe.data, event.previousIndex, event.currentIndex);
    this.isLoading = true;
    this.service.update(this.structure).then((response: UbgPriorityStructure) => {
      this.structure = response;
      this.loadStructure();
    }).catch(() => {
      this.snackbar.open('Ihre Änderung kann nicht gespeichert werden, bitte versuchen Sie es später noch einmal', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
  applyNewGroup(event) {
    for (let i in this.groupOptions) {
      if (event === '') {
        this.newGroup = null;
      }
      else if (event === this.groupOptions[i].id) {
        this.group.get(this.groupOptions[i].id).then((response: UbgGroup) => {
          this.newGroup = response;
        });
      }
      else if (event === this.groupOptions[i].label) {
        this.group.get(this.groupOptions[i].id).then((response: UbgGroup) => {
          this.newGroup = response;
        });
      }
      else {
        this.newGroup = new UbgGroup;
        this.newGroup.attributes = {
          title: event,
          kurzbezeichnung: '',
        };
      }
    }
  }
  
  add() {
    this.isLoading = true;
    if (this.newGroup.id === undefined) {
      // Neue Gruppe
      this.group.create(this.newGroup).then((response: UbgGroup) => {
        let groups = [];
        for (let i in this.structure.relationships.gruppe.data) {
          groups.push(this.structure.relationships.gruppe.data[i]);
        }
        groups.push(response);
        this.structure.relationships.gruppe.data = groups;
        this.structureUpdate();
      });
    }
    else {
      // Vorhandene Gruppe
      let groups = [];
      let groupExists = false;
      for (let i in this.structure.relationships.gruppe.data) {
        groups.push(this.structure.relationships.gruppe.data[i]);
        if (this.structure.relationships.gruppe.data[i].id == this.newGroup.id) {
          groupExists = true;
        }
      }
      if (!groupExists) {
        groups.push(this.newGroup)
      }
      this.structure.relationships.gruppe.data = groups;
      this.structureUpdate();
    }
  }
  
  structureUpdate() {
    this.service.update(this.structure).then((response: UbgPriorityStructure) => {
      this.structure = response;
      this.loadStructure();
    }).catch(() => {
      this.snackbar.open('Ihre Änderung kann nicht gespeichert werden, bitte versuchen Sie es später noch einmal', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
}

