/**
 * Text-Editor
 * 
 * Attila Németh, UBG
 * 16.05.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class UbgTextEditorDialogComponent {
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
  
  constructor(private dialogRef: MatDialogRef<UbgTextEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                label: string
                text: string
              }) {
  }
  
}
