/**
 * Proposal-Item-Service
 * 
 * Attila Németh, UBG
 * 06.05.2019
 */
 
import {Injectable} from '@angular/core';

import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgProposalItem} from '../model/proposal_item';
  
@Injectable({
  providedIn: 'root'
})
export class UbgProposalItemService {
  
  constructor(private contenta: UbgContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_proposal_item/ubg_proposal_item/' + id).then((response: {
        data: UbgProposalItem
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update (item: UbgProposalItem) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_proposal_item/ubg_proposal_item/' + item.id, {
        data: item
      }).then((response: UbgProposalItem) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}