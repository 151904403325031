// project-routing.module.ts

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectComponent } from './components/project/project.component';
import {ProjectAddComponent} from './components/project/project-add/project-add.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { ProjectDetailComponent } from './components/project/project-detail/project-detail.component';

import { AntragComponent} from './components/antrag/antrag.component';
import { AntragMeineComponent } from './components/antrag-meine/antrag-meine.component';

import {UbgStructureListComponent} from './components/structure/list/list.component'; 
import {UbgStructureEditComponent} from './components/structure/edit/edit.component';
import {UbgProjectOrgUserComponent} from './components/project/project-detail/org_user/org_user.component';
import {UbgProposalFallbackComponent} from './components/proposal/fallback/fallback.component';
import {UbgOrgUserAdminComponent} from './components/org_user/admin/admin.component';
import {ProjectEditComponent} from './components/project/project-edit/project-edit.component';
// import {UbgProjectEditComponent} from './components/project/project-edit/edit.component';
import {UbgUserSettingsComponent} from './components/user/settings/settings.component';
import {UbgThemenListComponent} from './components/themen/list/list.component';
import {UbgProjectDashboardComponent} from './components/project/dashboard/dashboard.component';
import {UbgProjectDashboardStatPageComponent} from './components/project/dashboard/stat/stat.page.component';
import {UbgProjectProtokolleComponent} from './components/project/protokolle/protokolle.component';

const routes: Routes = [
  {
    path: 'user/settings',
    component: UbgUserSettingsComponent
  },
  {
    path: 'project',
    children: [
      {
        path: 'add',
        component: ProjectAddComponent,
      },
      {
        path: 'list',
        component: ProjectListComponent
      },
      {
        path: 'gliederungen',
        component: UbgProjectOrgUserComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: ProjectDetailComponent,
          },
          {
            path: 'edit',
            component: ProjectEditComponent,
          },
          {
            path: 'gliederungen',
            component: UbgProjectOrgUserComponent,
          },
          {
            path: 'fallback/:antrag',
            component: UbgProposalFallbackComponent,
          },
          {
            path: 'meine-antrag',
            component: AntragMeineComponent,
            data: {
              loginNeeded: false
            }
          },
          {
            path: 'mein-antrag',
            component: AntragMeineComponent,
            data: {
              loginNeeded: false
            }
          },
          {
            path: 'dashboard',
            component: UbgProjectDashboardComponent,
          },
          {
            path: 'statistik',
            component: UbgProjectDashboardStatPageComponent,
          },
          {
            path: 'protokolle',
            component: UbgProjectProtokolleComponent,
          },
/*          {
            path: 'edit',
            component: ProjectEditComponent,
          },*/
        ]
      },
    ]
  },
/*  {
    path: 'antrag',
    children: [
      {
        path: 'meine',
        component: AntragMeineComponent,
        data: {
          loginNeeded: false
        }
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: AntragComponent,
            data: {
              loginNeeded: false
            }
          },
        ]
      },
    ]
  },*/
  {
    path: 'struktur',
    children: [
      {
        path: 'list',
        component: UbgStructureListComponent,
      },
      {
        path: ':id',
        component: UbgStructureEditComponent,
      },
      {
        path: ':id/:projectId',
        component: UbgStructureEditComponent,
      },
    ]
  },
  {
    path: 'abg',
    children: [
      {
        path: 'admin',
        component: UbgOrgUserAdminComponent,
      }
    ]
  },
  {
    path: 'themen',
    component: UbgThemenListComponent,
  },
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectRoutingModule { }

