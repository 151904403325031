/**
 * Floating Action Button
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {UbgFabComponent} from './components/fab.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    UbgFabComponent,
  ],
  exports: [
    UbgFabComponent,
  ],
  entryComponents: [
  ],
})
export class UbgFabModule {

  constructor() {}

}