/**
 * Statistik-Element
 * 
 * Attila Németh, UBG
 * 18.04.2019
 */
 
import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ubg-statistics-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class UbgStatisticsItemComponent implements OnInit {
  
  @Input() summary: string
  @Input() details: string
  areDetails: boolean = false
  showDetails: boolean = false
  filterParams: {}
  
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: any) => {
      this.filterParams = {};
      let newParams = {};
      for (let key in params) {
        if (key.match(/^filter_/g)) {
          newParams[key] = params[key];
        }
      }
      this.filterParams = newParams;
    });    
  }
  
  ngOnInit() {
    if (this.details !== undefined && this.details !== null) {
      this.areDetails = true;
    }
  }
  
  handleShowDetails(event: boolean) {
    this.showDetails = event;
  }
  
}