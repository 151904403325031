/**
 * Projektformular
 * 
 * Attila Németh, UBG
 * 14.06.2019
 */
 
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {NgForm, FormControl, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

import {MatSnackBar} from '@angular/material';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

import {UbgProject} from '../../../model/project';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UBGAntragService} from '../../../services/antrag.service';
import {AntragTypeModel} from '../../../model/taxonomies/antrag_type.model';
import {AntragTypeService} from '../../../services/taxonomies/antrag_type.service';

@Component({
  selector: 'ubg-project-form',
  templateUrl: './form.component.html',
})
export class UbgProjectFormComponent implements OnInit {
  
  @Input() project: UbgProject = null
  @Output() saved = new EventEmitter;
  
  pipe = new DatePipe('de-DE');
  
  antragLetterValidator = new FormControl('', [Validators.required, Validators.maxLength(1)]);
  antragTypeValidator = new FormControl('', [Validators.required]);
  projectTitleValidator = new FormControl('', [Validators.required]);
  projectBeginValidator = new FormControl('', [Validators.required]);
  projectEndValidator = new FormControl('', [Validators.required]);
  projectStartVotingValidator = new FormControl('', [Validators.required]);
  projectEndVotingValidator = new FormControl('', [Validators.required]);
  
  displayedColumns = ['letter', 'type'];
  
  antragTypes: AntragTypeModel[];
  selectedAntragType: AntragTypeModel;
  
  antrag: UBGAntragModel = {
      type: 'ubg_antrag--ubg_antrag',
      attributes: {
        title: '',
        letter: '',
      },
      relationships: {
        ubg_antrag_type: {
          data: null,
        },
        status: {
          data: null,
        },
        ziffers: {
          data: null,
        }
      }
    }
    antrags: Array<UBGAntragModel> = []
    submitedAntrage: Array<UBGAntragModel> = []
  
  constructor(private snackbar: MatSnackBar, 
                private antragService: UBGAntragService,
                private antragTypeService: AntragTypeService) {}
  
  ngOnInit() {
    this.loadExistingAntraege();
    this.loadOptionsAntragTypes();
  }
  
  loadExistingAntraege() {
    for (let i in this.project.relationships.antrage.data) {
      this.antragService.get(this.project.relationships.antrage.data[i].id).then((response: UBGAntragModel) => {
        let antraege: Array<UBGAntragModel> = this.antrags;
        antraege.push(response);
        let unSorted = {};
        for (let i in antraege) {
          unSorted[antraege[i].attributes.letter] = i;
        }
        let sorted = {};        
        Object.keys(unSorted).sort().forEach(function(key) {
          sorted[key] = unSorted[key];
        });
        let newAntraege = [];
        for (let i in sorted) {
          newAntraege.push((antraege[sorted[i]]));
        }
        this.antrags = newAntraege;
      });
    }
  }
  
  loadOptionsAntragTypes() {
    this.antragTypeService.getAll().then((response: Array<AntragTypeModel>) => {
      this.antragTypes = response;
    });
  }
  
  getErrorAntragLetter() {
      return this.antragLetterValidator.hasError('required') ? 'Bitte geben Sie einen Buchstaben ein' :
          this.antragLetterValidator.hasError('invalid') ? 'Nicht gültig, nur ein Buchstabe' :
              '';
  }
  getErrorAntragType() {
      return this.antragTypeValidator.hasError('required') ? 'Bitte wählen Sie einen Wert aus' :
              '';
  }
  
  onSubmitProjectAntrag() {
      if (this.antragLetterValidator.valid &&
          this.antragTypeValidator.valid
      ) {
          this.antrag.relationships.ubg_antrag_type.data = this.selectedAntragType;
          this.addAntrag();
      }
  }
  
  addAntrag() {
      this.antrags = this.antrags.concat({
        type: 'ubg_antrag--ubg_antrag',
        attributes: {
          title: this.project.attributes.title + ' ' + this.antrag.attributes.letter,
          letter: this.antrag.attributes.letter,
        },
        relationships: {
          ubg_antrag_type: {
            data: this.selectedAntragType,
          },
          status: {
            data: null,
          },
          ziffers: {
            data: null,
          }
        }
      });
  }
  
  save() {
    if (this.projectTitleValidator.valid &&
          this.projectBeginValidator.valid &&
          this.projectEndValidator.valid &&
          this.projectStartVotingValidator.valid &&
          this.projectEndVotingValidator.valid) {
      this.project.relationships.antrage.data = this.antrags;
      this.saved.emit();
    }
    else {
      this.snackbar.open('Prüfen Sie die angegebenen Daten!', null, {
        duration: 15000,
      });
    }
  } 
  
  formatDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // we change the format of the material date junk
    this.project.attributes[type] = this.pipe.transform(event.value, 'yyyy-MM-dd');
    return;
  }
  
}
