/**
 * Seitenbar-Service
 * 
 * Attila Németh, UBG
 * 15.03.2019
 */
 
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UbgSidebarService {
  
  opened: boolean = false
  
  constructor() {}
  
  toggle() {
    if (this.opened) {
      this.close();
    }
    else {
      this.open();
    }
  }
  
  close() {
    this.opened = false;
  }
  
  open() {
    this.opened = true;
  }
    
}