/**
 * Ziffer-Text-Komponent
 *
 * Attila Németh, UBG
 * 06.06.2019
 */
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MediaChange, MediaObserver} from '@angular/flex-layout';

import {UbgZifferStructured} from '../../../../../../../../model/ziffer_structured';
import {UbgLine} from '../../../../../../../../model/line';
import {UbgZifferService} from '../../../../../../../../services/ziffer.service';

@Component({
  selector: 'ubg-project-antrag-leitantrag-ziffer-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class UbgProjectAntragLeitantragZifferTextComponent {
  @Output() load = new EventEmitter;
  @Input() set ziffer(ziffer: UbgZifferStructured) {
    this.service.getLines(ziffer).then((response: Array<UbgLine>) => {
      this.lines = response;
      this.setContent(response);
      setTimeout(() => {
        this.load.emit();
      }, 250);
    });
  }
  lines: Array<UbgLine>;
  zifferClass: string = 'ubg-ziffer-line';
  content: string;
  margin = [];
  marginTop: boolean = false  

  constructor(private mediaObserver: MediaObserver,
                private service: UbgZifferService) {
    this.mediaObserver.media$.subscribe((change: MediaChange) => {
      this.zifferClass = 'ubg-ziffer-line ubg-ziffer-line-' + change.mqAlias;
    });
  }

  setContent(lines) {
    this.content = '';
    this.margin = [];
    let firstLine: number = 999999;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].attributes.number * 1 < firstLine) {
        firstLine = lines[i].attributes.number * 1;
      }
      this.content += lines[i].attributes.content.value;
      // placing margin before and after lists in numbers so it stays aligned with the text
      if (lines[i].attributes.content.value.includes('<ol>') ||
            lines[i].attributes.content.value.includes('<ul>') ) {
        this.margin.push(lines[i].attributes.number * 1);
      }
      if (lines[i].attributes.content.value.includes('</ol>') ||
        lines[i].attributes.content.value.includes('</ul>')) {
        this.margin.push( ((lines[i].attributes.number * 1)  + 1)  );
      }
    }    
    // Wenn eine Zeile einen Margin hat, die es aber nicht gibt (d.h. die Zeile vor der 
    // ersten Zeile), ein extra leeres Element muss hinzugefügt werden.
    if (this.margin.includes(firstLine - 1)) {
      this.marginTop = true;
    }
  }
}
