/**
 * Letzte-Änderung-Service
 * 
 * Attila Németh, UBG
 * 26.08.2019
 */
 
import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UBGAntragModel} from '../model/antrag.model';
import {UBGAntragService} from './antrag.service';
import {UbgProposal} from '../model/proposal';

@Injectable({
  providedIn: 'root'
})
export class UbgLastChangeService {
  
  antraege: Array<UbgLastChangeAntrag> = []
  getInterval: any
  private _changedProposal: BehaviorSubject<UbgProposal> = new BehaviorSubject(new UbgProposal);
  public changedProposal: Observable<UbgProposal> = this._changedProposal.asObservable();
  
  constructor(private contenta: UbgContentaService,
               private antragService: UBGAntragService) {
    this.getInterval = setInterval(() => {
      this.getLastChange();
    }, 900000);
  }
  
  addAntrag(antrag: UBGAntragModel) {
    let newAntraege: Array<UbgLastChangeAntrag> = [];
    for (let i in this.antraege) {
      if (this.antraege[i].id !== antrag.id) {
        newAntraege.push(this.antraege[i]);
      }
    }
    newAntraege.push({
      id: antrag.id,
      lastChange: 0,
    });
    this.antraege = newAntraege;
  }
  
  removeAntrag(antrag: UBGAntragModel) {
    let newAntraege: Array<UbgLastChangeAntrag> = [];
    for (let i in this.antraege) {
      if (this.antraege[i].id !== antrag.id) {
        newAntraege.push(this.antraege[i]);
      }
    }
    this.antraege = newAntraege;
  }
  
  getLastChange() {
    for (let i in this.antraege) {
      this.antragService.getLastChange(this.antraege[i].id).then((response: UbgLastChangeAntrag) => {
        let newAntraege: Array<UbgLastChangeAntrag> = []
        for (let j in this.antraege) {
          if (response.id == this.antraege[j].id) {
            if (response.lastChange !== this.antraege[j].lastChange) {
              this.updateAntrag(response.id);
            }
          }
          else {
            newAntraege.push(this.antraege[j]);
          }
        }
        newAntraege.push(response);
        this.antraege = newAntraege;
      });
    }
  }
  
  private updateAntrag(antragId: string) {
    this.antragService.getAllProposals(antragId).then((response: Array<UbgProposal>) => {
      for (let i in response) {
        this._changedProposal.next(response[i]);
      }
    });
  }
  
}

export class UbgLastChangeAntrag {
  
  id: string
  lastChange: number = 0
  
}