/**
 * Proposal-Text-Komponent
 * 
 * Attila Németh, UBG
 * 06.06.2019
 */
 
import {Component, Input} from '@angular/core';

import {UbgProposalStructured} from '../../../../../../../../model/proposal_structured';
import {ProposalReasonBottomSheetComponent} from '../../../../../../../antrag-meine/proposal-reason-sheet/proposal-reason-sheet';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
  selector: 'ubg-project-antrag-proposal-text',
  templateUrl: './text.component.html',
})
export class UbgProjectAntragProposalTextComponent {
  @Input() proposal: UbgProposalStructured;

  bottomSheetSign = '+';

  constructor(private proposalReasonBottomSheet: MatBottomSheet) {}

  openProposalReasonBottomSheet(item, editable) {
    this.bottomSheetSign = '-';
    if (!item.attributes.reason) {
      item.attributes.reason = {value: ''}; // if it is null!
    }
    const bottomRef = this.proposalReasonBottomSheet.open(ProposalReasonBottomSheetComponent, { data: {content: item.attributes.reason.value, editable: editable}});

    bottomRef.afterDismissed().subscribe(result => {
      if (result !== undefined) {
        if ( result ) {
          item.attributes.reason.value = result;
        } else {// it is empty so we discard
          item.attributes.reason = null;
        }
      } else {
        if (!item.attributes.reason.value) {
          item.attributes.reason = null;
        }
      }
      this.bottomSheetSign = '+';
    });
  }
}
