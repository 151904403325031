/**
 * Ziffer
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */

import {UbgLine} from './line' ;
import {UbgBegrundung} from './begrundung';
import {UbgUser} from './user';

export class UbgZiffer {
  type: string = 'ubg_ziffer--ubg-ziffer';
  id?: string;
  attributes: {
    title: string,
    number: number,
    content?: string,
    zuweisungsdatum?: string
  };
  relationships: {
    lines: {
      data: Array<UbgLine>
    }
    zustandigkeit?: {
      data: Array<UbgUser>
    }
    begrundung?: {
      data: UbgBegrundung
    }
  };
  // this is just for controlling proposes
  isExpanded?: boolean;
  // this is just for controlling proposes
  editable?: boolean;
  // the reverse entity reference of ziffer to proposals, just to ease structure of leitantrag proposal page
  proposals?: any;
}
