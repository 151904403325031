/**
 * Proposal-Markup-Komponent
 * 
 * Attila Németh, UBG
 * 09.07.2019
 */
 
import {Component, Input} from '@angular/core';

import {UbgProposalStructured} from '../../../../../../../../model/proposal_structured';
import {UbgVotum} from '../../../../../../../../model/votum';
import {UbgVotumService} from '../../../../../../../../services/votum.service';

@Component({
  selector: 'ubg-project-antrag-proposal-markup',
  templateUrl: './markup.component.html',
})
export class UbgProjectAntragProposalMarkupComponent {
  
  @Input() set proposal(proposal: UbgProposalStructured) {
    this.setMarkup(proposal);
  }
  
  id: string = ''
  original: string = ''
  fassung: string = ''

  constructor(private votumService: UbgVotumService) {}
  
  setMarkup(proposal: UbgProposalStructured) {
    this.id = 'proposal-' + proposal.id + '-markup';
    let textItems: Array<string> = [];
    for (let i in proposal.relationships.item.data) {
      textItems.push(proposal.relationships.item.data[i].attributes.content['value'].replace(/\/p\>\<p/g, '/p>-*-<p'));
    }
    this.original = textItems.join('-*-').replace(/\<\/p\>\-\*\-\<p\>/g, ' ').replace(/\<(strong|\/strong)\>/g, '');
    this.fassung = textItems.join('-*-').replace(/\<\/p\>\-\*\-\<p\>/g, ' ');
    this.votumService.getByProposal(proposal.id).then((voten: Array<UbgVotum>) => {
      for (let i in voten) {
        if (voten[i].attributes.fassung !== undefined) {
          this.fassung = voten[i].attributes.fassung.value;
        }
      }
    });
  }
  
}