/**
 * Contenta-Service
 * 
 * Attila Németh, UBG
 * 19.03.2019
 */
 
import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {TokenResponse} from '../model/token.response';

@Injectable({
  providedIn: 'root'
})
export class UbgContentaService {

  // Environment
  environment: {
    clientId: string
    clientSecret: string
    baseUrl: string
    tokenUrl: string
    scopes: Array<string>
  }
  
  accessTokenString: string = ''
  accessTokenExpires: number = 0
  tokenUpdating: boolean = false
  accessCode: string = ''

  constructor(private http: HttpClient) {
    this.environment = environment.contenta;
  }
  
  getCookies() {
    let cookies = document.cookie.split(';');
    let response = {};
    for (let i in cookies) {
      let cookieParts: Array<string> = cookies[i].split('=');
      response[cookieParts[0].trim()] = cookieParts[1];
    }
    return response;
  }
  
  requestToken(params: any) {
    let promise = new Promise((resolve, reject) => {
      if (!this.tokenUpdating) {
        this.tokenUpdating = true;
        console.info('Token wird angefragen...');
        let queryString = 'client_id=' + this.environment.clientId + '&client_secret=' + this.environment.clientSecret;
        let now = new Date().getTime();
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded'
          }),
        };
        for (let i in params) {
          queryString += '&' + i + '=' + params[i];
        }
        if (params['grant_type'] != 'refresh_token') {
          queryString += '&scope=' + this.environment.scopes.join(' ');
        }
        this.http.post(this.environment.tokenUrl, queryString, httpOptions).subscribe((response: TokenResponse) => {
          console.info('Token wurde erhalten');
          this.tokenUpdating = false;
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.tokenUpdating = false;
          this.outputError(error);
          reject();
        });
      }
      else {
        reject();
      }
    });
    return promise;
  }
  
  setAccessToken(tokens:TokenResponse) {
    console.info('Token wurde angewendet');
    var d = new Date();
    d.setTime(d.getTime() + tokens.expires_in * 1000);
    this.accessTokenString = tokens.access_token;
    this.accessTokenExpires = d.getTime();
  }
  
  removeAccessToken() {
    this.accessTokenString = null;
    this.accessTokenExpires = 0;
  }
  
  getAccessToken() {
    let promise = new Promise((resolve, reject) => {
      var d = new Date();
      if (this.accessTokenExpires > d.getTime() + 12000) {
        resolve(this.accessTokenString);
      }
      else {
        let cookies = this.getCookies();
        if (cookies['refresh_token'] !== undefined) {
          let params = {
            grant_type: 'refresh_token',
            refresh_token: cookies['refresh_token'],
          };
          this.requestToken(params).then((response: TokenResponse) => {
            this.setCookies(response);
            this.setAccessToken(response);
            resolve(response.access_token);
          }).catch(() => {
            reject();
          });
        }
      }
    });
    return promise;
  }
  
  private setCookies(tokens:TokenResponse) {
    var d = new Date();
    // Jetzt + 2 Wochen
    d.setTime(d.getTime() + 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=' + tokens.refresh_token + ';' + expiresString + ";path=/";
  }
  
  setAccessCode(code: string) {
    this.accessCode = code;
  }
  
  getHttpOptions() {
    let promise = new Promise((resolve, reject) => {
      this.getAccessToken().then((accessToken: string) => {
        let headers = {
          'Authorization': 'Bearer ' + accessToken,
          'X-Consumer-ID': this.environment.clientId,
        };
        if (this.accessCode !== '') {
          headers['X-Ubg-AccessCode'] = this.accessCode;
        }
        let httpOptions = {
          headers: new HttpHeaders(headers),
        };
        resolve(httpOptions);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(path: string) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        this.http.get(this.environment.baseUrl + path, options).subscribe((response: any) => {
          this.analyzeTime('GET', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.outputError(error);
          reject();
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByFullUrl(url: string) {
    let promise = new Promise((resolve, reject) => {
      let path = url.replace(this.environment.baseUrl, '');
      this.get(path).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getFile(url: string) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        options['responseType'] = 'blob';
        this.http.get(url, options).subscribe((response: any) => {
          this.analyzeTime('GET', url, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.outputError(error);
          reject();
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getAnonymous(path: string) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.environment.baseUrl + path).subscribe((response: any) => {
        this.analyzeTime('GET', path, startTime);
        resolve(response);
      }, (error: HttpErrorResponse) => {
        this.outputError(error);
        reject();
      })
    });
    return promise;
  }
  
  post(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        if (typeof data == 'string') {
          options.headers = options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        else {
          options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
        }
        this.http.post(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
          this.analyzeTime('POST', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.outputError(error);
          reject();
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  postGetFile(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        options['responseType'] = 'blob';
        options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
        this.http.post(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
          this.analyzeTime('POST', this.environment.baseUrl + path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.outputError(error);
          reject();
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  postAnonymous(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      let options = {
        headers: new HttpHeaders({})
      };
      if (typeof data == 'string') {
        options.headers = options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
      }
      else {
        options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
      }
      this.http.post(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
        this.analyzeTime('POST', path, startTime);
        resolve(response);
      }, (error: HttpErrorResponse) => {
        this.outputError(error);
        reject();
      })
    });
    return promise;
  }
  
  patch(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        if (typeof data == 'string') {
          options.headers = options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        else {
          options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
        }
        this.http.patch(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
          this.analyzeTime('PATCH', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          this.outputError(error);
          reject(error);
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  patchRestJson(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        options.headers = options.headers.set('Content-Type', 'application/json');
        this.http.patch(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
          this.analyzeTime('PATCH', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          this.outputError(error);
          reject(error);
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  patchAnonymous(path: string, data: any) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      let options = {
        headers: new HttpHeaders({})
      };
      if (typeof data == 'string') {
        options.headers = options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
      }
      else {
        options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
      }
      this.http.patch(this.environment.baseUrl + path, data, options).subscribe((response: any) => {
        this.analyzeTime('PATCH', path, startTime);
        resolve(response);
      }, (error: HttpErrorResponse) => {
        this.outputError(error);
        reject();
      })
    });
    return promise;
  }
  
  delete(path: string) {
    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: {
        headers: HttpHeaders,
      }) => {
        this.http.delete(this.environment.baseUrl + path, options).subscribe((response: any) => {
          this.analyzeTime('DELETE', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          this.outputError(error);
          reject();
        })
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
 
  private analyzeTime(method: string, path: string, startTime: Date) {
    let endTime = new Date();
    let processTime = endTime.getTime() - startTime.getTime();
    if (processTime > 1200) {
      console.warn('[Duration]', method, path, processTime);
    }
  }
  
  private outputError(error: HttpErrorResponse) {
    console.error(error.status, error.statusText);
    if (error.error !== null) {
      if (error.error['error'] !== undefined) {
        console.warn('--', error.error['error']);
      }
      if (error.error['errors'] !== undefined) {
        for (let i in error.error['errors']) {
          if (error.error['errors'][i]['detail'] !== undefined) {
            console.warn('--', error.error['errors'][i]['detail']);
          }
        }
      }
      if (error.error['message'] !== undefined) {
        console.warn('--', error.error['message']);
      }
      if (error.error['hint'] !== undefined) {
        console.warn('--', error.error['hint']);
      }
    }
  }

  /**
   * @TODO: It doesn't word. some drupal problems that we had before also: https://www.drupal.org/project/subrequests/issues/2971295
   * Because of timing matters we have to leave this for later to fix.
   *
   * Using this request you can bulk reqeust an array of entities(sharing the same entity type) in only on request.
   * We Contenta subrequest module for this action.
   * More about subrequest: https://www.drupal.org/project/subrequests
   * @path the uri of the request.
   * @param entities is the array of entities you wish to bulk request
   * @param action indicates the type of action this buld request will execute.
   * Common values for action property are view, create, update, replace, delete, exists and discover.
   */
  bulkRequest(path: string, entities, action) {

    let startTime = new Date();
    let promise = new Promise((resolve, reject) => {
      this.getHttpOptions().then((options: { headers: HttpHeaders, }) => {

        options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
        options.headers = options.headers.set('Accept', 'application/vnd.api+json');

        let data = [];
        for (let i = 0; i < entities.length; i++ ) {
          let request = {
            requestId: 'req-' + i,
            uri: path,
            action: action,
            body: JSON.stringify(entities[i]),
            headers: {
              'Authorization': 'Bearer ' + this.accessTokenString ,
              'X-Consumer-ID': this.environment.clientId,
              'Content-Type': 'application/vnd.api+json',
              'Accept': 'application/vnd.api+json',
            }
          };
          data.push(request);
        }
        //data = JSON.stringify(data);

        this.http.post(this.environment.baseUrl + 'subrequests', data, options).subscribe((response: any) => {
          console.debug('SUB');
          this.analyzeTime('POST', path, startTime);
          resolve(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          this.outputError(error);
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}
