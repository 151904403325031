/**
 * Filter-Komponent
 * 
 * Attila Németh, UBG
 * 23.04.2019
 */
 
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {MatSelectChange} from '@angular/material/select';

import {UbgEmpfehlungType} from '../../model/empfehlung_type';
import {UbgEmpfehlungTypeService} from '../../services/empfehlung_type.service';
import {UbgFilterParams} from '../../model/filter_params';
import {UbgFilterService} from '../../services/filter.service';
import {UBGAntragModel} from '../../model/antrag.model';
import {UbgAutocompleteOption} from '../../../ubg_components/autocomplete/model/option';
import {UBGAntragService} from '../../services/antrag.service';
import {UbgUser} from '../../model/user';
import {UbgEmpfehlungUberweisung} from '../../model/taxonomies/empfehlung_uberweisung';
import {UbgEmpfehlungUberweisungService} from '../../services/taxonomies/empfehlung_uberweisung.service';
import {UbgZifferStructured} from '../../model/ziffer_structured';
import {UbgThema} from '../../model/taxonomies/thema';
import {UbgThemaService} from '../../services/taxonomies/thema.service';

@Component({
  selector: 'ubg-filter',
  templateUrl: './filter.component.html'
})
export class UbgFilterComponent implements OnInit {
  
  @Input() set antrag(antrag: UBGAntragModel) {
    this.antragData = antrag;
    if (antrag !== null) {
      if (antrag.relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag') {
        this.themen = [];
      }
      else if (antrag.relationships.ubg_antrag_type.data.attributes.name == 'Sonstige') {
        this.loadThemen();
      }
      this.loadReda();
    }
  }
  antragData: UBGAntragModel = null
  @Input() set projectId(projectId: string) {
    this.projectIdIdata = projectId;
    this.loadReda();
  }
  projectIdIdata: string = ''
  @Output() filterParamsChanged = new EventEmitter 
  empfehlungTypes: Array<UbgEmpfehlungType>
  filterParams: UbgFilterParams
  redaOptions: Array<UbgAutocompleteOption> = []
  canAssign: boolean = false
  empfehlungTypeId: string = null
  uberweisungen: Array<UbgEmpfehlungUberweisung> = []
  themen: Array<UbgThema> = []
  
  constructor(private route: ActivatedRoute,
                private empfehlungType: UbgEmpfehlungTypeService,
                private service: UbgFilterService,
                private antragService: UBGAntragService,
                private uberweisungService:UbgEmpfehlungUberweisungService,
                private themaService: UbgThemaService) {
    this.empfehlungTypes = [];
    this.filterParams = {
      suche: '',
      empfehlung: '',
      thema: null,
      title: '',
      org_user: '',
      my: false,
      zuweisung: '',
      keinezuweisung: false,
      keinvotum: false,
    };
    this.route.queryParams.subscribe((params: any) => {
      for (let key in this.filterParams) {
        if (params['filter_' + key] !== undefined && params['filter_' + key] !== '') {
          this.filterParams[key] = params['filter_' + key];
        }
        else {
          this.filterParams[key] = '';
        }
      }
    });
  }
  
  ngOnInit() {
    this.loadEmpfehlungTypes();
  }
  
  loadThemen(): void {
    this.themaService.getAll().then((response: Array<UbgThema>) => {
      this.themen = response;
    });
  }
  
  loadEmpfehlungTypes() {
    this.empfehlungType.getAll().then((response: Array<UbgEmpfehlungType>) => {
      this.empfehlungTypes = response;
    })
  }
  
  filterEmpty(): boolean {
    for (let key in this.filterParams) {
      if (this.filterParams[key] !== null && this.filterParams[key] !== '') {
        return false;
      }
    }
    return true;
  }
  
  apply() {
    this.filterParamsChanged.emit(this.filterParams);
    this.service.setParams(this.filterParams);
  }
  
  reset() {
    this.filterParams = {
      suche: '',
      empfehlung: '',
      keinezuweisung: false,
    };
    this.empfehlungTypeId = null;
    this.apply();
  }
  
  loadReda() {
    if (this.projectIdIdata != '' && this.antragData !== null) {
      this.antragService.getReda(this.antragData).then((response: Array<UbgUser>) => {
        let newOptions: Array<UbgAutocompleteOption> = []
        for (let i in response) {
          newOptions.push({
            id: response[i].id,
            label: response[i].attributes.nachname + ', ' + response[i].attributes.vorname,
          });
        }
        this.redaOptions = newOptions;
      });
      this.canAssign = false;
      this.antragService.getStructuredZiffers(this.projectIdIdata, this.antragData, null).then((response: Array<UbgZifferStructured>) => {
        for (let i in response) {
          if (response[i].meta.permissions.assign === true) {
            this.canAssign = true;
          }
        }
      });
    }
  }
  
  zuweisungSelected(event: string) {
    this.filterParams.zuweisung = event;
  }
  
  votumTypeSelected(event: MatSelectChange ) {
    for (let i in this.empfehlungTypes) {
      if (this.empfehlungTypes[i].id == event.value) {
        this.empfehlungTypeId = this.empfehlungTypes[i].attributes.drupal_internal__id;
        if (this.empfehlungTypeId == 'uberweisung_an' 
              || this.empfehlungTypeId == 'annahme_und_uberweisung' 
              || this.empfehlungTypeId == 'annahme_fassubg_uber') {
          this.uberweisungService.getAll().then((response: Array<UbgEmpfehlungUberweisung>) => {
            this.uberweisungen = response;
          });
        }
      }
    }
  }
  
  themaSelected(event: MatSelectChange): void {
    this.filterParams.thema = event.value;
  }
  
  keyPressed(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.apply();
    }
  }
  
}
