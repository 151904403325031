/**
 * FAB-Component
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'ubg-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class UbgFabComponent {
  
  @Input() icon: string = 'add'
//  @Output() click = new EventEmitter();
  isMobile: boolean = false;

  constructor(private breakpoint: BreakpointObserver) {}
  
  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }
  
  getClasses() {
    let classes = [];
    if (this.isMobile) {
      classes.push('mobile');
    }
    return classes.join(' ');
  }
  
}