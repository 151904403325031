import {Component, Input} from '@angular/core';
import {UbgZifferStructured} from '../../../../model/ziffer_structured';
import {UbgLine} from '../../../../model/line';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {UbgZifferService} from '../../../../services/ziffer.service';
import {TextHelperService} from '../../../../services/helpers/text-helper.service';

@Component({
  selector: 'app-leitantrag-proposal-diff',
  templateUrl: './leitantrag-proposal-diff.component.html',
  styleUrls: ['./leitantrag-proposal-diff.component.scss'],
})
export class LeitantragProposalDiffComponent {
  @Input() set ziffer(ziffer: UbgZifferStructured) {
    this.service.getLines(ziffer).then((response: Array<UbgLine>) => {
      this.lines = response;
      this.setContent(response);
    });
  }
  @Input() set proposal(proposalContent) {
    this.setProposedContent(proposalContent);
  }
  lines: Array<UbgLine>;
  zifferClass: string = 'ubg-ziffer-line';
  content: string;
  proposedContent: string;

  constructor(private mediaObserver: MediaObserver,
              private service: UbgZifferService,
              private textHelperService: TextHelperService
  ) {
    this.mediaObserver.media$.subscribe((change: MediaChange) => {
      this.zifferClass = 'ubg-ziffer-line ubg-ziffer-line-' + change.mqAlias;
    });
  }

  setContent(lines) {
    this.content = '';
    for (let i = 0; i < lines.length; i++) {
      this.content += lines[i].attributes.content.value;
      // numbers make no sense when we are showing the diff.
    }
    this.content = this.textHelperService.dropStyling(this.content);

  }

  setProposedContent(proposalLines) {
    this.proposedContent = this.textHelperService.fixTags(this.textHelperService.dropStyling(proposalLines));
/*    const proposalStructureContent = this.textHelperService.breakEditedContentToLines(proposalLines.join('') );
    for (let i = 0; i < proposalStructureContent.length; i++) {
        this.proposedContent += proposalStructureContent[i];
    }*/
  }
}
