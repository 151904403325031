/**
 * Projekt-Service
 * 
 * Attila Németh, UBG
 * 01.04.2019
 */

import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgProject} from '../model/project';
import {UbgGroup} from '../model/group';
import {UbgProposal} from '../model/proposal';
import {UbgOrgUser} from '../model/org_user';
 
@Injectable({
  providedIn: 'root'
})
export class UbgProjectService {
  
  activeProjectId: string = null
  activeProjectIds: Observable<string>
  activeProjectIdData: BehaviorSubject<string>
  createdProjectIds: Observable<string>
  createdProjecData: BehaviorSubject<string>
  
  constructor(private contenta: UbgContentaService) {
    this.activeProjectIdData = <BehaviorSubject<string>>new BehaviorSubject('');
    this.activeProjectIds = this.activeProjectIdData.asObservable();
    this.createdProjecData = <BehaviorSubject<string>>new BehaviorSubject('');
    this.createdProjectIds = this.createdProjecData.asObservable();
  }
  
  setActiveProject(project: UbgProject) {
    this.activeProjectId = project.id;
    this.activeProjectIdData.next(project.id);
  }
  
  setActiveProjectId(id: string) {
    this.activeProjectId = id;
    this.activeProjectIdData.next(id);
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_project/ubg_project').then((response: {
        data: Array<UbgProject>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_project/ubg_project/' + id).then((response: {
        data: UbgProject
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(project: UbgProject) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_project/ubg_project/' + project.id;
      this.contenta.patch(path, {
        data: project
      }).then((response: {
        data: UbgProject
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  create(project: UbgProject) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_project/ubg_project', {
          data: project
      }).then((response: {
          data: UbgProject
      }) => {
          this.createdProjecData.next(response.data.id);
          resolve(response.data);
      }).catch(() => {
          reject();
      });
    });
    return promise;
  }
  
  print(project: UbgProject, args: {
    mitEmpfehlungen: boolean
    mitBegruendungen: boolean
    mitMarkups: boolean
    mitEntwurf: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/project/' + project.id + '/print'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printBegrundungen(project: UbgProject) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/project/' + project.id + '/print/begruendungen'
      this.contenta.postGetFile(path, null).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printMeine(project: UbgProject, orgUsers?: Array<UbgOrgUser>) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/project/' + project.id + '/print/meine';
      let data = {};
      if (orgUsers !== undefined) {
        data['orgUsers'] = [];
        for (let i in orgUsers) {
          data['orgUsers'].push(orgUsers[i].id);
        }
      }
      this.contenta.postGetFile(path, data).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getMyProposals(project: UbgProject, sortField?: string, sortDir?: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/project/api/' + project.id + '/my';
      if (sortField !== undefined && sortField !== null) {
        path += '?sortField=' + sortField;
      }
      if (sortDir !== undefined && sortDir !== null) {
        path += '&sortDir=' + sortDir;
      }
      this.contenta.get(path).then((response: {
        data: Array<UbgProposal>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAllProposals(project: UbgProject, keinVotum: boolean, keineZuweisung: boolean, sortField?: string, sortDir?: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/project/api/' + project.id + '/allp?keinVotum=' + keinVotum + '&keineZuweisung=' + keineZuweisung;
      if (sortField !== undefined) {
        path += '&sortField=' + sortField;
      }
      if (sortDir !== undefined) {
        path += '&sortDir=' + sortDir;
      }
      this.contenta.get(path).then((response: {
        data: Array<UbgProposal>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printAllProposals(project: UbgProject, keinVotum: boolean, keineZuweisung: boolean, sortField?: string, sortDir?: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/project/print/' + project.id + '/allp?keinVotum=' + keinVotum + '&keineZuweisung=' + keineZuweisung;
      if (sortField !== undefined && sortField !== null) {
        path += '&sortField=' + sortField;
      }
      if (sortDir !== undefined && sortDir !== null) {
        path += '&sortDir=' + sortDir;
      }
      this.contenta.postGetFile(path, {}).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  msWordProtokoll(projectId: string, name: string) {
    let promise = new Promise((resolve, reject) => {
      const path: string = 'ubg/project/' + projectId + '/protokoll/' + name;
      this.contenta.postGetFile(path, null).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  excelProtokoll(projectId: string) {
    let promise = new Promise((resolve, reject) => {
      const path: string = 'ubg/project/' + projectId + '/excel/protokoll';
      this.contenta.postGetFile(path, null).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  isExpired(project: UbgProject): boolean {
    const expireTime = new Date(project.attributes.end_voting_date + ' 23:59:59');
    const now = new Date();
    if (now > expireTime) {
      return true;
    }
    else {
      return false;
    }
  }
  
}