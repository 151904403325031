import { Component } from '@angular/core';
import {UbgSidebarService} from './modules/sidebar/services/sidebar.service';
import {UbgContentaUserService} from './modules/contenta/user/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'www-antragswesen';

  headerOptions = [
    {
      path: 'antrag/1',
      label: '1. Antrag',
    },
    {
      path: 'antrag/2',
      label: '2. Antrag',
    },
  ]
  activeAntragId: number = 1;


  constructor(private sidebar: UbgSidebarService, private user: UbgContentaUserService) {

  }
}
