import {Injectable} from '@angular/core';

import {UBGAntragModel} from '../model/antrag.model';
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgFilterParams} from '../model/filter_params';
import {UbgZifferStructured} from '../model/ziffer_structured';
import {UbgProposalStructured} from '../model/proposal_structured';
import {UbgUser} from '../model/user';

@Injectable({
  providedIn: 'root'
})

export class UBGAntragService {

  constructor(private contenta: UbgContentaService) {}
  
  lastChange: number = 0

  create(antrag: UBGAntragModel) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_antrag/ubg_antrag', {
        data: antrag
      }).then((response: {
        data: UBGAntragModel
      }) => {
        resolve(response.data);
      }).catch((response: any) => {
        console.log(response);
        reject();
      });
    });
    return promise;
  }


  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_antrag/ubg_antrag/' + id + '?include=ubg_antrag_type,status').then((response: {
        data: UBGAntragModel,
        included: any
      }) => {
        response.data.relationships.ubg_antrag_type.data = response.included[0];
        response.data.relationships.status.data = response.included[1];
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  update(antrag: UBGAntragModel) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_antrag/ubg_antrag/' + antrag.id;
      this.contenta.patch(path, {
        data: antrag
      }).then((response: {
        data: UBGAntragModel
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  delete(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_antrag/ubg_antrag/' + id ).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  generateNumbers(id:string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/generate/antrag/' + id ).then((response: {
        message: string,
        result: any
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getStructuredZiffers(projectId: string, antrag: UBGAntragModel, filterParams: UbgFilterParams) {
    let promise = new Promise((resolve, reject) => {
      let url = 'ubg/api/antrag/' + projectId + '/' + antrag.id + '/ziffern';
      let data = {
        filter: filterParams
      };
      this.contenta.post(url, data).then((response: {
        data: Array<UbgZifferStructured>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  reorder(antrag: UBGAntragModel) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/antrag/' + antrag.id + '/reorder';
      let data = {};
      this.contenta.post(path, data).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getSonstigeProposals(projectId: string, antrag: UBGAntragModel, filterParams: UbgFilterParams, offset: number, limit: number) {
    let promise = new Promise((resolve, reject) => {
      if (antrag === null) {
        console.error('Antrag ist NULL');
        reject();
      }
      let path = 'ubg/api/antrag/' + projectId + '/' + antrag.id + '/proposals';
      let data = {
        filter: filterParams,
        offset: offset,
      };
      if (limit > 0) {
        data['limit'] = limit;
      }
      this.contenta.post(path, data).then((response: {
        data: Array<UbgProposalStructured>,
        meta: {
          count: number
          offset: number
          pager: number
        }
      }) => {
        resolve(response);
      }).catch((error) => {
        console.error('Sonstige', error);
        reject();
      });
    });
    return promise;
  }
  
  getAllProposals(antragId: string) {
    let promise = new Promise((resolve, reject) => {
      const path = 'ubg/antrag/' + antragId + '/proposals';
      this.contenta.get(path).then((response: {
        data: Array<UbgProposalStructured>,
      }) => {
        resolve(response.data);
      }).catch((error) => {
        console.error(error);
        reject();
      });
    });
    return promise;
  }
  
  print(antrag: UBGAntragModel, args: {
    mitEmpfehlungen: boolean
    mitBegruendungen: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/antrag/' + antrag.id + '/print'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printSelected(antrag: UBGAntragModel, proposals: Array<UbgProposalStructured>, args: {
    mitEmpfehlungen: boolean
    mitBegruendungen: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      args['proposals'] = [];
      for (let i in proposals) {
        args['proposals'].push(proposals[i].id);
      }
      let path = 'ubg/api/antrag/' + antrag.id + '/print/selected'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printLeitantrag(antrag: UBGAntragModel) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/antrag/' + antrag.id + '/print/leitantrag'
      this.contenta.postGetFile(path, null).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  msWord(antrag: UBGAntragModel, args: {}) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/antrag/' + antrag.id + '/msword';
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  printBeschluss(antrag: UBGAntragModel, args: {
    mitMarkups: boolean
  }) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/api/antrag/' + antrag.id + '/print/beschluss'
      this.contenta.postGetFile(path, args).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getReda(antrag: UBGAntragModel) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/antrag/api/reda/' + antrag.id).then((response: UbgUser) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  public getLastChange(antragId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/antrag/' + antragId + '/last/change').then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  createDummyZiffer(antrag: UBGAntragModel) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/antrag/' + antrag.id + '/dummy', null).then((response: {
        data: UbgZifferStructured
      }) => {
        resolve(response.data);
      }).catch((response: any) => {
        reject();
      });
    });
    return promise;
  }

}