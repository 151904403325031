/**
 * Dialog-Teil des Komponents
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'ubg-tab-dialog-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class UbgTabDialogDialogComponent implements OnInit {
  
  isMobile: boolean = false;
  isWide: boolean = false;

  constructor(private breakpoint: BreakpointObserver) {}
  
  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    this.breakpoint
      .observe([Breakpoints.Medium, Breakpoints.Large,Breakpoints.XLarge])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isWide = true;
        } else {
          this.isWide = false;
        }
      });
  }
  
  getDialogClass() {
    let classes = [];
    if (this.isMobile) {
      classes.push('ubg-tab-dialog-mobile');
    }
    if (this.isWide) {
      classes.push('ubg-tab-dialog-wide');
    }
    return classes.join(' ');
  }
  
}