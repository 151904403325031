/**
 * Drücken-Dialog
 * 
 * Attila Németh, UBG
 * 03.06.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class UbgPrintConfirmDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                label: string
                mitEmpfehlungen: boolean
                empfehlungenPossible: boolean
                mitBegruendungen: boolean
                begruendungPossible: boolean
                mitMarkups: boolean
                markupsPossible: boolean
                mitStellerBegr: boolean
                stellerBegrPossible: boolean
                mitEntwurf: boolean
                entwurfPossible: boolean
              }) {
  }
  
}
