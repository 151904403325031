/**
 * Details
 * 
 * Attila Németh, UBG
 * 23.04.2019
 */
 
import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';

import {Sort} from '@angular/material/sort';

import {UbgStatisticsService} from '../../../services/statistics.service';
import {UbgStatisticsDetails} from '../../../model/details';

@Component({
  selector: 'ubg-statistics-item-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UbgStatisticsDetailsComponent implements OnInit {
  
  @Input() details: string = ''
  @Input() set filterParams(filterParams: {}) {
    this.inputFilterParams = filterParams;
    this.load(false);
  };
  inputFilterParams: {} = {};
  isLoading: boolean = true
  hasError: boolean = false
  data: UbgStatisticsDetails
  sortedData: UbgStatisticsDetails
  
  constructor(private service: UbgStatisticsService) {
    this.data = {
      columns: [],
      rows: [],
    };
    this.sortedData = {
      columns: [],
      rows: [],
    };
  }
  
  ngOnInit() {
    this.load(false);
  }
  
  refresh() {
    this.load(true);
  }
  
  load(refresh: boolean) {
    this.isLoading = true;
    this.service.getDetails(this.details, refresh, this.inputFilterParams).then((response: UbgStatisticsDetails) => {
      this.isLoading = false;
      this.hasError = false;
      this.data = JSON.parse(JSON.stringify(response));
      this.sortedData = JSON.parse(JSON.stringify(response));
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  sortData(event: Sort) {
    if (!event.active || event.direction === '') {
      this.sortedData = JSON.parse(JSON.stringify(this.data));
    }
    else {
      this.sortedData = {
        columns: [],
        rows: [],
      };
      let newColumns = JSON.parse(JSON.stringify(this.data.columns));
      let newRows = JSON.parse(JSON.stringify(this.data.rows));
      let newData: UbgStatisticsDetails = {
        columns: newColumns,
        rows: [],
      };
      newData.rows = newRows.sort((a, b) => {
        let aData = 0;
        if (a[event.active] !== undefined && a[event.active] !== '') {
          aData = a[event.active];
        }
        let bData = 0;
        if (b[event.active] !== undefined && b[event.active] !== '') {
          bData = b[event.active];
        }
        let re = 0;
        if (event.direction == 'asc' && aData < bData) { re = 1; }
        else if (event.direction == 'asc' && aData > bData) { re = -1; }
        else if (event.direction == 'desc' && aData < bData) { re = -1; }
        else if (event.direction == 'desc' && aData > bData) { re = 1; }
        return re;
      });
      this.sortedData = newData;
    }
  }
  
}