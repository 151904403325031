import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectManagementModule } from './modules/project-management/project-management.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// materials section
import {
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatMenuModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  MatCardModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatDividerModule,
} from '@angular/material';

// ubg components
import {UbgContentaUserModule} from './modules/contenta/user/user.module';
import { UbgComponentsModule } from './modules/ubg_components/ubg_components.module';
import { UbgSidebarModule } from './modules/sidebar/sidebar.module';
import { UbgSelectEntityModule } from './modules/select_entity/select_entity.module';
import {UbgLayoutModule} from './modules/ubg_components/layout/layout.module';


// components section
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  exports: [
    // materials
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDividerModule,
  ],
  declarations: []
})
export class MainMaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
//    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    // defined modules
    ProjectManagementModule,
    // materials
    MainMaterialModule,
    // ubg components
    UbgContentaUserModule,
    UbgComponentsModule,
    UbgSidebarModule,
    UbgSelectEntityModule,
    UbgLayoutModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
