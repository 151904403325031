/**
 * Projekt bearbeiten
 * 
 * Attila Németh, UBG
 * 14.06.2019
 */
 
import {Component} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {MatSnackBar} from '@angular/material';

import {UbgProject} from '../../../model/project';
import {UbgProjectService} from '../../../services/project_service';
import {UBGAntragService} from '../../../services/antrag.service';
import {UBGAntragModel} from '../../../model/antrag.model';

@Component({
  templateUrl: './edit.component.html',
})
export class UbgProjectEditComponent {
  
  hasError: boolean = false
  isLoading: boolean = false
  project: UbgProject = null
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private snackbar: MatSnackBar,
                private service: UbgProjectService,
                private antragService: UBGAntragService) {
    this.route.params.subscribe((params: {
      id: string
    }) => {
      if (params.id !== null) {
        this.service.setActiveProjectId(params.id);
        this.loadProject();
      }
    });
  }
  
  loadProject() {
    this.isLoading = true;
    this.service.get(this.service.activeProjectId).then((response: UbgProject) => {
      this.project = response;
      this.isLoading = false;
      this.hasError = false;
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  back() {
    this.router.navigate(['project/' + this.project.id]);
  }
  
  saved() {
    this.saveAntraege().then(() => {
      this.saveProject();
    });
  }
  
  saveAntraege() {
    let promise = new Promise((resolve, reject) => {
      for (let i in this.project.relationships.antrage.data) {
        if (this.project.relationships.antrage.data[i].id === undefined) {
          this.antragService.create(this.project.relationships.antrage.data[i]).then((response: UBGAntragModel) => {
            this.project.relationships.antrage.data[i] = response;
            if (this.checkAntraege()) {
              resolve();
            }
          });
        }
      }
      if (this.checkAntraege()) {
        resolve();
      }
    });
    return promise;
  }
  
  private checkAntraege() {
    for (let i in this.project.relationships.antrage.data) {
      if (this.project.relationships.antrage.data[i].id === undefined) {
        return false;
      }
    }
    return true;
  }
  
  saveProject() {
    this.service.update(this.project).then(() => {
      this.snackbar.open('Das Projekt wurde geändert', null, {
        duration: 6000,
      });
      this.back();
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden', null, {
        duration: 15000,
      });
    });
  }
  
}
