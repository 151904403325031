/**
 * Dialog auswählen
 * 
 * Attila Németh, UBG
 * 10.04.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgPriorityStructure} from '../../../../../../model/priority_structure';
import {UbgPriorityStructureService} from '../../../../../../services/priority_structure.service';
import {UbgAutocompleteOption} from '../../../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './change.component.html',
})
export class UbgProjectChangeStructureDialogComponent implements OnInit {
  
  options: Array<UbgAutocompleteOption>
  
  constructor(private dialogRef: MatDialogRef<UbgProjectChangeStructureDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                structureId: string
              },
              private service: UbgPriorityStructureService) {
    this.options = [];
  }
              
  ngOnInit() {
    this.service.getAll().then((response: Array<UbgPriorityStructure>) => {
      this.options = [];
      for (let i in response) {
        this.options.push({
          id: response[i].id,
          label: response[i].attributes.title,
        });
      }
    });
  }
  
  structureSelected(selectedId: string) {
    this.data.structureId = selectedId;
  }
 
}
