

import {Injectable} from '@angular/core';

import {UbgLine} from '../model/line';
import {UbgContentaService} from '../../contenta/services/contenta.service';



@Injectable({
  providedIn: 'root'
})

export class UBGLineService {

  constructor(private contenta: UbgContentaService) {}

  create(line: UbgLine) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_line/ubg_line', {
        data: line
      }).then((response: {
        data: UbgLine
      }) => {
        resolve(response.data);
      }).catch((response: any) => {
        reject();
      });
    });
    return promise;
  }

  update(line: UbgLine) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_line/ubg_line/' + line.id, {
        data: line
      }).then((response: {
        data: UbgLine
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  delete(id) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_line/ubg_line/' + id ).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  /**
   *  @NOTICE: This is not functional, read the comments on contenta.bulkRequest method.
   * @param {UbgLine[]} lines
   * @returns {Promise}
   */
  bulkCreate_subrequest(lines: UbgLine[]) {
    const promise = new Promise((resolve, reject) => {
      this.contenta.bulkRequest('api/ubg_line/ubg_line', lines, 'create').then((response: {
        data: any
      }) => {
        resolve(response);
      }).catch((response: any) => {
        reject();
      });
    });
    return promise;
  }

  bulkCreate(lines: UbgLine[]) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/api/line/bulk_create/' , {
        data: lines
      }).then((response: {
        lines: UbgLine[]
        message: string,
        result: any
      }) => {
        resolve(response.lines);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  bulkEdit(lines: UbgLine[], zifferId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/api/line/bulk_edit/' + zifferId , {
        data: lines
      }).then((response: {
        lines: UbgLine[]
        message: string,
        result: any
      }) => {
        resolve(response.lines);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }


}
