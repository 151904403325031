/**
 * Fassung bearbeiten
 * 
 * Attila Németh, UBG
 * 27.08.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  templateUrl: './fassung.component.html',
  styleUrls: ['./fassung.component.scss'],
})
export class UbgFassungEditorDialogComponent {
  
  editor = ClassicEditor;
  config = {
    language: 'de',
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ],
  };
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                label: string
                title: string
                text: string
                restore: string
              }) {
  }
  
  restoreText() {
    this.data.text = this.data.restore;
  }
  
}
