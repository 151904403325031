import { Injectable } from '@angular/core';
import {log} from 'util';
import {st} from '@angular/core/src/render3';
import {ParsedCssResult} from 'codelyzer/angular/styles/cssParser';
import {UbgLine} from '../../model/line';

@Injectable({
  providedIn: 'root'
})
export class TextHelperService {

  constructor() { }


  breakContentToLines(content, ignoreListings = true) {
    // every line is written in <p> tag in ckeditor
    // split the content by <p> tags or the heading tags
    // spaces are &nbsp; they will be striped if they are connected therefore:
    // content = content.replace(/&nbsp;/g, ' '); // update: after we changed the way we show the content we no longer need to touch user spaces. However an empty line is still a line and will get a number
    if (ignoreListings) {
      /*      console.log('content:');
            console.log(content);
            console.log(content.match(/<p.*?>.*?<\/p>|<h2.*?>.*?<\/h2>|<h3.*?>.*?<\/h3>|<h4.*?>.*?<\/h4>|<span.*?>.*?<\/span>/g));*/
      return content.match(/<p.*?>.*?<\/p>|<h2.*?>.*?<\/h2>|<h3.*?>.*?<\/h3>|<h4.*?>.*?<\/h4>|<span.*?>.*?<\/span>/g);
    } else {
      const rawLines: Array<string> = content.match(/<p.*?>.*?<\/p>|<h2.*?>.*?<\/h2>|<h3.*?>.*?<\/h3>|<h4.*?>.*?<\/h4>|<ul.*?>.*?<\/ul>|<ol.*?>.*?<\/ol>/g);
      return rawLines;
    }

    // NOTICE although in the CKeditor we have heading 1,2 and 3, they are actually h2, h3 and h4
  }

  breakEditedContentToLines(str): Array<string> {

    let lines = [];
    let line: string;
    let br: number;
    let p: number;
    let span: number;
    let h2: number;
    let h3: number;
    let h4: number;
    let li: number;
    let min: number;
    let tagLength: number;
    let i = 0;
    while (str.length > 0 && i < 50) {
      i++;
      br = str.indexOf('<br>');
      br = (br === -1 ? 9999 : br);

      p = str.indexOf('</p>');
      p = (p === -1 ? 9999 : p);

      span = str.indexOf('</span>');
      span = (span === -1 ? 9999 : span);

      li = str.indexOf('</li>');
      li = (li === -1 ? 9999 : li);

      h2 = str.indexOf('</h2>');
      h2 = (h2 === -1 ? 9999 : h2);

      h3 = str.indexOf('</h3>');
      h3 = (h3 === -1 ? 9999 : h3);

      h4 = str.indexOf('</h4>');
      h4 = (h4 === -1 ? 9999 : h4);

      if (span < Math.min(Math.min(br, p),  Math.min(li, h2, h3, h4)) ) {
        min = span;
        tagLength = 7;
      } else if (Math.min(br, p) < Math.min(li, h2, h3, h4)) {
        min = Math.min(br, p);
        tagLength = 4;
      } else {
        min = Math.min(li, h2, h3, h4);
        tagLength = 5;
      }
      line = str.substring(0, min + tagLength);
      if (line.includes('</li>') || line.includes('<br>') ) {
        lines.push('<span>' + line + '</span>');
      } else {
        lines.push(line);
      }

      str = str.substring(min + tagLength);
    }

    return lines;
  }

  structureData(data): string {
    console.error('Deprecated Function');
    return null;
  }

  structureDataOld(data): string {
    const MAX_ALLOWED_LENGTH = 65;
    let content = '';
/*    console.error('data');
    console.log(data);*/
    let blocks = this.breakContentToLines( data, false);
    
    // if it is empty then it's empty
    if (!blocks) {
      return data;
    }
    // we will not touch the blocks if they are already shorter than 75 characters
    for (let i = 0; i < blocks.length; i++) {
      // get rid of the tags
      let stripedBlock = this.stripHTMLTags(blocks[i], true);
      // The line style is placed in an array of 2 elements, first element is the open tag and the second is the close tag.
      // it can be <strong> , <i> or <u>.
      let style = this.getStyle(blocks[i]);
      // The line format is placed in an array of 2 elements, first element is the open tag and the second is the close tag.
      // it can be <p>, <ul>, <ol>, <h1>, <h2> or <h3>.
      let format = this.getFormat(blocks[i]);

      const liCount = stripedBlock.match(/-~-/g);
      
      if (liCount) { // then we break it
        // extraPermittedLenght = (liCount.length - 1) * 3;
         let splitedLiBlocks = stripedBlock.split('-~-');
         console.warn('splited li blocks:');
         console.log(splitedLiBlocks);
        // stripedBlock = stripedBlock.replace(/-\|-/g, ' ');
        content += '<span>' + format[0];
        for ( let l = 0; l < splitedLiBlocks.length ; l++) {
          if (splitedLiBlocks[l].length > MAX_ALLOWED_LENGTH ) {
            let brokenBlock = this.splitter(splitedLiBlocks[l], MAX_ALLOWED_LENGTH );
            // console.log('it is long we need to separate it!');
            // console.log(brokenBlock);
            for (let j = 0; j < brokenBlock.length; j++) {
                // brokenBlock[j] = brokenBlock[j] + '</span><span></li><li>'; // we need to return the li tags
                // brokenBlock[j] = brokenBlock[j].replace(/-\|-/g, '<br>'); // we need to return the br tags
              
                if ( j === 0) { // the first one
                  if (l  > 0) {
                    content += '</span><span>';
                  }
                  content += '<li>' + style[0] + brokenBlock[j] + style[1] + '<br>';
                } else if (j !== (brokenBlock.length - 1)) { // the last one
                  content += '</span><span>' + style[0] + brokenBlock[j] + style[1] + '<br>';
                } else {
                  content += '</span><span>' + style[0] + brokenBlock[j] + style[1] + '</li>' ;
                }
            }
          } else {
            if ( l === 0 ) { // first line
              content += '<li>' + style[0] + splitedLiBlocks[l] + style[1] + '</li>';
            } else if (l !== splitedLiBlocks.length - 1) { // last line
              content += '</span><span><li>' + style[0] + splitedLiBlocks[l] + style[1] + '</li>';
            } else { // in middle
              content += '</span><span><li>' + style[0] + splitedLiBlocks[l] + style[1] + '</li>';
            }
          }
        }
        content +=  format[1] + '</span>';
        
      } else {
        // stripedBlock = stripedBlock.replace(/-\|-/g, ' ');
        if (stripedBlock.length > MAX_ALLOWED_LENGTH ) {
          let brokenBlock = this.splitter(stripedBlock, MAX_ALLOWED_LENGTH );
          // console.log('it is long we need to separate it!');
          // console.log(brokenBlock);
          for (let j = 0; j < brokenBlock.length; j++) {
            if ( format[0] === '<ul>' || format[0] === '<ol>' ) {
              content += '<span>';
              // then we need to add the <li>
              if (j === 0 ) {
                content += format[0] + '<li>';
              }
              brokenBlock[j] = brokenBlock[j].replace(/-~-/g, '</span><span></li><li>'); // we need to return the li tags
              // brokenBlock[j] = brokenBlock[j].replace(/-\|-/g, '<br>'); // we need to return the br tags
              if (j !== (brokenBlock.length - 1)) {
                content += style[0] + brokenBlock[j] + style[1] + '<br></span>';
              } else {
                content += style[0] + brokenBlock[j] + style[1] + '</li>' + format[1] + '</span>';
              }
            } else {
              content +=  format[0] + style[0] + brokenBlock[j] + style[1] + format[1];
            }
          }
        } else {
          if (blocks[i].includes('<ul>') || blocks[i].includes('<ol>')) {
            const liCount = blocks[i].match(/<\/li>/g).length;
            /*          console.log('how many li:');
                      console.log(liCount);*/
            // we don't want to replace the last one
            for (let c = 0; c < (liCount - 1); c++) {
              blocks[i] = blocks[i].replace('</li>', '</span><span></li>');
            }
            content += '<span>' + blocks[i] + '</span>';
          } else {
            content += blocks[i];
          }
        }
      }

    }
    
    return content;
  }

  getPlainParagraph(lines: UbgLine[]): string {
    // let lines = this.breakContentToLines( string, true);
    let res = '';
    let p = '';
    let content = '';
    for (let i = 0; i < lines.length; i++) {
      content += lines[i].attributes.content.value;
    }
    let blocks = this.breakContentToLines(this.dropSpans(content), false); // we get rid of the spans
    console.warn('The BLOCKS');
    console.log(blocks);
    for (let i = 0; i < blocks.length; i++) {
      // if it start with a p tag we want to marge them together
      if (blocks[i].substring(0, 3) === '<p>') {
        blocks[i] = blocks[i].replace(/<p>/g, '');
        blocks[i] = blocks[i].replace(/<\/p>/g, '');
        if ( p === '') {
          p += '<p>' + blocks[i];
        } else {
          p += ' ' + blocks[i];
        }
        if ( i === blocks.length - 1 ) { // it is the last line
          res += p + '</p>';
        }
      } else {
        if (p !== '') {
          res += p + '</p>';
          p = '';
        }
        res += blocks[i];
      }
    }
    return res;
  }


  stripHTMLTags(html, locator = false): string {
    /*    console.log('input');
        console.log(html);*/
    if (locator) {
      html = html.replace(/<\/li><li>/g, '-~-'); // we need to know the location of li tags
      html = html.replace(/<br>/g, ' '); // stripping the br tags removes the space between the words
    }
    html = html.replace(/<sub>/g, ':-SUB-:');
    html = html.replace(/<\/sub>/g, ':-SUBC-:');
    html = html.replace(/<sup>/g, ':-SUP-:');
    html = html.replace(/<\/sup>/g, ':-SUPC-:');

    html = html.replace(/<(?:.|\n)*?>/gm, '');

    html = html.replace(/:-SUB-:/g, '<sub>');
    html = html.replace(/:-SUBC-:/g, '</sub>');
    html = html.replace(/:-SUP-:/g, '<sup>');
    html = html.replace(/:-SUPC-:/g, '</sup>');

    return html;

  }

  getStyle(content): Array<string> {
    let style = ['', ''];
    if (content.includes('</strong>')) {
      style[0] = '<strong>';
      style[1] = '</strong>';
    } else if (content.includes('</i>')) {
      style[0] = '<i>';
      style[1] = '</i>';
    } else if (content.includes('</em>')) {
      style[0] = '<em>';
      style[1] = '</em>';
    } else if (content.includes('</u>')) {
      style[0] = '<u>';
      style[1] = '</u>';
    }
    return style;
  }

  getFormat(content): Array<string> {
    let format = ['', ''];
    if (content.includes('</p>')) {
      format[0] = '<p>';
      format[1] = '</p>';
    } else if (content.includes('</ul>')) {
      format[0] = '<ul>';
      format[1] = '</ul>';
    } else if (content.includes('</ol>')) {
      format[0] = '<ol>';
      format[1] = '</ol>';
    } else if (content.includes('</h2>')) {
      format[0] = '<h2>';
      format[1] = '</h2>';
    } else if (content.includes('</h3>')) {
      format[0] = '<h3>';
      format[1] = '</h3>';
    } else if (content.includes('</h4>')) {
      format[0] = '<h4>';
      format[1] = '</h4>';
    }
    return format;
  }

  splitter(str, maxLength): Array<string> {
    let strs = [];
    while (str.length > maxLength ) {
      let pos = str.substring(0, maxLength ).lastIndexOf(' ');
      pos = pos <= 0 ? (maxLength ) : pos;
/*      // we want to igonre the -~- from the length TODO REMOVE
      const liCount = str.substring(0, pos).match(/-~-/g);
      let extraPermittedLenght = 0;
      if (liCount) {
        extraPermittedLenght = liCount.length * 3;
        pos = pos + extraPermittedLenght;
      }*/
      strs.push(str.substring(0, pos));
      let i = str.indexOf(' ', pos) + 1;
      if (i < pos || i > pos + maxLength ) {
        i = pos;
      }
      str = str.substring(i);
    }
    strs.push(str);
    return strs;
  }

  stripText(str) {
    return str.match(/<(?:.|\n)*?>/g).join('');
  }

  dropStyling(str): string {
    let text = str.replace(/<strong>/g, '');
    text = text.replace(/<\/strong>/g, '');

    text = text.replace(/<i>/g, '');
    text = text.replace(/<\/i>/g, '');

    text = text.replace(/<em>/g, '');
    text = text.replace(/<\/em>/g, '');

    text = text.replace(/<u>/g, '');
    text = text.replace(/<\/u>/g, '');

    text = text.replace(/<span>/g, '');
    text = text.replace(/<\/span>/g, '');

    return text;
  }

  dropSpans(str): string {
    let text = str.replace(/<span>/g, '');
    text = text.replace(/<\/span>/g, '');
    return text;
  }

  /**
   * remove the extra </ul> or </ol> tag added automatically by editor
   * @todo: show this support repetition
   */
  fixTags(str): string {
    if (str.includes('<ul>') || str.includes('<ol>')) {
      console.error('when input it is');
      console.log(str);
      // get rid of empty tags
      str = str.replace(/(<br>){2,}/g, '');
      str = str.replace(/<li><br>/g, '<li>');
      str = str.replace(/<br><\/li>/g, '</li>');
      str = str.replace(/<\/li><\/li>/g, '</li>');
      str = str.replace(/<li><li>/g, '<li>');
      str = str.replace(/<li><\/li>/g, '');
      str = str.replace(/<ul><\/ul>/g, '');
      str = str.replace(/<ol><\/ol>/g, '');

      let ulOpenCount = str.match(/<ul>/g);
      let ulCloseCount = str.match(/<\/ul>/g);
      let olOpenCount = str.match(/<ol>/g);
      let olCloseCount = str.match(/<\/ol>/g);
      let liOpenCount = str.match(/<li>/g);
      let liCloseCount = str.match(/<\/li>/g);

      if (ulCloseCount !== ulOpenCount) {
        let fo = str.indexOf('<ul>');
        let fc = str.indexOf('</ul>');
        let so = str.indexOf('<ul>', fo + 4);
        // let sc = str.indexOf('</ul>', fc + 5);
        if (so !== -1 && fc !== -1 && so < fc) {
          str = str.substr(0, so) + str.substr(so + 4);
        }
      }

      if (olCloseCount !== olOpenCount) {
        let fo = str.indexOf('<ol>');
        let fc = str.indexOf('</ol>');
        let so = str.indexOf('<ol>', fo + 4);
        // let sc = str.indexOf('</ol>', fc + 5);
        if (so !== -1 && fc !== -1 && so < fc) {
          str = str.substr(0, so) + str.substr(so + 4);
        }
      }
      if (liCloseCount !== liOpenCount) {
        let fo = str.indexOf('<li>');
        let fc = str.indexOf('</li>');
        let so = str.indexOf('<li>', fo + 4);
        // let sc = str.indexOf('</ol>', fc + 5);
        if (so !== -1 && fc !== -1 && so < fc) {
          str = str.substr(0, so) + str.substr(so + 4);
        }
      }
/*      console.error('when output it is');
      console.log(str);*/
    }
    return str;
  }

  /**
   *  Returns the similarity of two given text
   */
  similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let costs = [];
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0)
          costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            }
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) {
        costs[s2.length] = lastValue;
      }
    }
    return costs[s2.length];
  }

}
