/**
 * Benutzerverwaltung
 * 
 * Attila Németh, UBG
 * 30.04.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms'
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

import {MatSnackBar} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {PageEvent} from '@angular/material/paginator';

import {UbgOrgUser} from '../../../model/org_user';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgPriorityStructure} from '../../../model/priority_structure';
import {UbgPriorityStructureService} from '../../../services/priority_structure.service';
import {UbgMandant} from '../../../model/mandant';
import {UbgMandantService} from '../../../services/mandant.service';
import {UbgConfirmComponent} from '../../../../ubg_components/confirm/components/confirm.component';
import {UbgProjectService} from '../../../services/project_service';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class UbgOrgUserAdminComponent implements OnInit {
  
  orgUsers: Array<UbgOrgUser>
  orgUsersSelected: Array<UbgOrgUser>
  orgUsersChecked: {}
  orgUsersChanged: {}
  orgUsersAllChecked: boolean = false
  orgUserUpdatingId: string = ''
  orgUserUpdatingStatus: string = ''
  orgUserErrorField: string = ''
  orgUsersToUpdate: Array<UbgOrgUser> = []
  orgUsersUpdateLength: number = 0
  orgUsersLength: number = 0
  structures: Array<UbgPriorityStructure>
  mandants: Array<UbgMandant>
  isLoading: boolean = false
  hasError: boolean = false
  isSelecting: boolean = false
  displayedColumns = ['checkbox', 'title', 'lvkv', 'email', 'anrede', 'vorname', 'nachname', 'freier_name', 'mandant', 'structure', 'operations']
  emailFormControl = new FormControl(null, {
    validators: [
      Validators.required,
      Validators.email
    ],
  });
  limit: number = 50
  offset: number = 0
  searchString: string = ''
  
  constructor(private router: Router,
                private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private service: UbgOrgUserService,
                private structure: UbgPriorityStructureService,
                private mandant: UbgMandantService,
                private project: UbgProjectService) {
    this.orgUsersChecked = {};
    this.orgUsersChanged = {};
  }
  
  ngOnInit() {
    this.loadOrgUsers();
  }
  
  loadOrgUsers() {
    this.isLoading = true;
    let filter: {
      name?: string
    } = {};
    if (this.searchString !== '') {
      filter.name = this.searchString;
    }
    this.service.getLimited(this.limit, this.offset, filter).then((response: {
      data: Array<UbgOrgUser>
      meta: {
        length: number
      }
    }) => {
      this.orgUsers = response.data;
      this.orgUsersLength = response.meta.length;
      this.updateOrgUsersSelected();
      this.structure.getAll().then((response: Array<UbgPriorityStructure>) => {
        this.structures = response;
        this.mandant.getAll().then((response: Array<UbgMandant>) => {
          this.mandants = response;
          this.isLoading = false;
          this.hasError = false;
          this.orgUsersAllChecked = false;
        });
      }).catch(() => {
        this.isLoading = false;
        this.hasError = true;
      });
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  updateOrgUsersSelected() {
    this.isSelecting = true;
    let newSelected = [];
    for (let i in this.orgUsers) {
      if (this.orgUserisSelected(this.orgUsers[i])) {
        newSelected.push(this.orgUsers[i]);
      }
    }
    let newOrgUser: UbgOrgUser = this.service.getEmpty();
    newOrgUser.relationships.mandant.data.push({
      id: null,
      type: 'ubg_mandant--ubg_mandant',
      attributes: {
        title: '',
      },
      relationships: {
        struktur: {
          data: []
        },        
      },
    });
    newOrgUser.relationships.struktur.data.push({
      id: null,
      type: 'ubg_priority_structure--ubg_priority_structure',
      attributes: {
        title: '',
      },
      relationships: {
        gruppe: {
          data: [],
        }
      }
    });
    newSelected.push(newOrgUser);
    this.orgUsersSelected = newSelected;
    this.isSelecting = false;
  }
  
  private orgUserisSelected(orgUser: UbgOrgUser): boolean {
    return true;
  }
  
  private updateOrgUsersAllChecked() {
    this.orgUsersAllChecked = true;
    for (let i in this.orgUsersSelected) {
      if (this.orgUsersSelected[i].id !== undefined) {
        if (this.orgUsersChecked[this.orgUsersSelected[i].id] === undefined) {
          this.orgUsersAllChecked = false;
        }
        else if (this.orgUsersChecked[this.orgUsersSelected[i].id] === false) {
          this.orgUsersAllChecked = false;
        }
      }
    }
  }
  
  private toggleAllCheck() {
    if (!this.orgUsersAllChecked) {
      this.orgUsersChecked = {};
      this.orgUsersAllChecked = false;
    }
    else {
      this.orgUsersChecked = {};
      for (let i in this.orgUsersSelected) {
        if (this.orgUsersSelected[i].id !== undefined) {
          this.orgUsersChecked[this.orgUsersSelected[i].id] = true;
        }
      }
      this.orgUsersAllChecked = true;
    }
  }
  
  updateOrgUser(orgUser: UbgOrgUser) {
    this.orgUserUpdatingId = orgUser.id;
    this.orgUserUpdatingStatus = 'help';
    this.orgUsersChanged = {};
    if (orgUser.id === undefined) {
      orgUser.type = 'ubg_org_user--gliederung';
      this.create(orgUser);
    }
    else {
      this.update(orgUser);
    }
  }
  
  validateOrgUser(orgUser: UbgOrgUser): void {
    this.orgUserErrorField = '';
    this.orgUsersChanged[orgUser.id] = true;
    if (orgUser.attributes.title == '' || orgUser.attributes.title == undefined) {
      this.orgUserErrorField = 'title';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.mandant.data === undefined) {
      this.orgUserErrorField = 'mandant';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.mandant.data.length === 0) {
      this.orgUserErrorField = 'mandant';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.mandant.data[0].id === null) {
      this.orgUserErrorField = 'mandant';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.struktur.data === undefined) {
      this.orgUserErrorField = 'struktur';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.struktur.data.length === 0) {
      this.orgUserErrorField = 'struktur';
      this.orgUsersChanged[orgUser.id] = false;
    }
    if (orgUser.relationships.struktur.data[0].id === null) {
      this.orgUserErrorField = 'struktur';
      this.orgUsersChanged[orgUser.id] = false;
    }
    let structureOk = false;
    for (let i in this.mandants) {
      if (orgUser.relationships.mandant.data[0].id == this.mandants[i].id) {
        for (let delta in this.mandants[i].relationships.struktur.data) {
          if (this.mandants[i].relationships.struktur.data[delta].id == orgUser.relationships.struktur.data[0].id) {
            structureOk = true;
          }
        }
      }
    }
    if (!structureOk) {
      this.orgUserErrorField = 'struktur';
      this.orgUsersChanged[orgUser.id] = false;
    }
  }
  
  isOrgUserChanged(orgUser: UbgOrgUser) {
    if (this.orgUsersChanged[orgUser.id] === undefined) {
      return false;
    }
    else if (this.orgUsersChanged[orgUser.id] === false) {
      return false;
    }
    else if (this.orgUsersChanged[orgUser.id] === true) {
      return true;
    }
    else {
      console.error(this.orgUsersChanged[orgUser.id]);
    }
  }
  
  private create(orgUser: UbgOrgUser) {
    console.info('Gliederung wird erstellt...');
    this.isLoading = true;
    this.orgUserErrorField = null;
    this.orgUserUpdatingId = null;
    this.service.create(orgUser).then((response: UbgOrgUser) => {
      console.info('Gliederung wurde erstellt');
      this.loadOrgUsers();
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
  private update(orgUser: UbgOrgUser) {
    console.info('Gliederung wird aktualisiert...');
    this.isLoading = true;
    this.orgUserErrorField = null;
    this.orgUserUpdatingId = null;
    this.service.update(orgUser).then((response: UbgOrgUser) => {
      console.info('Gliederung wurde aktualisiert.');
      this.loadOrgUsers();
    }).catch(() => {
      this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
  checkedCount(): number {
    return Object.keys(this.orgUsersChecked).length;
  }
  
  addOrgUserToMandant(event: MatSelectChange) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die ausgewählten Gliederungen zum Mandant hinzufügen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let orgUsers = [];
        for (let i in this.orgUsersChecked) {
          if (this.orgUsersChecked[i]) {
            for (let j in this.orgUsers) {
              if (this.orgUsers[j].id == i) {
                let mandantIds = {};
                mandantIds[event.value] = true;
                for (let delta in this.orgUsers[j].relationships.mandant.data) {
                  mandantIds[this.orgUsers[j].relationships.mandant.data[delta].id] = true;
                }
                let mandants = [];
                for (let id in mandantIds) {
                  for (let k in this.mandants) {
                    if (this.mandants[k].id == id) {
                      mandants.push(this.mandants[k]);
                    }
                  }
                }
                this.orgUsers[j].relationships.mandant.data = mandants;
                orgUsers.push(this.orgUsers[j]);
              }
            }
          }
        }
        this.orgUsersToUpdate = orgUsers;
        this.bulkUpdate();
      }
      else {
        this.orgUsersChecked = {};
      }
    });
  }
  
  removeOrgUserFromMandant(event: MatSelectChange) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die ausgewählten Gliederungen vom Mandant entfernen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let orgUsers = [];
        for (let i in this.orgUsersChecked) {
          if (this.orgUsersChecked[i]) {
            for (let j in this.orgUsers) {
              if (this.orgUsers[j].id == i) {
                let mandants = [];
                for (let delta in this.orgUsers[j].relationships.mandant.data) {
                  if (this.orgUsers[j].relationships.mandant.data[delta].id !== event.value) {
                    mandants.push(this.orgUsers[j].relationships.mandant.data[delta]);
                  }
                }
                this.orgUsers[j].relationships.mandant.data = mandants;
                orgUsers.push(this.orgUsers[j]);
              }
            }
          }
        }
        this.orgUsersToUpdate = orgUsers;
        this.bulkUpdate();
      }
    });
  }
  
  addOrgUserToStructure(event: MatSelectChange) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die ausgewählten Gliederungen zur Struktur hinzufügen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let orgUsers = [];
        for (let i in this.orgUsersChecked) {
          if (this.orgUsersChecked[i]) {
            for (let j in this.orgUsers) {
              if (this.orgUsers[j].id == i) {
                let structureIds = {};
                structureIds[event.value] = true;
                for (let delta in this.orgUsers[j].relationships.struktur.data) {
                  structureIds[this.orgUsers[j].relationships.struktur.data[delta].id] = true;
                }
                let structures = [];
                for (let id in structureIds) {
                  for (let k in this.structures) {
                    if (this.structures[k].id == id) {
                      structures.push(this.structures[k]);
                    }
                  }
                }
                this.orgUsers[j].relationships.struktur.data = structures;
                orgUsers.push(this.orgUsers[j]);
              }
            }
          }
        }
        this.orgUsersToUpdate = orgUsers;
        this.bulkUpdate();
      }
      else {
        this.orgUsersChecked = {};
      }
    });
  }
  
  removeOrgUserFromStructure(event: MatSelectChange) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die ausgewählten Gliederungen von der Struktur entfernen?',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let orgUsers = [];
        for (let i in this.orgUsersChecked) {
          if (this.orgUsersChecked[i]) {
            for (let j in this.orgUsers) {
              if (this.orgUsers[j].id == i) {
                let structures = [];
                for (let delta in this.orgUsers[j].relationships.struktur.data) {
                  if (this.orgUsers[j].relationships.struktur.data[delta].id !== event.value) {
                    structures.push(this.orgUsers[j].relationships.struktur.data[delta]);
                  }
                }
                this.orgUsers[j].relationships.struktur.data = structures;
                orgUsers.push(this.orgUsers[j]);
              }
            }
          }
        }
        this.orgUsersToUpdate = orgUsers;
        this.bulkUpdate();
      }
    });
  }
  
  bulkUpdate() {
    this.orgUsersUpdateLength = this.orgUsersToUpdate.length;
    this.bulkUpdateStep().then(() => {
      this.orgUsersUpdateLength = -1;
      this.orgUsersChecked = {};
      this.loadOrgUsers();
    });
  }
  
  bulkUpdateStep() {
    let promise = new Promise((resolve, reject) => {
      if (this.orgUsersToUpdate.length > 0) {
        let orgUser = this.orgUsersToUpdate[0];
        this.service.update(orgUser).then((response: UbgOrgUser) => {
          let orgUsers = [];
          for (let i in this.orgUsersToUpdate) {
            if (this.orgUsersToUpdate[i].id !== response.id) {
              orgUsers.push(this.orgUsersToUpdate[i]);
            }
          }
          this.orgUsersToUpdate = orgUsers;
          this.bulkUpdateStep().then(() => {
            resolve();
          });
        }).catch((error: HttpErrorResponse) => {
          if (error.status === 422) {
            if (error.error !== undefined && error.error['errors'] !== undefined) {
              for (let i in error.error['errors']) {
                if (error.error['errors'][i]['detail'] !== undefined) {
                  this.snackbar.open(error.error['errors'][i]['detail'], null, {
                    duration: 15000,
                  })
                }
              }
            }
          }
          else {
            this.bulkUpdateStep().then(() => {
              resolve();
            });
          }
        });
      }
      else {
        resolve();
      }  
    });
    return promise;
  }
  
  orgUsersUpdatePercent(): number {
    return (this.orgUsersUpdateLength - this.orgUsersToUpdate.length) / this.orgUsersToUpdate.length * 100;
  }
  
  toProject() {
    this.router.navigate(['project/' + this.project.activeProjectId]);
  }
  
  page(event: PageEvent) {
    this.offset = event.pageIndex * this.limit;
    this.loadOrgUsers();
  }
  
  updateFilter() {
    this.loadOrgUsers();
  }
  
  clearSearchString() {
    this.searchString = '';
    this.loadOrgUsers();
  }
  
  canOrgUserBeDeleted(orgUser: UbgOrgUser) {
    if (orgUser.relationships.struktur.data === null) {
      return true;
    }
    if (orgUser.relationships.struktur.data.length == 0) {
      return true;
    }
    return false;
  }
  
  removeOrgUser(orgUser: UbgOrgUser) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie die ausgewählte Gliederung vom System löschen?',
      hint: 'Diese Operation kann nicht rückgängig gemacht werden',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isLoading = true;
        this.service.delete(orgUser).then(() => {
          this.loadOrgUsers();
        }).catch(() => {
          this.snackbar.open('Ihre Änderungen können nicht gespeichert werden, bitte versuchen Sie es später', null, {
            duration: 15000,
          });
          this.isLoading = false;
        });
      }
    });
  }
  
  availableStructures(orgUser: UbgOrgUser): Array<UbgPriorityStructure> {
    let structures: Array<UbgPriorityStructure> = [];
    for (let i in this.structures) {
      let isFree = true;
      for (let j in orgUser.relationships.struktur.data) {
        if (this.structures[i].id == orgUser.relationships.struktur.data[j].id) {
          isFree = false;
        }
      }
      if (isFree) {
        structures.push(this.structures[i]);
      }
    }
    return structures;
  }
  
  addStructureToOrgUser(orgUser: UbgOrgUser, event: MatSelectChange ) {
    for (let i in this.structures) {
      if (this.structures[i].id == event.value) {
        orgUser.relationships.struktur.data.push(this.structures[i]);
      }
    }
    this.validateOrgUser(orgUser);
  }
  
  changeFreierName(orgUser: UbgOrgUser) {
    if (orgUser.attributes.freier_name === undefined) {
      orgUser.attributes.freier_name = true;
    }
    else if (orgUser.attributes.freier_name === false) {
      orgUser.attributes.freier_name = true;
    }
    else {
      orgUser.attributes.freier_name = false;
    }
    this.validateOrgUser(orgUser);
  }

}
