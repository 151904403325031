/**
 * Gruppe
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {UbgOrgUser} from './org_user';
 
export class UbgGroup {
  
  id: string
  type: string = 'ubg_group--ubg_group'
  attributes: {
    title: string
    kurzbezeichnung: string
  }
  
}