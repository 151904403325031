/**
 * Antragssteller-Dialog
 * 
 * Attila Németh, UBG
 * 11.09.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgProposal} from '../../../../../../model/proposal';
import {UbgOrgUser} from '../../../../../../model/org_user';
import {UbgOrgUserService} from '../../../../../../services/org_user.service';
import {UbgAutocompleteOption} from '../../../../../../../ubg_components/autocomplete/model/option';
import {UbgProjectService} from '../../../../../../services/project_service';
import {UbgProject} from '../../../../../../model/project';

@Component({
  templateUrl: './antragssteller.component.html',
//  styleUrls: ['./fassung.component.scss'],
})
export class UbgAntragsstellerDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                proposal: UbgProposal,
              }, private projectService: UbgProjectService,
                  private orgUserService: UbgOrgUserService) {
  }
  
  orgUsersOptions: Array<UbgAutocompleteOption> = []
  orgUsers: Array<UbgOrgUser>
  hasError: boolean = false
  searchString: string = ''
  
  ngOnInit() {
    this.projectService.get(this.projectService.activeProjectId).then((project: UbgProject) => {
      this.orgUserService.getNamesByStructure(project.relationships.priority_structure.data.id).then((response: {
        data: Array<UbgOrgUser>
      }) => {
        this.orgUsers = response.data;
        let newOptions: Array<UbgAutocompleteOption> = []
        for (let i in response.data) {
          let label:string = response.data[i].attributes.title;
          if (response.data[i].attributes['kurzbezeichnung'] !== '') {
            label = response.data[i].attributes['kurzbezeichnung'] + ' ' + label;
          }
          let isFree = true;
          for (let j in this.data.proposal.relationships.org_user.data) {
            if (response.data[i].id == this.data.proposal.relationships.org_user.data[j].id) {
              isFree = false;
            }
          }
          if (isFree) {
            let newOption = {
              id: response.data[i].id,
              label: label,
            };
            newOptions.push(newOption);
          }
        }
        this.orgUsersOptions = newOptions;
      });
    });
  }
  
  getGliederungName(gliederung: UbgOrgUser) {
    for (let i in this.orgUsers) {
      if (this.orgUsers[i].id == gliederung.id) {
        let label:string = this.orgUsers[i].attributes.title;
        if (this.orgUsers[i].attributes['kurzbezeichnung'] !== '') {
          label = this.orgUsers[i].attributes['kurzbezeichnung'] + ' ' + label;
        }
        return label;
      }
    }
    return '...';
  }
  
  orgUserSelected(orgUserId: string) {
    let newOrgUsers: Array<UbgOrgUser> = [];
    for (let i in this.data.proposal.relationships.org_user.data) {
      newOrgUsers.push(this.data.proposal.relationships.org_user.data[i]);
    }
    for (let i in this.orgUsers) {
      if (this.orgUsers[i].id == orgUserId) {
        newOrgUsers.push(this.orgUsers[i]);
      }
    }
    this.data.proposal.relationships.org_user.data = newOrgUsers;
    this.searchString = '';
  }
  
  removeGliederung(gliederung: UbgOrgUser) {
    let newOrgUsers: Array<UbgOrgUser> = [];
    for (let i in this.data.proposal.relationships.org_user.data) {
      if (this.data.proposal.relationships.org_user.data[i].id !== gliederung.id) {
        newOrgUsers.push(this.data.proposal.relationships.org_user.data[i]);
      }
    }
    this.data.proposal.relationships.org_user.data = newOrgUsers;
  }
  
}
