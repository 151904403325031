/**
 * Tab-Dialog-Service
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UbgTabDialogService {
  
  activeTab: number
  count: number = 0;
  
  constructor() {
    this.activeTab = 0;
  }
  
  setActiveTab(id) {
    this.activeTab = id;
  }
  
  push() {
    this.count++;
  }
  
}
