/**
 * Gliederung-Komponent
 * 
 * Attila Németh, UBG
 * 23.09.2019
 */
 
import {Component, Input} from '@angular/core';

import {UbgOrgUser} from '../../../model/org_user';
import {UbgOrgUserService} from '../../../services/org_user.service';
import {UbgProjectService} from '../../../services/project_service';

@Component({
  selector: 'antrag-org-user-display',
  templateUrl: './org_user.component.html',
})
export class AntragOrgUserDisplayComponent {

  @Input() set orgUser(orgUser: UbgOrgUser) {
    this.orgUserData = orgUser;
    this.orgUserService.getLongNameBy(this.projectService.activeProjectId, orgUser).then((response: string) => {
      this.orgUserDisplay = response;
    }).catch(() => {
      console.warn('Langer Name kann nicht geladen werden');
      this.orgUserDisplay = orgUser.attributes.title;
    });
  }
  orgUserData: UbgOrgUser = null
  orgUserDisplay: string = null

  constructor(private orgUserService: UbgOrgUserService,
                private projectService: UbgProjectService) {}
  
}
