/**
 * Thema-Dialog
 * 
 * Attila Németh, UBG
 * 26.07.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';

import {UbgProposalStructured} from '../../../../../../../../../model/proposal_structured';
import {UbgThema} from '../../../../../../../../../model/taxonomies/thema';
import {UbgThemaService} from '../../../../../../../../../services/taxonomies/thema.service';

@Component({
  templateUrl: './thema.component.html',
})
export class UbgProposalSonstigeThemaDialogComponent implements OnInit {
  
  themen: Array<UbgThema> = []
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                proposals: Array<UbgProposalStructured>
                themaId: string
              },
              private service: UbgThemaService) {
  }
              
  ngOnInit() {
    this.loadThemen();
    for (let i in this.data.proposals) {
      if (this.data.proposals[i].relationships.thema !== undefined) {
        this.data.themaId = this.data.proposals[i].relationships.thema.data.id;
      }
    }
  }  
  
  loadThemen() {
    this.service.getAll().then((response: Array<UbgThema>) => {
      this.themen = response;
    });
  }
  
}
