/**
 * UBG-Komponente für Angular-Webseiten
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';

import {UbgTabDialogTabsComponent} from './components/tab_dialog/tabs/tabs.component';
import {UbgTabDialogTabItemComponent} from './components/tab_dialog/tabs/item.component';
import {UbgTabDialogDialogComponent} from './components/tab_dialog/dialog/dialog.component';
import {UbgTabDialogDialogItemComponent} from './components/tab_dialog/dialog/item.component';
import {UbgBannerComponent} from './components/banner/banner.component';
import {UbgBannerMessageComponent} from './components/banner/items/message.component';
import {UbgBannerActionsComponent} from './components/banner/items/actions.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
  ],
  declarations: [
    UbgTabDialogTabsComponent,
    UbgTabDialogTabItemComponent,
    UbgTabDialogDialogComponent,
    UbgTabDialogDialogItemComponent,
    UbgBannerComponent,
    UbgBannerMessageComponent,
    UbgBannerActionsComponent,
  ],
  exports: [
    UbgTabDialogTabsComponent,
    UbgTabDialogTabItemComponent,
    UbgTabDialogDialogComponent,
    UbgTabDialogDialogItemComponent,
    UbgBannerComponent,
    UbgBannerMessageComponent,
    UbgBannerActionsComponent,
  ],
  entryComponents: [
  ],
})
export class UbgComponentsModule {

  constructor() {}

}