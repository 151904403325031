
import {AntragTypeModel} from './taxonomies/antrag_type.model';
import {UbgZiffer} from './ziffer';
import {AntragStatusModel} from './taxonomies/antrag_status.model';

export class UBGAntragModel {
  id?: string;
  type: string = 'ubg_antrag--ubg_antrag';
  attributes: {
    title: string;
    description?: string;
    letter: string;
    sortierung?: string;
  };
  relationships: {
    ubg_antrag_type: {
      data: AntragTypeModel,
    },
    status?: {
      data: AntragStatusModel,
    },
    ziffers: {
      data: Array<UbgZiffer>,
    }
  };

}
