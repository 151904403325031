import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {FormControl, NgForm, Validators} from '@angular/forms';

import {MatSelectChange} from '@angular/material/select';

import {UbgProject} from '../../../model/project';
import {AntragTypeModel} from '../../../model/taxonomies/antrag_type.model';
import {debounceTime} from 'rxjs/operators';
import {UBGAntragService} from '../../../services/antrag.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UBGAntragModel} from '../../../model/antrag.model';
import {UbgMandant} from '../../../model/mandant';
import {AntragTypeService} from '../../../services/taxonomies/antrag_type.service';
import {UbgPriorityStructure} from '../../../model/priority_structure';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Subject} from 'rxjs';

import {DatePipe} from '@angular/common';
import {UbgMandantService} from '../../../services/mandant.service';
import {MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UbgProjectService} from '../../../services/project_service';
import {UbgPriorityStructureService} from '../../../services/priority_structure.service';
import {UbgContentaUserService} from '../../../../contenta/user/services/user.service';
import {UbgConfirmComponent} from '../../../../ubg_components/confirm/components/confirm.component';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})

// @todo i just copied project add here because of the time pressure!! We need to marge them together and remove the code duplication
export class ProjectEditComponent implements OnInit {

  @ViewChild( 'projectForm' ) projectForm: NgForm;
  @ViewChild( 'antragTypeForm' ) projectAntragForm: NgForm;
  @ViewChild( 'priorityForm' ) priorityForm: NgForm;
  @ViewChild( 'mandantForm' ) mandantForm: NgForm;

  pipe = new DatePipe('de-DE');

  displayedColumns = ['letter', 'title', 'type', 'sort', 'star'];

  antragTypes: AntragTypeModel[];
  selectedAntragType: AntragTypeModel;
  antragSortingTypes = [{title: 'Klassisch', value: 'classic'}, {title: 'Themen', value: 'theme'}];
  selectedAntragSorting = 'classic';

  antragModel: UBGAntragModel;
  antrags: UBGAntragModel[]; // this is the list of the antrag user make but it is not submitted to the server and has no uuid
  submitedAntrage: UBGAntragModel[];

  priorityStructures: UbgPriorityStructure[];
  selectedPriorityStructure: UbgPriorityStructure;

  mandants: UbgMandant[];
  selectedMandant: UbgMandant;

  projectModel: UbgProject;

  antragLetterValidator = new FormControl('', [Validators.required, Validators.maxLength(1)]);
  antragTypeValidator = new FormControl('', [Validators.required]);
  antragDescriptionValidator = new FormControl('');
  projectTitleValidator = new FormControl('', [Validators.required]);
  projectBeginValidator = new FormControl('', [Validators.required]);
  projectEndValidator = new FormControl('', [Validators.required]);
  projectStartVotingValidator = new FormControl('', [Validators.required]);
  projectEndVotingValidator = new FormControl('', [Validators.required]);
  projectPriorityValidator = new FormControl('', [Validators.required]);
  mandantValidator = new FormControl('', [Validators.required]);

  minDate: Date;
  maxDate: Date;

  isLoading = false;
  hasError = false;
  processing = false;
  isUpdatingAntrag: boolean = false

  loadedState: number = 0; // we need to know when the form and project is loaded so that we can set the selected values for select boxes
  loadingObservable = new Subject<number>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private projectService: UbgProjectService,
              private priorityService: UbgPriorityStructureService,
              private antragTypeService: AntragTypeService,
              private antragService: UBGAntragService,
              private mandantService: UbgMandantService,
              private userService: UbgContentaUserService,
  ) {

    this.projectService.setActiveProjectId(this.route.snapshot.paramMap.get('id'));


    this.antragModel = {
      type: 'ubg_antrag--ubg_antrag',
      attributes: {
        title: '',
        description: '',
        letter: '',
      },
      relationships: {
        ubg_antrag_type: {
          data: null,
        },
        status: {
          data: null,
        },
        ziffers: {
          data: null,
        }
      }
    };
    this.antrags = []; // this is the list of the antrag user make but it is not submitted to the server and has no uuid
    this.submitedAntrage = [];

    this.projectModel = new UbgProject;
    this.projectModel.type = 'ubg_project--ubg_project';
    this.projectModel.attributes = {
      title: '',
      start_date: '',
      end_date: '',
      start_voting_date: '',
      end_voting_date: '',
      description: '',
      ergebnis_anzeigen: null,
    };
    this.projectModel.relationships = {
      priority_structure: {
        data: this.selectedPriorityStructure,
      },
      mandant: {
        data: this.selectedMandant,
      },
      antrage: {
        data: this.submitedAntrage,
      }
    };
    let today = new Date();
    let todayYear = today.getFullYear();
    let todayMonth = today.getMonth();
    let todayDay = today.getDate();
    this.minDate = new Date(todayYear - 2, 1, 1);
    this.maxDate = new Date(todayYear + 1, 12, 31);
  }


  ngOnInit() {
    // this.loadOptionsPriorities();
    this.loadOptionsAntragTypes();
    this.loadOptionsMandants();
    this.loadProject();

    this.loadingObservable.pipe(debounceTime(500)).subscribe(resp => {
      console.log('the state is');
      console.log(this.loadedState);
      if (resp !== undefined && this.loadedState === 2) {
        this.loadProjectMandant();
        this.loadingObservable.unsubscribe();
      }
    });
  }

  loadProject() {
    this.isLoading = true;
    this.projectService.get(this.projectService.activeProjectId).then((response: UbgProject) => {
      this.projectModel = response;
      this.priorityService.get(this.projectModel.relationships.priority_structure.data.id).then((response: UbgPriorityStructure) => {
        this.projectModel.relationships.priority_structure.data = response;
      });
      this.loadExistingAntraege();
      this.loadingObservable.next(this.loadedState++);
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }

  loadExistingAntraege() {
    for (let i in this.projectModel.relationships.antrage.data) {
      this.antragService.get(this.projectModel.relationships.antrage.data[i].id).then((response: UBGAntragModel) => {
        let antraege: Array<UBGAntragModel> = this.antrags;
        antraege.push(response);
        let unSorted = {};
        for (let i in antraege) {
          unSorted[antraege[i].attributes.letter] = i;
        }
        let sorted = {};
        Object.keys(unSorted).sort().forEach(function(key) {
          sorted[key] = unSorted[key];
        });
        let newAntraege = [];
        for (let i in sorted) {
          newAntraege.push((antraege[sorted[i]]));
        }
        this.antrags = newAntraege;
        this.submitedAntrage = newAntraege;
      });
    }
  }

  loadOptionsAntragTypes() {
    this.antragTypeService.getAll().then((response: Array<AntragTypeModel>) => {
      this.antragTypes = response;
      console.log(response);

    }).catch(() => {
      console.log('error in getting antrag type list'); // TODO we need a error reporting
    });
  }
  loadOptionsMandants() {

    this.mandantService.getAll().then((response: Array<UbgMandant>) => {
      this.mandants = response;
      console.log(response);
      this.loadingObservable.next(this.loadedState++);
    }).catch(() => {
      console.log('error in getting mandants'); // TODO we need a error reporting
    });

  }
  loadOptionsPriorities() {

    this.priorityService.getAll().then((response: Array<UbgPriorityStructure>) => {
      this.priorityStructures = response;
      console.log(response);

    }).catch(() => {
      console.log('error in getting structures'); // TODO we need a error reporting
    });

  }
  loadOptionsPrioritiesByMandant(id) {
    this.priorityStructures = [];
    this.priorityService.getByMandant(id).then((response: Array<UbgPriorityStructure>) => {
      for (let i = 0 ; i < response.length; i++) {
        if (response[i].attributes) { // @todo we receive the priority id but not the content when the user has no right!
          this.priorityStructures.push(response[i]);
        }
      }
      console.log(response);
      this.loadProjectPriority(); // after loading the available option, we set the previously selected one
    }).catch(() => {
      console.log('error in getting structures'); // TODO we need a error reporting
    });

  }

  loadProjectMandant() {
    for ( let i = 0; i < this.mandants.length; i++ ) {
      if (this.mandants[i].id === this.projectModel.relationships.mandant.data.id) {
        this.selectedMandant = this.mandants[i];
        this.mandantValidator.setValue(this.selectedMandant);
        this.loadOptionsPrioritiesByMandant(this.selectedMandant.id);
      }
    }
  }

  loadProjectPriority() {
    for ( let i = 0; i < this.priorityStructures.length; i++ ) {
      if (this.priorityStructures[i].id === this.projectModel.relationships.priority_structure.data.id) {
        this.selectedPriorityStructure = this.priorityStructures[i];
        this.projectPriorityValidator.setValue(this.selectedPriorityStructure);
      }
    }
  }

  OnChangeMandant(e) {
    this.mandantValidator.setValue(e.value);
    this.selectedMandant = this.mandantValidator.value;
    this.loadOptionsPrioritiesByMandant(this.selectedMandant.id);
  }

  OnChangePriority(e) {
    this.projectPriorityValidator.setValue(e.value);
    this.selectedPriorityStructure = this.projectPriorityValidator.value;
  }

  OnChangeAntragType(e) {
    this.antragTypeValidator.setValue(e.value);
    this.selectedAntragType = this.antragTypeValidator.value;
  }


  getErrorAntragLetter() {
    return this.antragLetterValidator.hasError('required') ? 'Bitte geben Sie einen Buchstaben ein' :
      this.antragLetterValidator.hasError('invalid') ? 'Nicht gültig, nur ein Buchstabe' :
        '';
  }
  getErrorAntragType() {
    return this.antragTypeValidator.hasError('required') ? 'Bitte wählen Sie einen Wert aus' :
      '';
  }
  getErrorTitle() {
    return this.projectTitleValidator.hasError('required') ? 'Bitte geben Sie den Projekttitel ein' :
      '';
  }
  getErrorBegin() {
    return this.projectBeginValidator.hasError('required') ? 'Bitte wählen Sie ein Startdatum aus' :
      '';
  }
  getErrorEnd() {
    return this.projectEndValidator.hasError('required') ? 'Bitte wählen Sie ein Enddatum aus' :
      '';
  }
  getErrorStartVoting() {
    return this.projectStartVotingValidator.hasError('required') ? 'Bitte wählen Sie ein Datum aus' :
      '';
  }
  getErrorEndVoting() {
    return this.projectEndVotingValidator.hasError('required') ? 'Bitte wählen Sie ein Datum aus' :
      '';
  }
  getErrorPriority() {
    return this.projectPriorityValidator.hasError('required') ? 'Bitte wählen Sie ein Produktprioritätsstruktur' :
      '';
  }
  getErrorMandant() {
    return this.mandantValidator.hasError('required') ? 'Bitte wählen Sie ein Mandant' :
      '';
  }


  onSubmitProject() {
    this.processing = true;
    if (this.projectTitleValidator.valid &&
      this.projectBeginValidator.valid &&
      this.projectEndValidator.valid &&
      this.projectStartVotingValidator.valid &&
      this.projectEndVotingValidator.valid &&
      this.projectPriorityValidator.valid
    ) {
      this.postProject();
    } else {
      this.snackbar.open('Bitte überprüfen Sie alle erforderlichen Felder', null, {
        duration: 10000,
      });
      this.processing = false;
    }

  }



  postProject() {
    // Set the Project Relations
    this.projectModel.relationships.priority_structure.data = this.selectedPriorityStructure;
    this.projectModel.relationships.mandant.data = this.selectedMandant;
    this.projectModel.relationships.antrage.data = this.submitedAntrage;
    console.log(this.projectModel);
    // Now we create the project
    this.projectService.update(this.projectModel).then((response: UbgProject) => {
      console.log(response);
      this.processing = false;
      // redirect to the created project page
      this.router.navigate(['project/' + response.id]);
    }).catch(() => {
      console.log('ERROR in Project create');
    });
  }


  addAntrag() {
    if (this.antragLetterValidator.valid &&
      this.antragTypeValidator.valid
    ) {
      this.antragModel.relationships.ubg_antrag_type.data = this.selectedAntragType;
      this.antragModel.attributes.title = this.projectModel.attributes.title + ' ' + this.antragModel.attributes.letter;
      this.antragModel.attributes.sortierung = this.selectedAntragSorting;
      this.antragService.create(this.antragModel).then((response: UBGAntragModel) => {
        // we need to set back the type so it doesn't make problem in the table
        response.relationships.ubg_antrag_type.data = this.selectedAntragType;
        this.submitedAntrage = this.submitedAntrage.concat( {...response} );
      }).catch(() => {
        console.log('ERROR in Antrag create');
      });
    }
  }

  deleteAntrag(antrag) {
    const dialogRef = this.dialog.open(UbgConfirmComponent, {
      data: {
        question: 'Möchten Sie dieses Element wirklich löschen?',
        hint: 'Es kann nicht rückgängig gemacht werden!',
      }
    });
    dialogRef.afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.antragService.delete(antrag.id).then(() => {
          this.submitedAntrage = this.submitedAntrage.filter(
            function (element, index, array) {
              return (element.id !== antrag.id);
            }
          );
        }).catch((e) => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: PE-397', null, {
            duration: 15000,
          });
        });
      }
    });
  }

  openAntragDialog(antrag: UBGAntragModel) {
    const dialogRef = this.dialog.open(ProjectEditAntragDialogComponent, {
      width: '50%',
      data: {
        antrag: JSON.parse(JSON.stringify(antrag)),
        selectedAntragSorting: this.selectedAntragSorting,
        antragSortingTypes: this.antragSortingTypes
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result && result.antrag !== antrag) {
        antrag.attributes.description = result.antrag.attributes.description;
        antrag.attributes.letter = result.antrag.attributes.letter;
        antrag.attributes.sortierung = result.selectedAntragSorting;
        this.editAntrag(antrag);
      }
    });
  }

  editAntrag(antrag) {
    this.isUpdatingAntrag = true;
    this.antragService.update(antrag).then((response: UBGAntragModel) => {
      this.antragService.get(response.id).then((response: UBGAntragModel) => {
        let newAntraege: Array<UBGAntragModel> = [];
        for (let i in this.submitedAntrage) {
          if (this.submitedAntrage[i].id == response.id) {
            newAntraege.push(response);
          }
          else {
            newAntraege.push(this.submitedAntrage[i]);
          }
        }
        this.submitedAntrage = newAntraege;
        this.isUpdatingAntrag = false;
      });
    }).catch((e) => {
      this.snackbar.open('Ihre Änderung kann nicht gespeichert werden', null, {
        duration: 15000,
      });
    });
  }

  /*  antragReorder() {
        for ( let i = 0; i < this.antrags.length; i++) {
            this.antrags[i].attributes.index = i + 1;
        }
    }*/

  formatDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // we change the format of the material date junk
    this.projectModel.attributes[type] = this.pipe.transform(event.value, 'yyyy-MM-dd');
    return;
  }

  back() {
    this.router.navigate(['project/' + this.projectModel.id]);
  }
  
  editAntragPossible(antrag: UBGAntragModel): boolean {
    return this.userService.hasPermission('edit ubg_antrag');
  }

}

@Component({
  selector: 'app-project-edit-antrag-dialog',
  templateUrl: 'project-edit-antrag-dialog.component.html',
})
export class ProjectEditAntragDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ProjectEditAntragDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      antrag: UBGAntragModel
      selectedAntragSorting: string
    }) {
    
    }

  onDiscard(): void {
    this.dialogRef.close();
  }
  
  sortingSelected(event: MatSelectChange): void {
    this.data.selectedAntragSorting = event.value;
  }
}
