import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

// import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-leitantrag-proposal-confirmation-dialog',
  templateUrl: './leitantrag-proposal-confirmation-dialog.component.html',
  styleUrls: ['./leitantrag-proposal-confirmation-dialog.component.scss']
})
export class LeitantragProposalConfirmationDialogComponent {

  constructor(private _leitantragProposaldialogRef: MatDialogRef<LeitantragProposalConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public Editor = ClassicEditor;
  public Config = {
    language: 'de',
    toolbar: [
//      'heading',
//      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'undo',
      'redo' ]
  };
  onAdd(): void {
    this._leitantragProposaldialogRef.close(this.data.content);
  }

  onCancel(): void {
    this._leitantragProposaldialogRef.close();
  }

}
