import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule }	from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgScrollbarModule} from 'ngx-scrollbar';
import { ScrollToBottomDirective } from './components/utility/scroll-to-bottom.directive';
import { DiffMatchPatchModule } from 'ng-diff-match-patch';

import { ProjectRoutingModule } from './project-routing-module';

// materials section
import {
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatMenuModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  MatCardModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatDividerModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatStepperModule,
  MatPaginatorModule,
  MatBottomSheetModule,
  MatSortModule,
} from '@angular/material';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// ubg components
import { UbgContentaUserModule } from '../contenta/user/user.module';
import { UbgComponentsModule } from '../ubg_components/ubg_components.module';
import { UbgSidebarModule } from '../sidebar/sidebar.module';
import { UbgSelectEntityModule } from '../select_entity/select_entity.module';
import {UbgLayoutModule} from '../ubg_components/layout/layout.module';
import { UbgFabModule } from '../../modules/ubg_components/fab/fab.module';
import { UbgAutocompleteModule } from '../../modules/ubg_components/autocomplete/autocomplete.module';
import { UbgConfirmModule } from '../../modules/ubg_components/confirm/confirm.module';
import {UbgStatisticsModule} from '../../modules/ubg_components/statistics/statistics.module';

// components section
import { ProjectComponent } from './components/project/project.component';
import { ProjectAddComponent } from './components/project/project-add/project-add.component';
import {ProjectEditComponent, ProjectEditAntragDialogComponent} from './components/project/project-edit/project-edit.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { ProjectDetailComponent } from './components/project/project-detail/project-detail.component';
import { SidetabsComponent } from './components/utility/sidetabs/sidetabs.component';
import { AntragComponent } from './components/antrag/antrag.component';
import { AntragAddComponent } from './components/antrag-meine/antrag-add/antrag-add.component';
import { AntragListComponent } from './components/antrag-meine/antrag-list/antrag-list.component';
import { AntragMeineComponent } from './components/antrag-meine/antrag-meine.component';
import { LeitantragAddComponent } from './components/leitantrag/leitantrag-add/leitantrag-add.component';
import { AntrageEmpfehlungComponent } from './components/project/project-detail/antrage-empfehlung/antrage-empfehlung.component';
import { OperationsComponent } from './components/project/project-detail/antrage-empfehlung/operations/operations.component';
import { LeitantragNumberedComponent } from './components/leitantrag/leitantrag-numbered/leitantrag-numbered.component';
import { BeschlussComponent } from './components/beschluss/beschluss.component';
import { UbgStructureListComponent } from './components/structure/list/list.component';
import { UbgStructureEditComponent } from './components/structure/edit/edit.component';
import { UbgStructureTitleDialogComponent } from './components/structure/dialogs/title/title.component';
import { UbgStructureGroupDialogComponent } from './components/structure/dialogs/group/group.component';
import { UbgProjectOrgUserComponent } from './components/project/project-detail/org_user/org_user.component';
import { UbgProjectChangeStructureDialogComponent } from './components/project/project-detail/org_user/dialogs/change/change.component';
import { UbgSelectProjectComponent } from './components/select/select.component';
import {UbgProjectStatComponent} from './components/stat/stat.component';
import {UbgFilterComponent} from './components/filter/filter.component';
import {UbgProposalFallbackComponent} from './components/proposal/fallback/fallback.component';
import {UbgOrgUserAdminComponent} from './components/org_user/admin/admin.component';
import {UbgProposalAssignmentDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/assignment/assignment.component';
import {UbgMeineAntraegeComponent} from './components/project/project-detail/meine/meine.component';
import {UbgTextEditorDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/text/text.component';
import {UbgFassungEditorDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/fassung/fassung.component';
import {UbgZifferAssignmentDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/assignment/ziffer.component';
import { LeitantragProposalComponent } from './components/antrag-meine/leitantrag-proposal/leitantrag-proposal.component';
import {UbgProposalEditComponent} from './components/proposal/edit/edit.component';
import {UbgProposalEditItemComponent} from './components/proposal/edit/item.component';
import {UbgProposalGemeinsamDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/gemeinsam/gemeinsam.component';
import {UbgPrintConfirmDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/print/print.component';
import {UbgPrintBeschlussDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/print/beschluss.component';
import {UbgProjectAntragComponent} from './components/project/project-detail/antrage-empfehlung/antrag/antrag.component';
import {UbgProjectAntragLeitantragComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/leitantrag.component';
import {UbgProjectAntragSonstigeComponent} from './components/project/project-detail/antrage-empfehlung/antrag/sonstige/sonstige.component';
import {UbgProjectAntragLeitantragZifferComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/ziffer/ziffer.component';
import {UbgProjectAntragLeitantragProposalComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/proposal/proposal.component';
import {UbgProjectAntragLeitantragZifferTextComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/ziffer/text/text.component';
import {UbgProjectAntragLeitantragZifferMarkupComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/ziffer/markup/markup.component';
import {UbgProjectAntragEmpfehlungComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/empfehlung/empfehlung.component';
import {UbgProjectAntragProposalTextComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/proposal/text/text.component';
import {UbgProjectAntragProposalMarkupComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/proposal/markup/markup.component';
import {UbgProposalLeitantragEmpfehlungDialogComponent} from './components/project/project-detail/antrage-empfehlung/antrag/leitantrag/proposal/dialog/empfehlung/empfehlung.component';
import {UbgProjectAntragSonstigeProposalComponent} from './components/project/project-detail/antrage-empfehlung/antrag/sonstige/proposal/proposal.component';
import {UbgProposalSonstigeEmpfehlungDialogComponent} from './components/project/project-detail/antrage-empfehlung/antrag/sonstige/proposal/dialog/empfehlung/empfehlung.component';
import {AntragBlockComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/block/block.component';
import {AntragBlockTextComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/block/text.component';
import {AntragBlockEmpfehlungComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/block/empfehlung.component';
import {AntragBlockBegruendungComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/block/begruendung.component';
import {UbgProposalEmpfehlungUberweisungComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/empfehlung_uber/uberweisung.component';
import {UbgProjectEditComponent} from './components/project/project-edit/edit.component';
import {UbgProjectFormComponent} from './components/project/form/form.component';
import {UbgOrgUserPasswordsDialogComponent} from './components/project/project-detail/org_user/dialogs/passwords/passwords.component';
import {UbgMeineAntraegeItemComponent} from './components/project/project-detail/meine/item/item.component';
import {UbgProjectAntragZuweisungComponent} from './components/project/project-detail/antrage-empfehlung/antrag/elements/zuweisung/zuweisung.component';
import {UbgUserSettingsComponent} from './components/user/settings/settings.component';
import {ProposalReasonBottomSheetComponent} from './components/antrag-meine/proposal-reason-sheet/proposal-reason-sheet';
import {UbgProjectMarkupComponent} from './components/markup/markup.component';
import {UbgThemenListComponent} from './components/themen/list/list.component';
import {UbgThemaEditDialogComponent} from './components/themen/dialog/edit/edit.component';
import {UbgProposalSonstigeThemaDialogComponent} from './components/project/project-detail/antrage-empfehlung/antrag/sonstige/proposal/dialog/thema/thema.component';
import {UbgProjectDashboardComponent} from './components/project/dashboard/dashboard.component';
import {UbgProjectDashboardMeineComponent} from './components/project/dashboard/meine/meine.component';
import {UbgProjectDashboardOpenComponent} from './components/project/dashboard/open/open.component';
import {UbgProjectDashboardStatComponent} from './components/project/dashboard/stat/stat.component';
import {UbgProjectProtokolleComponent} from './components/project/protokolle/protokolle.component';
import {UbgProposalHeaderComponent} from './components/proposal/header/header.component';
import { LeitantragZifferEditableComponent } from './components/antrag-meine/leitantrag-proposal/leitantrag-ziffer-editable/leitantrag-ziffer-editable.component';
import { LeitantragProposalDiffComponent } from './components/antrag-meine/leitantrag-proposal/leitantrag-proposal-diff/leitantrag-proposal-diff.component';
import {UbgAntragsstellerDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/antragssteller/antragssteller.component';
import {UbgProjectDashboardStatPageComponent} from './components/project/dashboard/stat/stat.page.component';
import { LeitantragProposalConfirmationDialogComponent } from './components/antrag-meine/leitantrag-proposal/leitantrag-proposal-confirmation-dialog/leitantrag-proposal-confirmation-dialog.component';
import {UbgLeitantragFallbackComponent} from './components/antrag-meine/leitantrag-proposal/leitantrag-proposal-fallback/fallback.component';
import {AntragOrgUserDisplayComponent} from './components/antrag-meine/org_user/org_user.component';
import {UbgDeleteAntragDialogComponent} from './components/project/project-detail/antrage-empfehlung/dialogs/delete/delete.component';


@NgModule({
  exports: [
    // materials
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatSortModule,
  ],
  declarations: []
})
export class ProjectMaterialModule {}

@NgModule({
  declarations: [
    ProjectComponent,
    ProjectAddComponent,
    ProjectEditComponent,
    ProjectEditAntragDialogComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    SidetabsComponent,
    AntragComponent,
    AntragAddComponent,
    AntragListComponent,
    AntragMeineComponent,
    LeitantragAddComponent,
    LeitantragNumberedComponent,
    LeitantragProposalComponent,
    LeitantragZifferEditableComponent,
    LeitantragProposalDiffComponent,
    LeitantragProposalConfirmationDialogComponent,
    BeschlussComponent,
    AntrageEmpfehlungComponent,
    OperationsComponent,
    UbgSelectProjectComponent,
    UbgStructureListComponent,
    UbgStructureEditComponent,
    UbgStructureTitleDialogComponent,
    UbgStructureGroupDialogComponent,
    UbgProjectChangeStructureDialogComponent,
    UbgProjectOrgUserComponent,
    UbgProjectStatComponent,
    UbgFilterComponent,
    UbgProposalFallbackComponent,
    UbgOrgUserAdminComponent,
    UbgProposalAssignmentDialogComponent,
    UbgMeineAntraegeComponent,
    UbgTextEditorDialogComponent,
    UbgFassungEditorDialogComponent,
    UbgZifferAssignmentDialogComponent,
    ScrollToBottomDirective,
    UbgProposalEditComponent,
    UbgProposalEditItemComponent,
    UbgProposalGemeinsamDialogComponent,
    UbgPrintConfirmDialogComponent,
    UbgPrintBeschlussDialogComponent,
    UbgProjectAntragComponent,
    UbgProjectAntragLeitantragComponent,
    UbgProjectAntragSonstigeComponent,
    UbgProjectAntragLeitantragZifferComponent,
    UbgProjectAntragLeitantragProposalComponent,
    UbgProjectAntragLeitantragZifferTextComponent,
    UbgProjectAntragLeitantragZifferMarkupComponent,
    UbgProjectAntragEmpfehlungComponent,
    UbgProjectAntragProposalTextComponent,
    UbgProjectAntragProposalMarkupComponent,
    UbgProposalLeitantragEmpfehlungDialogComponent,
    UbgProjectAntragSonstigeProposalComponent,
    UbgProposalSonstigeEmpfehlungDialogComponent,
    AntragBlockComponent,
    AntragBlockTextComponent,
    AntragBlockEmpfehlungComponent,
    AntragBlockBegruendungComponent,
    UbgProposalEmpfehlungUberweisungComponent,
    UbgProjectEditComponent,
    UbgProjectFormComponent,
    UbgOrgUserPasswordsDialogComponent,
    UbgMeineAntraegeItemComponent,
    UbgProjectAntragZuweisungComponent,
    UbgUserSettingsComponent,
    ProposalReasonBottomSheetComponent,
    UbgProjectMarkupComponent,
    UbgThemenListComponent,
    UbgThemaEditDialogComponent,
    UbgProposalSonstigeThemaDialogComponent,
    UbgProjectDashboardComponent,
    UbgProjectDashboardMeineComponent,
    UbgProjectDashboardOpenComponent,
    UbgProjectDashboardStatComponent,
    UbgProjectProtokolleComponent,
    UbgProposalHeaderComponent,
    UbgAntragsstellerDialogComponent,
    UbgProjectDashboardStatPageComponent,
    UbgLeitantragFallbackComponent,
    AntragOrgUserDisplayComponent,
    UbgDeleteAntragDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgScrollbarModule,
    DiffMatchPatchModule,
    ProjectRoutingModule,
    // materials
    ProjectMaterialModule,
    DragDropModule,
    CKEditorModule,
    // ubg components
    UbgContentaUserModule,
    UbgComponentsModule,
    UbgSidebarModule,
    UbgSelectEntityModule,
    UbgLayoutModule,
    UbgFabModule,
    UbgAutocompleteModule,
    UbgConfirmModule,
    UbgStatisticsModule,
  ],
  exports: [
    UbgSelectProjectComponent,
  ],
  entryComponents: [
    UbgStructureTitleDialogComponent,
    UbgStructureGroupDialogComponent,
    UbgProjectChangeStructureDialogComponent,
    UbgProposalAssignmentDialogComponent,
    UbgTextEditorDialogComponent,
    UbgFassungEditorDialogComponent,
    UbgZifferAssignmentDialogComponent,
    UbgProposalGemeinsamDialogComponent,
    UbgPrintConfirmDialogComponent,
    UbgPrintBeschlussDialogComponent,
    UbgProposalLeitantragEmpfehlungDialogComponent,
    UbgProposalSonstigeEmpfehlungDialogComponent,
    UbgOrgUserPasswordsDialogComponent,
    ProjectEditAntragDialogComponent,
    ProposalReasonBottomSheetComponent,
    UbgThemaEditDialogComponent,
    UbgProposalSonstigeThemaDialogComponent,
    UbgAntragsstellerDialogComponent,
    LeitantragProposalConfirmationDialogComponent,
    UbgDeleteAntragDialogComponent,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    DatePipe,
  ],
})
export class ProjectManagementModule { }
