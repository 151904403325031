import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog, MatDialogConfig} from "@angular/material";

import {UbgEmpfehlungType} from '../../../../../model/empfehlung_type';
import {UbgProposal} from '../../../../../model/proposal';
import {UbgProposalService} from '../../../../../services/proposal.service';
//import {UbgEmpfehlung} from '../../../../../model/empfehlung';
//import {UbgEmpfehlungService} from '../../../../../services/empfehlung.service';
import {UbgConfirmComponent} from '../../../../../../ubg_components/confirm/components/confirm.component';
import {UbgProposalStructured} from '../../../../../model/proposal_structured';
import {UbgProposalAssignmentDialogComponent} from '../dialogs/assignment/assignment.component';
import {UBGAntragModel} from '../../../../../model/antrag.model';
import {UbgProposalGemeinsamDialogComponent} from '../dialogs/gemeinsam/gemeinsam.component';
import {UBGAntragService} from '../../../../../services/antrag.service';
import {UbgProposalLeitantragEmpfehlungDialogComponent} from '../antrag/leitantrag/proposal/dialog/empfehlung/empfehlung.component';
import {UbgProposalSonstigeEmpfehlungDialogComponent} from '../antrag/sonstige/proposal/dialog/empfehlung/empfehlung.component';
import {UbgEmpfehlungStructured} from '../../../../../model/empfehlung_structured';
import {UbgPrintConfirmDialogComponent} from '../dialogs/print/print.component';
import {UbgFilterService} from '../../../../../services/filter.service';
import {UbgFilterParams} from '../../../../../model/filter_params';
import {UbgVotum} from '../../../../../model/votum';
import {UbgVotumService} from '../../../../../services/votum.service';
import {UbgBegrundung} from '../../../../../model/begrundung';
import {UbgBegrundungService} from '../../../../../services/begrundung.service';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  @Input() antrag: UBGAntragModel
  @Output() proposalsUpdated = new EventEmitter()
  selectedProposals: Array<UbgProposalStructured> = []
  operations: UbgEmpfehlungType[]
  selectedOperation: UbgEmpfehlungType = null
  empfehlungDisabled: boolean = true
  removeEmpfehlungAllowed: boolean = false
  gemeinsamAllowed: boolean = false
  filterParams: UbgFilterParams = {}
  isPrinting: boolean = false
  voten: Array<UbgVotum> = []

  constructor(private dialog: MatDialog,
//                private empfehlungService: UbgEmpfehlungService,
                private proposalService: UbgProposalService,
                private antragService: UBGAntragService,
                private filterService: UbgFilterService,
                private votumService: UbgVotumService,
                private begruendungService: UbgBegrundungService) {
    this.proposalService.checkedProposals.subscribe((proposals: Array<UbgProposalStructured>) => {
      this.selectedProposals = proposals;
      this.loadVoten();
      this.updateDisabled();
    });
    this.filterService.params.subscribe((params: UbgFilterParams) => {
      let currentParams = JSON.stringify(this.filterParams);
      let newParams = JSON.stringify(params);
      if (newParams !== currentParams) {
        this.filterParams = JSON.parse(newParams);
      }
    });
  }

  ngOnInit() {}
  
  private isLeitantrag(): boolean {
    if (this.antrag === null) {
      return false;
    }
    if (this.antrag.relationships.ubg_antrag_type.data.attributes.name == 'Leitantrag') {
      return true;
    }
    else {
      return false;
    }
  }
  
  loadVoten() {
    this.voten = [];
    for (let i in this.selectedProposals) {
      this.votumService.getByProposal(this.selectedProposals[i].id).then((response: Array<UbgVotum>) => {
        let newVoten: Array<UbgVotum> = [];
        for (let i in this.voten) {
          newVoten.push(this.voten[i]);
        }
        for (let i in response) {
          newVoten.push(response[i]);
        }
        this.voten = newVoten;
        this.updateDisabled();
      });
    }
  }

  empfehlung() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      proposals: this.selectedProposals,
      empfehlungType: null,
      fields: {
        ziffer: null,
        empfehlung: null,
        uberweisung_an: null,
        begruendung: null,
      },
      gemeinsam: false
    }
    let formDialog;
    if (this.isLeitantrag()) {
      formDialog = this.dialog.open(UbgProposalLeitantragEmpfehlungDialogComponent, dialogConfig);
    }
    else {
      formDialog = this.dialog.open(UbgProposalSonstigeEmpfehlungDialogComponent, dialogConfig);
    }
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.addBegruendung(dialogConfig.data['fields']['begruendung']).then(() => {
          if (dialogConfig.data['gemeinsam']) {
            let votum = this.votumService.emptyVotum(dialogConfig.data['empfehlungType']);
              for (let fieldName in dialogConfig.data['fields']) {
                if (dialogConfig.data['fields'][fieldName] !== null 
                      && dialogConfig.data['fields'][fieldName] !== undefined) {
                  votum.attributes[fieldName] = dialogConfig.data['fields'][fieldName];
                }
              }
              votum.relationships.proposals = {
                data: dialogConfig.data['proposals'],
              };
              this.votumService.create(votum).then(() => {
                this.proposalsUpdated.emit();
              }).catch(() => {
                this.proposalsUpdated.emit();
              });
          }
          else {
            let proposalCounter: number = 0;
            let c: number = 0;
            for (let i in this.selectedProposals) {
              let votum = this.votumService.emptyVotum(dialogConfig.data['empfehlungType']);
              for (let fieldName in dialogConfig.data['fields']) {
                if (dialogConfig.data['fields'][fieldName] !== null 
                      && dialogConfig.data['fields'][fieldName] !== undefined) {
                  votum.attributes[fieldName] = dialogConfig.data['fields'][fieldName];
                }
              }
              votum.relationships.proposals = {
                data: [
                  dialogConfig.data['proposals'][i],
                ],
              };
              setTimeout(() => {
                this.votumService.create(votum).then(() => {
                  proposalCounter++;
                  if (proposalCounter == this.selectedProposals.length) {
                    this.proposalsUpdated.emit();
                  }
                }).catch(() => {
                  this.proposalsUpdated.emit();
                });
              }, c * 150);
              c++;
            }
          }
        });
      }
    });
  }
  
  addBegruendung(begruendungText: string) {
    let promise = new Promise((resolve, reject) => {
      if (begruendungText === null || begruendungText === '') {
        resolve();
      }
      else {
        let proposalCounter: number = 0;
        for (let i in this.selectedProposals) {
          let begruendung = new UbgBegrundung;
          begruendung.attributes = {
            title: 'Begründung' + this.selectedProposals[i].attributes.title,
            text: {
              value: begruendungText,
              format: null,
            }
          };
          this.begruendungService.create(begruendung).then((response: UbgBegrundung) => {
            this.proposalService.get(this.selectedProposals[i].id).then((proposal: UbgProposal) => {
              proposal.relationships.begrundung.data = response;
              this.proposalService.update(proposal).then(() => {
                proposalCounter++;
                if (proposalCounter == this.selectedProposals.length) {
                  resolve();
                }
              });
            });
          }).catch(() => {
            reject();
          });
        }
      }
    });
    return promise;
  }
  
  removeEmpfehlung() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten Sie das Votum der Komission wirklich entfernen?',
      hint: this.selectedProposals.length + ' Anträge sind ausgewählt',
    }
    let confirmDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let votenToUpdate: Array<UbgVotum> = [];
        let proposalIds: Array<string>  = [];
        for (let i in this.selectedProposals) {
          proposalIds.push(this.selectedProposals[i].id);
          for (let j in this.voten) {
            for (let k in this.voten[j].relationships.proposals.data) {
              if (this.voten[j].relationships.proposals.data[k].id == this.selectedProposals[i].id) {
                votenToUpdate.push(this.voten[j]);
                break;
              }
            }
          }
        }
        let votumCounter = votenToUpdate.length;
        let c: number = 0;
        for (let i in votenToUpdate) {
          setTimeout(() => {
            this.votumService.removeProposals(votenToUpdate[i], proposalIds).then(() => {
              votumCounter--;
              if (votumCounter == 0) {
                this.proposalsUpdated.emit();
              }
            }).catch(() => {
              this.proposalsUpdated.emit();
            })
          }, c * 150);
          c++;
        }
      }
    });
  }
  
  updateDisabled() {
    if (this.selectedProposals.length == 0) {
      // Nichts ist ausgewählt, nichts darf gemacht werden
      this.empfehlungDisabled = true;
      this.removeEmpfehlungAllowed = false;
      this.gemeinsamAllowed = false;
    }
    else {
      let empfehlungen = [];
      for (let i in this.selectedProposals) {
        for (let j in this.voten) {
          for (let k in this.voten[j].relationships.proposals.data) {
            if (this.voten[j].relationships.proposals.data[k].id == this.selectedProposals[i].id) {
              empfehlungen.push(this.voten[j]);
              break;
            }
          }
        }
      }
      if (empfehlungen.length == 0) {
        // Keiner der Anträge hat eine Empfehlung
        this.empfehlungDisabled = false;
        this.removeEmpfehlungAllowed = false;
        this.gemeinsamAllowed = true;
      }
      else {
        // Ein oder mehr Antrag haben Empfehlungen
        this.empfehlungDisabled = true;
        this.removeEmpfehlungAllowed = true;
        this.gemeinsamAllowed = false;
      }
      if (this.antrag.relationships.ubg_antrag_type.data.attributes.name !== 'Sonstige') {
        this.gemeinsamAllowed = false;
      }
    }
  }
  
  assign() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      proposals: this.selectedProposals,
      users: []
    }
    let formDialog = this.dialog.open(UbgProposalAssignmentDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        let proposalCounter = this.selectedProposals.length;
        for (let i in this.selectedProposals) {
          this.proposalService.get(this.selectedProposals[i].id).then((response: UbgProposal) => {
            response.relationships.zustandigkeit.data = dialogConfig.data['users'];
            response.attributes.zuweisungsdatum = new Date().toJSON().slice(0, 19) + '+0000';
            this.proposalService.update(response).then(() => {
              proposalCounter--;
              if (proposalCounter == 0) {
                this.proposalsUpdated.emit();
              }
            });
          });
        }
      }
    });
  }
  
  addGemeinsam() {
    let proposals: Array<UbgProposal> = [];
    for (let i in this.selectedProposals) {
      this.proposalService.get(this.selectedProposals[i].id).then((response: UbgProposal) => {
        proposals.push(response);
        if (proposals.length == this.selectedProposals.length) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            proposals: proposals,
            empfehlung: null
          };
          let formDialog = this.dialog.open(UbgProposalGemeinsamDialogComponent, dialogConfig);
          formDialog.afterClosed().subscribe((response) => {
            if (response == 1) {
              for (let i in proposals) {
                dialogConfig.data['empfehlung']['relationships']['proposals']['data'].push(proposals[i]);
              }
              this.votumService.update(dialogConfig.data['empfehlung']).then(() => {
                this.proposalsUpdated.emit();
              });
            }
          });
        }
      });
    }
  }
  
  print() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diesen Antrag drucken?',
      mitEmpfehlungen: false,
      mitBegruendungen: false,
      empfehlungenPossible: true,
      begruendungPossible: true,
      mitMarkups: false,
      markupsPossible: true,
    }
    if (!this.isLeitantrag()) {
      dialogConfig.data['empfehlungenPossible'] = false;
      dialogConfig.data['mitEmpfehlungen'] = true;
    }
    if (this.isFiltered()) {
      dialogConfig.data['label'] = 'Möchten Sie die ausgewählten Anträge drucken?';
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        let data = {
          mitEmpfehlungen: dialogConfig.data['mitEmpfehlungen'],
          mitBegruendungen: dialogConfig.data['mitBegruendungen'],
          mitMarkups: dialogConfig.data['mitMarkups'],
          filter: this.filterParams,
        };
        this.antragService.print(this.antrag, data).then((response: any) => {
          let fileName = 'Antrag-' + this.antrag.attributes.letter + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          this.isPrinting = false;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
  printSelected() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie die ausgewählten Anträge drucken?',
      mitEmpfehlungen: false,
      mitBegruendungen: false,
      empfehlungenPossible: true,
      begruendungPossible: true,
      mitMarkups: false,
      markupsPossible: true,
    }
    if (!this.isLeitantrag()) {
      dialogConfig.data['empfehlungenPossible'] = false;
      dialogConfig.data['mitEmpfehlungen'] = true;
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        let data = {
          mitEmpfehlungen: dialogConfig.data['mitEmpfehlungen'],
          mitBegruendungen: dialogConfig.data['mitBegruendungen'],
          mitMarkups: dialogConfig.data['mitMarkups'],
          filter: this.filterParams,
        };
        this.antragService.printSelected(this.antrag, this.selectedProposals, data).then((response: any) => {
          let fileName = 'Antrag-' + this.antrag.attributes.letter + '-Auswahl.pdf';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          this.isPrinting = false;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
  printLeitantrag() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie den Leitantrag drucken?',
      mitEmpfehlungen: false,
      mitBegruendungen: false,
      empfehlungenPossible: false,
      begruendungPossible: false,
    }
    let formDialog = this.dialog.open(UbgPrintConfirmDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        this.antragService.printLeitantrag(this.antrag).then((response: any) => {
          this.isPrinting = false;
          let fileName = 'Leitantrag-' + this.antrag.attributes.letter + '.pdf';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
  isFiltered() {
    for (let key in this.filterParams) {
      if (this.filterParams[key] !== null 
            && this.filterParams[key] !== ''
            && this.filterParams[key] !== false) {
              return true;
      }
    }
    return false;
  }
  
  msword() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      question: 'Möchten sie den Text in MS Word exportieren?',
    };
    let formDialog = this.dialog.open(UbgConfirmComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.isPrinting = true;
        this.antragService.msWord(this.antrag, {}).then((response: any) => {
          let fileName = 'Antrag-' + this.antrag.attributes.letter + '.doc';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          this.isPrinting = false;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        });
      }
    });
  }
  
  userCanAssign() {
    let canAssign: boolean = true;
    if (this.selectedProposals.length == 0) {
      canAssign = false;
    }
    for (let i in this.selectedProposals) {
      if (this.selectedProposals[i].meta === undefined) {
        canAssign = false;
      }
      else if (!this.selectedProposals[i].meta.permissions.assign) {
        canAssign = false;
      }
    }
    return canAssign;
  }
  
//  printBeschluss() {
//    let dialogConfig = new MatDialogConfig();
//    dialogConfig.autoFocus = true;
//    dialogConfig.data = {
//      mitMarkups: false,
//    }
//    let formDialog = this.dialog.open(UbgPrintBeschlussDialogComponent, dialogConfig);
//    formDialog.afterClosed().subscribe((response) => {
//      if (response == 1) {
//        this.antragService.printBeschluss(this.antrag, {
//          mitMarkups: dialogConfig.data['mitMarkups'],
//        }).then((response: any) => {
//          let fileName = 'Beschluss-' + this.antrag.attributes.letter + '.pdf';
//          const a = document.createElement('a');
//          document.body.appendChild(a);
//          const url = window.URL.createObjectURL(response);
//          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//            window.navigator.msSaveOrOpenBlob(response, fileName);
//          } 
//          else {
//            a.href = url;
//            a.download = fileName;
//            a.click();
//          }
//        });
//      }
//    });
//  }
  
}