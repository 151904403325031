/**
 * Login-Component
 * Attila Németh, UBG
 * 23.10.2018
 */
 
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogConfig} from "@angular/material";

import {ContentaUser} from '../../model/user';
import {UbgContentaUserService} from '../../services/user.service';

@Component({
  selector: 'ubg-contenta-user-login',
  templateUrl: './login.component.html',
})
export class ContentaUserLoginComponent implements OnInit {

  @Input() register: boolean = true

  user: ContentaUser
  loginParams: {
    username: string
    password: string
  }
    
  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private service: UbgContentaUserService) {
    this.loginParams = {
      username: '',
      password: '',
    };
  }
  
  ngOnInit() {
    let refreshCookie = this.service.getRefreshCookie();
    if (refreshCookie !== null && !this.service.isLoggedIn) {
      this.service.refresh(refreshCookie).catch(() => {
        this.snackbar.open('Sie müssen sich anmelden', '', {
          duration: 12000,
        });
      });
    }    
  }
  
  userLogin() {
    this.service.login(this.loginParams.username, this.loginParams.password).then(() => {
      this.snackbar.open('Sie haben sich angemeldet', '', {
        duration: 5000,
      });
    }).catch(() => {
      this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', '', {
        duration: 12000,
      });
    });
  }
  
  userKeyup(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.userLogin();
    }
  }
  
//  userForgot() {
//    const dialogConfig = new MatDialogConfig();
//    dialogConfig.autoFocus = true;
//    dialogConfig.data = {
//      email: '',
//    };
//    let formDialog = this.dialog.open(ContentaUserDialogForgotComponent, dialogConfig);
//    formDialog.afterClosed().subscribe((response) => {
//      if (response == 1) {
//        this.service.passwordForgot(dialogConfig.data.email).then(() => {
//          this.snackbar.open('Sie bekommen in kurze eine E-Mail', '', {
//            duration: 5000,
//          });
//        }).catch(() => {
//          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal, später', '', {
//            duration: 8000,
//          });
//        });
//      }
//    });
//  }

  /**
   * Weiterleitung an CDUplus, wo der Benutzer sich anmelden kann. 
   * Nach der Anmeldung wird zu /cduplus/auth weiterleitet s. das Komponent ContentaUserDialogCduplusComponent
   * 
   * 07.01.2019
   */
//  userCduplusLogin() {
//    const state = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
//    let d = new Date();
//    d.setTime(d.getTime() + 600000);
//    let expiresString = "expires="+ d.toUTCString();
//    document.cookie = 'cduplus_state=' + state + ';' + expiresString + ";path=/";
//    let url = 'https://www.cduplus.cdu.de/oauth2/authorize?response_type=code&scope=profile+offline_access+cduplus_auth+email+openid&client_id=' + environment.cduplus.clientId + '&state=' + state;
//    document.location.href = url;
//  }
  
}