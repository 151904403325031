/**
 * UBG-Projekt-Tokens
 * 
 * Attila Németh, UBG
 * 08.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgProject} from '../model/project';
import {UbgAccessToken} from '../model/access_token';
 
@Injectable({
  providedIn: 'root'
})
export class UbgAccessTokenService {
  
  constructor(private contenta: UbgContentaService) {}
  
  getByProject(project: UbgProject) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/org/users/' + project.id + '/passwords';
      //this.contenta.get('api/ubg_access_token/ubg_access_token').then((response: {
      this.contenta.get(path).then((response: {
        data: Array<UbgAccessToken>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(token: UbgAccessToken) {
    let promise = new Promise((resolve, reject) => {
      let path = 'api/ubg_access_token/ubg_access_token';
      this.contenta.post(path, {
        data: token
      }).then((response: {
        data: UbgAccessToken
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  delete(token: UbgAccessToken) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_access_token/ubg_access_token/' + token.id).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}