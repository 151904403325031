/**
 * Banner-Aktionen
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {Component} from '@angular/core';

@Component({
  selector: 'ubg-banner-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class UbgBannerActionsComponent {
  
  constructor() {}
  
}