import { Injectable } from '@angular/core';

import { AntragTypeModel } from '../../model/taxonomies/antrag_type.model';

import {UbgContentaService} from '../../../contenta/services/contenta.service';

@Injectable({
  providedIn: 'root'
})
export class AntragTypeService {

  constructor(private contenta: UbgContentaService) {
  }

  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/antrag_type').then((response: {
        data: Array<AntragTypeModel>
      }) => {
        resolve(response.data);
      }).then(() => {
        reject();
      });
    });
    return promise;
  }

}