/**
 * Mandant-Service
 * 
 * Attila Németh, UBG
 * 09.04.2019
 */
 
import {Injectable} from '@angular/core';
  
import {UbgContentaService} from '../../contenta/services/contenta.service';
import {UbgMandant} from '../model/mandant';
 
@Injectable({
  providedIn: 'root'
})
export class UbgMandantService {
  
  constructor(private contenta: UbgContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_mandant/ubg_mandant').then((response: {
        data: Array<UbgMandant>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}