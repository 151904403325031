/**
 * Projekt
 * 
 * Attila Németh, UBG
 * 04.04.2019
 */
 
import {UbgPriorityStructure} from './priority_structure';
import {UbgMandant} from './mandant';
import {UBGAntragModel} from './antrag.model';
 
export class UbgProject {
  id?: string
  type: string = 'ubg_project--ubg_project'
  attributes: {
    title: string,
    start_date: string,
    end_date: string,
    start_voting_date: string,
    end_voting_date: string,
    description: string,
    ergebnis_anzeigen: string
  }
  relationships: {
    priority_structure: {
      data: UbgPriorityStructure,
    }
    mandant:{
      data: UbgMandant
    }
    antrage:{
      data:  Array<UBGAntragModel>
    }
  }
  
}